// react
import React from 'react';
import moment from 'moment';
import { InputNumber } from 'primereact/inputnumber';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Badge } from 'reactstrap';

export default function SubTable({ row: line, AddChangeMovements, status }) {

    const RecibidoTemplate = (row) => {
        if (status === 'finished' || row.type === 'delivery' || row.delivered_qty === row.confirmed_qty) {
            return row.confirmed_qty;
        }
        return <InputNumber value={(row.confirmed_qty ? row.confirmed_qty : 0)} min={0} onValueChange={(val) => AddChangeMovements(row, 'confirmed_qty', val.value, line)} showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-info" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" size={1} />
    }

    return (
        <div className="card">
            <DataTable value={line.movements} className="p-datatable-sm">
                <Column header="Movimiento" body={(e) => <Badge color={e.type === 'delivery' ? 'info' : 'warning'}>{e.type === 'delivery' ? 'ENTREGA' : 'DEVOLUCIÓN'}</Badge>} />
                <Column field="delivered_qty" header="Cant. Enviada" />
                <Column header="Fecha" body={(e) => moment(e.delivered_date).format('DD/MM/YY hh:mm A')} />
                <Column header="Responsable" body={(e) => `${e.delivered_by.first_name} ${e.delivered_by.last_name}`} />
                <Column header="Cant. Recibida" body={RecibidoTemplate} />
                <Column header="Fecha" body={(e) => (e.confirmed_date && moment(e.confirmed_date).format('DD/MM/YY hh:mm A'))} />
                <Column header="Responsable" body={(e) => (e.confirmed_by && `${e.confirmed_by.first_name} ${e.confirmed_by.last_name}`)} />
            </DataTable>
        </div>
    );
}