import React, { useState } from 'react';
import { Dropdown } from 'primereact/dropdown';

export default function UpdatePriority({ value, options, onChange }) {

    const [val, setval] = useState(value);

    const handleChange = (value) => {
        setval(value);
        return onChange(value.value);
    };

    return <Dropdown value={val.value} options={options} onChange={handleChange} style={{ width: '90%' }} optionLabel="label" optionValue="value" />;
}
