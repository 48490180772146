// react
import React, { useState, useEffect } from "react";
// third-party
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "reactstrap";
import { DeleteItem, getDispatchesById, getPurchaseOrderLines, CreateItem, UpdateItem, UpdateItems } from "../../../store/directusSdk";
import swal from "sweetalert";
import { toast } from "react-toastify";
import Select from "react-select";
import { InputNumber } from "primereact/inputnumber";
//4216
export default ({ dispatch, purchaseOrder, updatePhater, currentUser }) => {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [itemsCopy, setItemsCopy] = useState([]);
    const [item, setItem] = useState({});
    const [maxQty, setmaxQty] = useState(0);
    const [qty, setQty] = useState(1);
    const [options, setOptions] = useState([]);
    const [disabled, setDisabled] = useState(true);

    const update = () => {
        dispatch &&
            getDispatchesById(dispatch.id)
                .then((data) => {
                    console.log(data);
                    const accItems = data.items.map((item) => ({
                        id: item.id,
                        idLine: item.item_id ? item.item_id.id : "ERROR",
                        qty: item.qty,
                        code: item.item_id ? item.item_id.code : "ERROR ID:" + item.id,
                        name: item.item_id ? item.item_id.name : "ERROR",
                        qtyOrdered: item.item_id ? item.item_id.qty : "ERROR",
                    }));

                    setItems(accItems);
                    setItemsCopy(accItems.map((item) => ({ id: item.id, qty: item.qty })));
                })
                .catch((error) => toast.error(`¡Error!, ${error.message} catch- TableEditDispatch: 0001 `))
                .finally(() => setLoading(false));

        purchaseOrder &&
            getPurchaseOrderLines(purchaseOrder.id)
                .then(setOptions)
                .catch((error) => toast.error(`¡Error!, ${error.message} catch- TableEditDispatch: 0002 `))
                .finally(() => setLoading(false));
    };

    useEffect(() => {
        //   setLoading(true);
        purchaseOrder && dispatch && update();
    }, [dispatch.id]);

    const buttonTemplate = (row) => (
        <div className="col-md-5">
            <Button color="danger" onClick={() => deleteItems(row)}>
                <i className="fas fa-trash"></i>
            </Button>
        </div>
    );

    const deleteItems = (row) =>
        swal({
            title: `Eliminar Ítem de Despacho Nº:  ${purchaseOrder.code}-${dispatch.n}`,
            text: `¿Desea eliminar código ${row.code} de este Despacho?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((value) => {
            if (value) {
                setLoading(true);
                return DeleteItem("log_dispatches_items", row.id)
                    .then(async () => {
                        await updatePhater(purchaseOrder.id);
                        update();
                        toast.success(`¡El producto con el código:${row.code} se ha borrado de la lista!`);
                    })
                    .catch((error) => swal("Error!", error.message, "error"))
                    .finally(() => setLoading(false));
            }
        });

    const mountItemResult = (e) => {
        const max = e && e.qty - e.dispatched >= 0 ? e.qty - e.dispatched : 0;
        setmaxQty(max);
        setQty(max);
        setItem(e);
    };

    const addItems = () =>
        swal({
            title: `Agregar Ítem de Despacho Nº: ${purchaseOrder.code}-${dispatch.n}`,
            text: `¿Desea agregar código ${item.code} de este Despacho?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((value) => {
            if (value) {
                setLoading(true);
                const itemExist = items.find((i) => i.codeProdcut === item.code);
                if (itemExist) {
                    if (qty > itemExist.qty) {
                        return toast.error(`¡Error!, La cantidad introducida para el producto código:${item.codeProdcut} excede la solicitada`);
                    }
                    let idItem = item.id_Items.find((e) => e.dispatch_id === dispatch.id).id;
                    if (!idItem) {
                        return toast.error(`¡Error!, No se ha encontrado el, id del ítem `);
                    }

                    return UpdateItem("log_dispatches_items", idItem, { qty: qty + itemExist.qty })
                        .then(() => {
                            toast.success("¡Se ha Agregado exitosamente!");
                            setQty(0);
                            updatePhater(purchaseOrder.id);
                            update();
                        })
                        .catch((error) => toast.error(`¡Error!, ${error.message}`))
                        .finally(() => setLoading(false));
                }

                CreateItem("log_dispatches_items", { qty, item_id: item.id, dispatch_id: dispatch.id })
                    .then(() => {
                        toast.success("¡Se ítem Agregado exitosamente!");
                        setQty(0);
                        updatePhater(purchaseOrder.id);
                        update();
                    })
                    .catch((error) => toast.error(`¡Error!, ${error.message}`))
                    .finally(() => setLoading(false));
            }
        });

    const header = ["Administrator", "Admin ICA", "Almacenista ICA", "VendedorICA-Logistica"].includes(currentUser.role.name) && (
        <div className="row">
            <div className="col-md-8">
                <Select
                    className="basic-single"
                    place
                    isDisabled={loading || !( options.length > 0)}
                    isLoading={loading}
                    name="color"
                    onChange={(e) => mountItemResult(e)}
                    options={options}
                    placeholder={
                        options.length > 0
                            ? `Listado de productos para el Despacho Nº ${purchaseOrder.code}-${dispatch.n}`
                            : `El Despacho Nº ${purchaseOrder.code}-${dispatch.n}, tiene todos sus productos seleccionados`
                    }
                />
            </div>
            <div className="col-md-2">
                <InputNumber
                    disabled={loading || !( options.length > 0) || !item.code}
                    width="50px"
                    id="exampleNumber"
                    min={1}
                    max={maxQty}
                    value={qty}
                    onChange={(e) => setQty(e.value)}
                    showButtons
                    buttonLayout="horizontal"
                    decrementButtonClassName="p-button-secondary"
                    incrementButtonClassName="p-button-info"
                    incrementButtonIcon="pi pi-plus"
                    decrementButtonIcon="pi pi-minus"
                    size={5}
                />
            </div>
            <div className="col-md-2">
                <Button color="info" disabled={loading || !item.code || !( options.length > 0)} onClick={() => addItems()}>
                    <i className="fas fa-save mr-2"></i>
                    Agregar
                </Button>
            </div>
        </div>
    );

    const checkedUpdate = () => {
        if (items.length < 1 || itemsCopy.length < 1) return setDisabled(true);
        for (let i = 0; i < items.length; i++) {
            if (items[i].qty !== itemsCopy[i].qty) return setDisabled(false);
        }
        return setDisabled(true);
    };

    const changeQtys = (id, qty) => {
        const newItems = [...items];
        const i = newItems.findIndex((item) => item.id === id);
        if (i >= 0) {
            newItems[i].qty = qty;
            setItems([...newItems]);
        }
        checkedUpdate();
    };

    const updateItemsQty = () => {
        const changes = [];
        for (let i = 0; i < items.length; i++) {
            if (items[i].qty !== itemsCopy[i].qty) changes.push({ id: items[i].id, qty: items[i].qty });
        }
        if (changes.length > 0) {
            UpdateItems("log_dispatches_items", changes)
                .then(() => {
                    toast.success("¡Items actualizados exitosamente!");
                    update();
                })
                .catch((error) => toast.error(`¡Error!, ${error.message}`))
                .finally(() => setLoading(false));
        }
    };

    return (
        <div className="orders-subtable">
            {loading ? (
                <div className="div-loading m-5 p-5" />
            ) : (
                <>
                    <DataTable value={items} className="p-datatable-sm mb-3" removableSort rowHover header={dispatch.status !== "R" && header}>
                        <Column
                            field="code"
                            header="Código"
                            sortable
                            filterPlaceholder="Todos"
                            filterHeaderClassName="p-inputtext-sm"
                            style={{ width: "14%" }}
                        />
                        <Column
                            field="name"
                            header="Producto"
                            sortable
                            filterPlaceholder="Todos"
                            filterHeaderClassName="p-inputtext-sm"
                            style={{ width: "37%" }}
                        />
                        <Column
                            field="qty"
                            header="Cantidad"
                            sortable
                            style={{ textAlign: "center", width: "12%" }}
                            filterType="number"
                            filterPlaceholder="Todos"
                            body={(row) => {
                                if (dispatch.status === "R" || !["Administrator", "Admin ICA", "Almacenista ICA", "VendedorICA-Logistica"].includes(currentUser.role.name)) {
                                    return row.qty;
                                }

                                return (
                                    <InputNumber
                                        disabled={loading}
                                        width="50px"
                                        id="exampleNumber"
                                        min={1}
                                        max={row.qtyOrdered}
                                        value={row.qty}
                                        onChange={(e) => changeQtys(row.id, e.value)}
                                        showButtons
                                        buttonLayout="horizontal"
                                        decrementButtonClassName="p-button-secondary"
                                        incrementButtonClassName="p-button-info"
                                        incrementButtonIcon="pi pi-plus"
                                        decrementButtonIcon="pi pi-minus"
                                        size={5}
                                    />
                                );
                            }}
                            filterHeaderClassName="p-inputtext-sm"
                        />
                        {dispatch.status !== "R" && ["Administrator", "Admin ICA", "Almacenista ICA", "VendedorICA-Logistica"].includes(currentUser.role.name) && (
                            <Column header="Acciones" style={{ width: "10%" }} body={buttonTemplate} />
                        )}
                    </DataTable>
                    <Button
                        color="primary"
                        onClick={() => updateItemsQty()}
                        disabled={disabled}
                        className="mt-3"
                        hidden={dispatch.status === "R" || !["Administrator", "Admin ICA", "Almacenista ICA", "VendedorICA-Logistica"].includes(currentUser.role.name)}
                    >
                        <i className="fas fa-edit mr-2" />
                        Editar
                    </Button>
                </>
            )}
        </div>
    );
};
