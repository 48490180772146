import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DataView } from "primereact/dataview";
import { Table } from "reactstrap";
import { ProgressBar } from "primereact/progressbar";
import { Button } from "primereact/button";
import Alert from "sweetalert2";
import { toast } from "react-toastify";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import PageHeader from "../shared/PageHeader";
import { CreateItem, getProjectTasks, UpdateItem } from "../../store/directusSdk";
import "./corusel.css";
import ActionsButtom from "./ActionsButtom";
import { FormattedDifference, msToTime } from "../shared/utilities";

const ListProjectTasks = ({ currentUser }) => {
    const [projects, setProjects] = useState(null);
    const [loading, setLoading] = useState(true);
    const actors = ["Administrator", "Ingeniero ICA", "Admin ICA", "Ingeniero AP", "Admin AP", "Vendedor ICA", "Vendedor AP",  "VendedorICA-Logistica"];

    useEffect(() => {
        searchData();

        const interval = setInterval(() => searchData(), 120000);

        return () => clearInterval(interval);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(function setupListener() {
        let mounted = true;

        function handleResize() {
            if (!document.hidden) {
                return searchData()
                    .then((proj) => mounted && setProjects(proj))
                    .finally(() => mounted && setLoading(false));
            }
        }
        window.addEventListener("visibilitychange", handleResize);

        return function cleanupListener() {
            window.removeEventListener("visibilitychange", handleResize);
            mounted = false;
        };
    });

    const searchData = () => {
        setLoading(true);
        return getProjectTasks()
            .then(setProjects)
            .finally(() => setLoading(false));
    };
    const searchIcon = (status) => {
        switch (true) {
            case status === "Pendiente":
                return "clock";
            case status === "Pausada":
                return "pause";
            case status === "En Proceso":
                return "cog";
            default:
                return "check";
        }
    };

    const renderStatus = (status) => {
        switch (true) {
            case status === "Pendiente":
                return "secondary";
            case status === "Pausada":
                return "warning";
            case status === "En Proceso":
                return "success";
            default:
                return "info";
        }
    };

    const IniTask = (task) => {
        return UpdateItem("activities", task.id, {
            iniciadapor: currentUser.id,
            hour_ini: moment().format("YYYY-MM-DD HH:mm:ss"),
            start: moment().format("YYYY-MM-DD HH:mm:ss"),
            allDay: false,
            status: "En Proceso",
            end: moment().add(moment.duration(task.time_plan)).format("YYYY-MM-DD HH:mm:ss"),
        })
            .then(async () => {
                await searchData();
                return toast.success("Actividad Iniciada con exito!");
            })
            .catch((e) => toast.error("¡Error!, " + e.message));
    };

    const ReiniTask = (task, comment) => {
        const timeUsed = FormattedDifference(moment(task.hour_pausada), moment(task.hour_ini));
        const timeDuration = moment.duration(task.time_plan) - moment.duration(timeUsed);
        const timePlanNew = msToTime(timeDuration < 0 ? 0 : timeDuration);
        const actividad = {
            status: "Terminada",
            hour_fin: task.hour_pausada,
            time_real: timeUsed,
            terminadapor: currentUser.id,
            end: moment(task.hour_pausada).format("YYYY-MM-DD HH:mm:ss"),
        };

        if (timeDuration > 0) {
            actividad.time_plan = timeUsed;
        }
        return UpdateItem("activities", task.id, actividad)
            .then(async () => {
                await CreateItem("activities", {
                    iniciadapor: currentUser.id,
                    hour_ini: moment().format("YYYY-MM-DD HH:mm:ss"),
                    start: moment().format("YYYY-MM-DD HH:mm:ss"),
                    allDay: false,
                    status: "En Proceso",
                    engproject_id: task.engproject_id.id,
                    time_plan: timePlanNew,
                    title: "C- " + task.title,
                    resources: task.resources.map((r) => ({ user_id: r.id })),
                    end: moment().add(moment.duration(timePlanNew)).format("YYYY-MM-DD HH:mm:ss"),
                    comment,
                });
                toast.success("Actividad Reiniciada con exito!");
                return searchData();
            })
            .catch((e) => toast.error("¡Error!, " + e.message));
    };

    const PauseTask = (task, comment) => {
        return UpdateItem("activities", task.id, {
            status: "Pausada",
            pausadapor: currentUser.id,
            hour_pausada: moment().format("YYYY-MM-DD HH:mm:ss"),
            comment,
        })
            .then(async () => {
                await searchData();
                return toast.success("Actividad Pausada con exito!");
            })
            .catch((e) => toast.error("¡Error!, " + e.message));
    };

    const FinTask = (task, comment) => {
        const time_real = FormattedDifference(moment(), moment(task.hour_ini));
        return UpdateItem("activities", task.id, {
            terminadapor: currentUser.id,
            hour_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
            status: "Terminada",
            end: moment().format("YYYY-MM-DD HH:mm:ss"),
            time_real,
            comment: task.comment && task.comment.length > 0 ? `Reinicio:${task.comment} \n / Fin: ${comment}` : comment,
        })
            .then(async () => {
                await searchData();
                return toast.success("Actividad Terminada con exito!");
            })
            .catch((e) => toast.error("¡Error!, " + e.message));
    };

    const confirm = (title, text, confirmText, comment, succesTitle, succesText, action) => {
        const bodyAlert = {
            title,
            text,
            showCancelButton: true,
            confirmButtonText: confirmText,
            cancelButtonText: "Cancelar",
            reverseButtons: true,
            confirmButtonColor: "#004FBD",
            icon: "question",
            showLoaderOnConfirm: true,

            allowOutsideClick: () => !Alert.isLoading(),
        };

        if (comment) {
            bodyAlert.input = "textarea";
            bodyAlert.inputLabel = "Indique un comentario (Opcional)";
            bodyAlert.inputPlaceholder = "Comentario...";
            bodyAlert.preConfirm = (cmt) => {
                action(cmt);
            };
        } else {
            bodyAlert.preConfirm = () => {
                action();
            };
        }

        Alert.fire(bodyAlert).then((result) => {
            if (result.isConfirmed) {
                return Alert.fire(succesTitle, succesText, "success");
            }
            return null;
        });
    };

    const breadcrumb = [
        { title: "Home", url: "/" },
        { title: "Tareas", url: "/tasks-manager" },
        { title: "Tareas Pendientes", url: "" },
    ];

    const itemTemplate = (project) => {
        return (
            <div className="p-col-12 p-lg-6 p-xl-4">
                <div className="product-grid-item card">
                    <div className="product-grid-item-content">
                        <Link to={`/tasks-manager/engproject/${project.id}`}>
                            <div className="product-name">{project.code}</div>
                            <div className="product-description">{project.name}</div>
                        </Link>
                    </div>

                    <ProgressBar value={project.progress} />
                    <Table responsive className="table-task mt-3">
                        <thead>
                            <tr>
                                <th width="70%">Tarea</th>
                                <th width="9%">Plan</th>
                                <th width="9%">Estado</th>
                                {actors.includes(currentUser.role.name) && <th width="12%">Acciones</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {project.activities.map((e, i) => (
                                <tr key={i}>
                                    <td>
                                        <b>{e.title}</b>
                                        <br />
                                        {!e.resources.length > 0 ? "Sin Definir" : e.resources.map((r) => r.name + ", ")}
                                    </td>
                                    <td align="center">{e.time_plan}</td>
                                    <td align="center">
                                        <Button title={e.status} className={`p-button-rounded p-button-${renderStatus(e.status)}`}>
                                            <i className={`pi pi-${searchIcon(e.status)}`} style={{ fontSize: "1.3em" }} />
                                        </Button>
                                    </td>
                                    {actors.includes(currentUser.role.name) && (
                                        <td>
                                            <div className="p-d-flex p-jc-center">
                                                {e.status === "Pendiente" && (
                                                    <ActionsButtom
                                                        title="Iniciar"
                                                        className="p-button-rounded p-mr-2"
                                                        icon="pi pi-play"
                                                        onClick={() =>
                                                            confirm(
                                                                "Iniciar Actividad",
                                                                "¿Desea iniciar la actividad " + e.title + "?",
                                                                "Iniciar",
                                                                false,
                                                                "¡Actividad Iniciada Correctamente!",
                                                                "Actividad " + e.title + " iniciada.",
                                                                () => IniTask(e)
                                                            )
                                                        }
                                                    />
                                                )}
                                                {e.status === "Pausada" && (
                                                    <ActionsButtom
                                                        title="Reiniciar"
                                                        className="p-button-rounded p-mr-2"
                                                        icon="pi pi-play"
                                                        onClick={() =>
                                                            confirm(
                                                                "Reiniciar Actividad",
                                                                "¿Desea Reiniciar la actividad " + e.title + "?",
                                                                "Reiniciar",
                                                                true,
                                                                "¡Actividad Reiniciada Correctamente!",
                                                                "Actividad " + e.title + " reiniciada.",
                                                                () => ReiniTask(e)
                                                            )
                                                        }
                                                    />
                                                )}
                                                {e.status === "En Proceso" && (
                                                    <ActionsButtom
                                                        title="Pausar"
                                                        className="p-button-rounded p-button-warning p-mr-2"
                                                        icon="pi pi-pause"
                                                        onClick={() =>
                                                            confirm(
                                                                "Pausar Actividad",
                                                                "¿Desea Pausar la actividad " + e.title + "?",
                                                                "Pausar",
                                                                true,
                                                                "¡Actividad Pausada Correctamente!",
                                                                "Actividad " + e.title + " pausada.",
                                                                (comment) => PauseTask(e, comment)
                                                            )
                                                        }
                                                    />
                                                )}
                                                {e.status === "En Proceso" && (
                                                    <ActionsButtom
                                                        title="Finalizar"
                                                        className="p-button-rounded p-button-success"
                                                        icon="pi pi-check"
                                                        onClick={() =>
                                                            confirm(
                                                                "Finalizar Actividad",
                                                                "¿Desea Finalizar la actividad " + e.title + "?",
                                                                "Finalizar",
                                                                true,
                                                                "¡Actividad Finalizada Correctamente!",
                                                                "Actividad " + e.title + " finalizada.",
                                                                (comment) => FinTask(e, comment)
                                                            )
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Tareas por Proyecto - Ingeniería y Controles Automatizados</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="container-fluid">
                <div className="dataview-demo">
                    <div className="card">
                        <DataView
                            value={projects}
                            layout="grid"
                            itemTemplate={itemTemplate}
                            paginator
                            rows={3}
                            loading={loading}
                            emptyMessage="No Se Encontraron Registros."
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ListProjectTasks;
