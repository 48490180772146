import React, { Component, Fragment } from 'react';
import { CSVReader } from 'react-papaparse';
import { toast } from 'react-toastify';
import moment from 'moment';
import { getUsersByEmail, getEngprojectByCode } from '../../store/directusSdk';

export default class ImportCsvShared extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            header: [],
        };
    }

    handleOnDrop = async (data) => {
        const header = data.shift();
        this.setState({ errors: [], header, data });

        const name = header.data.findIndex((e) => e === 'nombre');
        if (name === -1) {
            return toast.error('¡Error, falta la columna nombre!');
        }
        const plan = header.data.findIndex((e) => e === 'planificado');
        if (plan === -1) {
            return toast.error('¡Error, falta la columna planificado!');
        }
        const proyectos = header.data.findIndex((e) => e === 'proyectos');
        if (proyectos === -1) {
            return toast.error('¡Error,  falta la columna proyectos!');
        }
        const resources = header.data.findIndex((e) => e === 'recursos');
        if (resources === -1) {
            return toast.error('¡Error,  falta la columna recursos!');
        }

        const inicio = header.data.findIndex((e) => e === 'inicio');
        if (inicio === -1) {
            return toast.error('¡Error,  falta la columna inicio!');
        }
        const allDay = header.data.findIndex((e) => e === 'dia');

        const { uploadTask, setErrorsEmails, setErrorsEngprojects } = this.props;
        const tasks = [];
        const emails = [];
        const prys = [];
        data.forEach((e) => {
            if (e.data.length > 2) {
                const resourcess = [];
                const engprojects = [];

                if (e.data[resources].length > 0) {
                    const rows = e.data[resources].split('_');
                    if (rows.length > 1) {
                        rows.forEach((r) => {
                            if (r !== '') {
                                const email = r.trim();
                                if (!emails.includes(email)) {
                                    emails.push(email);
                                }
                                resourcess.push(email);
                            }
                        });
                    } else {
                        const email = e.data[resources].trim();
                        if (!emails.includes(email)) {
                            emails.push(email);
                        }
                        resourcess.push(email);
                    }
                }

                if (e.data[proyectos].length > 0) {
                    const rows = e.data[proyectos].split('_');
                    if (rows.length > 1) {
                        rows.forEach((r) => {
                            if (r !== '') {
                                const pry = r.trim();
                                if (!prys.includes(pry)) {
                                    prys.push(pry);
                                }
                                engprojects.push(pry);
                            }
                        });
                    } else {
                        const pry = e.data[proyectos].trim();
                        if (!prys.includes(pry)) {
                            prys.push(pry);
                        }
                        engprojects.push(pry);
                    }
                }

                const task = {
                    title: e.data[name],
                    resources: resourcess,
                    engprojects,
                    start: moment(e.data[inicio], "DD/MM/YYYY HH:mm:ss").format('YYYY-MM-DD HH:mm:ss'),
                    time_plan: e.data[plan],
                    end: moment(e.data[inicio], "DD/MM/YYYY HH:mm:ss").add(moment.duration(e.data[plan])).format('YYYY-MM-DD HH:mm:ss')
                };
                if (e.data[allDay] !== -1) {
                    task.allDay = e.data[allDay] === 'si';
                }
                tasks.push(task);
            }
        });

        const err = [];
        await getEngprojectByCode(prys)
            .then((engprojects) => {
                tasks.map((p) => {
                    const projects = [];
                    p.engprojects.forEach((r) => {
                        r.trim();
                        const pry = engprojects.find((p) => p.code === r);
                        if (pry) {
                            projects.push(pry);
                        } else if (!err.includes(r)) {
                            err.push(r);
                        }
                    });
                    p.engprojects = projects;
                    return p;
                });
            })
            .catch((err) => toast.error('¡Error!, ' + err.message));

        await setErrorsEngprojects(err);
        const err2 = [];
        await getUsersByEmail(emails)
            .then((users) => {
                tasks.map((t) => {
                    const recursos = [];
                    t.resources.forEach((r) => {
                        r.trim();
                        const usr = users.find((user) => user.email === r + '@automation-power.com' || user.email === r + '@icautomatizados.com');
                        if (usr) {
                            recursos.push(usr);
                        } else {
                            if (!err2.includes(r + '@automation-power.com')) {
                                err2.push(r + '@automation-power.com', r + '@icautomatizados.com');
                            }
                        }
                    });
                    t.resources = recursos;
                    return t;
                });
            })
            .catch((err) => toast.error('¡Error!, ' + err.message));

        await setErrorsEmails(err2);
        await uploadTask(tasks);
    };

    handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
    };

    handleOnRemoveFile = (data) => {
        return this.setState({ errors: [] });
    };

    render() {
        const { errors, header, data } = this.state;
        const { loading } = this.props;
        return (
            <Fragment>
                {errors.length > 0 && (
                    <div className="alert alert-danger">
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Error</th>
                                </tr>
                            </thead>
                            <tbody>
                                {errors.map((e, i) => (
                                    <tr key={i}>
                                        <th>{e.value}</th>
                                        <th>{e.message}</th>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                <div style={{ width: '100%', height: 200 }}>
                    <CSVReader
                        onDrop={this.handleOnDrop}
                        onError={this.handleOnError}
                        addRemoveButton
                        removeButtonColor='#659cef'
                        onRemoveFile={this.handleOnRemoveFile}
                    >
                        <span>Arrastra y Suelta el archivo CSV aquí o haz clic para cargarlo.</span>
                    </CSVReader>
                </div>
            </Fragment>
        );
    }
}