import React, { Component, Fragment } from "react";
import { CSVReader } from "react-papaparse";
import { toast } from "react-toastify";
import * as XLSX from "xlsx/xlsx.mjs";

export default class ImportCsvShared extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            header: [],
        };
    }

    //Se encarga de hacer el procesamiento de la información en caso de que el archivo sea un CSV
    caseCsc = (data) => {
        const header = data.shift();
        const code = header.data.findIndex((e) =>
            ["codigo", "code", "nmerodeartculoscode", "cdigo"].includes(
                e
                    .toLocaleLowerCase()
                    .trim()
                    .replace(/[\W_]/g, "")
                    .replace(/\s+/g, "")
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
            )
        );

        if (code === -1) {
            return toast.error("¡Error, Debe indicar la columna Codigo!");
        }

        const sku = header.data.findIndex((e) =>
            ["sku", "cdigodefabricantesku"].includes(
                e
                    .toLocaleLowerCase()
                    .trim()
                    .replace(/[\W_]/g, "")
                    .replace(/\s+/g, "")
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
            )
        );
        if (sku === -1) {
            return toast.error("¡Error, Debe indicar la columna SKU!");
        }

        const description = header.data.findIndex((e) =>
            ["descripcion", "descripcin", "name"].includes(
                e
                    .toLocaleLowerCase()
                    .trim()
                    .replace(/[\W_]/g, "")
                    .replace(/\s+/g, "")
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
            )
        );
        if (description === -1) {
            return toast.error("¡Error, Debe indicar la columna Descripción!");
        }

        const category = header.data.findIndex((e) =>
            ["categora", "categoria"].includes(
                e
                    .toLocaleLowerCase()
                    .trim()
                    .replace(/[\W_]/g, "")
                    .replace(/\s+/g, "")
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
            )
        );
        if (category === -1) {
            return toast.error("¡Error, Debe indicar la columna Categoría!");
        }

        const subCategoria = header.data.findIndex((e) =>
            ["subcategora", "subcategoria"].includes(
                e
                    .toLocaleLowerCase()
                    .trim()
                    .replace(/[\W_]/g, "")
                    .replace(/\s+/g, "")
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
            )
        );
        if (subCategoria === -1) {
            return toast.error("¡Error, Debe indicar la columna Sub-categoría!");
        }

        const brand = header.data.findIndex((e) =>
            ["marca"].includes(
                e
                    .toLocaleLowerCase()
                    .trim()
                    .replace(/[\W_]/g, "")
                    .replace(/\s+/g, "")
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
            )
        );
        if (brand === -1) {
            return toast.error("¡Error, Debe indicar la columna Marca!");
        }

        const qty = header.data.findIndex((e) =>
            ["cantidad", "qty"].includes(
                e
                    .toLocaleLowerCase()
                    .trim()
                    .replace(/[\W_]/g, "")
                    .replace(/\s+/g, "")
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
            )
        );
        if (qty === -1) {
            return toast.error("¡Error, Debe indicar la columna Cantidad!");
        }

        const price = header.data.findIndex((e) =>
            ["precio"].includes(
                e
                    .toLocaleLowerCase()
                    .trim()
                    .replace(/[\W_]/g, "")
                    .replace(/\s+/g, "")
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
            )
        );
        if (price === -1) {
            return toast.error("¡Error, Debe indicar la columna Precio!");
        }

        const products = [];
        //se recorren los productos dentro de el csv

        data.forEach(({ data }) => {
            // Se excluyen todas aquellas líneas que su cantidad sea menos a 1
            if (data[qty] > 0) {
                products.push({
                    id: Math.floor(Math.random() * 90000) + 100000,
                    code: data[code],
                    code_manufacture: data[sku],
                    name: data[description],
                    category: data[category],
                    subCategoria: data[subCategoria],
                    brand: data[brand],
                    qty: data[qty],
                    price: data[price],
                    ubication: "",
                    csv: true,
                });
            }
        });
        const { setProducts, products: prds, setUpdate } = this.props;

        const newProductsList = prds;
        newProductsList.push(...products.map((a) => a));

        setProducts(newProductsList);
        setUpdate([...products.map((a) => a)]);
    };

    //Se encarga de hacer el procesamiento de la información en caso de que el archivo sea un XLSX
    caseXlsx = (file) => {
        if (file) {

            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const dataxlsx = XLSX.utils.sheet_to_json(worksheet);

                if (!dataxlsx.length > 0) {
                    return toast.error(`El archivo ${file.name}, no contiene información o está errónea.`);
                }
                //codigo	sku	Descripción	Categoría	Sub-categoría	Marca	Cantidad	Precio	Ubicación

                const code = Object.keys(dataxlsx[0]).findIndex((e) => ["codigo", "code", "Número de artículos (Code)"].includes(e));

                if (code === -1) {
                    return toast.error("¡Error, Debe indicar la columna Codigo!");
                }

                const sku = Object.keys(dataxlsx[0]).findIndex((e) => ["SKU", "sku", "Código de Fabricante (SKU)"].includes(e));
                if (sku === -1) {
                    return toast.error("¡Error, Debe indicar la columna SKU!");
                }

                const description = Object.keys(dataxlsx[0]).findIndex((e) => ["Descripción", "Descripcion", "descripción"].includes(e));
                if (description === -1) {
                    return toast.error("¡Error, Debe indicar la columna Descripción!");
                }

                const category = Object.keys(dataxlsx[0]).findIndex((e) => ["Categoría", "Categoria", "categoría"].includes(e));
                if (category === -1) {
                    return toast.error("¡Error, Debe indicar la columna Categoría!");
                }

                const subCategoria = Object.keys(dataxlsx[0]).findIndex((e) => ["Sub-categoría", "Sub-categoria", "sub-categoría"].includes(e));
                if (subCategoria === -1) {
                    return toast.error("¡Error, Debe indicar la columna Sub-categoría!");
                }

                const brand = Object.keys(dataxlsx[0]).findIndex((e) => ["Marca", "marca"].includes(e));
                if (brand === -1) {
                    return toast.error("¡Error, Debe indicar la columna Marca!");
                }

                const qty = Object.keys(dataxlsx[0]).findIndex((e) => e === "Cantidad" || e === "cantidad");
                if (qty === -1) {
                    return toast.error("¡Error, Debe indicar la columna Cantidad!");
                }

                const price = Object.keys(dataxlsx[0]).findIndex((e) => ["Precio", "precio"].includes(e));
                if (price === -1) {
                    return toast.error("¡Error, Debe indicar la columna Precio!");
                }

                const { setProducts, products: prds, setUpdate, loading } = this.props;
                loading(true);

                //ser obtiene el nober de la key del objeto en caso e que se consiga alguna
                const codeKeyName = Object.keys(dataxlsx[0])[code];
                const skuKeyName = Object.keys(dataxlsx[0])[sku];
                const descriptionKeyName = Object.keys(dataxlsx[0])[description];
                const categoryKeyName = Object.keys(dataxlsx[0])[category];
                const subCategoriaKeyName = Object.keys(dataxlsx[0])[subCategoria];
                const brandKeyName = Object.keys(dataxlsx[0])[brand];
                const qtyKeyName = Object.keys(dataxlsx[0])[qty];
                const priceKeyName = Object.keys(dataxlsx[0])[price];

                const products = [];
                const errors = [];
                //se recorren los productos dentro de el csv
                dataxlsx.forEach((data, i) => {
                    let qty = parseFloat(data[qtyKeyName]);
                    let price = parseFloat(data[priceKeyName]);
                    let subcategory = parseInt(data[subCategoriaKeyName]);
                    let category = parseInt(data[categoryKeyName]);

                    if (qty > 0 && !isNaN(qty) && !isNaN(price) && !isNaN(subcategory) && !isNaN(category)) {
                        products.push({
                            id: i,
                            code: data[codeKeyName],
                            code_manufacture: data[skuKeyName],
                            name: data[descriptionKeyName].toString(),
                            qty: qty,
                            price: price,
                            subCategoria: subcategory,
                            category: category,
                            brand: data[brandKeyName],
                            ubication: "",
                            csv: true,
                        });
                    } else {
                        let errorMessage = "";

                        if (!(qty > 0)) {
                            errorMessage += "la cantidad debe ser mayor a cero, ";
                        }

                        if (isNaN(qty)) {
                            errorMessage += "la cantidad debe ser un valor numérico válido, ";
                        }

                        if (isNaN(price)) {
                            errorMessage += "el precio debe ser un valor numérico válido, ";
                        }

                        if (isNaN(subcategory)) {
                            errorMessage += "la subcategoría debe ser un valor numérico entero válido, ";
                        }

                        if (isNaN(category)) {
                            errorMessage += "la categoría debe ser un valor numérico entero válido, ";
                        }

                        errors.push({
                            id: i + 1,
                            code: data[codeKeyName],
                            msj: errorMessage,
                        });
                    }
                });
                this.setState({ errors });
                const newProductsList = prds;
                newProductsList.push(...products.map((a) => a));

                setProducts(newProductsList);
                setUpdate([...products.map((a) => a)]);
                loading(false);
            };
            reader.readAsArrayBuffer(file);

            return toast.info("Procesando productos...");
        }
    };

    handleOnDrop = (data, file) => {
        switch (file.type) {
            case "application/vnd.ms-excel":
                return this.caseCsc(data);
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":  
                return this.caseXlsx(file);
            case "text/csv":
                return this.caseCsc(data);
            default:
                return toast.error("Formato No Aceptado");
        }
    };

    handleOnError = (err) => {
        console.log(err);
    };

    handleOnRemoveFile = () => {
        const { setProducts, setProductsErrors, products, update } = this.props;
        setProducts(products.filter((product) => !product.csv));
        this.setState({ errors: [] });
        setProductsErrors([]);
        update();
    };

    render() {
        const { errors } = this.state;

        return (
            <Fragment>
                {errors.length > 0 && (
                    <div className="alert alert-danger">
                        <h5>Listado de productos no encontrados o con algún error.</h5>
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th width="20%">Linea</th>
                                    <th width="40%">Código</th>
                                    <th width="20%">Error</th>
                                </tr>
                            </thead>
                            <tbody>
                                {errors.map((e, i) => (
                                    <tr key={i}>
                                        <td>{e.id}</td>
                                        <td>{e.code}</td>
                                        <td>{e.msj}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                <div style={{ width: "93%", height: 200 }}>
                    <CSVReader
                        config={{
                            inputAccept: ".csv,.xlsx",
                        }}
                     //   encoding="ISO-8859-1"
                        onDrop={this.handleOnDrop}
                        onError={this.handleOnError}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={this.handleOnRemoveFile}
                    >
                        <span>Arrastra y Suelta el archivo Excel aquí o haz clic para cargarlo.</span>
                    </CSVReader>
                </div>
            </Fragment>
        );
    }
}
