// react
import React, { useState } from 'react';
// third-party
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import PageHeader from '../shared/PageHeader';
import { ResetPassword } from '../../store/directusSdk';


function ForgetPassword(props) {
    const { currentUser, history } = props;
    const [state, setState] = useState({sendEmail: false});
    if (currentUser) {
        history.replace('/account/dashboard');
        return null;
    }
    const breadcrumb = [
        { title: 'Cuenta', url: 'account/login' },
        { title: 'Recuperar Contraseña', url: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>Recuperar Contraseña</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="document">
                        {!state.sendEmail && (
                            <React.Fragment>
                                <div className="document__header">
                                    <div align="center">
                                        <img src="images/restart.png" alt=""/>
                                        <br />
                                        <br />
                                        <h3 className="document__title">¿Olvidaste tu contraseña?</h3>
                                        <span>Ingresa a continuación tu dirección de correo electronico.</span>
                                        <br />
                                        <br />

                                        <Formik
                                            initialValues={{email: '' }}
                                            validationSchema={Yup.object({
                                                email: Yup.string()
                                                    .required('Campo requerido.')
                                                    .email('Correo invalido.'),
                                            })}
                                            onSubmit={ async (values) => {
                                                try {
                                                    await ResetPassword(values.email).then(() => {
                                                        toast.success('¡Correo Electronico Enviado con Exito!');
                                                        setState({ sendEmail: true });
                                                    });
                                                } catch (error) {
                                                    toast.error('Error al enviar Correo Electronico!');
                                                    setState({ sendEmail: true });
                                                }
                                            }}
                                        >
                                            {({errors, touched, isSubmitting}) => (
                                                <Form className="footer-newsletter__form">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-8">
                                                            <label className="sr-only" htmlFor="email">Correo Electrónico</label>
                                                            <Field
                                                                name="email"
                                                                type="text"
                                                                id="email"
                                                                className={`footer-newsletter__form-input form-control ${errors.email && touched.email && 'is-invalid'}`}
                                                                placeholder="alguien@dominio.com"/>
                                                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                                        </div>
                                                        <div className="form-group col-md-4">
                                                            <button className="footer-newsletter__form-button btn btn-primary" type="submit" disabled={isSubmitting}>{isSubmitting ? 'Eviando...' : 'Enviar'}</button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>

                                </div>

                            </React.Fragment>
                        )}
                        {state.sendEmail && (
                            <div className="document__header">
                                <div align="center">
                                    <img src="images/email_sended.png" alt=""/>
                                    <br />
                                    <br />
                                    <h3 className="document__title">¡Correo Enviado!</h3>
                                    <span>Se le ha enviado un correo para continuar con la recuperacion de su contraseña.</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
