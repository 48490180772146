// react
import React, { useState } from "react";
// third-party

import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { getResultInventory } from "../../store/directusSdk";
import PageHeader from "../shared/PageHeader";
import ImportCsv from "./ImportCsv";

export default function ResultIventory() {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [itemsUpdated, setItemsUpdated] = useState([]);

    const loadItems = (items) => setItems(items);


    const saveAsCSVFile = (buffer, fileName) => {
        import('file-saver').then((FileSaver) => {
            let _TYPE = 'text/csv;charset=utf-8;';
            let _EXTENSION = '.csv';
            const data = new Blob([buffer], {
                type: _TYPE
            });
            FileSaver.saveAs(data, fileName + _EXTENSION);
        });
    }

    const exportCSV = async (data, sap) => {
        let csv='';
        if (
          !Array.isArray(data) ||
          !data.every((p) => typeof p === "object" && p !== null)
        ) {
          return;
        }
        const heading = Object.keys(data[0]).join(",");
        const body = data.map((j) => Object.values(j).join(",")).join("\n");
        csv+=`${heading}\n${body} `;

        saveAsCSVFile(csv, sap ? 'Resultado SAP' : 'Resultado Comparativo');
      };


    const generarResult = (sap) => {
        setLoading(true);
        setItemsUpdated([]);
        return getResultInventory(items, sap)
            .then((itemsResult) => {
                toast.success("¡Archivo Generado con Exito!.");
                exportCSV(itemsResult, sap);
            })
            .catch((error) => toast.error("Error!, " + error.message))
            .finally(() => setLoading(false));
    };

    const breadcrumb = [
        { title: "Home", url: "/" },
        { title: "Almacén", url: "/almacen" },
        { title: "Resultado de Inventario", url: "" },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>Módulo Almacén - Ingeniería y Controles Automatizados</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />
            <div className="container">
                <div className="card">
                    <div className="card-body">
                        <h5 className="mt-3">Cargar CSV</h5>
                        <ImportCsv loadItems={loadItems} />
                        <button type="button" className="btn btn-primary mr-3 mt-4" onClick={() => generarResult(false)} disabled={loading || !items.length > 0}>
                            <i className={`${loading ? "fas fa-cog fa-spin" : "fas fa-cloud-upload-alt"} mr-2`} />
                            {`${loading ? "Calculando" : "Calcular"}`} Resultado Comparativo
                        </button>

                        <button type="button" className="btn btn-primary mr-3 mt-4" onClick={() => generarResult(true)} disabled={loading || !items.length > 0}>
                            <i className={`${loading ? "fas fa-cog fa-spin" : "fas fa-cloud-upload-alt"} mr-2`} />
                            {`${loading ? "Calculando" : "Calcular"}`} Resultado Final para SAP
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
