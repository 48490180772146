// react
import React, { useState } from 'react';
// third-party
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../../store/directusSdk';
import { currentUserChange } from '../../store/currentUser';


function AccountLogin(props) {
    const [email, setEmail] = useState(null);
    const [pwd, setPwd] = useState('');
    const [err, setErr] = useState(null);
    const [loading, setLoading] = useState(false);
    const { currentUserChange: changecurrentUser } = props;

    function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);
        if (email && pwd) {
            login(email, pwd)
                .then((user) => changecurrentUser(user))
                .catch(() => {
                    setErr('Correo o contraseña incorrectos!');
                    setPwd('');
                    setLoading(false);
                });
        }
    }
    function handleChangeEmail(e) {
        setEmail(e.target.value);
    }
    function handlepwd(e) {
        setPwd(e.target.value);
    }
    return (
        <form className="account-menu__form" name="form" onSubmit={handleSubmit} id="login">
            <div className="account-menu__form-title">Ingrese a su cuenta</div>
            {err ? <div className="alert alert-danger mb-3" style={{ fontSize: '12px' }}>{err}</div> : null}
            <div className="form-group">
                <label htmlFor="header-signin-email" className="sr-only">Correo Electronico</label>
                <input
                    id="header-signin-email"
                    type="email"
                    className="form-control form-control-sm"
                    placeholder="Correo Electronico"
                    onChange={handleChangeEmail}
                    required
                />
            </div>
            <div className="form-group">
                <label htmlFor="header-signin-password" className="sr-only">Contraseña</label>
                <div className="account-menu__form-forgot">
                    <input
                        id="header-signin-password"
                        type="password"
                        className="form-control form-control-sm"
                        placeholder="Contraseña"
                        onChange={handlepwd}
                        minLength="6"
                        required
                        value={pwd}
                    />
                    <Link to="/cuenta/recuperar-contrasena" className="account-menu__form-forgot-link">Olvidó?</Link>
                </div>
            </div>
            <div className="form-group account-menu__form-button">
                <button type="submit" className="btn btn-primary btn-sm" disabled={loading}>Entrar</button>
            </div>
            <div className="account-menu__form-link">
                <Link to="/account/login">Crear una Cuenta</Link>
            </div>
        </form>
    );
}

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
    cart: state.cart,
});

const mapDispatchToProps = {
    currentUserChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountLogin);
