import moment from "moment";
import Swal from "sweetalert2";
import swal from "sweetalert";
import "../../../loadingTable.css";
import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { InputNumber } from "primereact/inputnumber";
import { Button, UncontrolledCollapse } from "reactstrap";
import { getProductsForCustomerInventory, CreateItem, UpdateItem } from "../../../../../store/directusSdk";

const InventoryAddEach = ({ products, setProduct, inventoryId, updateTable, currentUser, type }) => {
    const [productSelected, setProductSelected] = useState(null);
    const [stock, setStock] = useState(0);

    const promiseOptions = (inputValue) => getProductsForCustomerInventory(inputValue, "ICA");

    const addProductEach = () => {
        if (type === "U" && !products[0].csv) {
            const product = productSelected.value;
            let isCreate = true;

            const hasCodeExist = products.find((pr) => pr.code === product.code);
            const hasSKUExist = products.find((pr) => pr.sku === product.code_manufacture);

            if (hasCodeExist && hasSKUExist) isCreate = false;

            const newProduct = {
                code: product.code,
                name: product.name,
                sku: product.code_manufacture,
                commited: 0,
                inventory: inventoryId,
                stock,
            };

            Swal.fire({
                title: isCreate ? "Agregar Producto" : "  Producto Existe",
                text: isCreate
                    ? `¿Está seguro que desea agregar el producto con el codigo ${newProduct.code}?.`
                    : "El producto que intenta agregar ya se encuentra registrado. Si procede con la acción, el stock existente se sumará al nuevo ingreso.",
                icon: "warning",
                confirmButtonColor: "#0059BD",
                cancelButtonColor: "#3d464d",
                buttons: true,
                dangerMode: true,
                inputAttributes: {
                    autocapitalize: "off",
                },
                showCancelButton: true,
                showLoaderOnConfirm: true,
                confirmButtonText: "Aceptar",
                preConfirm: () => {
                    if (isCreate) {
                        return CreateItem("cus_inventory_items", newProduct)
                            .then(async ({ data }) => {
                                setProductSelected(null);
                                setStock(0);
                                updateTable();
                                await CreateItem("cus_inventory_movements", {
                                    type: "A",
                                    user_id: currentUser.id,
                                    qty: newProduct.stock,
                                    note: "Se agrego un producto.",
                                    retirement_date: moment().format("YYYY-MM-DD"),
                                    line_id: data.id,
                                });
                            })
                            .catch((error) => swal("Error!", error.message, "error"))                           
                    } else {
                        return UpdateItem("cus_inventory_items", hasCodeExist.id, { stock: hasCodeExist.stock + stock })
                            .then(async () => {
                                setProductSelected(null);
                                setStock(0);
                                updateTable();
                                await CreateItem("cus_inventory_movements", {
                                    type: "U",
                                    user_id: currentUser.id,
                                    qty: newProduct.stock,
                                    note: "Se actualizo un producto.",
                                    retirement_date: moment().format("YYYY-MM-DD"),
                                    line_id: hasCodeExist.id,
                                });
                            })
                            .catch((error) => swal("Error!", error.message, "error"))                        
                    }
                },
                allowOutsideClick: () => !Swal.isLoading(),
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        icon: "success",
                        title: `¡El producto se ha agregado correctamente!`,
                    });
                }
            });
        } else {
            setProduct((value) => {
                const product = productSelected.value;

                const hasCodeExist = value.findIndex((pr) => pr.code === product.code);
                const hasSKUExist = value.findIndex((pr) => pr.sku === product.code_manufacture);

                if (!hasCodeExist && !hasSKUExist) {
                    value[hasCodeExist].stock += stock;
                    value.sort((a, b) => b.id - a.id);
                    return [...value];
                } else {
                    value.push({
                        id: value[0] && value[0].id ? value[0].id + 1 : 1,
                        code: product.code,
                        name: product.name,
                        sku: product.code_manufacture,
                        inventory: inventoryId,
                        stock,
                    });

                    value.sort((a, b) => b.id - a.id);
                    return [...value];
                }
            });
            setProductSelected(null);
            setStock(0);
        }
    };

    return (
        <UncontrolledCollapse toggler="#toggler">
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ width: "50%" }}>
                    <AsyncSelect
                        isClearable
                        cacheOptions
                        loadOptions={promiseOptions}
                        noOptionsMessage={() => "No se Encontraron registros..."}
                        loadingMessage={() => "Buscando..."}
                        placeholder="Códigos de productos..."
                        value={productSelected}
                        onChange={(e) => setProductSelected(e)}
                        className="ms-md-auto"
                    />
                </div>
                <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <InputNumber
                        width="50px"
                        id="exampleNumber"
                        min={1}
                        value={stock}
                        onChange={(e) => setStock(e.value)}
                        showButtons
                        buttonLayout="horizontal"
                        decrementButtonClassName="p-button-secondary"
                        incrementButtonClassName="p-button-info"
                        incrementButtonIcon="pi pi-plus"
                        decrementButtonIcon="pi pi-minus"
                        size={5}
                    />
                </div>
                <div>
                    <Button color="info" onClick={addProductEach} disabled={!productSelected || !(stock > 0)}>
                        <i className="fas fa-save mr-2"></i>
                        Agregar
                    </Button>
                </div>
            </div>
        </UncontrolledCollapse>
    );
};

export default InventoryAddEach;
