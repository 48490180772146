import React, { Component, Fragment } from "react";
import { CSVReader } from "react-papaparse";
import { toast } from "react-toastify";

export default class ImportCsvExternalInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            header: [],
        };
    }
    //Se encarga de hacer el procesamiento de la información en caso de que el archivo sea un CSV
    caseCsc = (data) => {
        const header = data.shift();
        this.setState({ errors: [], header, data });
        const { LoadCodes } = this.props;

        const sku = header.data.findIndex((e) => e === "SKU" || e === "sku");
        if (sku === -1) {
            return toast.error("¡Error, Debe indicar la columna SKU!");
        }

        const codes = [];
        const SetProducts = new Set();
        /*Se agrega al Set ("") y (" "), 
        para que no permita la introducción de 
        espacios vacíos en el array de códigos. */
        SetProducts.add("");
        SetProducts.add(" ");
        //se recorren los productos dentro de el csv
        data.forEach(({ data }) => {
            if (!SetProducts.has(data[sku])) {
                SetProducts.add(data[sku]);
                codes.push({ code: data[sku] });
            }
        });

        LoadCodes([...codes.map((pr) => ({ label: pr.code, value: pr.code }))]);
    };

    handleOnDrop = (data, file) => {
        this.caseCsc(data);
    };

    handleOnError = (err) => {
        console.log(err);
    };

    handleOnRemoveFile = () => {
        const { LoadCodes } = this.props;
        LoadCodes([]);
    };

    render() {
        return (
            <Fragment>
                <div style={{ width: "93%", height: 200 }}>
                    <CSVReader
                        onDrop={this.handleOnDrop}
                        onError={this.handleOnError}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={this.handleOnRemoveFile}
                    >
                        <span>Arrastra y Suelta el archivo CSV aquí o haz clic para cargarlo.</span>
                    </CSVReader>
                </div>
            </Fragment>
        );
    }
}
