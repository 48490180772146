import React, { useState, useRef } from 'react';
import esLocale from '@fullcalendar/core/locales/es';
import { Helmet } from 'react-helmet-async';
import { Badge } from 'primereact/badge';
import { toast } from 'react-toastify';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { confirmPopup } from 'primereact/confirmpopup';
import AsyncSelect from 'react-select/async';
import Alert from "sweetalert2";
import { Calendar } from 'primereact/calendar';
import { Modal, ModalHeader, ModalBody, UncontrolledCollapse } from 'reactstrap';
import moment from "moment";
import { Button } from 'primereact/button';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import PageHeader from '../shared/PageHeader';
import ImportCsvShared from './ImportCsvShared';
import { getEventsShared, createAtivities, AddAtivityShared, updateAtivityShared, deleteAtivityShared, createAtivitiesShared, searchUser, searchEngprojects, deleteResourcesShared, deleteEngprojectsShared } from '../../store/directusSdk';
import { FormattedDifference, msToTime } from '../shared/utilities';


const ActivitiesShared = ({ currentUser }) => {
    const [activities, setActivities] = useState([]);
    const [task, setTask] = useState(null);
    const [upload, setUpload] = useState([]);
    const [oldTask, setOldTask] = useState(null);
    const [errorsEmails, setErrorsEmails] = useState([]);
    const [errorsEngprojects, setErrorsEngprojects] = useState([]);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [mode, setMode] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const calendary = useRef();
    const actors = ['Administrator', 'Ingeniero ICA', 'Admin ICA', 'Ingeniero AP', 'Admin AP', 'Vendedor ICA', 'Vendedor AP', 'VendedorICA-Logistica'];
    const editors = ['Administrator', 'Admin ICA', 'Admin AP'];

    const getActivities = ({ start, end }) => {
        setLoading(true);
        return getEventsShared(moment(start).format('YYYY-MM-DD HH:mm:ss'), moment(end).hour(23).minute(59).second(59).format('YYYY-MM-DD HH:mm:ss'))
            .then(setActivities)
            .catch((error) => toast.error('Error!, ' + error.message))
            .finally(() => setLoading(false));
    }

    const renderStatus = (status) => {
        switch (true) {
            case status === 'Pendiente':
                return 'info';
            case status === 'Pausada':
                return 'warning';
            case status === 'En Proceso':
                return 'info';
            default:
                return 'success';
        }
    }

    const handleEventClick = ({ event }) => {
        setTask({ ...event.extendedProps, title: event.title, id: event.id, start: moment(event.start).format('YYYY-MM-DD HH:mm:ss'), end: moment(event.end).format('YYYY-MM-DD HH:mm:ss') });
        setMode('read');
        setOpen(true);
    }

    const handleEventResize = (eventDrop) => {
        setLoading1(true);
        const json = {
            end: eventDrop.event.end && moment(eventDrop.event.end).format('YYYY-MM-DD HH:mm:ss'),
            time_plan: FormattedDifference(moment(eventDrop.event.end), moment(eventDrop.event.start))
        };

        return updateAtivityShared(eventDrop.event.id, json)
            .then((activity) => {
                const index = activities.findIndex((a) => a.id === activity.id);
                const tareas = [...activities];
                tareas[index] = activity;
                setActivities(tareas);
                toast.success('Duración Modificada con exito!');
            })
            .catch((e) => {
                eventDrop.revert();
                toast.error('¡Error!, ' + e.message);
            })
            .finally(() => setLoading1(false));
    }

    const handleDateSelect = (selectInfo) => {
        let calendarApi = selectInfo.view.calendar
        calendarApi.unselect() // clear date selection
        setTask({
            title: '',
            resources: [],
            time_plan: '00:00',
            engprojects: [],
            start: moment(selectInfo.start).format('YYYY-MM-DD HH:mm:ss'),
            end: moment(selectInfo.end).format('YYYY-MM-DD HH:mm:ss'),
            allDay: selectInfo.allDay
        });
        setMode('add');
        setOpen(true);
    }

    const saveTask = () => {
        setLoading1(true);
        //let calendarApi = calendary.current.getApi();
        if (mode === 'add') {
            return AddAtivityShared({ ...task, resources: task.resources.map((r) => ({ directus_users_id: { id: r.id } })), engprojects: task.engprojects.map((p) => ({ engprojects_id: { id: p.id } })) })
                .then((activity) => {
                    setActivities([...activities, activity]);
                    //calendarApi.addEvent(activity);
                    toast.success('Actividad Compartida Creada con Exito!');
                    setOpen(false);
                    setTask(null);
                })
                .catch((error) => toast.error(`¡Error!, ${error.message}`))
                .finally(() => setLoading1(false));
        }
        let change = 0;
        const toDelete = [];
        const toAdd = [];
        const toDeleteEng = [];
        const toAddEng = [];
        const json = {};
        if (task.title !== oldTask.title) {
            json.title = task.title;
            change++;
        }
        if (JSON.stringify(task.resources) !== JSON.stringify(oldTask.resources)) {
            oldTask.resources.forEach((r) => {
                const resource = task.resources.find((item) => item.id === r.id);
                if (!resource) {
                    toDelete.push(r.resId);
                }
            });

            task.resources.forEach((r) => {
                const resource = oldTask.resources.find((item) => item.id === r.id);
                if (!resource) {
                    toAdd.push({ directus_users_id: { id: r.id } });
                }
            });
            if (toAdd.length > 0) {
                json.resources = toAdd;
                change++;
            }
        }
        if (JSON.stringify(task.engprojects) !== JSON.stringify(oldTask.engprojects)) {
            oldTask.engprojects.forEach((r) => {
                const engproject = task.engprojects.find((item) => item.id === r.id);
                if (!engproject) {
                    toDeleteEng.push(r.EngId);
                }
            });

            task.engprojects.forEach((r) => {
                const engproject = oldTask.engprojects.find((item) => item.id === r.id);
                if (!engproject) {
                    toAddEng.push({ engprojects_id: { id: r.id } });
                }
            });
            if (toAddEng.length > 0) {
                json.engprojects = toAddEng;
                change++;
            }
        }
        if (task.time_plan !== oldTask.time_plan) {
            json.time_plan = task.time_plan;
            change++;
        }

        if (toDelete.length > 0) {
            deleteResourcesShared(toDelete);
        }

        if (toDeleteEng.length > 0) {
            deleteEngprojectsShared(toDeleteEng);
        }

        if (!change > 0) {
            setLoading1(false);
            return setMode('read');
        }
        //  const event = calendarApi.getEventById(task.id);

        return updateAtivityShared(task.id, json)
            .then((activity) => {
                const index = activities.findIndex((a) => a.id === activity.id);
                const tareas = [...activities];
                tareas[index] = activity;
                setActivities(tareas);
                toast.success('Actividad Compartida Modificada con Exito!');
                setMode('read');
            })
            .catch((error) => toast.error(`¡Error!, ${error.message}`))
            .finally(() => setLoading1(false));
    }

    const changeTask = (event) => {
        if (event && event.target && event.target.name) {
            setTask({
                ...task,
                [event.target.name]: event.target.value
            });
        }
    }

    const changeTimePlan = (event) => {
        const time_plan = moment(event.value, 'HH:mm:ss').format('HH:mm:ss');
        const duration = moment.duration(time_plan);
        setTask({
            ...task,
            end: duration > 0 ? moment(task.start).add(duration).format('YYYY-MM-DD HH:mm:ss') : '00:00:00',
            time_plan
        });
    }


    const changeResources = (resources) => {
        setTask({
            ...task,
            resources
        });
    }

    const changeEngproject = (engprojects) => {
        setTask({
            ...task,
            engprojects
        });
    }


    const eventDrop = (eventDrop) => {
        setLoading1(true);
        const json = {
            start: moment(eventDrop.event.start).format('YYYY-MM-DD HH:mm:ss'),
            allDay: eventDrop.event.allDay,
            end: moment(eventDrop.event.start).add(moment.duration(eventDrop.event.extendedProps.time_plan)).format('YYYY-MM-DD HH:mm:ss'),
        };

        updateAtivityShared(eventDrop.event.id, json)
            .then((activity) => {
                const index = activities.findIndex((a) => a.id === activity.id);
                const tareas = [...activities];
                tareas[index] = activity;
                setActivities(tareas);
                toast.success('Actividad reprogramada con exito!');
            })
            .catch((e) => {
                eventDrop.revert();
                toast.error('¡Error!, ' + e.message);
            })
            .finally(() => setLoading1(false));
    };

    const editTask = () => {
        setOldTask(task);
        setMode('edit');
    }

    const iniTask = () => {
        setLoading1(true);
        // let calendarApi = calendary.current.getApi();
        //  const event = calendarApi.getEventById(task.id);
        return updateAtivityShared(task.id, { iniciadapor: currentUser.id, hour_ini: moment().format('YYYY-MM-DD HH:mm:ss'), start: moment().format('YYYY-MM-DD HH:mm:ss'), allDay: false, status: 'En Proceso', end: moment().add(moment.duration(task.time_plan)).format('YYYY-MM-DD HH:mm:ss') })
            .then((activity) => {
                const index = activities.findIndex((a) => a.id === activity.id);
                const tareas = [...activities];
                tareas[index] = activity;
                setActivities(tareas);
                setTask(activity);
                toast.success('Actividad Iniciada con exito!');
            })
            .catch((e) => toast.error('¡Error!, ' + e.message))
            .finally(() => setLoading1(false));
    }

    const ReiniTask = async () => {
        setLoading1(true);
        const timeUsed = FormattedDifference(moment(task.hour_pausada), moment(task.hour_ini));
        const timeDuration = moment.duration(task.time_plan) - moment.duration(timeUsed);
        const timePlanNew = msToTime(timeDuration < 0 ? 0 : timeDuration);

        const actividad = {
            status: 'Terminada',
            hour_fin: task.hour_pausada,
            time_real: timeUsed,
            terminadapor: currentUser.id,
            end: moment(task.hour_pausada).format('YYYY-MM-DD HH:mm:ss')
        };

        if (timeDuration > 0) {
            actividad.time_plan = timeUsed;
        }

        const time_planComparted = msToTime(moment.duration(task.time_plan) / task.engprojects.length);
        const time_realComparted = msToTime(moment.duration(timeUsed) / task.engprojects.length);

        const taskss = task.engprojects.map((eng) => ({
            iniciadapor: task.iniciadapor.id,
            pausadapor: task.pausadapor && task.pausadapor.id,
            terminadapor: currentUser.id,
            hour_ini: task.hour_ini,
            hour_fin: task.hour_pausada,
            start: task.start,
            allDay: false,
            status: 'Terminada',
            time_plan: time_planComparted,
            time_real: time_realComparted,
            title: task.title,
            end: task.hour_pausada,
            engproject_id: eng.id,
            resources: task.resources.map((r) => ({ user_id: { id: r.id } }))
        }));
        if (taskss.length > 0) {
            await createAtivities(taskss)
                .catch((e) => toast.error('¡Error!, ' + e.message))
                .finally(() => setLoading1(false));
        }

        await updateAtivityShared(task.id, actividad)
            .then((activity) => {
                const index = activities.findIndex((a) => a.id === activity.id);
                const tareas = [...activities];
                tareas[index] = activity;

                AddAtivityShared({
                    iniciadapor: currentUser.id,
                    hour_ini: moment().format('YYYY-MM-DD HH:mm:ss'),
                    start: moment().format('YYYY-MM-DD HH:mm:ss'),
                    allDay: false,
                    status: 'En Proceso',
                    time_plan: timePlanNew,
                    title: 'C- ' + task.title,
                    engprojects: task.engprojects.map((p) => ({ engprojects_id: { id: p.id } })),
                    resources: task.resources.map((r) => ({ directus_users_id: { id: r.id } })),
                    end: moment().add(moment.duration(timePlanNew)).format('YYYY-MM-DD HH:mm:ss')
                })
                    .then((newActivity) => {
                        tareas.push(newActivity);
                    })
                    .catch((error) => toast.error(`¡Error!, ${error.message}`));

                setActivities(tareas);
                setTask(activity);
                toast.success('Actividad Reiniciada con exito!');
            })
            .catch((e) => toast.error('¡Error!, ' + e.message))
            .finally(() => setLoading1(false));
    }


    const pauseTask = () => {
        setLoading1(true);
        // let calendarApi = calendary.current.getApi();
        // const event = calendarApi.getEventById(task.id);
        return updateAtivityShared(task.id, { status: 'Pausada', pausadapor: currentUser.id, hour_pausada: moment().format('YYYY-MM-DD HH:mm:ss') })
            .then((activity) => {
                const index = activities.findIndex((a) => a.id === activity.id);
                const tareas = [...activities];
                tareas[index] = activity;
                setActivities(tareas);
                setTask(activity);
                toast.success('Actividad Pausada con exito!');
            })
            .catch((e) => toast.error('¡Error!, ' + e.message))
            .finally(() => setLoading1(false));
    }

    const finishTask = async () => {
        setLoading1(true);
        // let calendarApi = calendary.current.getApi();
        const time_real = FormattedDifference(moment(), moment(task.hour_ini));
        // const event = calendarApi.getEventById(task.id);
        const time_planComparted = msToTime(moment.duration(task.time_plan) / task.engprojects.length);
        const time_realComparted = msToTime(moment.duration(task.time_real) / task.engprojects.length);

        const taskss = task.engprojects.map((eng) => ({
            iniciadapor: task.iniciadapor.id,
            pausadapor: task.pausadapor && task.pausadapor.id,
            terminadapor: currentUser.id,
            hour_ini: task.hour_ini,
            hour_fin: moment().format('YYYY-MM-DD HH:mm:ss'),
            start: task.start,
            allDay: false,
            status: 'Terminada',
            engproject_id: eng.id,
            time_plan: time_planComparted,
            time_real: time_realComparted,
            title: task.title,
            resources: task.resources.map((r) => ({ user_id: r.id })),
            end: moment(task.hour_ini).add(moment.duration(time_realComparted)).format('YYYY-MM-DD HH:mm:ss')
        }));
        if (taskss.length > 0) {
            await createAtivities(taskss)
                .catch((e) => toast.error('¡Error!, ' + e.message))
                .finally(() => setLoading1(false));
        }

        await updateAtivityShared(task.id, { terminadapor: currentUser.id, hour_fin: moment().format('YYYY-MM-DD HH:mm:ss'), status: 'Terminada', end: moment().format('YYYY-MM-DD HH:mm:ss'), time_real })
            .then((activity) => {
                const index = activities.findIndex((a) => a.id === activity.id);
                const tareas = [...activities];
                tareas[index] = activity;
                setActivities([...tareas]);
                setTask(activity);
                toast.success('Actividad Compartida Terminada con exito!');
            })
            .catch((e) => toast.error('¡Error!, ' + e.message))
            .finally(() => setLoading1(false));
    }

    const confirm2 = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: '¿Esta Seguro que desea borrar esta Actividad?',
            icon: 'pi pi-info-circle',
            acceptLabel: 'Si',
            acceptClassName: 'p-button-danger',
            accept: delTask,
        });
    };

    const delTask = () => {
        setLoading1(true);
        let calendarApi = calendary.current.getApi();
        const event = calendarApi.getEventById(task.id);
        return deleteAtivityShared(task.id)
            .then(() => {
                const index = activities.findIndex((a) => a.id === task.id);
                const tareas = [...activities];
                tareas.splice(index, 1);
                setActivities([...tareas]);
                setTask(null);
                event.remove();
                setOpen(false);
                toast.success('Actividad Borrada con exito!');
            })
            .catch((e) => toast.error('¡Error!, ' + e.message))
            .finally(() => setLoading1(false));
    }

    const uploadTask = (taskks) => {
        setUpload(taskks);
        setOpen2(true);
    }

    const importTasks = () => {
        setLoading1(true);
        const tasks = [];
        upload.forEach((e) => {
            tasks.push({ ...e, resources: e.resources.map((r) => ({ directus_users_id: { id: r.id } })), engprojects: e.engprojects.map((p) => ({ engprojects_id: { id: p.id } })) });
        });

        return createAtivitiesShared(tasks)
            .then(() => {
                const calendarApi = calendary.current.getApi();
                getActivities(calendarApi.view.currentStart, calendarApi.view.currentEnd);
                setUpload([]);
                setOpen2(false);
                toast.success('Actividades Compartidas Importadas con exito!');
            })
            .catch((e) => toast.error('¡Error!, ' + e.message))
            .finally(() => setLoading1(false));
    }

    const promiseOptions = (inputValue) => searchUser(inputValue);

    const promiseOptions2 = (inputValue) => searchEngprojects(inputValue);

    const confirm = (title, text, confirmText, succesTitle, succesText, action) => {
        return Alert.fire({
            title,
            text,
            showCancelButton: true,
            confirmButtonText: confirmText,
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
            confirmButtonColor: '#004FBD',
            icon: 'question',
        }).then((result) => {
            if (result.isConfirmed) {
                return action().then(() =>
                    Alert.fire(
                        succesTitle,
                        succesText,
                        'success'
                    )
                )
            }
        })
    }


    const breadcrumb = [
        { title: 'Home', url: '/' },
        { title: 'Tareas', url: '/tasks-manager' },
        { title: 'Tareas Compartidas', url: '' },
    ];

    return (
        <>
            <Helmet>
                <title>Tareas de Proyecto - ICAutomatizados</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="container-fluid">
                <div className="card">
                    <div className="mb-2 p-d-flex p-jc-between">
                        {editors.includes(currentUser.role.name) && <Button id="toggler" label="Importar Actividades" icon="pi pi-cloud-upload" iconPos="right" className="p-button-secondary" />}
                    </div>
                    {editors.includes(currentUser.role.name) && (
                        <UncontrolledCollapse toggler="#toggler">
                            <div>
                                <ImportCsvShared uploadTask={uploadTask} setErrorsEmails={setErrorsEmails} setErrorsEngprojects={setErrorsEngprojects} />
                            </div>
                        </UncontrolledCollapse>
                    )}

                    <div className="card-table">
                        {loading && <div className="oscurecer" ><div className="loadingg" /></div>}
                        <FullCalendar
                            ref={calendary}
                            events={activities}
                            initialView='timeGridWeek'
                            locale={esLocale}
                            initialDate={moment().format('YYYY-MM-DD')}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                            headerToolbar={{ left: 'prev,next,today', center: 'title', right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek' }}
                            editable={editors.includes(currentUser.role.name)}
                            droppable={editors.includes(currentUser.role.name)}
                            selectable={editors.includes(currentUser.role.name)}
                            selectMirror
                            dayMaxEvents
                            eventResize={handleEventResize}
                            eventClick={handleEventClick}
                            eventDrop={eventDrop}
                            eventOverlap={(stillEvent, movingEvent) => stillEvent.extendedProps.status === 'Pendiente' && movingEvent.extendedProps.status === 'Pendiente'}
                            select={handleDateSelect}
                            eventTimeFormat={{ hour: '2-digit', minute: '2-digit', meridiem: 'short', hour12: true }}
                            businessHours={{ daysOfWeek: [1, 2, 3, 4, 5], startTime: '07:00', endTime: '17:00' }}
                            datesSet={getActivities}
                        />
                    </div>
                </div>
            </div>
            {task && (
                <Modal isOpen={open} toggle={() => setOpen(false)} centered size="lg" zIndex={999}>
                    <ModalHeader toggle={() => setOpen(false)}>{task.title}</ModalHeader>
                    <ModalBody>
                        <table width="100%" className="table table-sm">
                            <tbody>
                                {mode === 'add' && (
                                    <tr>
                                        <th width="30%">Todo el Dia</th>
                                        <td width="70%" align="right"><InputSwitch checked={task.allDay} name="allDay" onChange={changeTask} /></td>
                                    </tr>
                                )}
                                <tr>
                                    <th width="30%">Nombre</th>
                                    <td width="70%" align="right">{mode !== 'read' ? <InputText style={{ width: '100%' }} name="title" value={task.title} onChange={changeTask} /> : task.title}</td>
                                </tr>
                                <tr>
                                    <th width="30%">Tiempo Planificado</th>
                                    <td width="70%" align="right">{mode !== 'read' ? <Calendar name="time_plan" value={moment(task.time_plan, 'HH:mm:ss').toDate()} onChange={changeTimePlan} timeOnly hourFormat="24" /> : task.time_plan}</td>
                                </tr>
                                <tr>
                                    <th width="30%">Proyectos</th>
                                    <td width="70%" align="right">
                                        {mode !== 'read' ? (
                                            <AsyncSelect
                                                isClearable
                                                isMulti
                                                cacheOptions
                                                name="engprojects"
                                                loadOptions={promiseOptions2}
                                                noOptionsMessage={() => 'No se Encontró registros...'}
                                                loadingMessage={() => 'Buscando...'}
                                                placeholder="Ingrese Codigo de Proyecto..."
                                                onChange={changeEngproject}
                                                value={task.engprojects}
                                                getOptionLabel={(option) => option.code}
                                                getOptionValue={(option) => option.id}
                                            />
                                        ) : task.engprojects.map((p, i) => (
                                            <React.Fragment key={i}>
                                                {p.code}
                                                < br />
                                            </React.Fragment >
                                        ))}
                                    </td>
                                </tr>
                                <tr>
                                    <th width="30%">Recursos</th>
                                    <td width="70%" align="right">
                                        {mode !== 'read' ? (
                                            <AsyncSelect
                                                isClearable
                                                isMulti
                                                cacheOptions
                                                name="resources"
                                                loadOptions={promiseOptions}
                                                noOptionsMessage={() => 'No se Encontró registros...'}
                                                loadingMessage={() => 'Buscando...'}
                                                placeholder="Ingrese Nombre del Colaborador..."
                                                onChange={changeResources}
                                                value={task.resources}
                                                getOptionLabel={(option) => option.email}
                                                getOptionValue={(option) => option.id}
                                            />
                                        ) : task.resources.map((r, i) => (
                                            <React.Fragment key={i}>
                                                {r.email}
                                                < br />
                                            </React.Fragment >
                                        ))}
                                    </td>
                                </tr>
                                {mode !== 'add' && (
                                    <tr>
                                        <th width="30%">Estado</th>
                                        <td width="70%" align="right"><Badge value={task.status} severity={renderStatus(task.status)} /></td>
                                    </tr>
                                )}
                                {['En Proceso', 'Pausada', 'Terminada'].includes(task.status) && (
                                    <tr>
                                        <th width="30%">Iniciada Por:</th>
                                        <td width="70%" align="right">{task.iniciadapor && task.iniciadapor.first_name + ' ' + task.iniciadapor.last_name}</td>
                                    </tr>
                                )}
                                {task.status === 'Pausada' && (
                                    <tr>
                                        <th width="30%">Pausada Por:</th>
                                        <td width="70%" align="right">{task.pausadapor && task.pausadapor.first_name + ' ' + task.pausadapor.last_name}</td>
                                    </tr>
                                )}
                                {task.status === 'Terminada' && (
                                    <>
                                        <tr>
                                            <th width="30%">Terminada Por:</th>
                                            <td width="70%" align="right">{task.terminadapor && task.terminadapor.first_name + ' ' + task.terminadapor.last_name}</td>
                                        </tr>
                                        <tr>
                                            <th width="30%">Tiempo Real:</th>
                                            <td width="70%" align="right">{task.time_real}</td>
                                        </tr>

                                    </>
                                )}
                            </tbody>
                        </table>
                        {actors.includes(currentUser.role.name) && (
                            <div className="p-d-flex p-jc-between">
                                {['read'].includes(mode) && editors.includes(currentUser.role.name) && task.status === 'Pendiente' && <Button label="Editar" icon="pi pi-pencil" iconPos="right" disabled={loading1} loading={loading1} onClick={editTask} />}
                                {['add', 'edit'].includes(mode) && editors.includes(currentUser.role.name) && task.title.length > 0 && <Button label="Guardar" icon="pi pi-save" iconPos="right" disabled={loading1} loading={loading1} onClick={saveTask} />}
                                {['read', 'edit'].includes(mode) && ['Pendiente'].includes(task.status) && (
                                    <>
                                        <Button label="Iniciar" icon="pi pi-play" iconPos="right" disabled={loading1} loading={loading1} onClick={() => confirm('Iniciar Actividad', '¿Desea iniciar la actividad ' + task.title + '?', 'Iniciar', '¡Actividad Iniciada Correctamente!', 'Actividad ' + task.title + ' iniciada.', iniTask)} className="p-button-info" />
                                        {editors.includes(currentUser.role.name) && <Button label="Borrar" icon="pi pi-erase" iconPos="right" disabled={loading1} loading={loading1} onClick={confirm2} className="p-button-danger" />}
                                    </>
                                )}
                                {['read', 'edit'].includes(mode) && ['Pausada'].includes(task.status) && (
                                    <>
                                        <Button label="Reiniciar" icon="pi pi-play" iconPos="right" disabled={loading1} loading={loading1} onClick={() => confirm('Reiniciar Actividad', '¿Desea Reiniciar la actividad ' + task.title + '?', 'Reiniciar', '¡Actividad Reiniciada Correctamente!', 'Actividad ' + task.title + ' reiniciada.', ReiniTask)} className="p-button-info" />
                                    </>
                                )}
                                {['read', 'edit'].includes(mode) && task.status === 'En Proceso' && (
                                    <>
                                        <Button label="Pausar" icon="pi pi-pause" iconPos="right" disabled={loading1} loading={loading1} onClick={() => confirm('Pausar Actividad', '¿Desea Pausar la actividad ' + task.title + '?', 'Pausar', '¡Actividad Pausada Correctamente!', 'Actividad ' + task.title + ' pausada.', pauseTask)} className="p-button-warning" />
                                        <Button label="Terminar" icon="pi pi-check" iconPos="right" disabled={loading1} loading={loading1} onClick={() => confirm('Finalizar Actividad', '¿Desea Finalizar la actividad ' + task.title + '?', 'Finalizar', '¡Actividad Finalizada Correctamente!', 'Actividad ' + task.title + ' finalizada.', finishTask)} className="p-button-success" />
                                    </>
                                )}
                            </div>
                        )}
                    </ModalBody>
                </Modal>
            )}
            {upload.length > 0 && (
                <Modal isOpen={open2} centered size="xl" zIndex={999}>
                    <ModalHeader toggle={() => setOpen2(false)}>Cargar Actividades Compartidas</ModalHeader>
                    <ModalBody>
                        {(errorsEmails.length > 0 || errorsEngprojects.length > 0) && (
                            <div className="alert alert-danger">
                                <table className="table table-sm">
                                    <thead>
                                        <tr>
                                            <th>Columna</th>
                                            <th>Elemento</th>
                                            <th>Error</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {errorsEngprojects.map((e, i) => (
                                            <tr key={i}>
                                                <td>{'Proyectos'}</td>
                                                <td>{e}</td>
                                                <td>{'Proyecto no Encontrado.'}</td>
                                            </tr>
                                        ))}
                                        {errorsEmails.map((e, i) => (
                                            <tr key={i}>
                                                <td>{'Email'}</td>
                                                <td>{e}</td>
                                                <td>{'Email no Encontrado.'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}

                        <table width="100%" className="table table-sm">
                            <thead>
                                <tr>
                                    <th width="35%" >Nombre</th>
                                    <th width="8%">Planificado</th>
                                    <th width="12%" >Proyectos</th>
                                    <th width="28%" >Recursos</th>
                                    <th width="10%">Inicio</th>
                                    <th width="7%">Dia</th>
                                </tr>
                            </thead>
                            <tbody>
                                {upload.map((e, i) => (
                                    <tr key={i}>
                                        <td>{e.title}</td>
                                        <td>{e.time_plan}</td>
                                        <td>{e.engprojects.map((p, ind) => (
                                            <React.Fragment key={ind}>
                                                {p.code}
                                                <br />
                                            </React.Fragment>
                                        ))}</td>
                                        <td>{e.resources.map((r, ind) => (
                                            <React.Fragment key={ind}>
                                                {r.email}
                                                <br />
                                            </React.Fragment>
                                        ))}</td>
                                        <td>{moment(e.start).format('DD-MM-YYYY')}</td>
                                        <td>{e.allDay ? 'Si' : 'No'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div>
                            {<Button label="Importar" icon="pi pi-save" iconPos="right" disabled={loading1} loading={loading1} onClick={importTasks} />}
                        </div>
                    </ModalBody>
                </Modal>
            )}
        </>
    );
}

export default ActivitiesShared;