// react
import React, { Component } from "react";
// third-party
import AsyncSelect from "react-select/async";
import { UncontrolledCollapse, Modal, ModalHeader, ModalBody, Table } from "reactstrap";
import { Button } from "primereact/button";
import InputNumber from "../shared/InputNumber";
import Select from "react-select";
import { getSuggestedProductsICA, getSuggestedProductsAP } from "../../store/directusSdk";
import ChangeQtty from "./ChangeQtty";
import ChangeWhs from "./ChangeWhs";
import ImportCsv from "./ImportCsv";
import UpdatePriority from "./UpdatePriority";

export default class ProductsTable extends Component {
    constructor(props) {
        super(props);
        this.options = [
            { label: "P1", value: "P1" },
            { label: "P2", value: "P2" },
            { label: "P3", value: "P3" },
            { label: "P4", value: "P4" },
            { label: "S/P", value: "S/P" },
        ];
        this.state = {
            product: null,
            cant: 1,
            warehouse: props.whs[0],
            priority: this.options[1],
            open: false,
            productss: [],
            errores: [],
        };
    }

    promiseOptions = (company, inputValue) => (company === "ICA" ? getSuggestedProductsICA(inputValue) : getSuggestedProductsAP(inputValue));

    Remove = (table, i) => {
        const { products, changeProducts } = this.props;
        if (table === "ICA") {
            products.splice(i, 1);
            return changeProducts(table, products);
        }
        products.splice(i, 1);
        return changeProducts(table, products);
    };

    Add = (product, cant, priority, warehouse) => {
        const { products, table, changeProducts } = this.props;

        const i = products.findIndex((e) => e.id == product.id && e.warehouse.value == warehouse.value && e.priority.value === priority.value);

        if (i !== -1) {
            products.splice(i, 1, { ...product, priority, warehouse, cant: cant + products[i].cant });
        } else {
            products.push({ ...product, cant, priority, warehouse });
        }

        this.setState({ product: null });
        return changeProducts(table, products);
    };

    ImportProducts = (productos) => {
        const { products, table, changeProducts } = this.props;
        changeProducts(table, [...products, ...productos]);
        this.setState({ open: false, productss: [], errores: [] });
    };

    HandleCant = (i, cant) => {
        const { products, table, changeProducts } = this.props;
        products.splice(i, 1, { ...products[i], cant });
        return changeProducts(table, products);
    };

    HandlePriority = (i, priority) => {
        const { products, changeProducts, table } = this.props;
        products.splice(i, 1, { ...products[i], priority });
        return changeProducts(table, products);
    };

    HandleWhs = (i, warehouse) => {
        const { products, table, changeProducts } = this.props;
        products.splice(i, 1, { ...products[i], warehouse });
        return changeProducts(table, products);
    };

    showBodegas = (OnHandByWhs) => {
        let warehouses = "";
        for (const property in OnHandByWhs) {
            warehouses += `${property}: ${OnHandByWhs[property]} un.   `;
        }
        return warehouses;
    };

    LoadProducts = (table, productss, errores) => {
        this.setState({ open: true, productss, errores });
    };

    render() {
        const { product, cant, warehouse, priority, errores, open, productss } = this.state;
        const { products, table, whs, errors } = this.props;

        return (
            <>
                <h5>{`Listado de Materiales ${table}`}</h5>
                <Button id={`toggler${table}`} style={{ marginBottom: "1rem" }}>
                    {" "}
                    Importar .CSV
                </Button>
                <UncontrolledCollapse toggler={`#toggler${table}`}>
                    <div className="mb-2">
                        <ImportCsv LoadProducts={this.LoadProducts} whs={whs} company={table} />
                    </div>
                </UncontrolledCollapse>

                <Table hover className="mt-2">
                    <thead>
                        <tr>
                            <th width="3%">#</th>
                            <th width="12%">Código</th>
                            <th width="40%">Producto</th>
                            <th width="12%">Prioridad</th>
                            <th width="10%">Cantidad</th>
                            <th width="15%">Bodega</th>
                            <th width="4%">Accion</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((e, i) => (
                            <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{e.code}</td>
                                <td>{e.name}</td>
                                <td>
                                    <UpdatePriority
                                        value={e.priority}
                                        options={this.options}
                                        onChange={(priority) => this.HandlePriority(i, priority)}
                                    />
                                </td>
                                <td>
                                    <ChangeQtty value={e.cant} onChange={(cant) => this.HandleCant(i, cant)} />
                                </td>
                                <td>
                                    <ChangeWhs value={e.warehouse} options={whs} onChange={(warehouse) => this.HandleWhs(i, warehouse)} />
                                </td>
                                <td>
                                    <Button className="p-button-danger" onClick={() => this.Remove(table, i)} title="Remover">
                                        <i className="fa fa-trash" />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td colSpan="3">
                                <AsyncSelect
                                    cacheOptions
                                    loadOptions={(input) => this.promiseOptions(table, input)}
                                    defaultOptions
                                    isClearable={true}
                                    noOptionsMessage={() => "No se Encontró ningun producto."}
                                    loadingMessage={() => "Buscando..."}
                                    placeholder="Buscar producto..."
                                    onChange={(product) => this.setState({ product })}
                                    value={product}
                                />
                            </td>
                            <td>
                                <Select value={priority} onChange={(priority) => this.setState({ priority })} options={this.options} />
                            </td>
                            <td>
                                <InputNumber min={1} value={cant} onChange={(cant) => this.setState({ cant })} />
                            </td>
                            <td>
                                <Select value={warehouse} onChange={(warehouse) => this.setState({ warehouse })} options={whs} />
                                {product && <span style={{ fontSize: 12 }}>{this.showBodegas(product.OnHandByWhs)}</span>}
                            </td>
                            <td>
                                <Button
                                    className="p-button-success"
                                    title="Agregar"
                                    onClick={() => this.Add(product, cant, priority, warehouse)}
                                    disabled={cant < 1 || !product || !warehouse}
                                >
                                    <i className="fa fa-plus" />
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                {errors && (
                    <div className="col-12">
                        <span className="text-danger ml-2 mt-5">{errors}</span>
                    </div>
                )}
                {open && (
                    <Modal isOpen={open} centered size="xl" zIndex={999}>
                        <ModalHeader toggle={() => this.setState({ open: false })}>Cargar Listado de Materiales</ModalHeader>
                        <ModalBody>
                            {errores.length > 0 && (
                                <div className="alert alert-danger">
                                    <table className="table table-sm">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Código</th>
                                                <th>Error</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {errores.map((e, i) => (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{e.code}</td>
                                                    <td>{e.error}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}

                            <table width="100%" className="table table-sm">
                                <thead>
                                    <tr>
                                        <th width="4%">#</th>
                                        <th width="18%">Código</th>
                                        <th width="42%">Producto</th>
                                        <th width="10%">Cant.</th>
                                        <th width="10%">Prioridad</th>
                                        <th width="16%">Bodega</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productss.map((e, i) => (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{e.code}</td>
                                            <td>{e.name}</td>
                                            <td>{e.cant}</td>
                                            <td>{e.priority}</td>
                                            <td>{e.warehouse.name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div>{<Button label="Importar" icon="pi pi-save" iconPos="right" onClick={() => this.ImportProducts(productss)} />}</div>
                        </ModalBody>
                    </Modal>
                )}
            </>
        );
    }
}
