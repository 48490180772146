import {
    PAGESCONTENT_CHANGE,
    FILTERS_CHANGE,
    RESET_FILTERS,
    DROP_FILTER,
} from './pagesContentActionTypes';

let initialState = {
    filters: [],
    categories: [],
    menu: [],
    multiplications: [],

};

export default function pagesContentReducer(state = initialState, action) {
    switch (action.type) {
    case PAGESCONTENT_CHANGE:
        return Object.assign(state, action.pagesContent);
    case FILTERS_CHANGE:
        const filters = state.filters;
        const fil = filters.findIndex((e) => e.name === action.filter.name);
        if (fil !== -1) {
            (filters[fil] = action.filter);
            return { ...state, filters };
        }
        filters.push(action.filter);
        return { ...state, filters };
    case DROP_FILTER:
        const filtros = state.filters.filter((e) => e.name !== action.filter.name);
        return { ...state, filters: filtros };
    case RESET_FILTERS:
        return { ...state, filters: [] };
    default:
        return state;
}
}