// react
import React, { useState } from 'react';
import { Button } from 'reactstrap';

export default ({ deleteItem, row }) => {
    const [loading, setLoading] = useState(false);

    const click = () => {
        setLoading(true);
        deleteItem(row.id);
        setLoading(false);
    };

    return (
        <Button className="ml-2" onClick={click} color="danger" title="Eliminar Item" disabled={loading}>
            <i className={`fas ${loading ? 'fa-cog fa-spin' : 'fa-trash'}`} />
        </Button>
    );
}