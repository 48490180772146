// react
import React, { Component } from 'react';
// third-party
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { InputSwitch } from 'primereact/inputswitch';
import { getEngprojectIdAndWhs, getWarehouses, UpdateItems, ReturnInvetoryParticular } from '../../store/directusSdk';
import PageHeader from '../shared/PageHeader';
import ProductsTableManager from './ProductsTableManager';
import { statusBadge } from '../engprojects/utils';

class EngprojectManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: null,
            loading: false,
            loading3: false,
            whsICA: [],
            whsAP: [],
            newChangesLinesICA: [],
            newChangesLinesAP: [],
            showListMain: true,
            showListSec: false,
        };
    }

    componentDidMount() {
        this.getProject();
        getWarehouses().then(({ whsICA, whsAP }) => this.setState({ whsICA, whsAP }));
    }

    getProject = () => {
        this.setState({ loading3: true });
        const { match: { params: { id } }, currentUser } = this.props;
        const whsICA = [];
        const whsAP = [];
        if (currentUser.warehouses.length > 0) {
            currentUser.warehouses.forEach((e) => {
                if (e.company === 'ICA') {
                    whsICA.push(e.id);
                } else {
                    whsAP.push(e.id);
                }
            });
        }

        getEngprojectIdAndWhs(id, whsICA, whsAP)
            .then((project) => {
                this.setState({ project });
            }).finally(() => this.setState({ loading3: false }));

    }

    Save = async () => {
        this.setState({ loading: true });
        const { newChangesLinesICA, newChangesLinesAP, project } = this.state;

        const externalICA = [];
        const externalAP = [];

        if (newChangesLinesICA.length > 0) {
            newChangesLinesICA.forEach((e) => {
                if (e.is_external) {
                    externalICA.push(e);
                }
            });
            if (externalICA.length > 0) {
                await ReturnInvetoryParticular('ICA', externalICA, project.id);
            }
            await UpdateItems('engprojectlinesmovementsica', newChangesLinesICA.map((e) => {
                delete e.product;
                delete e.is_external;
                return e;
            }));
        }
        if (newChangesLinesAP.length > 0) {
            newChangesLinesAP.forEach((e) => {
                if (e.is_external) {
                    externalAP.push(e);
                }
            });
            if (externalAP.length > 0) {
                await ReturnInvetoryParticular('AP', externalAP, project.id);
            }
            await UpdateItems('engprojectlinesmovementsap', newChangesLinesAP.map((e) => {
                delete e.product;
                delete e.is_external;
                return e;
            }));
        }

        await new Promise((resolve) => {
            this.getProject();
            this.setState({ loading: false, newChangesLinesICA: [], newChangesLinesAP: [] });
            toast.success('¡Cambios Realizados con Exito!');
            resolve();
        });
    };
    SetChangesLines = (table, Changes) => {
        if (table === 'ICA') {
            return this.setState({ newChangesLinesICA: Changes })
        }
        return this.setState({ newChangesLinesAP: Changes })
    };

    AddMovement = (table, i, movement) => {
        const { project } = this.state;
        if (table === 'ICA') {
            project.productsICA[i].movements.push(movement);
            return this.setState({ project });
        }
        project.productsAP[i].movements.push(movement);
        return this.setState({ project });
    };

    render() {
        const { showListMain, showListSec, project, loading, loading3, whsICA, whsAP, newChangesLinesICA, newChangesLinesAP } = this.state;
        if (project && (project.status !== 'A')) {
            return <Redirect to="/almacen/proyectos-de-ingeneria/listado" />
        }

        const breadcrumb = [
            { title: 'Home', url: '/' },
            { title: 'Proyectos de Ingeneria', url: '/almacen/proyectos-de-ingeneria/listado' },
            { title: 'Gestionar Proyecto', url: '' },
        ];
        return (
            <React.Fragment>
                <PageHeader header="Gestionar Proyecto" breadcrumb={breadcrumb} />
                <div className="container">
                    <div className="row mt-4">
                        {loading3 && (
                            <div className="col-md-12 mt-5 mb-5 pt-5 pb-5">
                                <div className="div-loading" />
                            </div>
                        )}
                        <div className="col-md-6">
                            {project && (
                                <React.Fragment>
                                    <table width="100%" className="table table-sm">
                                        <tbody>
                                            <tr>
                                                <th width="30%">Empresa Encargada</th>
                                                <td width="70%" align="right">{project.company.name}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Ingeniero Encargado</th>
                                                <td width="70%" align="right">{project.engineer}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Código</th>
                                                <td width="70%" align="right">{project.mainProject.code}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Proyecto</th>
                                                <td width="70%" align="justify">{project.mainProject.name}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Estado</th>
                                                <td width="70%" align="right">{statusBadge(project.status)}</td>
                                            </tr>
                                            {project && project.subProject && (
                                                <React.Fragment>
                                                    <tr>
                                                        <th width="30%">Subproyecto</th>
                                                        <td width="70%" align="right">{project.subProject.code}</td>
                                                    </tr>
                                                    <tr>
                                                        <th width="30%">Orden de Venta</th>
                                                        <td width="70%" align="right">{!project.subProject.orden_venta ? <span className="badge badge-danger">Proyecto sin Orden de Venta</span> : project.subProject.orden_venta}</td>
                                                    </tr>
                                                </React.Fragment>
                                            )}
                                            <tr>
                                                <th width="30%"></th>
                                                <td width="70%" align="right"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <Link className="btn btn-info" to={`/proyectos-de-ingeneria/imprimir/${project.id}`} onClick={() => sessionStorage.setItem('PrintEngpr', JSON.stringify(project))} target="__Blank"><i className="fas fa-print mr-2" />Imprimir</Link>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="col-md-6">
                            {project && (
                                <>
                                    <table width="100%" className="table table-sm">
                                        <tbody>
                                            <tr>
                                                <th width="30%">Cód. Cliente</th>
                                                <td width="70%" align="right">{project.mainProject.code_cliente}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Cliente</th>
                                                <td width="70%" align="right">{project.mainProject.name_cliente}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Orden de Venta</th>
                                                <td width="70%" align="right">{project.mainProject.orden_venta}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Fecha Inicio</th>
                                                <td width="70%" align="right">{project.dateCreated}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Fecha Entrega</th>
                                                <td width="70%" align="right">{project.dateFinish}</td>
                                            </tr>
                                            {project && project.status === 'finished' && (
                                                <tr>
                                                    <th width="30%">Fecha Finalizado</th>
                                                    <td width="70%" align="right">{project.dateFinished}</td>
                                                </tr>
                                            )}
                                            <tr>
                                                <th width="30%">{`Productos ${project.company.code}`}</th>
                                                <td width="70%" align="right"><InputSwitch checked={showListMain} onChange={(e) => this.setState({ showListMain: e.value })} /></td>
                                            </tr>
                                            {project && project.subProject && (
                                                <tr>
                                                    <th width="30%">{`Productos ${project.company.code !== 'ICA' ? 'ICA' : 'AP'}`}</th>
                                                    <td width="70%" align="right"><InputSwitch checked={showListSec} onChange={(e) => this.setState({ showListSec: e.value })} /></td>
                                                </tr>
                                            )}
                                            <tr>
                                                <th width="30%"></th>
                                                <td width="70%" align="right"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="p-d-flex p-jc-between">
                                        <label>Solicitados a Bodega:</label>
                                        <div className="p-d-flex p-flex-column">
                                            <label htmlFor="p1">P1</label>
                                            <InputSwitch disabled tooltipOptions={{ position: 'top' }} tooltip="Prioridad 1" checked={project.p1} inputId="p1" />
                                        </div>
                                        <div className="p-d-flex p-flex-column">
                                            <label htmlFor="p2">P2</label>
                                            <InputSwitch disabled tooltipOptions={{ position: 'top' }} tooltip="Prioridad 2" checked={project.p2} inputId="p2" />
                                        </div>
                                        <div className="p-d-flex p-flex-column">
                                            <label htmlFor="p3">P3</label>
                                            <InputSwitch tooltipOptions={{ position: 'top' }} disabled tooltip="Prioridad 3" checked={project.p3} inputId="p3" />
                                        </div>
                                        <div className="p-d-flex p-flex-column">
                                            <label htmlFor="p4">P4</label>
                                            <InputSwitch tooltipOptions={{ position: 'top' }} disabled tooltip="Prioridad 4" checked={project.p4} inputId="p4" />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <br />
                <div className="container-fluid">
                    {project && showListMain && (
                        <React.Fragment>
                            <h5>Listado de Materiales de Proyecto</h5>
                            <ProductsTableManager
                                SetChangesLines={this.SetChangesLines}
                                SetChanges={this.SetChanges}
                                projectId={project.id}
                                whs={project.company.code === 'ICA' ? whsICA : whsAP}
                                products={project.company.code === 'ICA' ? project.productsICA : project.productsAP}
                                table={project.company.code}
                                whsSelect={project.company.code === 'ICA' ? { code: '01', label: 'Bodega 01', value: 1, email: 'warehouse01@icautomatizados.com' } : { code: '01', label: 'Insumos', value: 4, email: '' }}
                                RemoveLine={this.RemoveLine}
                                AddLine={this.AddLine}
                                AddMovement={this.AddMovement}
                                status={project.status}
                            />
                            <br />
                        </React.Fragment>
                    )}
                    {project && showListSec && (
                        <React.Fragment>
                            <h5>Listado de Materiales Subproyecto</h5>
                            <ProductsTableManager
                                SetChangesLines={this.SetChangesLines}
                                SetChanges={this.SetChanges}
                                projectId={project.id}
                                whs={project.company.code !== 'ICA' ? whsICA : whsAP}
                                products={project.company.code !== 'ICA' ? project.productsICA : project.productsAP}
                                table={project.company.code !== 'ICA' ? 'ICA' : 'AP'}
                                whsSelect={project.company.code !== 'ICA' ? { code: '01', label: 'Bodega 01', value: 1, email: 'warehouse01@icautomatizados.com' } : { code: '01', label: 'Insumos', value: 4, email: '' }}
                                RemoveLine={this.RemoveLine}
                                AddLine={this.AddLine}
                                AddMovement={this.AddMovement}
                                status={project.status}
                            />
                            <br />
                        </React.Fragment>
                    )}

                    {project && project.mainProject && project.status !== 'finished' && (newChangesLinesICA.length > 0 || newChangesLinesAP.length > 0) && (
                        <button type="button" className="btn btn-primary mr-3" onClick={this.Save} disabled={loading}><i className={`${loading ? 'fas fa-cog fa-spin' : 'fas fa-save'} mr-2`} />Guardar</button>
                    )}
                </div>
            </React.Fragment >
        );
    }
}
const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(EngprojectManage);
