import React, { Component, Fragment } from "react";
import { CSVReader } from "react-papaparse";
import { toast } from "react-toastify";
import moment from "moment";
import { getUsersByEmail } from "../../store/directusSdk";
import { isTimeFormar } from "../shared/utilities";

export default class ImportCsv extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            header: [],
        };
    }

    handleOnDrop = (data) => {
        const header = data.shift();
        this.setState({ errors: [], header, data });

        const name = header.data.findIndex((e) => e === "nombre");
        if (name === -1) {
            return toast.error("¡Error, falta la columna nombre!");
        }
        const plan = header.data.findIndex((e) => e === "planificado");
        if (plan === -1) {
            return toast.error("¡Error, falta la columna planificado!");
        }
        const resources = header.data.findIndex((e) => e === "recursos");
        if (resources === -1) {
            return toast.error("¡Error,  falta la columna recursos!");
        }
        const inicio = header.data.findIndex((e) => e === "inicio");
        if (inicio === -1) {
            return toast.error("¡Error,  falta la columna inicio!");
        }
        const allDay = header.data.findIndex((e) => e === "dia");

        const { uploadTask, engproject_id, setErrorsEmails } = this.props;
        const tasks = [];
        const emails = [];
        data.forEach((e) => {
            if (e.data.length > 2) {
                const resourcess = [];

                if (e.data[resources].length > 0) {
                    const rows = e.data[resources].split("_");
                    if (rows.length > 1) {
                        rows.forEach((r) => {
                            if (r !== "") {
                                const email = r.trim();
                                if (!emails.includes(email)) {
                                    emails.push(email);
                                }
                                resourcess.push(email);
                            }
                        });
                    } else {
                        const email = e.data[resources].trim();
                        if (!emails.includes(email)) {
                            emails.push(email);
                        }
                        resourcess.push(email);
                    }
                }

                const task = {
                    title: e.data[name],
                    resources: resourcess,
                    start: moment(e.data[inicio], "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
                    time_plan: isTimeFormar(e.data[plan]),
                    planned_date: moment().format("YYYY-MM-DD"),
                    engproject_id,
                    end: moment(e.data[inicio], "DD/MM/YYYY HH:mm:ss").add(moment.duration(e.data[plan])).format("YYYY-MM-DD HH:mm:ss"),
                };
                if (e.data[allDay] !== -1) {
                    task.allDay = e.data[allDay] === "si";
                }
                tasks.push(task);
            }
        });

        const err = [];
        getUsersByEmail(emails)
            .then((users) => {
                tasks.map((t) => {
                    const recursos = [];
                    t.resources.forEach((r) => {
                        r.trim();
                        const usr = users.find((user) => user.email === r + "@automation-power.com" || user.email === r + "@icautomatizados.com");
                        if (usr) {
                            recursos.push(usr);
                        } else {
                            if (!err.includes(r + "@automation-power.com")) {
                                err.push(r + "@automation-power.com", r + "@icautomatizados.com");
                            }
                        }
                    });
                    t.resources = recursos;
                    return t;
                });
                setErrorsEmails(err);
                uploadTask(tasks);
            })
            .catch((err) => toast.error("¡Error!, " + err.message));
    };

    handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
    };

    handleOnRemoveFile = (data) => {
        return this.setState({ errors: [] });
    };

    render() {
        const { errors,  } = this.state;       
        return (
            <Fragment>
                {errors.length > 0 && (
                    <div className="alert alert-danger">
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Error</th>
                                </tr>
                            </thead>
                            <tbody>
                                {errors.map((e, i) => (
                                    <tr key={i}>
                                        <th>{e.value}</th>
                                        <th>{e.message}</th>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                <div style={{ width: "100%", height: 200 }}>
                    <CSVReader
                        onDrop={this.handleOnDrop}
                        onError={this.handleOnError}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={this.handleOnRemoveFile}
                    >
                        <span>Arrastra y Suelta el archivo CSV aquí o haz clic para cargarlo.</span>
                    </CSVReader>
                </div>
            </Fragment>
        );
    }
}
