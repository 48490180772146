// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import Indicator from './Indicator';
import { Cart20Svg, Cross10Svg } from '../../svg';
import { cartRemoveItem } from '../../store/cart';


function IndicatorCart(props) {
    const { cart, cartRemoveItem } = props;
    let dropdown;

    const totals = (
        <React.Fragment>
            <tr>
                <th>Subtotal</th>
                <td><Currency value={cart.subtotal} /></td>
            </tr>
            <tr>
                <th>ITBMS</th>
                <td>
                    <Currency value={cart.itbms} />
                </td>
            </tr>
        </React.Fragment>
    );


    const items = cart.items.map((item) => {
        let image;

        if (item.product.images.length) {
            image = (
                <div className="dropcart__product-image">
                    <Link to={`/ventas/product/${item.product.code.replace(/\//g, '-')}/${item.product.id}`}>
                        <img src={item.product.images[0]} alt="" />
                    </Link>
                </div>
            );
        }

        const removeButton = (
            <AsyncAction
                action={() => cartRemoveItem(item.id)}
                render={({ run, loading }) => {
                    const classes = classNames('dropcart__product-remove btn btn-light btn-sm btn-svg-icon', {
                        'btn-loading': loading,
                    });

                    return (
                        <button type="button" onClick={run} className={classes}>
                            <Cross10Svg />
                        </button>
                    );
                }}
            />
        );

        return (
            <div key={item.id} className="dropcart__product">
                {image}
                <div className="dropcart__product-info">
                    <div className="dropcart__product-name">
                        <Link to={`/ventas/product/${item.product.code.replace(/\//g, '-')}/${item.product.id}`}>{item.product.name}</Link>
                    </div>
                    <div className="dropcart__product-meta">
                        <span className="dropcart__product-quantity">{item.quantity}</span>
                        {' × '}
                        <span className="dropcart__product-price"><Currency value={item.price} /></span>
                    </div>
                </div>
                {removeButton}
            </div>
        );
    });

    if (cart.quantity) {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__products-list">
                    {items}
                </div>

                <div className="dropcart__totals">
                    <table>
                        <tbody>
                            {totals}
                            <tr>
                                <th>Total</th>
                                <td><Currency value={cart.total} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="dropcart__buttons">
                    <Link className="btn btn-secondary" to="/ventas/cart">Ir al Carrito</Link>
                </div>
            </div>
        );
    } else {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__empty">
                    ¡Su Carrito de compras está vacío!
                </div>
                <div className="dropcart__buttons">
                    <Link className="btn btn-secondary" to="/ventas/cart">Ir al Carrito</Link>
                </div>
            </div>
        );
    }

    return (
        <Indicator url="/ventas/cart" dropdown={dropdown} value={cart.quantity} icon={<Cart20Svg />} id="cart"/>
    );
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    currentUser: state.currentUser,
});

const mapDispatchToProps = {
    cartRemoveItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorCart);
