// react
import React from 'react';
import InputNumber from '../shared/InputNumber';

export default function ChangeQtty({ value, i, onChange }) {

    const HandleCant = (cant) => {
        return onChange(cant);
    };

    return (
        <InputNumber min={1} value={value} onChange={HandleCant} />
    );
}