// react
import React from 'react';

// third-party
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ClearCacheProvider } from 'react-clear-cache';
// application
import * as serviceWorker from './serviceWorker';
import App from './components/App';
import store from './store';
// styles
import 'slick-carousel/slick/slick.css';
import 'react-toastify/dist/ReactToastify.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
/* import 'react-input-range/lib/css/index.css';*/
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import './scss/style.scss';

ReactDOM.render((
    // eslint-disable-next-line react/jsx-filename-extension
    <ClearCacheProvider duration={600000} auto >
        <Provider store={store}>
            <App />
        </Provider>
    </ClearCacheProvider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
