import fs from "file-saver";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import "../../../loadingTable.css";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import React, { useState, useEffect } from "react";
import TableItemsInventory from "./TableItemsInventory";
import ImportListProductsCSV from "../../../ImportListProductsCSV";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from "reactstrap";
import { GenerateExcelInventoryCustomerById, GenerateExcelInventoryCustomerMovements } from "../../../../shared/utilities";
import {
    getInventoryCustomersById,
    uploadInventory,
    getInventoriesMovementsCustomer,
    searchCategories,
    getCustomerByCode,
} from "../../../../../store/directusSdk";

import InventoryAddEach from "./InventoryAddEach";

function InventoryClientModal({ update, isAdmin, open, setOpen, type, inventoryId, company, currentUser }) {
    const Lodash = require("lodash");
    const [copy, setCopy] = useState([]);
    const [items, setItems] = useState([]);
    const [toggler, setToggler] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [inventory, setInventory] = useState(null);
    const haveChange = () => Lodash.isEqual(items, copy);
    const [mainLoading, setMainLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [categories, setCategories] = useState(null);

    const promiseOptions = (inputValue) => getCustomerByCode(company, inputValue);

    const getInventoy = () => {
        getInventoryCustomersById(company, inventoryId)
            .then((data) => {
                const dataCopy = [...data.items];
                setCopy(dataCopy);
                setInventory(data);
                setItems(data.items);
            })
            .catch((error) => console.log(error))
            .finally(() => setMainLoading(false));
    };

    const getCategories = () => {
        setMainLoading(true);
        searchCategories()
            .then(setCategories)
            .catch((error) => toast.error("¡Error!, " + error.message))
            .finally(() => setMainLoading(false));
    };

    const exportExcelInventoryProducts = () => {
        GenerateExcelInventoryCustomerById(inventory)
            .then((response) => {
                const blob = new Blob([response]);
                fs.saveAs(blob, `${inventory.cusName.toUpperCase()}.xlsx`);
            })
            .catch((error) => {});
    };

    const exportExcelInventoryMovements = () => {
        getInventoriesMovementsCustomer(inventory.id)
            .then((data) =>
                GenerateExcelInventoryCustomerMovements(data.sort((a, b) => b.mId - a.mId))
                    .then((response) => {
                        const blob = new Blob([response]);
                        fs.saveAs(blob, `${inventory.cusName.toUpperCase()}.xlsx`);
                    })
                    .catch((error) => {})
            )
            .catch((error) => console.log(error));
    };

    const ImportProducts = () => {
        const newInventory = {
            items:
                type === "U"
                    ? items
                    : items.map((item) => ({
                          code: item.code,
                          sku: item.code_manufacture,
                          commited: item.commited,
                          name: item.name,
                          stock: item.stock,
                      })),
        };
        const keyCustomerCompany = company === "ICA" ? "customer_ica" : "customer_ap";
        const value = type === "U" ? inventory.customerId : customer.value;

        newInventory[keyCustomerCompany] = value;

        const title = type === "U" ? "Actualizar Inventario." : "Crear un Inventario";
        const text =
            type === "U"
                ? "¿Está seguro que desea actualizar la lista de productos de este inventario?"
                : "¿Está seguro que desea crear un nuevo inventario?";

        Swal.fire({
            title,
            text,
            icon: "warning",
            confirmButtonColor: "#0059BD",
            cancelButtonColor: "#3d464d",
            buttons: true,
            dangerMode: true,
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: type === "U" ? "Actualizar" : "Guardar",
            preConfirm: () => {
                return uploadInventory(newInventory)
                    .then(() => {
                        if (type === "U") getInventoy();
                        if (type === "A") {
                            update();
                            setOpen(false);
                            setCustomer(null);
                        }
                    })
                    .catch((error) => toast.error(`Error!, ${error.message}`))
                    .finally(() => setMainLoading(false));
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                const title = type === "U" ? "¡Se ha actualizado el inventario exitosamente.!" : "¡Se ha Creado el inventario exitosamente.!";
                Swal.fire({
                    icon: "success",
                    title,
                });
            }
        });
    };

    useEffect(() => {
        if (type === "U") {
            setMainLoading(true);
            getInventoy();
            isAdmin && getCategories();
        }
    }, [inventoryId]);

    return (
        <Modal
            isOpen={open}
            toggle={() => {
                setOpen(false);
                update();
            }}
            centered
            size="lg"
            style={{ maxWidth: "1600px", width: "100%" }}
            zIndex="1000"
        >
            <ModalHeader
                toggle={() => {
                    setOpen(false);
                    update();
                }}
            >
                {mainLoading ? <div className="spinner-border text-primary" role="status" /> : inventory && inventory.inventoryName}
            </ModalHeader>
            <ModalBody>
                {isAdmin ? (
                    <div className="row ml-1">
                        <div className="col-md-12">
                            {inventory && (
                                <div className="row ">
                                    <div className="col-md-6 ">
                                        <div className="card col-md-12" style={{ width: "100%" }}>
                                            <div className="row" style={{ display: "flex" }}>
                                                <div className="col-md-12" style={{ display: "flex", flexDirection: "row" }}>
                                                    <div className="font-weight-bold">Nombre del Cliente:&nbsp;</div>
                                                    {inventory.cusName}
                                                </div>
                                                <div className="col-md-12 mt-1" style={{ display: "flex", flexDirection: "row" }}>
                                                    <div className="font-weight-bold">Código:&nbsp;</div>
                                                    {inventory.code}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <ImportListProductsCSV
                                            ImportProducts={(value) => setItems(value)}
                                            company={company}
                                            inventoryId={inventoryId}
                                            updateOnClean={getInventoy}
                                            setLoading={setTableLoading}
                                        />
                                    </div>
                                </div>
                            )}

                            {type === "A" && isAdmin && (
                                <div className="row ">
                                    <div className="col-md-6 card">
                                        <Label for="cust">Cliente</Label>
                                        <AsyncSelect
                                            id="cust"
                                            isClearable
                                            cacheOptions
                                            loadOptions={promiseOptions}
                                            noOptionsMessage={() => "No se Encontraron registros..."}
                                            loadingMessage={() => "Buscando..."}
                                            placeholder="Buscar Cliente..."
                                            onChange={setCustomer}
                                            value={customer}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <ImportListProductsCSV
                                            ImportProducts={(value) => setItems(value)}
                                            products={items}
                                            company={company}
                                            setLoading={setTableLoading}
                                            Type={type}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {isAdmin && (
                            <div style={{ width: "100%" }}>
                                <Button
                                    color="info"
                                    id="toggler"
                                    //disabled={loading}
                                    className="mb-2"
                                    onClick={() => setToggler((value) => !value)}
                                >
                                    Agregar Producto
                                    {toggler ? <i className="fas fa-sort-up ml-2"></i> : <i className="fas fa-sort-down ml-2"></i>}
                                </Button>

                                <InventoryAddEach
                                    categories={categories}
                                    products={items}
                                    updateTable={getInventoy}
                                    inventoryId={inventoryId}
                                    company={company}
                                    setProduct={setItems}
                                    currentUser={currentUser}
                                    type={type}
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <>
                        {inventory && (
                            <div className="card mb-1" style={{ width: "60%", marginLeft: "20%" }}>
                                <div className="row ml-2" style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        {" "}
                                        <div className="font-weight-bold">Nombre del Cliente:&nbsp;</div>
                                        {inventory.cusName}
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        {" "}
                                        <div className="font-weight-bold">Código:&nbsp;</div>
                                        {inventory.code}
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}

                <div className="row mt-5">
                    <div className="col-md-12 ">
                        <TableItemsInventory
                            company={company}
                            isAdmin={isAdmin}
                            items={items}
                            setItems={setItems}
                            loading={mainLoading || tableLoading}
                            type={type}
                            update={getInventoy}
                            excelProcut={exportExcelInventoryProducts}
                            excelMovements={exportExcelInventoryMovements}
                            currentUser={currentUser}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <>
                    {type === "A" && isAdmin && (
                        <Button color="primary" disabled={!(items.length > 0) || !customer} onClick={() => ImportProducts()}>
                            Guardar
                        </Button>
                    )}
                    {type === "U" && isAdmin && (
                        <Button color="primary" disabled={haveChange()} onClick={() => ImportProducts()}>
                            Actualizar
                        </Button>
                    )}
                    <Button
                        color="secondary"
                        onClick={() => {
                            update();
                            setOpen(false);
                        }}
                    >
                        <i className="fa fa-ban mr-2" />
                        Cancelar
                    </Button>
                </>
            </ModalFooter>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryClientModal);
