import React from 'react';
import ReactDOM from 'react-dom';
import { PDFViewer, Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import queryString from 'query-string';
// Create styles
const styles = StyleSheet.create({
    page: {
        fontSize: 9,
        marginBottom: 20,
    },

    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'baseline',
        width: '100%',
        padding: 10,
    },

    section: {
        flexGrow: 1,
    },

    subtitule: {
        margin: 1,
        padding: 1,
        width: '15%',
        fontWeight: 'bold',
        fontFamily: 'Helvetica-Bold',
    },

    value: {
        margin: 1,
        padding: 1,
        width: '12%',
    },

    thead: {
        flexDirection: 'row',
        border: 1,
        backgroundColor: '#A9D0F5',
        margin: '10 20 0 20',
        textAlign: 'center'

    },

    tbody: {
        flexDirection: 'row',
        textAlign: 'center',
        margin: '10 20 0 20',
        fontSize: 8
    },

    th: {
        margin: '3, 1',
        padding: '3, 1',
        textAlign: 'center',
        fontFamily: 'Helvetica-Bold'
    },

    td: {
        margin: '1, 1',
        padding: '1, 1',
        textAlign: 'center'
    },


});
const { tab } = queryString.parse(window.location.search);
const project = sessionStorage.getItem('faltCompPry') ? JSON.parse(sessionStorage.getItem('faltCompPry')) : null;

// Create Document Component
const MyDocument = () => (
    <Document title={`Faltantes de Proyecto ${project.code}`}>
        <Page size="LETTER" style={styles.page}>
            <View style={{ ...styles.row, margin: 0, padding: 0 }} fixed>
                <Image src="images/header.png" style={{ width: '100%' }} />
            </View>

            <Text style={{ fontSize: 10, textAlign: 'center', marginTop: 10, fontFamily: 'Helvetica-Bold' }}>{`Faltantes de Proyecto ${project.code}`}</Text>
            <View style={styles.thead}>
                <Text style={{ ...styles.th, width: '3%' }}>#</Text>
                <Text style={{ ...styles.th, width: '13%', textAlign: 'left' }}>Código</Text>
                <Text style={{ ...styles.th, width: tab === 'ICA' ? '10%' : '8%', textAlign: 'center' }}>Stock 01</Text>
                <Text style={{ ...styles.th, width: tab === 'ICA' ? '10%' : '8%', textAlign: 'center' }}>{tab === 'ICA' ? 'Stock 05:' : 'Stock 02'}</Text>
                {tab === 'AP' && <Text style={{ ...styles.th, width: '8%', textAlign: 'center' }}>Stock 03</Text>}
                <Text style={{ ...styles.th, width: tab === 'ICA' ? '10%' : '9%', textAlign: 'center' }}>Comprom.</Text>
                <Text style={{ ...styles.th, width: tab === 'ICA' ? '10%' : '9%', textAlign: 'center' }}>Por Llegar</Text>
                <Text style={{ ...styles.th, width: tab === 'ICA' ? '10%' : '9%', textAlign: 'center' }}>Disp. SAP</Text>
                <Text style={{ ...styles.th, width: tab === 'ICA' ? '10%' : '9%', textAlign: 'center' }}>Disp. Real</Text>
                <Text style={{ ...styles.th, width: tab === 'ICA' ? '10%' : '10%', textAlign: 'center' }}>Disponible Físico</Text>
                <Text style={{ ...styles.th, width: tab === 'ICA' ? '10%' : '10%', textAlign: 'center' }}>Por Comprar</Text>
            </View>
            {project.products.map((e, i) => (
                <View style={styles.tbody} key={i} break={i !== 0 && i % 27 === 0}>
                    <Text style={{ ...styles.td, width: '3%' }}>{i + 1}</Text>
                    <Text style={{ ...styles.td, width: '13%', textAlign: 'left' }}>{e.code}</Text>
                    <Text style={{ ...styles.td, width: tab === 'ICA' ? '10%' : '8%', textAlign: 'center' }}>{e.bod01}</Text>
                    <Text style={{ ...styles.td, width: tab === 'ICA' ? '10%' : '8%', textAlign: 'center' }}>{e[tab === 'ICA' ? 'bod05' : 'bod02']}</Text>
                    {tab === 'AP' && <Text style={{ ...styles.td, width: '8%', textAlign: 'center' }}>{e.bod03}</Text>}
                    <Text style={{ ...styles.td, width: tab === 'ICA' ? '10%' : '9%', textAlign: 'center' }}>{e.IsCommited}</Text>
                    <Text style={{ ...styles.td, width: tab === 'ICA' ? '10%' : '9%', textAlign: 'center' }}>{e.sap_onorder}</Text>
                    <Text style={{ ...styles.td, width: tab === 'ICA' ? '10%' : '9%', textAlign: 'center' }}>{e.sap_available}</Text>
                    <Text style={{ ...styles.td, width: tab === 'ICA' ? '10%' : '9%', textAlign: 'center' }}>{e.available}</Text>
                    <Text style={{ ...styles.td, width: tab === 'ICA' ? '10%' : '10%', textAlign: 'center' }}>{e.faltEntregar}</Text>
                    <Text style={{ ...styles.td, width: tab === 'ICA' ? '10%' : '10%', textAlign: 'center' }}>{e.faltComprar}</Text>
                </View>
            ))}
        </Page>
    </Document >
);

const App = () => (
    <PDFViewer width="100%" height="100%">
        <MyDocument />
    </PDFViewer>
);

const Render = () => ReactDOM.render(<App />, document.getElementById('root'));

export default Render;