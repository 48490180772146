// react
import React, { useState } from 'react';
// third-party
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import queryString from 'query-string'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import PageHeader from '../shared/PageHeader';
import { resetedPassword } from '../../store/directusSdk';


function ResetPassword(props) {
    const { location: { search }, history } = props;
    const params = queryString.parse(search);
    if (!params.token) {
        history.replace('/account/login');
        return null;
    }
    const breadcrumb = [
        { title: 'Cuenta', url: 'account/login' },
        { title: 'Reestablecer Contraseña', url: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>Reestablecer Contraseña</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="document">
                        <div className="document__header">
                            <div align="center">
                                <img src="images/enter_password.png" alt="" />
                                <br />
                                <br />
                                <h3 className="document__title">Nueva Contraseña</h3>
                                <span>Para finalizar, procesa a ingresar una nueva contraseñapara su cuenta.</span>
                                <br />
                                <br />

                                <Formik
                                    initialValues={{ password: '', repeatPassword: '' }}
                                    validationSchema={Yup.object({
                                        password: Yup.string()
                                            .required('Campo requerido.')
                                            .min(6, 'Minimo 6 caracteres.'),
                                        repeatPassword: Yup.string()
                                            .required('Campo requerido.')
                                            .oneOf([Yup.ref('password')], 'Contraseñas no coinciden.'),
                                    })}
                                    onSubmit={async (values) => {
                                        await resetedPassword(params.token, values.password)
                                            .then(() => {
                                                toast.success('¡Correo Electronico Enviado con Exito!');
                                                history.replace('/cuenta/reestablecer-contrasena/true');
                                            }).catch((error) => {
                                                if (error.code === '105') {
                                                    toast.error('¡Su Token ha Expirado!');
                                                    history.replace('/cuenta/reestablecer-contrasena/false');
                                                }
                                            });
                                    }}
                                >
                                    {({ errors, touched, isSubmitting }) => (
                                        <Form className="footer-newsletter__form">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label className="sr-only" htmlFor="password">Nueva Contraseña</label>
                                                    <Field
                                                        name="password"
                                                        type="password"
                                                        id="password"
                                                        className={`footer-newsletter__form-input form-control ${errors.password && touched.password && 'is-invalid'}`}
                                                        placeholder="Nueva Contraseña..."
                                                    />
                                                    <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label className="sr-only" htmlFor="repeatPassword">Repita Contraseña</label>
                                                    <Field
                                                        name="repeatPassword"
                                                        type="password"
                                                        id="repeatPassword"
                                                        className={`footer-newsletter__form-input form-control ${errors.repeatPassword && touched.repeatPassword && 'is-invalid'}`}
                                                        placeholder="Repita Contraseña..."
                                                    />
                                                    <ErrorMessage name="repeatPassword" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <button className="footer-newsletter__form-button btn btn-primary" type="submit" disabled={isSubmitting}>{isSubmitting ? 'Eviando...' : 'Enviar'}</button>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
