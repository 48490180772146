// react
import React, { useState } from 'react';
import { Dropdown } from 'primereact/dropdown';

export default function UpdateWhs({ value, options, onChange }) {
    const [val, setval] = useState(value);

    const handleChange = ({ value }) => {
        setval(value);
        return onChange(value);
    };

    return (
        <Dropdown value={val} options={options} onChange={handleChange} style={{width: '90%'}} optionLabel="label" optionValue="value" />

    );
}