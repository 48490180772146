import React, { useRef, useState } from "react";
// third-party
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import moment from "moment";
import { msToTime, isTimeFormar } from "../shared/utilities";

export default function TableTaskReport({ project, renderStatus }) {
    const [activities, setActivities] = useState(
        project.activities.map((a) => {
            let recursos = "";
            a.resources.forEach((r) => {
                recursos += r.email.substring(0, 20) + ". ";
            });

            a.recursos = recursos;
            return a;
        })
    );
    const sdt = useRef();

    const exportCSV = () => sdt.current.exportCSV();

    const exportExcel = () => {
        import("xlsx").then((xlsx) => {
            const tasks = {};
            const items = [];
  
            activities.forEach((e) => {
                if (!tasks.hasOwnProperty(e.tt)) {
                    tasks[e.tt] = [];
                }

                tasks[e.tt].push(e);
            });

            const today = moment();
            for (const key in tasks) {
                items.push(
                    ...tasks[key].map((e) => ({
                        Id: e.id,
                        Actividad: e.title,
                        "Tiempo Planeado": isTimeFormar(e.time_plan),
                        "Tiempo Real": isTimeFormar(e.time_real),
                        "Dia Planificado": e.planned_date && moment(e.planned_date).format("DD-MM-YYYY"),
                        Recursos: e.recursos,
                        Inicio: e.start && moment(e.start).format("DD-MM-YYYY HH:mm:ss"),
                        Fin: e.end && moment(e.end).format("DD-MM-YYYY HH:mm:ss"),
                        "Días de Retraso": e.status === "Pendiente" || e.status === "Pausada" ? moment(today).diff(e.start, "days") : "",
                        Comentarios: e.comment,
                        "Iniciada Por": e.iniciadapor && e.iniciadapor.first_name + " " + e.iniciadapor.last_name,
                        "Pausada Por": e.pausadapor && e.pausadapor.first_name + " " + e.pausadapor.last_name,
                        "Terminada Por": e.terminadapor && e.terminadapor.first_name + " " + e.terminadapor.last_name,
                        "Todo el Dia": e.allDay ? "Si" : "No",
                        Estado: e.status,
                    }))
                );

                if (tasks[key].length > 1) {
                    const total = tasks[key].reduce(
                        (acum, t) => {
                            acum.plan = acum.plan + moment.duration(t.time_plan);
                            acum.real = acum.real + moment.duration(t.time_real);

                            return acum;
                        },
                        { plan: 0, real: 0 }
                    );

                    items.push({
                        Id: "",
                        Actividad: "TOTAL TIEMPOS",
                        "Tiempo Planeado": msToTime(total.plan),
                        "Tiempo Real": msToTime(total.real),
                        Recursos: "DIFERENCIA",
                        Inicio: msToTime(total.real - total.plan),
                        Fin: "",
                        "Iniciada Por": "",
                        "Pausada Por": "",
                        "Terminada Por": "",
                        "Todo el Dia": "",
                        Estado: "",
                    });
                }
            }

            const worksheet = xlsx.utils.json_to_sheet(items);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer = xlsx.write(workbook, { bookType: "xlsx", type: "array" });
            saveAsExcelFile(excelBuffer, project.code);
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import("file-saver").then((FileSaver) => {
            let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            let EXCEL_EXTENSION = ".xlsx";
            const data = new Blob([buffer], {
                type: EXCEL_TYPE,
            });
            FileSaver.saveAs(data, fileName + " " + moment().format("DD-MM-YYYY HH:mm:ss") + EXCEL_EXTENSION);
        });
    };

    const header = (
        <div className="p-d-flex p-jc-between">
            <Button
                label="Exportar CSV"
                icon="pi pi-file"
                className="p-button-primary"
                iconPos="right"
                disabled={!project.activities.length > 0}
                onClick={exportCSV}
            />
            <Button
                label="Exportar Excel"
                icon="pi pi-file-excel"
                className="p-button-success"
                iconPos="right"
                disabled={!project.activities.length > 0}
                onClick={exportExcel}
            />
        </div>
    );

    return (
        <React.Fragment>
            <div className="card">
                <DataTable
                    ref={sdt}
                    header={header}
                    dataKey="id"
                    value={activities}
                    paginator
                    rows={10}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    removableSort
                    emptyMessage="No se han encontrado registros..."
                    rowHover
                    className="p-datatable-sm"
                    exportFilename={`Actividades - ${project.code}  ${moment().format("DD-MM-YYYY hh-mm a")}`}
                >
                    <Column
                        field="title"
                        header="Nombre"
                        sortable
                        filter
                        style={{ width: "31%" }}
                        filterPlaceholder="Todos"
                        filterMatchMode="contains"
                    />
                    <Column field="time_plan" header="Plan" sortable filter style={{ width: "10%", textAlign: "center" }} />
                    <Column field="time_real" header="Real" sortable filter style={{ width: "10%", textAlign: "center" }} />
                    <Column
                        field="recursos"
                        header="Recursos"
                        sortable
                        filter
                        style={{ width: "22%", textAlign: "center" }}
                        filterMatchMode="contains"
                        filterPlaceholder="Todos"
                    />
                    <Column
                        field="start"
                        header="Inicio"
                        body={(row) => moment(row.start).format("DD-MM-YYYY")}
                        sortable
                        filter
                        style={{ width: "16%", textAlign: "center" }}
                        filterType="date"
                    />
                    <Column
                        field="status"
                        header="Estado"
                        body={(row) => <Badge value={row.status} severity={renderStatus(row.status)} />}
                        sortable
                        filter
                        style={{ width: "10%", textAlign: "center" }}
                    />
                </DataTable>
            </div>
        </React.Fragment>
    );
}
