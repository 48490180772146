// react
import React, { useEffect, useState } from 'react';
// third-party
import { Helmet } from 'react-helmet-async';
import { getDeliveriesConfirm } from '../../store/directusSdk';
import PageHeader from '../shared/PageHeader';
import UpdateQtty from './UpdateQtty';
import ProductsTableConfirm from './ProductsTableConfirm';

export default function ConfirmDelivery() {
    const [productsICA, setOrdersICA] = useState([]);
    const [productsAP, setOrdersAP] = useState([]);

    const GetProjects = (collection, SetOrders) => getDeliveriesConfirm(collection).then((products) => {
        products.map((e) => {
            e.confirmed = <UpdateQtty value={0} />
        })
        SetOrders(products);
    });

    useEffect(() => {
        GetProjects('engprojectlinesmovementsica', setOrdersICA);
        GetProjects('engprojectlinesmovementsap', setOrdersAP);
    }, []);


    const breadcrumb = [
        { title: 'Home', url: '/' },
        { title: 'Proyectos de Ingenería', url: '/proyectos-de-ingeneria' },
        { title: 'Entregas por Confirmar', url: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>Entregas por Confirmar - Ingeniería y Controles Automatizados</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />
            <div className="container-fluid mt-4">
                <ProductsTableConfirm products={productsICA} table="ICA" setProducts={setOrdersICA} GetProjects={GetProjects} />
                <div className="card-divider mb-4" />
                <ProductsTableConfirm products={productsAP} table="AP" setProducts={setOrdersAP} GetProjects={GetProjects} />
            </div>
        </React.Fragment>
    );
}