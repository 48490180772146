// react
import React, { Component } from 'react';
// third-party
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { DataTable } from 'primereact/datatable';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Badge, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import moment from 'moment';
import { UpdateItems, CreateItems, GenerateComprobantDelivery, getProductsWhs, BatchReturnInvExtern, BatchDeliveriesInvExtern } from '../../store/directusSdk';
import '../engprojects/tableStyle.css';

class ProductsTableOrders extends Component {
    constructor(props) {
        super(props);
        this.options = [
            { label: 'P1', value: 'P1' },
            { label: 'P2', value: 'P2' },
            { label: 'P3', value: 'P3' },
            { label: 'P4', value: 'P4' }
        ]
        this.state = {
            selectedRows: [],
            productos: [],
            errors: [],
            whsSelect: null,
            typeSelect: null,
            loading: false,
            loading2: false,
            open: false,
            prioritySelect: null
        };
    }

    AddChange = (row, value) => {
        const { products, setProducts } = this.props;
        const i = products.findIndex((item) => item.id === row.id);
        if (row.type === 'order') {
            products[i].delivered_qty = value;
        } else {
            products[i].confirmed_qty = value;
        }
        return setProducts(products);
    };

    exportCSV = () => {
        return this.dt.exportCSV();
    }

    exportCSV2 = () => {
        return this.dt2.exportCSV();
    }

    ChangeQty = (row, value) => {
        const { products, setProducts } = this.props;
        const i = products.findIndex((item) => item.id === row.id);
        if (row.type === 'order') {
            products[i].delivered_qty = value;
        } else {
            products[i].confirmed_qty = value;
        }
        return setProducts(products);
    }

    deliveryOrders = () => {
        const { selectedRows } = this.state;
        if (selectedRows.length > 0) {
            this.setState({ loading: true });
            const { currentUser, table, GetProjects, setProducts, whs } = this.props;
            const collection = table === 'ICA' ? 'engprojectlinesmovementsica' : 'engprojectlinesmovementsap';

            const deliveries = [];
            const deliveriesExternal = [];
            const confirms = [];
            const confirmsExternal = [];
            const envios = [];

            selectedRows.forEach((e) => {
                if (e.type === 'return') {
                    if (e.warehouse === 'Inventario Externo') {
                        confirmsExternal.push({ engproject: e.engproject_id, code: e.code, confirmed_qty: e.confirmed_qty, id: e.id, confirmed_date: moment().format('YYYY-MM-DD HH:mm:ss'), confirmed_by: currentUser.id });
                    } else {
                        confirms.push({ id: e.id, confirmed_date: moment().format('YYYY-MM-DD HH:mm:ss'), confirmed_qty: e.confirmed_qty, confirmed_by: currentUser.id });
                    }
                } else {
                    delete e.confirmed;
                    envios.push(e);
                    if (e.warehouse === 'Inventario Externo') {
                        deliveriesExternal.push({ engproject: e.engproject_id, code: e.code, delivered_qty: e.delivered_qty, type: 'delivery', delivered_date: moment().format('YYYY-MM-DD HH:mm:ss'), delivered_by: currentUser.id, engprojectline_id: e.id });
                    } else {
                        deliveries.push({ type: 'delivery', delivered_date: moment().format('YYYY-MM-DD HH:mm:ss'), delivered_qty: e.delivered_qty, delivered_by: currentUser.id, engprojectline_id: e.id });
                    }
                }
            });

            const peticiones = [];
            if (confirms.length > 0) {
                peticiones[0] = UpdateItems(collection, confirms);
            }
            if (deliveries.length > 0) {
                peticiones[1] = CreateItems(collection, deliveries, { fields: 'id' });
            }
            if (confirmsExternal.length > 0) {
                peticiones[2] = BatchReturnInvExtern(table, confirmsExternal);
            }
            if (deliveriesExternal.length > 0) {
                peticiones[3] = BatchDeliveriesInvExtern(table, deliveriesExternal);
            }

            return Promise.all(peticiones)
                .then((res) => {
                    GetProjects(table === 'ICA' ? 'engprojectlinesica' : 'engprojectlinesap', whs, setProducts);
                    let items = [];
                    if (deliveriesExternal.length > 0 && res[3] && res[3].errors.length > 0) {
                        this.setState({ errors: res[3].errors });
                        items = envios.filter((env) => res[3].errors.some((err) => err.code === env.code && err.warehouse === env.warehouse));
                    } else {
                        items = envios;
                    }
                    if (items.length > 0) {
                        return GenerateComprobantDelivery('deliveries', { items, company: table })
                            .then((delivery) => window.open(`${process.env.REACT_APP_API_URL}deliveries/${delivery.id}.pdf`, '_blank'))
                            .catch((error) => console.log(error));
                    }
                })
                .then(() => toast.success('¡Procesado con Exito!'))
                .catch((error) => toast.error(`¡Error!, ${error.message}`))
                .finally(() => this.setState({ loading: false, selectedRows: [] }));
        }
    }

    onTypeChange = (e) => {
        this.setState({ typeSelect: e.value });
        return this.dt.filter(e.value, 'type', 'equals');
    };

    onWhsChange = (e) => {
        this.setState({ whsSelect: e.value });
        return this.dt.filter(e.value, 'warehouse', 'equals');
    };

    onPriorityChange = (e) => {
        this.setState({ prioritySelect: e.value });
        return this.dt.filter(e.value, 'priority', 'equals');
    };


    searchStock = () => {
        const { selectedRows } = this.state;
        if (selectedRows.length > 0) {
            this.setState({ loading2: true, open: true });
            const { table } = this.props;

            const codes = [];
            selectedRows.forEach((e) => {
                if (!codes.includes(e.code)) {
                    codes.push(e.code);
                }
            });

            return getProductsWhs(table === 'ICA' ? 'products' : 'products_ap', codes)
                .then((productos) => this.setState({ productos }))
                .catch((error) => toast.error(`¡Error!, ${error.message}`))
                .finally(() => this.setState({ loading2: false }));
        }
    }

    render() {
        const { selectedRows, loading, loading2, typeSelect, whsSelect, open, productos, errors, prioritySelect } = this.state;
        const { products, table } = this.props;
        const warehouses = products.reduce((tempArray, e) => (tempArray.includes(e.warehouse) ? tempArray : [...tempArray, e.warehouse]), []);
        const typeFilter = <Dropdown style={{ width: '100%' }} value={typeSelect} options={[{ label: 'Devolución', value: 'return' }, { label: 'Pedido', value: 'order' }]} onChange={this.onTypeChange} placeholder="Todos..." showClear />
        const whsPriority = <Dropdown style={{ width: '100%' }} value={prioritySelect} options={this.options} onChange={this.onPriorityChange} placeholder="Todos..." showClear />
        const whsFilter = <Dropdown style={{ width: '100%' }} value={whsSelect} options={warehouses} onChange={this.onWhsChange} placeholder="Todos..." showClear />

        const header = (
            <div className="row">
                <div className="col-md-6">
                    <h5>{`Materiales por Entregar ${table}`}</h5>
                </div>
                <div className="col-md-2 offset-md-4">
                    <Button color="info" onClick={this.exportCSV}><i className="fas fa-file-export mr-2" />Exportar</Button>
                </div>
            </div>
        );

        const header2 = (
            <div className="row">
                <div className="col-md-6">
                </div>
                <div className="col-md-2 offset-md-4">
                    <Button color="info" onClick={this.exportCSV2}><i className="fas fa-file-export mr-2" />Exportar</Button>
                </div>
            </div>
        );

        const footer = (
            <div className="row">
                <div className="col-md-4 ">
                    <Button color="primary" disabled={selectedRows.length < 1 || loading} onClick={this.deliveryOrders}><i className={`${loading ? 'fas fa-cog fa-spin' : 'fas fa-check-double'} mr-2`} />Confirmar</Button>
                    <Button color="info ml-5" disabled={selectedRows.length < 1 || loading2} onClick={this.searchStock}><i className={`${loading2 ? 'fas fa-cog fa-spin' : 'fas fa-search'} mr-2`} /> Ver Stock</Button>
                </div>
            </div>
        );

        return (
            <div className="datatable-rowexpansion-demo">
                {errors.length > 0 && errors.map((e, i) => (
                    <div className="container">
                        <div key={i} className="alert alert-danger mb-2" style={{ fontSize: '12px' }}>{e.message}</div>
                    </div>
                ))}
                <DataTable
                    ref={(el) => this.dt = el}
                    value={products}
                    header={header}
                    footer={footer}
                    paginator
                    rows={10}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    removableSort
                    emptyMessage="No se han encontrado registros..."
                    className="p-datatable-customers mt-4"
                    rowHover
                    selection={selectedRows}
                    onSelectionChange={(e) => this.setState({ selectedRows: e.value })}
                >
                    <Column selectionMode="multiple" style={{ width: '3em' }} />
                    <Column field="type" header="Tipo" sortable filter style={{ width: '8%' }} filterPlaceholder="Todos" filterElement={typeFilter} body={(row) => <Badge color={row.type === 'order' ? 'info' : 'warning'}>{row.type === 'order' ? 'PEDIDO' : 'DEVOLUCIÓN'}</Badge>} />
                    <Column field="pryCode" header="Proyecto" sortable filter style={{ width: '10%' }} filterPlaceholder="Todos" />
                    <Column field="code" header="Código" sortable filter style={{ width: '10%' }} filterPlaceholder="Todos" />
                    <Column field="name" header="producto" sortable filter style={{ width: '20%' }} filterPlaceholder="Todos" />
                    <Column field="priority" header="Prioridad" sortable filter style={{ width: '10%' }} filterPlaceholder="Todos" filterElement={whsPriority} />
                    <Column field="warehouse" header="Bodega" sortable filter style={{ width: '10%' }} filterPlaceholder="Todos" filterElement={whsFilter} />
                    <Column field="encargado" header="Encargado" sortable filter style={{ width: '10%' }} filterPlaceholder="Todos" />
                    <Column field="orderQty" header="Pedido" sortable filter style={{ width: '8%' }} filterType="number" filterPlaceholder="Todos" />
                    <Column header="Entregado" style={{ width: '10%' }} body={(row) => <InputNumber inputStyle={{ width: '3em' }} max={row.orderQty} value={row.orderQty} onValueChange={({ value }) => this.ChangeQty(row, value)} min={1} showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-info" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" size={1} />} />
                </DataTable>

                <Modal isOpen={open} toggle={() => this.setState({ open: false })} centered size="xl">
                    <ModalHeader toggle={() => this.setState({ open: false })} >{'Stock por Bodega'}</ModalHeader>
                    <ModalBody>
                        {loading2 ? (
                            <div className="div-loading" style={{ height: 300 }} />
                        ) : (
                            <div>
                                {table === 'ICA' ? (
                                    <DataTable
                                        ref={(el) => this.dt2 = el}
                                        value={productos}
                                        paginator
                                        header={header2}
                                        rows={10}
                                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                        currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                                        rowsPerPageOptions={[10, 20, 50, 100]}
                                        removableSort
                                        emptyMessage="No se han encontrado registros..."
                                        className="p-datatable-sm"
                                        rowHover
                                    >
                                        <Column field="code" header="Código" sortable filter style={{ width: '28%' }} filterPlaceholder="Todos" filterHeaderClassName="p-inputtext-sm" />
                                        <Column field="stock01" header="Stock 01" sortable filter style={{ width: '18%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                        <Column field="stock05" header="Stock 05" sortable filter style={{ width: '18%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                        <Column field="commited01" header="Comprometido 01" sortable filter style={{ width: '18%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                        <Column field="commited05" header="Comprometido 05" sortable filter style={{ width: '18%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                    </DataTable>
                                ) :
                                    (
                                        <DataTable
                                            ref={(el) => this.dt2 = el}
                                            value={productos}
                                            header={header2}
                                            paginator
                                            rows={10}
                                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                            currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                                            rowsPerPageOptions={[10, 20, 50, 100]}
                                            removableSort
                                            emptyMessage="No se han encontrado registros..."
                                            className="p-datatable-sm"
                                            rowHover
                                        >
                                            <Column field="code" header="Código" sortable filter style={{ width: '19%' }} filterPlaceholder="Todos" filterHeaderClassName="p-inputtext-sm" />
                                            <Column field="stock01" header="Stock Insumos" sortable filter style={{ width: '13%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                            <Column field="stock02" header="Stock Equipos" sortable filter style={{ width: '13%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                            <Column field="stock03" header="Stock Sobrantes" sortable filter style={{ width: '13%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                            <Column field="commited01" header="Comprometido Insumos" sortable filter style={{ width: '14%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                            <Column field="commited02" header="Comprometido Equipos" sortable filter style={{ width: '14%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                            <Column field="commited03" header="Comprometido Sobrantes" sortable filter style={{ width: '14%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                        </DataTable>
                                    )}
                            </div>
                        )}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsTableOrders);
