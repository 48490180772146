// third-party
import React from "react";
import { MDBDataTableV5 } from "mdbreact";

export default function ExternalInventories({ products, loading , loading2}) {
    const dataTable = {
        columns: [
            { label: "SKU", field: "code_manufacture", width: 100 },
            { label: "Código", field: "code", width: 100 },
            { label: "Descripción", field: "name", width: 300 },
            { label: "Cantidad", field: "qty", width: 30 },
        ],
        rows: products,
    };
    const loadingTable = {
        pointerEvents: "none",
        opacity: " 0.5",
    };
    return (
        <React.Fragment>
            <div className="container">
                <div className="card">
                    <div className="card-body">
                        <h5 className="mt-3">Listado de productos</h5>
                        <div className="t-loading-table-container" style={loading || loading2 ? loadingTable : { width: "1" }}>
                            <MDBDataTableV5
                                hover
                                entriesOptions={[25, 50, 75, 100]}
                                entries={25}
                                pagesAmount={4}
                                data={dataTable}
                                pagingTop
                                searchTop
                                searchBottom={false}
                                entriesLabel="Mostar"
                                searchLabel="Buscar"
                                infoLabel={["Viendo", "al", "de", "registros"]}
                                paginationLabel={["Anterior", "Siguiente"]}
                                noRecordsFoundLabel="No se han encontrado registros..."
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
