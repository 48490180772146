// react
import React from 'react';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Badge } from 'reactstrap';

export default function SubTable({ fila }) {

    const RecibidoTemplate = (row) => {
        return row.confirmed_qty;
    }

    return (
        <DataTable value={fila.movements} className="p-datatable-sm">
            <Column header="Movimiento" body={(e) => <Badge color={e.type === 'delivery' ? 'info' : 'warning'}>{e.type === 'delivery' ? 'ENTREGA' : 'DEVOLUCIÓN'}</Badge>} style={{ width: '12%' }} />
            <Column field="delivered_qty" header="Cant. Enviada" style={{ width: '14%' }} />
            <Column header="Fecha" body={(e) => moment(e.delivered_date).format('DD/MM/YY hh:mm')} style={{ width: '16%' }} />
            <Column header="Responsable" body={(e) => `${e.delivered_by.first_name} ${e.delivered_by.last_name}`} style={{ width: '13%' }} />
            <Column header="Cant. Recibida" body={RecibidoTemplate} style={{ width: '14%' }} />
            <Column header="Fecha" body={(e) => (e.confirmed_date && moment(e.confirmed_date).format('DD/MM/YY hh:mm'))} style={{ width: '16%' }} />
            <Column header="Responsable" body={(e) => (e.confirmed_by && `${e.confirmed_by.first_name} ${e.confirmed_by.last_name}`)} style={{ width: '13%' }} />
        </DataTable>
    );

}