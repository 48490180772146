export const CART_ADD_ITEM = 'CART_ADD_ITEM';
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';
export const CART_UPDATE_QUANTITIES = 'CART_UPDATE_QUANTITIES';
export const CART_REMOVE_ALL_ITEMS = 'CART_REMOVE_ALL_ITEMS';
export const CHANGE_PRICE_ITEM = 'CHANGE_PRICE_ITEM';
export const CART_LOAD_ALL_ITEMS = 'CART_LOAD_ALL_ITEMS';
export const RECALCULATE_PRICES = 'RECALCULATE_PRICES';
export const CHANGE_COMMENTS = 'CHANGE_COMMENTS';
export const CHANGE_CONTACT = 'CHANGE_CONTACT';
export const CHANGE_CONTACTS = 'CHANGE_CONTACTS';
export const CHANGE_CUSTOMER = 'CHANGE_CUSTOMER';
export const CHANGE_TEXT_ITEM = 'CHANGE_TEXT_ITEM';
export const RECALCULATE_ITEM_PRICE = 'RECALCULATE_ITEM_PRICE';
export const CART_ADD_ITEM_SERV = 'CART_ADD_ITEM_SERV';
export const CHANGE_QTY = 'CHANGE_QTY';
export const CHANGE_VALIDITY = 'CHANGE_VALIDITY';
export const CHANGE_CONDITIONS = 'CHANGE_CONDITIONS';
export const CHANGE_EXEMPT_ITBMS = 'CHANGE_EXEMPT_ITBMS';
export const CHANGE_INCITBMS = 'CHANGE_INCITBMS';

