// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

// application
import AsyncSelect from 'react-select/async';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Indicator from '../header/Indicator';
import {
    Menu18x14Svg,
    Search20Svg,
    Heart20Svg,
    Cart20Svg,
    Person20Svg,
} from '../../svg';
import { mobileMenuOpen } from '../../store/mobile-menu';
import Search from '../header/Search';
import AccountLogin from '../account/AccountLogin';
import { getSuggestedCustomer, getSuggestedContacts } from '../../store/directusSdk';
import { ContactChange, ContactsChange, CustomerChange } from '../../store/cart';
import Account from '../account/Account';

class MobileHeader extends Component {
    constructor(props) {
        super(props);
        const { cart: { customer } } = props;
        Cookies.set("companyid", !customer ? 0 : customer.value, { path: "/", domain: '.icautomatizados.com', expires: 7 });

        this.state = {
            searchOpen: false,
            open2: false,
            select: customer,
        };
        this.searchInput = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        const { searchOpen } = this.state;

        if (searchOpen && searchOpen !== prevState.searchOpen && this.searchInput.current) {
            this.searchInput.current.focus();
        }
    }

    handleOpenSearch = () => {
        this.setState(() => ({ searchOpen: true }));
    };

    handleCloseSearch = () => {
        this.setState(() => ({ searchOpen: false }));
    };

    render() {
        const { openMobileMenu, wishlist, cart, history, currentUser, CustomerChange, ContactChange, ContactsChange, layout } = this.props;
        const { searchOpen, select, open2 } = this.state;
        const value = select ? select.value : 0;

        let SearchIndicator = null;
        let whitelistIndicator = null;
        let cartIndicator = null;

        if (layout === 'default') {
            SearchIndicator = (
                <Indicator
                    className="indicator--mobile indicator--mobile-search d-md-none"
                    onClick={this.handleOpenSearch}
                    icon={<Search20Svg />}
                />
            );
            whitelistIndicator = (
                <Indicator
                    className="indicator--mobile d-sm-flex d-none"
                    url="/ventas/wishlist"
                    value={wishlist.length}
                    icon={<Heart20Svg />}
                />
            );
            cartIndicator = (
                <Indicator
                    className="indicator--mobile"
                    url="/ventas/cart"
                    value={cart.quantity}
                    icon={<Cart20Svg />}
                />
            );
        }

        const promiseOptions = (inputValue) => getSuggestedCustomer(inputValue);

        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                color: state.isSelected ? 'white' : 'blue',
            }),
        }

        const searchClasses = classNames('mobile-header__search', {
            'mobile-header__search--open': searchOpen,
        });

        const dropdown = (
            <div className="account-menu">
                {!currentUser ? <AccountLogin /> : <Account history={history} />}
            </div>
        );

        return (
            <div className="mobile-header" style={{ marginBottom: (layout === 'default' ? 60 : 0) }}>
                <div className="mobile-header__panel">
                    <div className="container">
                        <div className="mobile-header__body">
                            <button type="button" className="mobile-header__menu-button" onClick={openMobileMenu}>
                                <Menu18x14Svg />
                            </button>
                            <Link to="/" className="mobile-header__logo"><img src="/images/logos/logoblanco.png" alt="ICAutomatizados" height="40px" /></Link>
                            <Search
                                context="mobile-header"
                                className={searchClasses}
                                inputRef={this.searchInput}
                                onClose={this.handleCloseSearch}
                            />
                            <div className="mobile-header__indicators">
                                {SearchIndicator}
                                {whitelistIndicator}
                                {cartIndicator}
                                <Indicator
                                    className="indicator--mobile"
                                    url="/account"
                                    dropdown={dropdown}
                                    icon={<Person20Svg />}
                                />
                            </div>
                        </div>
                        {layout === 'default' && (
                            <div className="mt-2">
                                <AsyncSelect
                                    cacheOptions
                                    loadOptions={promiseOptions}
                                    defaultOptions
                                    isClearable={true}
                                    noOptionsMessage={() => 'No se Encontró ningun cliente.'}
                                    loadingMessage={() => 'Buscando...'}
                                    placeholder="Ingrese un cliente..."
                                    onChange={(e) => this.setState({ select: e, open2: true })}
                                    value={select}
                                    styles={customStyles}
                                    defaultValue={cart.customer}
                                />
                                <br />
                                <Modal isOpen={open2} toggle={() => this.setState({ select: cart.customer, open2: false })} centered>
                                    <ModalHeader toggle={() => this.setState({ select: cart.customer, open2: false })}>Cambiar Cliente</ModalHeader>
                                    <ModalBody>
                                        ¿Esta seguro que desea cambiar el cliente?
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button
                                            color="primary"
                                            onClick={() => {
                                                Cookies.set("companyid", !select ? 0 : select.value, { path: "/", domain: '.icautomatizados.com', expires: 7 });
                                                CustomerChange(select);
                                                if (!select) {
                                                    ContactChange(null);
                                                }
                                                if (select) {
                                                    getSuggestedContacts(select.value).then((elements) => {
                                                        ContactChange(elements[0]);
                                                        ContactsChange(elements);
                                                    });
                                                }
                                                this.setState({ open2: false })
                                            }}
                                        >
                                            Continuar
                                        </Button>
                                        <Button
                                            color="secondary"
                                            onClick={() => this.setState({ select: cart.customer, open2: false })}
                                        >
                                            Cancelar
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    wishlist: state.wishlist,
});

const mapDispatchToProps = {
    openMobileMenu: mobileMenuOpen,
    ContactChange,
    ContactsChange,
    CustomerChange,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MobileHeader);
