import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Table } from "reactstrap";
import { Badge } from "primereact/badge";
import Alert from "sweetalert2";
import { toast } from "react-toastify";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import PageHeader from "../shared/PageHeader";
import { CreateItem, getMyTasks, UpdateItem, CreateItems } from "../../store/directusSdk";
import ActionsButtom from "./ActionsButtom";
import { FormattedDifference, msToTime } from "../shared/utilities";

const MyTasksByPry = ({ currentUser }) => {
    const [activities, setActivities] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    const searchData = () => {
        setLoading(true);
        return getMyTasks(currentUser.id, id).then((tasks) => {
            setActivities(tasks);
            setLoading(false);
        });
    };

    useEffect(() => {
        searchData();
        const interval = setInterval(() => searchData(), 120000);
        return () => clearInterval(interval);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(function setupListener() {
        function handleResize() {
            if (!document.hidden) {
                return searchData();
            }
        }
        window.addEventListener("visibilitychange", handleResize);

        return function cleanupListener() {
            window.removeEventListener("visibilitychange", handleResize);
        };
    });

    const renderStatus = (status) => {
        switch (true) {
            case status === "Pendiente":
                return "secondary";
            case status === "Pausada":
                return "warning";
            case status === "En Proceso":
                return "success";
            default:
                return "info";
        }
    };

    const IniTask = (task) => {
        if (task.taskShared) {
            return UpdateItem("activities_shared", task.id, {
                iniciadapor: currentUser.id,
                hour_ini: moment().format("YYYY-MM-DD HH:mm:ss"),
                start: moment().format("YYYY-MM-DD HH:mm:ss"),
                allDay: false,
                status: "En Proceso",
                end: moment().add(moment.duration(task.time_plan)).format("YYYY-MM-DD HH:mm:ss"),
            })
                .then(async () => {
                    await searchData();
                    return toast.success("Actividad Iniciada con exito!");
                })
                .catch((e) => toast.error("¡Error!, " + e.message));
        }
        return UpdateItem("activities", task.id, {
            iniciadapor: currentUser.id,
            hour_ini: moment().format("YYYY-MM-DD HH:mm:ss"),
            start: moment().format("YYYY-MM-DD HH:mm:ss"),
            allDay: false,
            status: "En Proceso",
            end: moment().add(moment.duration(task.time_plan)).format("YYYY-MM-DD HH:mm:ss"),
        })
            .then(async () => {
                await searchData();
                return toast.success("Actividad Iniciada con exito!");
            })
            .catch((e) => toast.error("¡Error!, " + e.message));
    };

    const ReiniTask = async (task, comment) => {
        const timeUsed = FormattedDifference(moment(task.hour_pausada), moment(task.hour_ini));
        const timeDuration = moment.duration(task.time_plan) - moment.duration(timeUsed);
        const timePlanNew = msToTime(timeDuration < 0 ? 0 : timeDuration);
        const actividad = {
            status: "Terminada",
            hour_fin: task.hour_pausada,
            time_real: timeUsed,
            terminadapor: currentUser.id,
            end: moment(task.hour_pausada).format("YYYY-MM-DD HH:mm:ss"),
        };
        if (timeDuration > 0) {
            actividad.time_plan = timeUsed;
        }

        if (task.taskShared) {
            const time_planComparted = msToTime(moment.duration(task.time_plan) / task.engprojects.length);
            const time_realComparted = msToTime(moment.duration(timeUsed) / task.engprojects.length);
            const taskss = task.engprojects.map((eng) => ({
                iniciadapor: task.iniciadapor.id,
                pausadapor: task.pausadapor && task.pausadapor.id,
                terminadapor: currentUser.id,
                hour_ini: task.hour_ini,
                hour_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
                start: task.start,
                allDay: false,
                status: "Terminada",
                engproject_id: eng.id,
                time_plan: time_planComparted,
                time_real: time_realComparted,
                title: task.title,
                resources: task.resources.map((r) => ({ user_id: r.id })),
                end: moment(task.hour_ini).add(moment.duration(time_realComparted)).format("YYYY-MM-DD HH:mm:ss"),
            }));

            if (taskss.length > 0) {
                await CreateItems("activities", taskss).catch((e) => toast.error("¡Error!, " + e.message));
                //.finally(() => setLoading1(false));
            }

            return UpdateItem("activities_shared", task.id, actividad)
                .then(async () => {
                    await CreateItem("activities_shared", {
                        iniciadapor: currentUser.id,
                        hour_ini: moment().format("YYYY-MM-DD HH:mm:ss"),
                        start: moment().format("YYYY-MM-DD HH:mm:ss"),
                        allDay: false,
                        status: "En Proceso",
                        time_plan: timePlanNew,
                        title: "C- " + task.title,
                        end: moment().add(moment.duration(timePlanNew)).format("YYYY-MM-DD HH:mm:ss"),
                        resources: task.resources.map((r) => ({ directus_users_id: { id: r.id } })),
                        engprojects: task.engprojects.map((p) => ({ engprojects_id: { id: p.id } })),
                    });
                    await searchData();
                    toast.success("Actividad Reiniciada con exito!");
                })
                .catch((e) => toast.error("¡Error!, " + e.message));
        }

        return UpdateItem("activities", task.id, actividad)
            .then(async () => {
                await CreateItem("activities", {
                    iniciadapor: currentUser.id,
                    hour_ini: moment().format("YYYY-MM-DD HH:mm:ss"),
                    start: moment().format("YYYY-MM-DD HH:mm:ss"),
                    allDay: false,
                    status: "En Proceso",
                    engproject_id: task.engproject_id,
                    time_plan: timePlanNew,
                    title: "C- " + task.title,
                    resources: task.resources.map((r) => ({ user_id: r.id })),
                    end: moment().add(moment.duration(timePlanNew)).format("YYYY-MM-DD HH:mm:ss"),
                    comment,
                });
                await searchData();
                toast.success("Actividad Reiniciada con exito!");
            })
            .catch((e) => toast.error("¡Error!, " + e.message));
    };

    const PauseTask = (task, comment) => {
        if (task.taskShared) {
            return UpdateItem("activities_shared", task.id, {
                status: "Pausada",
                pausadapor: currentUser.id,
                hour_pausada: moment().format("YYYY-MM-DD HH:mm:ss"),
                comment,
            })
                .then(async () => {
                    await searchData();
                    return toast.success("Actividad Pausada con exito!");
                })
                .catch((e) => toast.error("¡Error!, " + e.message));
        }
        return UpdateItem("activities", task.id, {
            status: "Pausada",
            pausadapor: currentUser.id,
            hour_pausada: moment().format("YYYY-MM-DD HH:mm:ss"),
            comment,
        })
            .then(async () => {
                await searchData();
                return toast.success("Actividad Pausada con exito!");
            })
            .catch((e) => toast.error("¡Error!, " + e.message));
    };

    const FinTask = (task, comment) => {
        const time_real = FormattedDifference(moment(), moment(task.hour_ini));
        if (task.taskShared) {
            return UpdateItem("activities_shared", task.id, {
                terminadapor: currentUser.id,
                hour_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
                status: "Terminada",
                end: moment().format("YYYY-MM-DD HH:mm:ss"),
                time_real,
                comment,
            })
                .then(async () => {
                    await searchData();
                    return toast.success("Actividad Terminada con exito!");
                })
                .catch((e) => toast.error("¡Error!, " + e.message));
        }
        return UpdateItem("activities", task.id, {
            terminadapor: currentUser.id,
            hour_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
            status: "Terminada",
            end: moment().format("YYYY-MM-DD HH:mm:ss"),
            time_real,
            comment: task.comment && task.comment.length > 0 ? `Reinicio:${task.comment} \n / Fin: ${comment}` : comment,
        })
            .then(async () => {
                await searchData();
                return toast.success("Actividad Terminada con exito!");
            })
            .catch((e) => toast.error("¡Error!, " + e.message));
    };

    const confirm = (title, text, confirmText, comment, succesTitle, succesText, action) => {
        const bodyAlert = {
            title,
            text,
            showCancelButton: true,
            confirmButtonText: confirmText,
            cancelButtonText: "Cancelar",
            reverseButtons: true,
            confirmButtonColor: "#004FBD",
            icon: "question",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Alert.isLoading(),
        };

        if (comment) {
            bodyAlert.input = "textarea";
            bodyAlert.inputLabel = "Indique un comentario (Opcional)";
            bodyAlert.inputPlaceholder = "Comentario...";
            bodyAlert.preConfirm = (cmt) => {
                action(cmt);
            };
        } else {
            bodyAlert.preConfirm = () => {
                action();
            };
        }

        Alert.fire(bodyAlert).then((result) => {
            if (result.isConfirmed) {
                return Alert.fire(succesTitle, succesText, "success");
            }
            return null;
        });
    };

    const breadcrumb = [
        { title: "Home", url: "/" },
        { title: "Tareas", url: "/tasks-manager" },
        { title: "Mis Tareas", url: "/tasks-manager/mis-tareas" },
        { title: "Proyecto", url: "" },
    ];

    if (loading) {
        return (
            <div className="container">
                <div style={{ marginTop: "20em", marginBottom: "20em" }}>
                    <div className="div-loading" />
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Tareas por Proyecto - Ingeniería y Controles Automatizados</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="container">
                {activities.length < 1 ? (
                    <h5 align="center" className="mt-4">
                        Actualmente No Posee Tareas Asignadas
                    </h5>
                ) : (
                    <Table striped className="mt-3">
                        <tbody>
                            {activities.map((e, i) => (
                                <tr key={i}>
                                    <td>
                                        <dl className="row mt-1" style={{ fontSize: "0.8em" }}>
                                            <dt className="col-2">PRY: </dt>
                                            <dd className="col-6">{e.taskShared ? "Tarea Compartida" : e.engprojectCode}</dd>
                                            <dd className="col-4">
                                                <Badge title={e.status} severity={renderStatus(e.status)} value={e.status} />
                                            </dd>

                                            <dt className="col-2">Tarea: </dt>
                                            <dd className="col-10">{e.title}</dd>

                                            <dt className="col-2">Fecha: </dt>
                                            <dd className="col-10">{moment(e.start).format("DD-MM-YYYY hh:mm a")}</dd>

                                            <dt className="col-2">Plan: </dt>
                                            <dd className="col-6">{e.time_plan}</dd>

                                            <dd className="col-4">
                                                <div>
                                                    {e.status === "Pendiente" && (
                                                        <ActionsButtom
                                                            title="Iniciar"
                                                            className="p-button-rounded p-mr-2"
                                                            icon="pi pi-play"
                                                            onClick={() =>
                                                                confirm(
                                                                    "Iniciar Actividad",
                                                                    "¿Desea iniciar la actividad " + e.title + "?",
                                                                    "Iniciar",
                                                                    false,
                                                                    "¡Actividad Iniciada Correctamente!",
                                                                    "Actividad " + e.title + " iniciada.",
                                                                    () => IniTask(e)
                                                                )
                                                            }
                                                        />
                                                    )}
                                                    {e.status === "Pausada" && (
                                                        <ActionsButtom
                                                            title="Reiniciar"
                                                            className="p-button-rounded p-mr-2"
                                                            icon="pi pi-play"
                                                            onClick={() =>
                                                                confirm(
                                                                    "Reiniciar Actividad",
                                                                    "¿Desea Reiniciar la actividad " + e.title + "?",
                                                                    "Reiniciar",
                                                                    true,
                                                                    "¡Actividad Reiniciada Correctamente!",
                                                                    "Actividad " + e.title + " reiniciada.",
                                                                    () => ReiniTask(e)
                                                                )
                                                            }
                                                        />
                                                    )}
                                                    {e.status === "En Proceso" && (
                                                        <ActionsButtom
                                                            title="Pausar"
                                                            className="p-button-rounded p-button-warning p-mr-2"
                                                            icon="pi pi-pause"
                                                            onClick={() =>
                                                                confirm(
                                                                    "Pausar Actividad",
                                                                    "¿Desea Pausar la actividad " + e.title + "?",
                                                                    "Pausar",
                                                                    true,
                                                                    "¡Actividad Pausada Correctamente!",
                                                                    "Actividad " + e.title + " pausada.",
                                                                    (cmt) => PauseTask(e, cmt)
                                                                )
                                                            }
                                                        />
                                                    )}
                                                    {e.status === "En Proceso" && (
                                                        <ActionsButtom
                                                            title="Finalizar"
                                                            className="p-button-rounded p-button-success"
                                                            icon="pi pi-check"
                                                            onClick={() =>
                                                                confirm(
                                                                    "Finalizar Actividad",
                                                                    "¿Desea Finalizar la actividad " + e.title + "?",
                                                                    "Finalizar",
                                                                    true,
                                                                    "¡Actividad Finalizada Correctamente!",
                                                                    "Actividad " + e.title + " finalizada.",
                                                                    (cmt) => FinTask(e, cmt)
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </dd>
                                        </dl>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </div>
        </React.Fragment>
    );
};

export default MyTasksByPry;
