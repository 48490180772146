// react
import "../../loadingTable.css";
import Swal from "sweetalert2";
import swal from "sweetalert";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import React, { useEffect, useState } from "react";
// third-party
import { MDBDataTableV5 } from "mdbreact";
import AsyncSelect from "react-select/async";
import GetProductsCodesCSV from "../../GetProductsCodesCSV";
import { InputNumber } from "primereact/inputnumber";
import {
    CreateItems,
    getInventoriesSurplusAp,
    deleteSurplusAp,
    getInventorySurplusAp,
    searchProducsByCodesAp,
    searchCategories,
    searchSubCategoriesByCategories,
    searchProductsSurplusForCodeAp,
    addProductsInventoriesSurplusAp,
    updateSurplusItemsAp,
} from "../../../../store/directusSdk";
import { ExcelFormatSearch } from "../../../shared/utilities";
import PageHeader from "../../../shared/PageHeader";
import GetInventoriesSurplusCSV from "./GetInventoriesSurplusCSV";
import ListInventoriCsvSurplus from "./ListInventoriCsvSurplus";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, UncontrolledCollapse } from "reactstrap";

export function InventoryManagementSurplus(props) {
    const { currentUser } = props;
    const Lodash = require("lodash");
    const [timeDelivery, setTimeDelivery] = useState("");
    const [categories, setCategories] = useState(null);
    const [subCategories, setSubCategories] = useState(null);
    const [productsErrors, setProductsErrors] = useState([]);
    const [optionSelected, setOptionSelected] = useState(null);
    const [loading2, setLoading2] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [pseudonym, setPseudonym] = useState("");
    const [loading, setLoading] = useState(false);
    const [inventory, setInventory] = useState([]);
    const [products, setProducts] = useState([]);
    const [newProducts, setNewProducts] = useState([]);
    const [update, setUpdate] = useState(false);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [openClose, setOpenClose] = useState(false);
    const [typeModal, setTypeModal] = useState(null);
    const [open2, setOpen2] = useState(false);
    const [codes, setCodes] = useState([]);
    const [type, setType] = useState("IS");
    const [name, setName] = useState("");
    const [mode, setMode] = useState("A");
    const [copy, setCopy] = useState(null);
    const [ctimeDelivery, setCtimeDelivery] = useState("");
    const [cpseudonym, setCpseudonym] = useState("");
    const [cName, setCname] = useState("");
    const [code, setCode] = useState([]);
    const [codeManufacture, setCodeMmanufacture] = useState();
    const [description, setDescription] = useState();
    const [category, setCategory] = useState();
    const [subCategoria, setSubCategoria] = useState();
    const [brand, setBrand] = useState();
    const [qty, setQty] = useState();
    const [price, setPrice] = useState();
    const [ubication, setUbication] = useState();
    const [comparate, setComparate] = useState();

    const addProductEach = () => {
        if (!code) return toast.error("Debe indicar un código de producto.");
        if (!codeManufacture) return toast.error("Debe indicar un código de fabricante SKU.");
        if (!description) return toast.error("Debe indicar nombre del producto o descripción.");
        if (!category) return toast.error("Debe seleccionar una categoría.");
        if (!subCategoria) return toast.error("Debe seleccionar una subcategoría.");
        if (!qty) return toast.error("Debe indicar una cantida.");
        if (!price) return toast.error("Debe indicar un precio.");

        const additem = {
            code,
            sku: codeManufacture,
            category,
            name: description,
            sub_category: subCategoria,
            brand,
            qty,
            price,
            location: ubication,
            inventory_id: optionSelected,
        };

        if (mode === "A") {
            additem.id = Math.floor(Math.random() * 90000) + 100000;
            additem.ubication = ubication;
            additem.code_manufacture = codeManufacture;
            additem.subCategoria = subCategoria;
            const newProductsList = products;
            newProductsList.push(additem);
            setProducts(newProductsList);
            resetInputProductEach();
            return;
        }

        Swal.fire({
            title: "Agregar Producto",
            text: `¿Está seguro que desea agregar el producto con el codigo ${code}?.`,
            icon: "warning",
            confirmButtonColor: "#0059BD",
            cancelButtonColor: "#3d464d",
            buttons: true,
            dangerMode: true,
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: "Aceptar",
            preConfirm: () => {
                setLoading3(true);
                return addProductsInventoriesSurplusAp({ additem, id: optionSelected })
                    .then(() => {
                        resetInputProductEach();
                        updateTable();
                    })
                    .catch((error) => swal("Error!", error.message, "error"))
                    .finally(() => setLoading3(false));
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: "success",
                    title: `¡El producto se ha agregado correctamente!`,
                });
            }
        });
    };

    const resetInputProductEach = () => {
        setCode("");
        setCodeMmanufacture("");
        setDescription("");
        setCategory("");
        setSubCategoria("");
        setBrand("");
        setQty("");
        setPrice("");
        setUbication("");
    };

    const promiseOptions = (inputValue) => searchProductsSurplusForCodeAp(inputValue);

    /*Función enc1argada de solicitar los inventarios externos a la API. */
    const getInventories = () =>
        getInventoriesSurplusAp()
            .then((inventory) => {
                const inv = inventory.map((e) => {
                    e.actions = (
                        <>
                            <Button
                                className="mr-2"
                                outline
                                color="info"
                                title="Ver Detalles"
                                onClick={() => {
                                    getInventory(e.id);
                                    setOpen(true);
                                    setTypeModal("D");
                                    setOptionSelected(e.id);
                                }}
                            >
                                <i className="fas fa-search" />
                            </Button>
                            {["Administrator", "Admin AP"].includes(currentUser.role.name) && (
                                <Button
                                    outline
                                    color="danger"
                                    title="Eliminar"
                                    onClick={() => {
                                        deleteInventory(e);
                                    }}
                                >
                                    <i className="fa fa-trash" />
                                </Button>
                            )}
                        </>
                    );
                    return e;
                });
                setInventory(inv);
            })
            .catch((error) => toast.error("¡Error!, " + error.message))
            .finally(() => setLoading(false));

    /*Función encargada de buscar todos los datos en la API, relacionados con el inventario seleccionado. */
    const getInventory = (id) => {
        setLoading3(true);
        id &&
            getInventorySurplusAp(id)
                .then((data) => {
                    setMode("U");
                    setOptionSelected(id);
                    setProducts(data.items);
                    setName(data.name);
                    const dataCopy = data.items.map((d) => ({ ...d }));
                    setCopy(dataCopy);
                    setComparate({ pseudonym: data.pseudonym, location: data.location });
                    setPseudonym(data.pseudonym);
                    setTimeDelivery(data.location);
                    setCtimeDelivery(data.location);
                    setCpseudonym(data.pseudonym);
                    setCname(data.name);
                })
                .catch((error) => toast.error("¡Error!, " + error.message))
                .finally(() => setLoading3(false));
    };

    const updateTable = () => {
        if (optionSelected) getInventory(optionSelected);
        getInventories();
    };

    const getCategories = () => {
        searchCategories()
            .then(setCategories)
            .catch((error) => toast.error("¡Error!, " + error.message))
            .finally(() => setLoading(false));
    };

    const getSubCategories = (subCate) => {
        searchSubCategoriesByCategories(subCate)
            .then(setSubCategories)
            .catch((error) => toast.error("¡Error!, " + error.message))
            .finally(() => setLoading(false));
    };

    const getProductsByCodes = () => {
        const cod = codes.map((code) => code.value);
        setLoading1(true);
        searchProducsByCodesAp({ codes: cod })
            .then((products) => {
                const formatProducts = products.map((i) => ({
                    ...i,
                    id: i.id,
                    code_manufacture: i.sku,
                    code: i.code,
                    name: i.name,
                    category: i.category,
                    subCategoria: i.sub_category,
                    brand: i.brand,
                    qty: i.qty,
                    price: i.price,
                    ubication: i.location,
                }));

                setProducts(formatProducts);
            })
            .catch((error) => toast.error("¡Error!, " + error.message))
            .finally(() => setLoading1(false));
    };

    const deleteInventory = (e) => {
        Swal.fire({
            title: "Eliminar Inventario",
            text: `¿Está seguro que desea eliminar el inventario ${e.name}, con ${e.products} productos que tiene.
            `,
            icon: "warning",
            confirmButtonColor: "#0059BD",
            cancelButtonColor: "#3d464d",
            buttons: true,
            dangerMode: true,
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            preConfirm: () => {
                setLoading3(true);
                return deleteSurplusAp({ id: e.id })
                    .then(() => {
                        getInventories();
                    })
                    .catch((error) => swal("Error!", error.message, "error"))
                    .finally(() => setLoading3(false));
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: "success",
                    title: `¡El Inventatio se ha eliminado!`,
                });
            }
        });
    };

    useEffect(() => {
        setLoading(true);
        getInventories();
        getCategories();
    }, []);

    const breadcrumb = [
        { title: "Home", url: "/" },
        { title: "Inventarios", url: "/inventarios" },
        { title: "Gestion De Inventarios AP", url: "/inventarios/gestion-de-inventarios-ap" },
        { title: "Inventarios Sobrantes", url: "" },
    ];

    const dataTable = {
        columns: [
            { label: "Nº", field: "id", width: 90 },
            { label: "Nombre", field: "name", width: 200 },
            { label: "Ubicación", field: "time", width: 200 },
            { label: "N° De Productos", field: "products", width: 180 },
            { label: "Última Actualización", field: "timeAction", width: 180 },
            { label: "Acciones", field: "actions", width: 80 },
        ],
        rows: inventory,
    };

    const resetState = () => {
        setProducts([]);
        setProductsErrors([]);
        setName("");
        setTimeDelivery("");
        setUpdate(false);
        setPseudonym("");
    };

    const createInventory = () => {
        if (!name) return toast.error("Falta el nombre ");
        if (!pseudonym) return toast.error("Indique un seudónimo");
        if (!products.length > 0) return toast.error("Falta productos");

        const bodyAlert = {
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonColor: "#0059BD",
            cancelButtonColor: "#3d464d",
            icon: "question",
            showLoaderOnConfirm: true,
            title: mode === "U" ? "Agregar un nuevo producto." : "Crear inventario de sobrantes.",
            text:
                mode === "U"
                    ? "¿Está seguro que desea actualizar los datos de este inventario?"
                    : `¿Está seguro que desea crear un nuevo inventario de sobrantes?. `,
            icon: "warning",
            buttons: true,
            dangerMode: true,
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
            allowOutsideClick: () => !Swal.isLoading(),
        };

        const items = products.map((product) => ({
            code: product.code,
            sku: product.code_manufacture,
            category: product.category,
            name: product.name,
            sub_category: product.subCategoria,
            brand: product.brand,
            qty: product.qty,
            price: product.price,
            location: timeDelivery,
        }));

        const newInventory = {};
        newInventory.name = name;
        newInventory.location = timeDelivery;
        newInventory.items = items;
        newInventory.pseudonym = pseudonym;

        if (mode === "U") {
            const changes = [];

            copy.forEach((cop) => {
                const product = products.find((prd) => prd.id === cop.id);
                if (product) {
                    if (product.qty !== cop.qty || product.price !== cop.price) {
                        changes.push({ id: product.id, qty: product.qty, price: product.price });
                    }
                }
            });

            const additems = newProducts.map((product) => ({
                code: product.code,
                sku: product.code_manufacture,
                category: product.category,
                name: product.name,
                sub_category: product.subCategoria,
                brand: product.brand,
                qty: product.qty,
                price: product.price,
                location: timeDelivery,
                inventory_id: optionSelected,
            }));

            return Swal.fire(bodyAlert).then((result) => {
                if (result.isConfirmed) {
                    setLoading3(true);
                    updateSurplusItemsAp({
                        changes,
                        additems,
                        id: optionSelected,
                        name,
                        pseudonym,
                        location: timeDelivery,
                        time_delivery: timeDelivery,
                    })
                        .then(() => {
                            swal("¡Exito!", "¡El inventario se ha actualizado.!", "success");
                            getInventory(optionSelected);
                            updateTable();
                        })
                        .catch((error) => swal("Error!", error.message, "error"))
                        .finally(() => setLoading3(false));
                }

                return setLoading3(false);
            });
        }

        Swal.fire(bodyAlert).then((result) => {
            if (result.isConfirmed) {
                CreateItems("ap_inv_surplus_inventory", newInventory)
                    .then(() => {
                        swal("¡Exito!", "¡Se ha registrado un nuevo inventario de sobrantes.!", "success");
                        resetState();
                        resetInputProductEach();
                        getInventories();
                        setOpen(false);
                    })
                    .catch((error) => swal("Error!", error.message, "error"))
                    .finally(() => {
                        setLoading2(false);
                        setLoading3(false);
                    });
            }
            setLoading2(false);
            return;
        });
    };

    const loadingTable = {
        pointerEvents: "none",
        opacity: " 0.5",
    };

    const loadSubcategories = (e) => {
        getSubCategories(e);
    };

    const coparateProps = () => {
        if (ctimeDelivery !== timeDelivery || cpseudonym !== pseudonym || cName !== name || !Lodash.isEqual(products, copy)) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Módulo Almacen - Ingeniería y Controles Automatizados</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="container">
                <div className="card">
                    <div className="card-body">
                        <UncontrolledCollapse toggler="#toggler">
                            <div className="mb-2 mb-3">
                                <GetProductsCodesCSV LoadCodes={(codes) => setCodes(codes)} />
                            </div>
                        </UncontrolledCollapse>

                        <div className="row">
                            <div className="col-md-10">
                                {["Administrator", "Admin AP"].includes(currentUser.role.name) && (
                                    <Button
                                        width="100%"
                                        color="info"
                                        className="mb-3"
                                        onClick={() => {
                                            setTypeModal(null);
                                            setOpen(true);
                                            setMode("A");
                                        }}
                                    >
                                        <i className={"fa fa-cubes mr-2"} />
                                        Nuevo &nbsp;
                                    </Button>
                                )}

                                <Button color="info" id="toggler" className="mb-3 ml-2 ">
                                    <i className="fas fa-file-upload  mr-2" /> Importar
                                </Button>
                                <Button color="info" className="mb-3 ml-2" onClick={ExcelFormatSearch}>
                                    <i className="fas fa-file-download  mr-2" /> Formato
                                </Button>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-10">
                                <AsyncSelect
                                    isClearable
                                    cacheOptions
                                    loadOptions={promiseOptions}
                                    noOptionsMessage={() => "No se Encontraron registros..."}
                                    loadingMessage={() => "Buscando..."}
                                    placeholder="Códigos de productos..."
                                    value={codes}
                                    onChange={(e) => setCodes(e)}
                                    className="ms-md-auto"
                                    isMulti
                                />
                            </div>
                            <div className="col-md-2">
                                <Button
                                    color="primary"
                                    disabled={!(codes.length > 0)}
                                    onClick={() => {
                                        setTypeModal(null);
                                        setOptionSelected(5);
                                        getProductsByCodes();
                                        setOpen2(true);
                                        setMode("S");
                                    }}
                                >
                                    <i className="pi pi-search mr-2" />
                                    Buscar
                                </Button>
                            </div>
                        </div>

                        {loading ? (
                            <>
                                <div className="div-loading" style={{ width: 300 }} />
                                <br />
                                <br />
                            </>
                        ) : (
                            <div style={loading ? loadingTable : { width: "100%" }}>
                                <MDBDataTableV5
                                    entriesOptions={[25, 50, 75, 100]}
                                    entries={10}
                                    pagesAmount={4}
                                    data={dataTable}
                                    pagingTop
                                    searchBottom={false}
                                    entriesLabel="Mostar"
                                    searchLabel="Buscar"
                                    infoLabel={["Viendo", "al", "de", "registros"]}
                                    paginationLabel={["Anterior", "Siguiente"]}
                                    noRecordsFoundLabel="No se han encontrado registros..."
                                />
                            </div>
                        )}
                    </div>
                </div>

                {open && (
                    <Modal
                        isOpen={open}
                        toggle={() => {
                            setOpen(false);
                            resetState();
                            resetInputProductEach();
                        }}
                        centered
                        size="lg"
                        style={{ maxWidth: "1600px", width: "100%" }}
                        zIndex="1000"
                    >
                        <ModalHeader
                            toggle={() => {
                                setOpen(false);
                                resetState();
                                resetInputProductEach();
                            }}
                        >
                            Detalles de Inventario
                        </ModalHeader>
                        <ModalBody>
                            {["Administrator", "Admin AP"].includes(currentUser.role.name) ? (
                                <div className="row ml-5">
                                    <div className="col-md-5">
                                        <FormGroup>
                                            <Label for="guide">Nombre:</Label>
                                            <Input
                                                autoComplete="off"
                                                type="text"
                                                id="code"
                                                disabled={loading2}
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </FormGroup>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <FormGroup>
                                                    <Label for="invoice">Alias:</Label>
                                                    <Input
                                                        autoComplete="off"
                                                        type="text"
                                                        id="time"
                                                        disabled={loading2}
                                                        value={pseudonym}
                                                        onChange={(e) => setPseudonym(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup>
                                                    <Label for="invoice">Ubicación:</Label>
                                                    <Input
                                                        autoComplete="off"
                                                        id="time"
                                                        disabled={loading2}
                                                        type="text"
                                                        value={timeDelivery}
                                                        onChange={(e) => setTimeDelivery(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-7">
                                        {["Administrator", "Admin AP"].includes(currentUser.role.name) && (
                                            <GetInventoriesSurplusCSV
                                                setProducts={setProducts}
                                                products={products}
                                                setProductsErrors={setProductsErrors}
                                                loading={setLoading1}
                                                setUpdate={setNewProducts}
                                                update={updateTable}
                                                inventory={{ name, id: optionSelected }}
                                            />
                                        )}
                                    </div>
                                    {["Administrator", "Admin AP"].includes(currentUser.role.name) && (
                                        <div className="row">
                                            <div className="col-md-12">
                                                {" "}
                                                <Button
                                                    color="info"
                                                    id="toggler"
                                                    disabled={loading}
                                                    className="mb-2"
                                                    onClick={() => setOpenClose((value) => !value)}
                                                >
                                                    Agregar Producto
                                                    {openClose ? <i className="fas fa-sort-up ml-2"></i> : <i className="fas fa-sort-down ml-2"></i>}
                                                </Button>
                                            </div>
                                            <div className="col-md-12">
                                                <UncontrolledCollapse toggler="#toggler">
                                                    <div style={{ width: "100%" }}>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <FormGroup>
                                                                            <Label for="guide">Código:</Label>
                                                                            <Input
                                                                                autoComplete="off"
                                                                                type="text"
                                                                                id="code"
                                                                                value={code}
                                                                                onChange={(e) => setCode(e.target.value)}
                                                                            />
                                                                        </FormGroup>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <FormGroup>
                                                                            <Label for="invoice">SKU:</Label>
                                                                            <Input
                                                                                autoComplete="off"
                                                                                type="text"
                                                                                id="time"
                                                                                value={codeManufacture}
                                                                                onChange={(e) => setCodeMmanufacture(e.target.value)}
                                                                            />
                                                                        </FormGroup>
                                                                    </div>
                                                                </div>
                                                                <FormGroup>
                                                                    <Label for="invoice">Marca:</Label>
                                                                    <Input
                                                                        autoComplete="off"
                                                                        id="exampleSelect"
                                                                        name="select"
                                                                        type="text"
                                                                        value={brand}
                                                                        onChange={(e) => setBrand(e.target.value)}
                                                                    ></Input>
                                                                </FormGroup>
                                                            </div>
                                                            <div className="col">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <FormGroup>
                                                                            <Label for="invoice">Categoría:</Label>
                                                                            <Input
                                                                                autoComplete="off"
                                                                                id="exampleSelect"
                                                                                name="select"
                                                                                type="select"
                                                                                value={category}
                                                                                onChange={(e) => {
                                                                                    loadSubcategories(e.target.value);
                                                                                    setCategory(e.target.value);
                                                                                }}
                                                                            >
                                                                                <option value={null}></option>
                                                                                {categories &&
                                                                                    categories.map((category) => (
                                                                                        <option value={category.id}>{category.descripcion} </option>
                                                                                    ))}
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <FormGroup>
                                                                            <Label for="invoice">Sub-categoría:</Label>
                                                                            <Input
                                                                                autoComplete="off"
                                                                                id="exampleSelect"
                                                                                name="select"
                                                                                type="select"
                                                                                value={subCategoria}
                                                                                onChange={(e) => setSubCategoria(e.target.value)}
                                                                            >
                                                                                <option value={null}></option>
                                                                                {subCategories &&
                                                                                    subCategories.map((subCategory) => (
                                                                                        <option value={subCategory.id}>
                                                                                            {subCategory.descripcion}{" "}
                                                                                        </option>
                                                                                    ))}
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </div>
                                                                </div>
                                                                <FormGroup>
                                                                    <Label for="invoice">Descripción:</Label>
                                                                    <Input
                                                                        autoComplete="off"
                                                                        id="time"
                                                                        disabled={loading2}
                                                                        type="text"
                                                                        value={description}
                                                                        onChange={(e) => setDescription(e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="col">
                                                                <div className="row">
                                                                    <div className="col-md-6" style={{ maxWidth: "200px" }}>
                                                                        <FormGroup>
                                                                            <Label for="invoice">Cantidad:</Label>
                                                                            <InputNumber
                                                                                disabled={loading}
                                                                                min={1}
                                                                                value={qty}
                                                                                onValueChange={(e) => setQty(e.target.value)}
                                                                                style={{ width: "100%" }}
                                                                                size={5}
                                                                            />
                                                                        </FormGroup>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <FormGroup>
                                                                            <Label for="invoice">Precio:</Label>
                                                                            <InputNumber
                                                                                id="horizontal"
                                                                                onValueChange={(e) => setPrice(e.target.value)}
                                                                                min={1}
                                                                                value={price}
                                                                                locale="en-US"
                                                                                mode="currency"
                                                                                currency="USD"
                                                                                buttonLayout="horizontal"
                                                                                step={0.25}
                                                                                style={{ width: "100%" }}
                                                                            />
                                                                        </FormGroup>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <Button
                                                                        className="mt-4"
                                                                        color="success"
                                                                        disabled={
                                                                            loading ||
                                                                            !code ||
                                                                            !codeManufacture ||
                                                                            !description ||
                                                                            !category ||
                                                                            !subCategoria ||
                                                                            !qty ||
                                                                            !price
                                                                        }
                                                                        onClick={() => addProductEach()}
                                                                    >
                                                                        <i
                                                                            className={`${
                                                                                loading ? "fas fa-cog fa-spin" : "fa fa-plus-circle"
                                                                            }  mr-2`}
                                                                        />
                                                                        Agregar
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </UncontrolledCollapse>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <div className="card mb-1" style={{ width: "60%", marginLeft: "20%" }}>
                                        <div className="row ml-2" style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                {" "}
                                                <div className="font-weight-bold">Nombre:&nbsp;</div>
                                                {name}
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                {" "}
                                                <div className="font-weight-bold">Alias:&nbsp;</div>
                                                {pseudonym}
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                {" "}
                                                <div className="font-weight-bold">Location:&nbsp;</div>
                                                {timeDelivery}&nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className="row mt-5">
                                <div className="col-md-12 ">
                                    <ListInventoriCsvSurplus
                                        products={products}
                                        loading={loading3}
                                        loading2={loading2}
                                        type={mode}
                                        user={currentUser}
                                        setProducts={setProducts}
                                        setUpdate={setUpdate}
                                        setDisabled={setDisabled}
                                        update={updateTable}
                                        inventory={{ name, pseudonym, timeDelivery, id: optionSelected }}
                                    />
                                </div>
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                color="secondary"
                                disabled={loading1}
                                onClick={() => {
                                    setTypeModal(null);
                                    setOpen(false);
                                    resetState();
                                }}
                            >
                                <i className="fa fa-ban mr-2" />
                                Cancelar
                            </Button>
                            {["Administrator", "Admin AP"].includes(currentUser.role.name) && (
                                <>
                                    {mode === "U" && (
                                        <Button color="primary" disabled={!coparateProps() || loading1} onClick={createInventory}>
                                            <i className={`${loading1 ? "fas fa-cog fa-spin" : "fas fa-save"} mr-2`} />
                                            Actualizar
                                        </Button>
                                    )}
                                    {mode === "A" && (
                                        <Button
                                            color="primary"
                                            disabled={loading1 | !(products.length > 0) | !timeDelivery | !pseudonym | !name}
                                            onClick={createInventory}
                                        >
                                            <i className={`${loading1 ? "fas fa-cog fa-spin" : "fas fa-edit"} mr-2`} />
                                            Guardar
                                        </Button>
                                    )}
                                </>
                            )}
                        </ModalFooter>
                    </Modal>
                )}

                {open2 && (
                    <Modal
                        isOpen={open2}
                        toggle={() => {
                            setOpen2(false);
                            resetState();
                            resetInputProductEach();
                        }}
                        centered
                        size="lg"
                        style={{ maxWidth: "1600px", width: "100%" }}
                        zIndex="1000"
                    >
                        <ModalHeader
                            toggle={() => {
                                setOpen2(false);
                                resetState();
                                resetInputProductEach();
                            }}
                        >
                            Listado de productos encontrados.
                        </ModalHeader>
                        <ModalBody>
                            <div className="row mt-5">
                                <div className="col-md-12 ">
                                    {loading1 ? (
                                        <div>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <div className="div-loading " />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                        </div>
                                    ) : (
                                        <ListInventoriCsvSurplus
                                            user={currentUser}
                                            update={getProductsByCodes}
                                            products={products}
                                            loading={loading1}
                                            loading2={loading2}
                                            type={mode}
                                        />
                                    )}
                                </div>
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            {["D", "N"].includes(typeModal) && (
                                <>
                                    <Button
                                        color="secondary"
                                        disabled={loading1}
                                        onClick={() => {
                                            setOpen2(false);
                                            resetState();
                                        }}
                                    >
                                        <i className="fa fa-ban mr-2" />
                                        Cancelar
                                    </Button>
                                    <Button color="primary" onClick={createInventory}>
                                        <i className={`${loading1 ? "fas fa-cog fa-spin" : mode === "A" ? "fas fa-save" : "fas fa-edit"} mr-2`} />
                                        {mode === "A" ? "Guardar" : "Actualizar"}
                                    </Button>
                                </>
                            )}
                            <Button
                                color="secondary"
                                disabled={loading1}
                                onClick={() => {
                                    setTypeModal(null);
                                    setOpen2(false);
                                    resetState();
                                }}
                            >
                                <i className="fa fa-ban mr-2" />
                                Cancelar
                            </Button>
                        </ModalFooter>
                    </Modal>
                )}
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryManagementSurplus);
