// react
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody } from 'reactstrap';
import { SendEmail } from '../../store/directusSdk';
import { Cross20Svg } from '../../svg';

export default function Dropdowns({ quotation, GeneratePDF, uploadCart, editQuote, company }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [openEmail, setOpenEmail] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const cart = JSON.parse(quotation.cart);
    const [to, setTo] = useState(cart.contact.email);
    const [error, setError] = useState('');
    const [sending, setSending] = useState(false);
    const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

    const sendEmail = () => {
        if (!to) {
            return setOpenEmail(true);
        }

        if (!emailRegex.test(to)) {
            return setError('Email invalido!')
        }

        setSending(true);
        const email = {
            to,
            subject: 'Propuesta Comercial ICAutomatizados',
            body: `<p>Estimado(a)   {{contact}}, muchas gracias por su solicitud, tenemos el agrado de adjuntar nuestra propuesta comercial # {{id}}, esperamos que la misma sea de su conveniencia.</p> 
            <p>Quedamos muy atentos a sus comentarios.</p> 
            <p>Para nosotros su opinión es importante, agradecemos nos colabore con la siguiente encuesta de satisfacción y marketing.  https://form.jotform.com/200945055322852</p>
            <img src="https://apibeta.icautomatizados.com/public/cotizaciones/footer_e.png" alt="img_footer" />`,
            attachment: [`${process.env.REACT_APP_API_URL}cotizaciones/${company + quotation.sap_id}.pdf`],
            type: 'html',
            data: {
                contact: cart.contact.label,
                id: quotation.sap_id,
            },
        }

        return SendEmail(email).then(() => {
            setOpenEmail(false);
            setSending(false);
            setTo(cart.contact.email);
            toast.success('Cotización enviada con Exito!');
        }).catch(() => {
            setOpenEmail(false);
            setSending(false);
            setTo(cart.contact.email);
            toast.error('Falló envio de cotización...');
        });
    }

    const ViewErrors = () => setOpen(true);

    const changeEmail = (e) => {
        setTo(e.target.value);
        if (emailRegex.test(e.target.value)) {
            return setError(null);
        }
    };
    return (
        <React.Fragment>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle className="btn btn-ligth" caret>
                    <i className="fa fa-cogs fa-lg mr-2" />
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header>Acciones</DropdownItem>
                    {quotation.status === 'failed' && quotation.sap_error_message && (
                        <DropdownItem onClick={() => ViewErrors()}>
                            <i className="fa fa-exclamation-triangle fa-lg mr-2" />
                            Ver Error
                        </DropdownItem>
                    )}
                    {quotation.status === 'published' && quotation.sap_id && (
                        <DropdownItem onClick={() => GeneratePDF(quotation)}>
                            <i className="fa fa-download fa-lg mr-2" />
                            Descargar Cotización
                        </DropdownItem>
                    )}
                    {quotation.status === 'published' && quotation.sap_id && (
                        <DropdownItem onClick={() => sendEmail()}>
                            <i className="fa fa-paper-plane fa-lg mr-2" />
                            Enviar Cotización
                        </DropdownItem>
                    )}
                    <DropdownItem onClick={() => uploadCart(quotation)}>
                        <i className="fa fa-cart-arrow-down fa-lg mr-2" />
                        Cargar al Carrito
                    </DropdownItem>
                    <DropdownItem onClick={() => editQuote(quotation)}>
                        <i className="fas fa-edit fa-lg mr-2" />
                        Editar
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
            <Modal isOpen={open} toggle={() => setOpen((prevState) => !prevState)} centered size="l">
                <div className="quickview">
                    <button className="quickview__close" type="button" onClick={() => setOpen(false)}>
                        <Cross20Svg />
                    </button>
                    <p>{quotation.sap_error_message}</p>
                </div>
            </Modal>
            <Modal isOpen={openEmail} toggle={() => setOpenEmail((prevState) => !prevState)} centered size="l">
                <div className="quickview">
                    <button className="quickview__close" type="button" onClick={() => setOpenEmail(false)}>
                        <Cross20Svg />
                    </button>
                </div>
                <ModalBody>
                    <div className="row">
                        <div className="col-8">
                            <div className="form-group">
                                <label htmlFor="to">Ingrese Correo Electrónico Destinatario</label>
                                <input id="to" className={`form-control ${error && 'is-invalid'}`} value={to} type="text" placeholder="Correo Electrónico..." onChange={changeEmail} />
                                {error && <div className="invalid-feedback">{error}</div>}
                            </div>
                        </div>
                        <div className="col-4">
                            <button type="button" className={`mt-4 btn btn-info ${sending && 'btn-loading'}`} onClick={() => sendEmail()} disabled={sending || !to}>Enviar</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );

}