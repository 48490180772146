// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// application
import moment from 'moment';
import { Modal } from 'reactstrap';
import { Cross20Svg } from '../../svg';
import Pagination from '../shared/Pagination';
import { getPurchaseReport, getPurchaseReportById } from '../../store/directusSdk';
import Dropdowns from './Dropdowns';

export default class AccountPagePurchaseReports extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reports: [],
            page: 1,
            pages: 1,
            start: 0,
            end: 9,
            loading: true,
            loading2: false,
            open: false,
            report: null
        };
    }

    componentDidMount() {
        const { currentUser } = this.props;
        const { page } = this.state;
        getPurchaseReport(currentUser.id)
            .then((reports) => {
                if (reports.length > 0) {
                    const pages = Math.ceil(reports.length / 10);
                    const end = page * 10;
                    const start = end - 10;
                    this.setState({ reports, pages, end, start });
                }
                this.setState({ loading: false });
            });
    }

    handlePageChange = (page) => {
        const end = page * 10;
        const start = end - 10;
        this.setState({ page, end, start });
    };

    searchReport = (id) => {
        this.setState({ loading2: true });
        return getPurchaseReportById(id)
            .then((report) => {
                this.setState({ report, loading2: false, open: true })
            });
    }

    render() {
        const { page, reports, report, pages, end, start, loading, loading2, open } = this.state;

        const reportsList = reports.map((s) => {
            const date = moment(s.created_on);
            const dateFormated = date.format('DD/MM/YYYY hh:mm a');
            return (
                <tr key={s.id}>
                    <td>{s.id}</td>
                    <td>{s.project_code}</td>
                    <td>{s.company}</td>
                    <td>{dateFormated}</td>
                    <td>
                        <Dropdowns report={s} searchReport={this.searchReport} type="PR" />
                    </td>
                </tr >
            );
        });

        return (
            <div className="card" >
                <Helmet>
                    <title>Mis Compras - ICAutomatizados</title>
                </Helmet>

                <div className="card-header">
                    <h5>Historial de Informes de Compras</h5>
                </div>
                <div className="card-divider" />
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Código</th>
                                    <th>Company</th>
                                    <th>Fecha</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading && <tr><td colSpan="5" ><div className="div-loading" style={{ height: 300 }} /></td></tr>}
                                {reportsList.slice(start, end)}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-divider" />
                <div className="card-footer">
                    {!loading && reports.length < 1 && <strong> Actualmente no tiene Informes de Compras... </strong>}
                    {pages > 1 && <Pagination current={page} total={pages} onPageChange={this.handlePageChange} siblings={2} />}
                </div>
                {report && (
                    <Modal isOpen={open} toggle={() => this.setState({ open: false })} centered size="xl">
                        <div className="quickview">
                            <button className="quickview__close" type="button" onClick={() => this.setState({ open: false })}>
                                <Cross20Svg />
                            </button>

                            {loading2 ? (
                                <div className="div-loading" style={{ height: 300 }} />
                            ) : (
                                <div>
                                    <h3 align="center">{report.project_name}</h3>
                                    <div className="row">
                                        <div className="col-4"><b>Código: </b> <br />{report.project_code}</div>
                                        <div className="col-4"><b>Marcado por: </b> <br />{report.mark_by}</div>
                                        <div className="col-4"><b>Marcado el: </b> <br />{report.mark_on}</div>
                                    </div>

                                    <div className="datatable-rowexpansion-demo">
                                        {report.company === 'ICA' ? (
                                            <DataTable
                                                ref={(el) => this.dt = el}
                                                value={report.items}
                                                paginator
                                                rows={10}
                                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                                                rowsPerPageOptions={[10, 20, 50, 100]}
                                                removableSort
                                                emptyMessage="No se han encontrado registros..."
                                                className="p-datatable-customers"
                                                rowHover
                                            >
                                                <Column field="code" header="Código" sortable filter style={{ width: '13%' }} filterPlaceholder="Todos" filterHeaderClassName="p-inputtext-sm" />
                                                <Column field="bod01" header="Stock 01" sortable filter style={{ width: '9%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                                <Column field="bod05" header="Stock 05" sortable filter style={{ width: '9%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                                <Column field="IsCommited" header="Comprometido" sortable filter style={{ width: '11%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                                <Column field="sap_onorder" header="Por Llegar" sortable filter style={{ width: '11%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                                <Column field="sap_available" header="Disponible SAP" sortable filter style={{ width: '11%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                                <Column field="available" header="Disponible Físico" sortable filter style={{ width: '11%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                                <Column field="faltEntregar" header="Por Entregar" sortable filter style={{ width: '11%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                                <Column field="faltComprar" header="Por Comprar" sortable filter style={{ width: '12%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                            </DataTable>
                                        ) :
                                            (
                                                <DataTable
                                                    ref={(el) => this.dt = el}
                                                    value={report.items}
                                                    paginator
                                                    rows={10}
                                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                    currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                                    removableSort
                                                    emptyMessage="No se han encontrado registros..."
                                                    className="p-datatable-customers"
                                                    rowHover
                                                >
                                                    <Column field="code" header="Código" sortable filter style={{ width: '12%' }} filterPlaceholder="Todos" filterHeaderClassName="p-inputtext-sm" />
                                                    <Column field="bod01" header="Stock 01" sortable filter style={{ width: '9%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                                    <Column field="bod02" header="Stock 02" sortable filter style={{ width: '9%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                                    <Column field="bod03" header="Stock 03" sortable filter style={{ width: '9%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                                    <Column field="IsCommited" header="Comprometido" sortable filter style={{ width: '10%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                                    <Column field="sap_onorder" header="Por Llegar" sortable filter style={{ width: '10%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                                    <Column field="sap_available" header="Disponible SAP" sortable filter style={{ width: '10%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                                    <Column field="available" header="Disponible Físico" sortable filter style={{ width: '10%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                                    <Column field="faltEntregar" header="Por Entregar" sortable filter style={{ width: '10%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                                    <Column field="faltComprar" header="Por Comprar" sortable filter style={{ width: '11%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                                </DataTable>
                                            )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </Modal>
                )}
            </div>
        );
    }
}