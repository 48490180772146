import { toast } from 'react-toastify';
import {
    CART_ADD_ITEM_AP,
    CART_REMOVE_ITEM_AP,
    CART_UPDATE_QUANTITIES_AP,
    CART_REMOVE_ALL_ITEMS_AP,
    CHANGE_PRICE_ITEM_AP,
    CART_LOAD_ALL_ITEMS_AP,
    RECALCULATE_PRICES_AP,
    CHANGE_COMMENTS_AP,
    CHANGE_CONTACT_AP,
    CHANGE_CONTACTS_AP,
    CHANGE_CUSTOMER_AP,
    CHANGE_TEXT_ITEM_AP,
    RECALCULATE_ITEM_PRICE_AP,
    CHANGE_INCITBMS_AP,
    CHANGE_QTY_AP,
} from './cartActionTypes';

export function recalculatePrices(products = [], services = []) {
    return {
        type: RECALCULATE_PRICES_AP,
        products,
        services,
    };
}

export function cartLoadItemsSuccess(cart) {
    toast.success('Productos agregados al Carrito!');
    return {
        type: CART_LOAD_ALL_ITEMS_AP,
        cart,
    };
}

export function changeComments(comments) {
    return {
        type: CHANGE_COMMENTS_AP,
        comments,
    };
}

export function cartAddItemSuccess(product, incItbms, quantity = 1) {
    toast.success(`Producto "${product.name}" agregado al Carrito!`);

    return {
        type: CART_ADD_ITEM_AP,
        product,
        quantity,
        incItbms,
    };
}

export function cartRemoveItemSuccess(itemId) {
    return {
        type: CART_REMOVE_ITEM_AP,
        itemId,
    };
}

export function cartUpdateQuantitiesSuccess(quantities) {
    return {
        type: CART_UPDATE_QUANTITIES_AP,
        quantities,
    };
}


export function cartAddItem(product, incItbms, quantity = 1) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartAddItemSuccess(product, incItbms, quantity));
                resolve();
            }, 500);
        })
    );
}

export function cartRemoveItem(itemId) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartRemoveItemSuccess(itemId));
                resolve();
            }, 500);
        })
    );
}

export function cartUpdateQuantities(quantities) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartUpdateQuantitiesSuccess(quantities));
                resolve();
            }, 500);
        })
    );
}

export function cartRemoveAllItems() {
    return {
        type: CART_REMOVE_ALL_ITEMS_AP,
    };
}
export const ContactChange = (contact) => ({
    type: CHANGE_CONTACT_AP,
    contact,
});

export const ContactsChange = (contacts) => ({
    type: CHANGE_CONTACTS_AP,
    contacts,
});

export const CustomerChangeAP = (customer, priceList) => ({
    type: CHANGE_CUSTOMER_AP,
    customer,
    priceList,
});

export const TextItemsChange = (index, text) => ({
    type: CHANGE_TEXT_ITEM_AP,
    index,
    text,
});
export const recalculateItemPrice = (index, oldFactor, newFactor) => ({
    type: RECALCULATE_ITEM_PRICE_AP,
    index,
    oldFactor,
    newFactor,
});
export const incItbmsAP = (index, value) => ({
    type: CHANGE_INCITBMS_AP,
    index,
    value,
});

export const ChangeQuantityAP = (index, qty = 1) => ({
    type: CHANGE_QTY_AP,
    index,
    qty,
});

export const ChangePriceItemAP = (index, price = 1) => ({
    type: CHANGE_PRICE_ITEM_AP,
    index,
    price
});