// react
import React, { useState } from 'react';
import { InputNumber } from 'primereact/inputnumber';

export default ({ id , qty, max, changeQty }) => {
    const [cant, setcant] = useState(qty);
    const onChange = ({ value }) => {
        setcant(value);
        changeQty(id, value);
    };

    return (
        <InputNumber value={cant} min={1} max={max} onValueChange={onChange} showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-info" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" size={1} />
    );
}