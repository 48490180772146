// react
import React, { useState, useRef } from 'react';
// third-party
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button, Badge, Input } from 'reactstrap';

export default ({ row, getDispatches, searchClients }) => {
    const [statusF, SetStatusF] = useState('')
    const sdt = useRef();

    const onStatusChange = (e) => {
        SetStatusF(e.target.value);
        return sdt.current.filter(e.target.value, 'status', 'equals');
    }

    const statusFilter = (
        <Input type="select" name="select" id="exampleSelect" value={statusF} onChange={onStatusChange}>
            <option value="" >Todos</option>
            <option value="O" >Abierto</option>
            <option value="C"> Cerrado</option>
        </Input>
    );
    return (
        <div className="orders-subtable">
            {row.lines.length > 0 ? (
                <DataTable value={row.lines} className="p-datatable-sm" ref={sdt} >
                    <Column field="code" header="Código" sortable filter filterPlaceholder="Todos"  style={{ width: '20%' }} />
                    <Column field="name" header="Producto" sortable filter filterPlaceholder="Todos"  style={{ width: '30%' }} />
                    <Column field="qty" header="Cantidad" sortable filter style={{ textAlign: 'center', width: '14%' }} filterPlaceholder="Todos"  />
                    <Column field="status" header="Estado" sortable filter style={{ width: '14%' }} body={(row) => <Badge color={row.status === 'O' ? 'info' : 'success'}>{row.status === 'O' ? 'Abierto' : 'Cerrado'}</Badge>} filterElement={statusFilter} />
                    <Column header="Despachos" body={(row) => <Button outline color="primary" title="Ver Despachos de Producto" onClick={() => getDispatches(row)}><i className="fas fa-truck" /></Button>} style={{ width: '12%' }} />
                    <Column header="Clientes" body={(row) => <Button outline color="info" title="Ver Clientes" onClick={() => searchClients(row)}><i className="fas fa-users" /></Button>} style={{ width: '10%' }} />
                    <Column style={{ width: '30%' }} />
                </DataTable>
            ) :
                (
                    <p>Orden de venta sin Items...</p>
                )
            }
        </div>
    );
}