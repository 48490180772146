// react
import React from 'react';
import Select from 'react-select';

export default function ChangeWhs({ value, options, onChange }) {

    const HandleWhs = (whs) => {
        return onChange(whs);
    };

    return (
        <Select value={value} onChange={HandleWhs} options={options} />
    );
}