import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_UPDATE_QUANTITIES,
    CART_REMOVE_ALL_ITEMS,
    CHANGE_PRICE_ITEM,
    CART_LOAD_ALL_ITEMS,
    RECALCULATE_PRICES,
    CHANGE_COMMENTS,
    CHANGE_CONTACT,
    CHANGE_CONTACTS,
    CHANGE_CUSTOMER,
    CHANGE_TEXT_ITEM,
    RECALCULATE_ITEM_PRICE,
    CART_ADD_ITEM_SERV,
    CHANGE_QTY,
    CHANGE_VALIDITY,
    CHANGE_CONDITIONS,
    CHANGE_EXEMPT_ITBMS,
    CHANGE_INCITBMS
} from './cartActionTypes';

export function round(value, decimals = 2) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

function findItemIndex(items, product) {
    return items.findIndex((item) => {
        if (item.product.id !== product.id) {
            return false;
        }
        return true;
    });
}

export function calcSubtotal(items) {
    const subtotal = round(items.reduce((subtotal, item) => subtotal + item.total, 0));
    return subtotal;
}

function calcQuantity(items) {
    return items.reduce((quantity, item) => quantity + item.quantity, 0);
}

export function calcITBMS(items) {
    const subtotal = items.reduce((total, e) => {
        if (e.incItbms) {
            return total + e.total;
        }
        return total;
    }, 0);
    const itbms = round(subtotal * 0.07);
    return itbms;
}

export function calcTotal(subtotal, itbms) {
    const total = round(subtotal + itbms);
    return total;
}

function addItem(state, product, priceList, quantity) {
    const itemIndex = findItemIndex(state.items, product);
    let newItems;
    let { lastItemId } = state;
    const price = round(product.price);

    if (itemIndex === -1) {
        lastItemId += 1;
        newItems = [...state.items, {
            id: lastItemId,
            product: JSON.parse(JSON.stringify(product)),
            price: price,
            total: round(price * quantity),
            quantity,
            priceList,
            incItbms: !state.exempt_itbms
        }];
    } else {
        const item = state.items[itemIndex];

        newItems = [
            ...state.items.slice(0, itemIndex),
            {
                ...item,
                quantity: item.quantity + quantity,
                total: round((item.quantity + quantity) * item.price),
            },
            ...state.items.slice(itemIndex + 1),
        ];
    }

    const subtotal = calcSubtotal(newItems);
    const itbms = calcITBMS(newItems);
    const total = calcTotal(subtotal, itbms);

    return {
        ...state,
        lastItemId,
        subtotal,
        itbms,
        total,
        items: newItems,
        quantity: calcQuantity(newItems),
    };
}

function addItemServ(state, product, priceList, quantity, incItbms) {
    let newItems;
    let { lastItemId } = state;
    const price = round(product.price);
    lastItemId += 1;
    newItems = [...state.items, {
        id: lastItemId,
        product,
        price,
        total: round(price * quantity),
        quantity,
        priceList,
        incItbms: state.exempt_itbms ? false : incItbms
    }];

    const subtotal = calcSubtotal(newItems);
    const itbms = calcITBMS(newItems);
    const total = calcTotal(subtotal, itbms);

    return {
        ...state,
        lastItemId,
        subtotal,
        itbms,
        total,
        items: newItems,
        quantity: calcQuantity(newItems),
    };
}

function removeItem(state, itemId) {
    const { items } = state;
    const newItems = items.filter((item) => item.id !== itemId);

    const subtotal = calcSubtotal(newItems);
    const itbms = calcITBMS(newItems);
    const total = calcTotal(subtotal, itbms);

    return {
        ...state,
        items: newItems,
        quantity: calcQuantity(newItems),
        subtotal,
        itbms,
        total,
    };
}

function updateQuantities(state, quantities) {
    let needUpdate = false;

    const newItems = state.items.map((item) => {
        const quantity = quantities.find((x) => x.itemId === item.id && x.value !== item.quantity);

        if (!quantity) {
            return item;
        }

        needUpdate = true;

        return {
            ...item,
            quantity: quantity.value,
            total: round(quantity.value * item.price),
        };
    });

    if (needUpdate) {
        const subtotal = calcSubtotal(newItems);
        const itbms = calcITBMS(newItems);
        const total = calcTotal(subtotal, itbms);

        return {
            ...state,
            items: newItems,
            quantity: calcQuantity(newItems),
            subtotal,
            itbms,
            total,
        };
    }

    return state;
}

function updatePrice(state, i, price) {
    price = round(price);
    state.items[i].price = price;
    state.items[i].total = round(state.items[i].quantity * price);

    const subtotal = calcSubtotal(state.items);
    const itbms = calcITBMS(state.items);
    const total = calcTotal(subtotal, itbms);
    return {
        ...state,
        subtotal,
        itbms,
        total,
    };
}

function RecalculatePrices(state, products, services) {
    const items = [...state.items];
    if (products.length > 0) {
        products.forEach((p) => {
            const i = items.findIndex((e) => e.product.id == p.id);
            if (i !== -1) {
                const price = round(p.price);
                items[i].price = price;
                items[i].total = round(price * state.items[i].quantity);
                items[i].product.price = price;
            }
        });
    }
    if (services.length > 0) {
        services.forEach((p) => {
            const i = items.findIndex((e) => e.product.id == p.id);
            if (i !== -1) {
                const price = round(p.price);
                items[i].price = price;
                items[i].total = round(price * state.items[i].quantity);
                items[i].product.price = price;
            }
        });
    }
    const subtotal = calcSubtotal(items);
    const itbms = calcITBMS(items);
    const total = calcTotal(subtotal, itbms);

    return {
        ...state,
        items,
        subtotal,
        itbms,
        total,
    };
}

function updateComments(state, comments) {
    return {
        ...state,
        comments
    };

}
function updateCommentItem(state, index, comments) {
    const { items } = state;
    items[index].comments = comments;
    return {
        ...state,
        items,
    };

}

function recalculatePriceItem(state, index, oldFactor, newFactor) {  
    if (index !== -1) {
        const { items } = state;    
        items[index].price = round(round(items[index].product.price / oldFactor) * newFactor.value);
        items[index].total = round(items[index].price * items[index].quantity);
        items[index].priceList = newFactor;      
        items[index].error = false;     
        const subtotal = calcSubtotal(items);
        const itbms = calcITBMS(items);
        const total = calcTotal(subtotal, itbms);

        return {
            ...state,
            items,
            subtotal,
            itbms,
            total,
        };
    }
    return state;
}

function recalculatePriceAllItem(state, customer, priceList) {
    const items = state.items.map((e) => {
        e.price = round(round(e.product.price / e.priceList.value) * parseFloat(priceList.value));
        e.priceList = priceList;
        e.total = round(e.price * e.quantity);
        e.incItbms = customer ? !customer.exempt_itbms : true;
        e.error = false;
        return e;
    });

    const subtotal = calcSubtotal(items);
    const itbms = calcITBMS(items);
    const total = calcTotal(subtotal, itbms);

    return {
        ...state,
        customer,
        items,
        subtotal,
        itbms,
        total,
        exempt_itbms: customer ? customer.exempt_itbms : false
    };
}

function editQty(state, index, qty) {
    if (index !== -1) {
        const { items } = state;
        items[index].quantity = qty;
        items[index].total = round(qty * items[index].price);

        const subtotal = calcSubtotal(items);

        const itbms = calcITBMS(items);

        const total = calcTotal(subtotal, itbms);

        return {
            ...state,
            items,
            subtotal,
            itbms,
            total,
        };
    }
    return state;
}

function editValidity(state, validity) {
    return {
        ...state,
        validity
    };
}

function editConditions(state, paymentConditions) {
    return {
        ...state,
        paymentConditions
    };
}

function editExemptItbms(state, exempt_itbms) {

    const items = state.items.map((e) => ({ ...e, incItbms: !exempt_itbms }));

    const subtotal = calcSubtotal(items);
    const itbms = calcITBMS(items);
    const total = calcTotal(subtotal, itbms);

    return {
        ...state,
        items,
        subtotal,
        itbms,
        total,
        exempt_itbms
    };
}

function editIncItbms(state, index, value) {
    if (index !== -1) {
        const { items } = state;
        items[index].incItbms = value;

        const subtotal = calcSubtotal(items);
        const itbms = calcITBMS(items);
        const total = calcTotal(subtotal, itbms);

        return {
            ...state,
            items,
            subtotal,
            itbms,
            total,
        };
    }
    return state;
}

const initialState = {
    lastItemId: 0,
    customer: null,
    contact: null,
    contacts: [],
    comments: '',
    quantity: 0,
    items: [],
    subtotal: 0,
    itbms: 0,
    total: 0,
    mode: 'create',
    sapId: null,
    validity: null,
    paymentConditions: '',
    exempt_itbms: false
};

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case CART_ADD_ITEM:
            return addItem(state, action.product, action.priceList, action.quantity);

        case CART_ADD_ITEM_SERV:
            return addItemServ(state, action.product, action.priceList, action.quantity, action.incItbms);

        case CART_REMOVE_ITEM:
            return removeItem(state, action.itemId);

        case CART_UPDATE_QUANTITIES:
            return updateQuantities(state, action.quantities);

        case CHANGE_PRICE_ITEM:
            return updatePrice(state, action.index, action.price);

        case CHANGE_COMMENTS:
            return updateComments(state, action.comments);

        case RECALCULATE_PRICES:
            return RecalculatePrices(state, action.products, action.services);

        case CART_REMOVE_ALL_ITEMS:
            return initialState;

        case CART_LOAD_ALL_ITEMS:
            return action.cart;

        case CHANGE_CONTACT:
            return { ...state, contact: action.contact };

        case CHANGE_CONTACTS:
            return { ...state, contacts: action.contacts };

        case CHANGE_CUSTOMER:
            return recalculatePriceAllItem(state, action.customer, action.priceList);

        case CHANGE_TEXT_ITEM:
            return updateCommentItem(state, action.index, action.text);

        case RECALCULATE_ITEM_PRICE:
            return recalculatePriceItem(state, action.index, action.oldFactor, action.newFactor);

        case CHANGE_QTY:
            return editQty(state, action.index, action.qty);

        case CHANGE_VALIDITY:
            return editValidity(state, action.validity);

        case CHANGE_CONDITIONS:
            return editConditions(state, action.conditions);

        case CHANGE_EXEMPT_ITBMS:
            return editExemptItbms(state, action.exempt);

        case CHANGE_INCITBMS:
            return editIncItbms(state, action.index, action.value);

        default:
            return state;
    }
}
