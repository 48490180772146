import { CURRENT_USER, ADDRESSES_USER, CHANGE_CUSTOMER } from './currentUserActionTypes';

const initialState = null;

export default function currentUserReducer(state = initialState, action) {
    if (action.type === CURRENT_USER) {
        return JSON.parse(JSON.stringify(action.currentUser));
    }
    if (action.type === ADDRESSES_USER) {
        return { ...state, addresses: action.addresses };
    }
    if (action.type === CHANGE_CUSTOMER) {
        return { ...state, customer: action.customer };
    }
    return state;
}
