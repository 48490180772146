// react
import React, { Component } from 'react';
// third-party
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Button } from 'reactstrap';
import { MultiSelect } from 'primereact/multiselect';
import { getSuggestedProductsICA, getStockInvPart, getSuggestedProductsAP } from '../../store/directusSdk';
import SubTable from './SubTable';

class ProductsTable extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                show: true, style: { width: '13%', textAlign: 'center' }, bodyStyle: { textAlign: 'justify' }, header: 'SKU', field: 'sku', sortable: true, filter: true
            },
            {
                show: true, style: { width: '30%', textAlign: 'center' }, bodyStyle: { textAlign: 'justify' }, header: 'Producto', field: 'name', sortable: true, filter: true
            },
            {
                show: true, style: { width: '11%', textAlign: 'center' }, bodyStyle: { textAlign: 'center' }, header: 'Pedido', sortable: true, field: 'order', filter: true, filterPlaceholder: 'Pedido...', filterType: 'Number', body: this.PedidoTemplate
            },
            {
                show: true, style: { width: '13%', textAlign: 'center' }, bodyStyle: { textAlign: 'center' }, header: 'Bodega', sortable: true, filter: true, filterPlaceholder: 'Bodega...', body: this.WarehouseTemplate, sortField: 'warehouse_id.value', filterField: 'warehouse_id.value'
            },
            {
                show: true, style: { width: '11%', textAlign: 'center' }, bodyStyle: { textAlign: 'center' }, header: 'Filtar', field: 'filter', body: this.DeleteTemplate, sortable: true, filter: true,
            },
        ];

        this.state = {
            selectedColumns: this.columns.filter((col) => col.show),
            product: null,
            products: props.products,
            cant: 1,
            warehouse: props.whsSelect,
            expandedRows: null,
            newChanges: [],
            newChangesLines: [],
            whsSelect: null,
            filterSelect: null,
            commitedSelect: null,
            externalInvSelect: null,
            codeInp: '',
            nameInp: '',
            orderInp: null,
            newMovements: [],
            open: false,
            modalData: [],
            itemsInvPart: [],
            expandAll: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.products !== state.products) {
            return {
                products: props.products,
            };
        }
        return null;
    }

    onColumnToggle = (event) => {
        const selectedColumns = event.value;
        const orderedSelectedColumns = this.columns.filter((col) => selectedColumns.some((sCol) => sCol.header === col.header));
        return this.setState({ selectedColumns: orderedSelectedColumns });
    }

    promiseOptions = (table, inputValue) => new Promise((resolve) => resolve(table === 'ICA' ? getSuggestedProductsICA(inputValue) : getSuggestedProductsAP(inputValue)));

    expandAll = () => {
        const { products } = this.props;
        const expandedRows = {};
        products.forEach((p) => { expandedRows[`${p.id}`] = true; });

        this.setState({ expandedRows, expandAll: true });
    }

    collapseAll = () => this.setState({ expandedRows: null, expandAll: false });

    rowExpansionTemplate = (row) => (
        <div className="orders-subtable">
            {row.movements.length > 0 ? (
                <SubTable
                    fila={row}
                    status={this.props.status}
                    currentUser={this.props.currentUser}
                    table={this.props.table}
                    products={this.props.products}
                />
            ) :
                (
                    <p>No se Han Realizado Entregas...</p>
                )
            }
        </div>
    );

    onWhsChange = (e) => {
        this.setState({ whsSelect: e.value });
        return this.dt.filter(e.value, 'warehouse_id.value', 'equals');
    };

    onCommitedChange = (e) => {
        this.setState({ commitedSelect: e.value });
        return this.dt.filter(e.value, 'commited', 'equals');
    };


    onCodeChange = (e) => {
        this.setState({ codeInp: e.target.value });
        return this.dt.filter(e.target.value, 'code', 'startsWith');
    };

    onNameChange = (e) => {
        this.setState({ nameInp: e.target.value });
        return this.dt.filter(e.target.value, 'name', 'contains');
    };

    onOrderChange = ({ value }) => {
        this.setState({ orderInp: value });
        return this.dt.filter(value, 'order', 'equals');
    };

    onFilterChange = (e) => {
        this.setState({ filterSelect: e.value });
        return this.dt.filter(e.value, 'filter', 'equals');
    };

    CodeTemplate = (row) => row.code;

    PedidoTemplate = (row) => {
        return row.order;
    }

    WarehouseTemplate = (row) => {
        return row.warehouse_id.label;
    }

    AlertTemplate = (row) => {
        if (!row.movements.length > 0 && row.warehouse_id.code === '-1') {
            if (!row.stockExternal) {
                return { error: '#ea553d', message: 'Item no se Encuentra  en Inventario particular del Cliente.', icon: 'fas fa-exclamation-triangle fa-2x' };
            }
            if (row.stockExternal && row.order > row.stockExternal) {
                return { error: '#ea553d', message: 'Cantidad Solicitada es Mayor a la Existente en Inventario particular del Cliente.', icon: 'fas fa-exclamation-triangle fa-2x' };
            }
        }

        if (!row.movements.length > 0) {
            return { error: '#3bc3e9', message: 'Sin Entregas.', icon: 'fas fa-exclamation-triangle fa-2x' };
        }

        for (let m of row.movements) {
            if (m.delivered_qty !== m.confirmed_qty && m.confirmed_date) {
                return { error: '#ea553d', message: 'Incongruencias entre cantidad Enviada y Confirmada.', icon: 'fas fa-exclamation-triangle fa-2x' };
            }
            if (m.type === 'delivery' && m.delivered_qty > 0 && !m.confirmed_date) {
                return { error: '#ffbb44', message: 'Entregas pendientes por confirmar.', icon: 'fas fa-exclamation-triangle fa-2x' };
            }
            if (m.type === 'return' && m.delivered_qty > 0 && !m.confirmed_date) {
                return { error: '#ffbb44', message: 'Devoluciones pendientes por confirmar.', icon: 'fas fa-exclamation-triangle fa-2x' };
            }
        }

        let delivered = 0;
        let Return = 0;

        row.movements.forEach((item) => {
            if (item.type === 'delivery') {
                delivered += item.confirmed_qty;
            } else if (item.type === 'return') {
                Return += (item.confirmed_qty);
            }
        });

        if (delivered > 0 && row.order > delivered) {
            return { error: '#3bc3e9', message: 'Cantidad Recibida es menor a la orden.', icon: 'fas fa-exclamation-triangle fa-2x' };
        }

        if (delivered > 0 && delivered >= row.order) {
            return { error: '#28a765', message: 'Producto Completo!', icon: 'fa fa-check fa-2x' };
        }

        return { error: '', message: '', icon: '' };
    }

    DeleteTemplate = (row) => {
        const alert = this.AlertTemplate(row);
        if (!alert) {
            return '';
        }
        return <i className={alert.icon} title={alert.message} style={{ color: alert.error }} />;
    }

    exportCSV = () => {
        return this.dt.exportCSV();
    }

    showBodegas = (OnHandByWhs) => {
        let warehouses = '';
        for (const property in OnHandByWhs) {
            warehouses += `${property}: ${OnHandByWhs[property]} un.   `;
        }
        return warehouses;
    }

    showInvPart = (product, table, ruc) => {
        if (!product) {
            return this.setState({ product });
        }

        return getStockInvPart(product.code, table, ruc)
            .then(({ message }) => {
                product.stockExternal = message;
                return this.setState({ product });
            })
            .catch(({ code }) => {
                if (code == 203) {
                    product.stockExternal = 'Inventario Particular: No Encontrado.';
                    return this.setState({ product });
                }
            });
    }

    render() {
        const { codeInp, nameInp, expandAll, orderInp, filterSelect, externalInvSelect, whsSelect, expandedRows, cant, product, warehouse, products, selectedColumns, open, modalData } = this.state;
        const { whs, project } = this.props;
        if (!project.orden_venta) {
            return <h3>¡Debe Crear Orden de Venta en SAP!</h3>
        }
        const whsFilter = <Dropdown style={{ width: '100%' }} value={whsSelect} options={whs} onChange={this.onWhsChange} placeholder="Todos..." className="p-column-filter" showClear />
        const filter = <Dropdown style={{ width: '100%' }} value={filterSelect} options={[
            { label: 'Sin Entregas', value: 0 },
            { label: 'Por Confirmar', value: 1 },
            { label: 'Incompletos', value: 3 },
            { label: 'Completos', value: 2 }
        ]} onChange={this.onFilterChange} placeholder="Todos..." className="p-column-filter" showClear />
        const codeFilter = <InputText value={codeInp} onChange={this.onCodeChange} placeholder="Todos..." className="p-column-filter" />
        const nameFilter = <InputText value={nameInp} onChange={this.onNameChange} placeholder="Todos..." className="p-column-filter" />
        const orderFilter = <InputNumber value={orderInp} onChange={this.onOrderChange} placeholder="Todos..." className="p-column-filter" />

        const header = (
            <React.Fragment>
                <div className="row mt-3">
                    <div className="table-header-container col-md-2">
                        {expandAll ? <Button color="warning" onClick={this.collapseAll}><i className="fas fa-angle-double-right mr-2" />Contraer</Button> : <Button color="info" className="mr-2" onClick={this.expandAll} ><i className="fas fa-angle-double-down mr-2" />Expandir</Button>}
                    </div>
                    <div className="offset-md-5 col-md-2">
                        <Button color="info" onClick={this.exportCSV}><i className="fas fa-file-export mr-2" />Exportar</Button>
                    </div>
                    <div className="col-md-3" style={{ textAlign: 'left' }}>
                        <MultiSelect value={selectedColumns} options={this.columns} optionLabel="header" onChange={this.onColumnToggle} style={{ width: '15em' }} />
                    </div>
                </div>
            </React.Fragment>
        );

        const columnComponents = selectedColumns.map((col, i) => {
            switch (col.header) {
                case 'name':
                    col.filterElement = nameFilter;
                    break;
                case 'Pedido':
                    col.filterElement = orderFilter;
                    break;
                case 'Bodega':
                    col.filterElement = whsFilter;
                    break;
                case 'Filtar':
                    col.filterElement = filter;
                    break;
                default:
                    break;
            }

            return <Column key={i} {...col} filterHeaderClassName="p-inputtext-sm" />;
        });

        return (
            <div className="datatable-rowexpansion-demo">
                <DataTable
                    ref={(el) => this.dt = el}
                    value={products}
                    dataKey="id"
                    header={header}
                    expandedRows={expandedRows}
                    onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                    rowExpansionTemplate={this.rowExpansionTemplate}
                    paginator
                    rows={10}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    removableSort
                    emptyMessage="No se han encontrado registros..."
                    className="p-datatable-customers"
                >
                    <Column expander style={{ width: '4%' }} />
                    <Column field="id" header="ID" style={{ width: '7%', textAlign: 'center' }} sortable filter filterType="number" filterHeaderClassName="p-inputtext-sm" />
                    <Column field="code" header="Código" style={{ width: '10%', textAlign: 'left' }} sortable filter filterElement={codeFilter} filterHeaderClassName="p-inputtext-sm" body={this.CodeTemplate} />
                    {columnComponents}
                </DataTable>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsTable);
