import React, { Component, Fragment } from "react";
import { CSVReader } from "react-papaparse";
import { toast } from "react-toastify";

export default class ImportListProductsCSV extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            header: [],
        };
    }

    handleOnDrop = (data) => {
        const header = data.shift();
        const code = header.data.findIndex((e) => ["codigo", "Codigo", "Código", "código"].includes(e));
        if (code === -1) {
            return toast.error("¡Error, El archivo debe contener la columna Código.!");
        }
        const { LoadCodes } = this.props;

        const codesClean = data.reduce((acc, current) => {
            const i = acc.find((a) => a.value && a.value === current.data[code]);
            if (!i) {
                acc.push({ label: current.data[code], value: current.data[code] });
                return acc;
            }
            return acc;
        }, []);

        LoadCodes(codesClean);
    };

    handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
    };

    handleOnRemoveFile = (data) => {
        this.props.resetTop();
        return this.props.LoadCodes([]);
    };

    render() {
        const { errors, header, data } = this.state;

        return (
            <Fragment>
                {errors.length > 0 && (
                    <div className="alert alert-danger">
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th width="20%">Linea</th>
                                    <th width="40%">Código</th>
                                    <th width="40%">SKU</th>
                                    <th width="20%">Stock</th>
                                </tr>
                            </thead>
                            <tbody>
                                {errors.map((e, i) => (
                                    <tr key={i}>
                                        <td>{e.line}</td>
                                        <td>{e.code}</td>
                                        <td>{e.sku}</td>
                                        <td>{e.stock}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                <div style={{ width: "100%", height: 200 }}>
                    <CSVReader
                        onDrop={this.handleOnDrop}
                        onError={this.handleOnError}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={this.handleOnRemoveFile}
                    >
                        <span>Arrastra y Suelta el archivo CSV aquí o haz clic para cargarlo.</span>
                    </CSVReader>
                </div>
            </Fragment>
        );
    }
}
