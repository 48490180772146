import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import esLocale from "@fullcalendar/core/locales/es";
import { Helmet } from "react-helmet-async";
import { Badge } from "primereact/badge";
import { toast } from "react-toastify";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { confirmPopup } from "primereact/confirmpopup";
import AsyncSelect from "react-select/async";
import Alert from "sweetalert2";
import { InputMask } from "primereact/inputmask";
import { Modal, ModalHeader, ModalBody, UncontrolledCollapse, Input } from "reactstrap";
import moment from "moment";
import { Button } from "primereact/button";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import PageHeader from "../shared/PageHeader";
import ImportCsv from "./ImportCsv";
import TableTasksReport from "./TableTasksReport";
import { getProjectTask, AddAtivity, updateAtivity, deleteAtivity, createAtivities, searchUser, deleteResources } from "../../store/directusSdk";
import { FormattedDifference, msToTime, isTimeFormar } from "../shared/utilities";

const ProjectTasksDetails = ({ currentUser }) => {
    const [project, setProject] = useState({ activities: [] });
    const [task, setTask] = useState({ planned_date: moment().format("DD/MM/YYYY") });
    const [upload, setUpload] = useState([]);
    const [oldTask, setOldTask] = useState(null);
    const [errorsEmails, setErrorsEmails] = useState([]);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [mode, setMode] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);

    const { id } = useParams();
    const calendary = useRef();
    const actors = ["Administrator", "Ingeniero ICA", "Admin ICA", "Ingeniero AP", "Admin AP", "Vendedor ICA", "Vendedor AP",  "VendedorICA-Logistica"];
    const editors = ["Administrator", "Admin ICA", "Admin AP"];
    useEffect(() => {
        getProject();
    }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

    const getProject = () => {
        setLoading(true);
        getProjectTask(id)
            .then(setProject)
            .catch((error) => toast.error("Error!, " + error.message))
            .finally(() => setLoading(false));
    };

    const renderStatus = (status) => {
        switch (true) {
            case status === "Pendiente":
                return "info";
            case status === "Pausada":
                return "warning";
            case status === "En Proceso":
                return "info";
            default:
                return "success";
        }
    };

    const handleEventClick = ({ event }) => {
        setTask({ ...event.extendedProps, title: event.title, id: event.id });
        setMode("read");
        setOpen(true);
    };

    const handleEventResize = (eventDrop) => {
        setLoading1(true);
        const json = {
            end: eventDrop.event.end && moment(eventDrop.event.end).format("YYYY-MM-DD HH:mm:ss"),
            time_plan: FormattedDifference(moment(eventDrop.event.end), moment(eventDrop.event.start)),
        };

        return updateAtivity(eventDrop.event.id, json)
            .then((activity) => {
                const index = project.activities.findIndex((a) => a.id === activity.id);
                let activities = [...project.activities];
                activities[index] = activity;
                setProject({ ...project, activities });
                toast.success("Duración Modificada con exito!");
            })
            .catch((e) => {
                eventDrop.revert();
                toast.error("¡Error!, " + e.message);
            })
            .finally(() => setLoading1(false));
    };

    const handleDateSelect = (selectInfo) => {
        let calendarApi = selectInfo.view.calendar;
        calendarApi.unselect(); // clear date selection
        setTask({
            title: "",
            resources: [],
            time_plan: "00:00",
            engproject_id: project.id,
            start: moment(selectInfo.start).format("YYYY-MM-DD HH:mm:ss"),
            end: moment(selectInfo.end).format("YYYY-MM-DD HH:mm:ss"),
            allDay: selectInfo.allDay,
        });
        setMode("add");
        setOpen(true);
    };

    const saveTask = () => {
        setLoading1(true);
        if (mode === "add") {
            return AddAtivity({ ...task, planned_date: moment().format("YYYY-MM-DD"), resources: task.resources.map((r) => ({ user_id: r.id })) })
                .then((activity) => {
                    setProject({ ...project, activities: [...project.activities, activity] });
                    //calendarApi.addEvent(activity);
                    toast.success("Actividad Creada con Exito!");
                    setOpen(false);
                    setTask(null);
                })
                .catch((error) => toast.error(`¡Error!, ${error.message}`))
                .finally(() => setLoading1(false));
        }
        let change = 0;
        const toDelete = [];
        const toAdd = [];
        const json = {};
        if (task.title !== oldTask.title) {
            json.title = task.title;
            change++;
        }
        if (JSON.stringify(task.resources) !== JSON.stringify(oldTask.resources)) {
            oldTask.resources.forEach((r) => {
                const resource = task.resources.find((item) => item.id === r.id);
                if (!resource) {
                    toDelete.push(r.resId);
                }
            });

            task.resources.forEach((r) => {
                const resource = oldTask.resources.find((item) => item.id === r.id);
                if (!resource) {
                    toAdd.push({ user_id: r.id });
                }
            });
            if (toAdd.length > 0) {
                json.resources = toAdd;
                change++;
            }
        }
        if (task.time_plan !== oldTask.time_plan) {
            json.time_plan = task.time_plan;
            change++;
        }

        if (toDelete.length > 0) {
            deleteResources(toDelete);
        }

        if (!change > 0) {
            setLoading1(false);
            return setMode("read");
        }
        //  const event = calendarApi.getEventById(task.id);

        return updateAtivity(task.id, json)
            .then((activity) => {
                const index = project.activities.findIndex((a) => a.id === activity.id);
                let activities = [...project.activities];
                activities[index] = activity;
                setProject({ ...project, activities });
                toast.success("Actividad Modificada con Exito!");
                setMode("read");
            })
            .catch((error) => toast.error(`¡Error!, ${error.message}`))
            .finally(() => setLoading1(false));
    };

    const changeTask = (event) => {
        if (event && event.target && event.target.name) {
            setTask({
                ...task,
                [event.target.name]: event.target.value,
            });
        }
    };

    const changeTimePlan = (event) => {
        let time_plan = event.value;
        const arr = event.value.split(":");

        if (arr[1] > 59) {
            arr[1] = 59;
            time_plan = arr.join(":");
        }

        const duration = moment.duration(time_plan);
        setTask({
            ...task,
            end: duration > 0 ? moment(task.start).add(duration).format("YYYY-MM-DD HH:mm:ss") : null,
            time_plan,
        });
    };

    const changeResources = (resources) => {
        setTask({
            ...task,
            resources,
        });
    };

    const eventDrop = (eventDrop) => {
        setLoading1(true);
        const json = {
            start: moment(eventDrop.event.start).format("YYYY-MM-DD HH:mm:ss"),
            allDay: eventDrop.event.allDay,
            end: moment(eventDrop.event.start).add(moment.duration(eventDrop.event.extendedProps.time_plan)).format("YYYY-MM-DD HH:mm:ss"),
        };

        updateAtivity(eventDrop.event.id, json)
            .then((activity) => {
                const index = project.activities.findIndex((a) => a.id === activity.id);
                let activities = [...project.activities];
                activities[index] = activity;
                setProject({ ...project, activities });
                toast.success("Actividad reprogramada con exito!");
            })
            .catch((e) => {
                eventDrop.revert();
                toast.error("¡Error!, " + e.message);
            })
            .finally(() => setLoading1(false));
    };

    const editTask = () => {
        setOldTask(task);
        setMode("edit");
    };

    const iniTask = () => {
        setLoading1(true);
        let calendarApi = calendary.current.getApi();
        const event = calendarApi.getEventById(task.id);
        return updateAtivity(task.id, {
            iniciadapor: currentUser.id,
            hour_ini: moment().format("YYYY-MM-DD HH:mm:ss"),
            start: moment().format("YYYY-MM-DD HH:mm:ss"),
            allDay: false,
            status: "En Proceso",
            end: moment().add(moment.duration(task.time_plan)).format("YYYY-MM-DD HH:mm:ss"),
        })
            .then((activity) => {
                const index = project.activities.findIndex((a) => a.id === activity.id);
                let activities = [...project.activities];
                activities[index] = activity;
                setProject({ ...project, activities });
                setTask(activity);
                toast.success("Actividad Iniciada con exito!");
            })
            .catch((e) => toast.error("¡Error!, " + e.message))
            .finally(() => setLoading1(false));
    };

    const ReiniTask = (comment) => {
        setLoading1(true);
        const timeUsed = FormattedDifference(moment(task.hour_pausada), moment(task.hour_ini));
        const timeDuration = moment.duration(task.time_plan) - moment.duration(timeUsed);
        const timePlanNew = msToTime(timeDuration < 0 ? 0 : timeDuration);
        const actividad = {
            status: "Terminada",
            hour_fin: task.hour_pausada,
            time_real: timeUsed,
            terminadapor: currentUser.id,
            end: moment(task.hour_pausada).format("YYYY-MM-DD HH:mm:ss"),
        };

        if (timeDuration > 0) {
            actividad.time_plan = timeUsed;
        }

        return updateAtivity(task.id, actividad)
            .then((activity) => {
                const index = project.activities.findIndex((a) => a.id === activity.id);
                let activities = [...project.activities];
                activities[index] = activity;
                AddAtivity({
                    iniciadapor: currentUser.id,
                    hour_ini: moment().format("YYYY-MM-DD HH:mm:ss"),
                    start: moment().format("YYYY-MM-DD HH:mm:ss"),
                    allDay: false,
                    status: "En Proceso",
                    engproject_id: project.id,
                    time_plan: timePlanNew,
                    title: "C- " + task.title,
                    resources: task.resources.map((r) => ({ user_id: r.id })),
                    end: moment().add(moment.duration(timePlanNew)).format("YYYY-MM-DD HH:mm:ss"),
                    comment,
                })
                    .then((newActivity) => {
                        setProject({ ...project, activities: [...activities, newActivity] });
                    })
                    .catch((error) => toast.error(`¡Error!, ${error.message}`));

                setTask(activity);
                toast.success("Actividad Reiniciada con exito!");
            })
            .catch((e) => toast.error("¡Error!, " + e.message))
            .finally(() => setLoading1(false));
    };

    const pauseTask = (comment) => {
        setLoading1(true);
        let calendarApi = calendary.current.getApi();
        const event = calendarApi.getEventById(task.id);
        return updateAtivity(task.id, {
            status: "Pausada",
            pausadapor: currentUser.id,
            hour_pausada: moment().format("YYYY-MM-DD HH:mm:ss"),
            comment,
        })
            .then((activity) => {
                const index = project.activities.findIndex((a) => a.id === activity.id);
                let activities = [...project.activities];
                activities[index] = activity;
                setProject({ ...project, activities });
                setTask(activity);
                toast.success("Actividad Pausada con exito!");
            })
            .catch((e) => toast.error("¡Error!, " + e.message))
            .finally(() => setLoading1(false));
    };

    const finishTask = (comment) => {
        setLoading1(true);
        let calendarApi = calendary.current.getApi();
        const time_real = FormattedDifference(moment(), moment(task.hour_ini));
        const event = calendarApi.getEventById(task.id);
        console.log(task.comment);
        return updateAtivity(task.id, {
            terminadapor: currentUser.id,
            hour_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
            status: "Terminada",
            end: moment().format("YYYY-MM-DD HH:mm:ss"),
            time_real,
            comment: task.comment && task.comment.length > 0 ? `Reinicio:${task.comment} \n / Fin: ${comment}` : comment,
        })
            .then((activity) => {
                const index = project.activities.findIndex((a) => a.id === activity.id);
                let activities = [...project.activities];
                activities[index] = activity;
                setProject({ ...project, activities });
                setTask(activity);
                toast.success("Actividad Terminada con exito!");
            })
            .catch((e) => toast.error("¡Error!, " + e.message))
            .finally(() => setLoading1(false));
    };

    const confirm2 = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: "¿Esta Seguro que desea borrar esta Actividad?",
            icon: "pi pi-info-circle",
            acceptLabel: "Si",
            acceptClassName: "p-button-danger",
            accept: delTask,
        });
    };

    const delTask = () => {
        setLoading1(true);
        let calendarApi = calendary.current.getApi();
        const event = calendarApi.getEventById(task.id);
        return deleteAtivity(task.id)
            .then(() => {
                const index = project.activities.findIndex((a) => a.id === task.id);
                project.activities.splice(index, 1);
                setProject({ ...project });
                setTask(null);
                event.remove();
                setOpen(false);
                toast.success("Actividad Borrada con exito!");
            })
            .catch((e) => toast.error("¡Error!, " + e.message))
            .finally(() => setLoading1(false));
    };

    const uploadTask = (taskks) => {
        setUpload(taskks);
        setOpen2(true);
    };

    const importTasks = () => {
        setLoading1(true);
        const actividades = [];
        upload.forEach((e) => {
            actividades.push({ ...e, resources: e.resources.map((r) => ({ user_id: r.id })) });
        });

        return createAtivities(actividades)
            .then(() => {
                getProject();
                setUpload([]);
                setOpen2(false);
                toast.success("Actividades Importadas con exito!");
            })
            .catch((e) => toast.error("¡Error!, " + e.message))
            .finally(() => setLoading1(false));
    };

    const generateReport = () => {
        setOpen3(true);
    };

    const promiseOptions = (inputValue) => searchUser(inputValue);

    const confirm = (title, text, confirmText, comment, succesTitle, succesText, action) => {
        const bodyAlert = {
            title,
            text,
            showCancelButton: true,
            confirmButtonText: confirmText,
            cancelButtonText: "Cancelar",
            reverseButtons: true,
            confirmButtonColor: "#004FBD",
            icon: "question",
            allowOutsideClick: () => !Alert.isLoading(),
            preConfirm: (cmt) => {
                action(cmt);
            },
        };

        if (comment) {
            bodyAlert.input = "textarea";
            bodyAlert.inputLabel = "Indique un comentario (Opcional)";
            bodyAlert.inputPlaceholder = "Comentario...";
        }

        return Alert.fire(bodyAlert).then((result) => {
            if (result.isConfirmed) {
                return Alert.fire(succesTitle, succesText, "success");
            }
        });
    };

    const breadcrumb = [
        { title: "Home", url: "/" },
        { title: "Tareas", url: "/tasks-manager" },
        { title: "Tareas de Proyecto", url: "" },
    ];

    if (loading) {
        return (
            <div className="container">
                <div style={{ marginTop: "20em", marginBottom: "20em" }}>
                    <div className="div-loading" />
                </div>
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Tareas de Proyecto - ICAutomatizados</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <table width="100%" className="table table-sm">
                            <tbody>
                                <tr>
                                    <th width="30%">Código</th>
                                    <td width="70%" align="right">
                                        {project.code}
                                    </td>
                                </tr>
                                <tr>
                                    <th width="30%">Ingeniero Encargado</th>
                                    <td width="70%" align="right">
                                        {project.encargado}
                                    </td>
                                </tr>
                                <tr>
                                    <th width="30%">Orden Venta</th>
                                    <td width="70%" align="right">
                                        {project.ordenVenta}
                                    </td>
                                </tr>
                                <tr>
                                    <th width="30%"></th>
                                    <td width="70%" align="right"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-6">
                        <table width="100%" className="table table-sm">
                            <tbody>
                                <tr>
                                    <th width="30%">Proyecto</th>
                                    <td width="70%" align="right">
                                        {project.name}
                                    </td>
                                </tr>
                                <tr>
                                    <th width="30%">Cliente</th>
                                    <td width="70%" align="right">
                                        {project.cliente}
                                    </td>
                                </tr>
                                <tr>
                                    <th width="30%">Fecha Entrega</th>
                                    <td width="70%" align="right">
                                        {moment(project.fechaEntrega).format("DD-MM-YYYY")}
                                    </td>
                                </tr>
                                <tr>
                                    <th width="30%"></th>
                                    <td width="70%" align="right"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="card">
                    <div className="mb-2 p-d-flex p-jc-between">
                        {editors.includes(currentUser.role.name) && (
                            <Button
                                id="toggler"
                                label="Importar Actividades"
                                icon="pi pi-cloud-upload"
                                iconPos="right"
                                className="p-button-secondary"
                            />
                        )}
                        <Button
                            label="Reporte de Actividades"
                            icon="pi pi-chart-line"
                            iconPos="right"
                            className="p-button-info"
                            onClick={generateReport}
                            disabled={project.activities.length < 1}
                        />
                    </div>
                    {editors.includes(currentUser.role.name) && (
                        <UncontrolledCollapse toggler="#toggler">
                            <div>
                                <ImportCsv uploadTask={uploadTask} engproject_id={project.id} setErrorsEmails={setErrorsEmails} />
                            </div>
                        </UncontrolledCollapse>
                    )}

                    <div className="card-table">
                        <FullCalendar
                            ref={calendary}
                            events={project.activities}
                            initialView="timeGridWeek"
                            locale={esLocale}
                            initialDate={moment().format("YYYY-MM-DD")}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                            headerToolbar={{ left: "prev,next,today", center: "title", right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek" }}
                            editable={editors.includes(currentUser.role.name)}
                            droppable={editors.includes(currentUser.role.name)}
                            selectable={editors.includes(currentUser.role.name)}
                            selectMirror
                            dayMaxEvents
                            eventResize={handleEventResize}
                            eventClick={handleEventClick}
                            eventDrop={eventDrop}
                            eventOverlap={(stillEvent, movingEvent) =>
                                stillEvent.extendedProps.status === "Pendiente" && movingEvent.extendedProps.status === "Pendiente"
                            }
                            select={handleDateSelect}
                            eventTimeFormat={{ hour: "2-digit", minute: "2-digit", meridiem: "short", hour12: true }}
                            businessHours={{ daysOfWeek: [1, 2, 3, 4, 5], startTime: "07:00", endTime: "17:00" }}
                        />
                    </div>
                </div>
            </div>

            {task && (
                <Modal isOpen={open} toggle={() => setOpen(false)} centered size="lg" zIndex={999}>
                    <ModalHeader toggle={() => setOpen(false)}>{task.title}</ModalHeader>
                    <ModalBody>
                        <table width="100%" className="table table-sm">
                            <tbody>
                                {mode === "add" && (
                                    <tr>
                                        <th width="30%">Todo el Dia</th>
                                        <td width="70%" align="right">
                                            <InputSwitch checked={task.allDay} name="allDay" onChange={changeTask} />
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <th width="30%">Nombre</th>
                                    <td width="70%" align="right">
                                        {mode !== "read" ? (
                                            <InputText style={{ width: "100%" }} name="title" value={task.title} onChange={changeTask} />
                                        ) : (
                                            task.title
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th width="30%">Tiempo Planificado</th>
                                    <td width="70%" align="right">
                                        {mode !== "read" ? (
                                            <InputMask
                                                autoClear={false}
                                                id="basic"
                                                mask="?99:99"
                                                value={task.time_plan}
                                                slotChar="00:00"
                                                onChange={changeTimePlan}
                                            ></InputMask>
                                        ) : task.time_plan !== "" ? (
                                            task.time_plan
                                        ) : (
                                            "00:00"
                                        )}
                                    </td>
                                </tr>
                                {mode === "read" && (
                                    <tr>
                                        <th width="30%">Fecha Planificada</th>
                                        <td width="70%" align="right">
                                            {task.planned_date && moment(task.planned_date).format("DD/MM/YYYY")}
                                        </td>
                                    </tr>
                                )}

                                <tr>
                                    <th width="30%">Recursos</th>
                                    <td width="70%" align="right">
                                        {mode !== "read" ? (
                                            <AsyncSelect
                                                isClearable
                                                isMulti
                                                cacheOptions
                                                name="resources"
                                                loadOptions={promiseOptions}
                                                noOptionsMessage={() => "No se Encontró registros..."}
                                                loadingMessage={() => "Buscando..."}
                                                placeholder="Ingrese Nombre del Colaborador..."
                                                onChange={changeResources}
                                                value={task.resources}
                                                getOptionLabel={(option) => option.email}
                                                getOptionValue={(option) => option.id}
                                            />
                                        ) : (
                                            task.resources.map((r, i) => (
                                                <React.Fragment key={i}>
                                                    {r.email}
                                                    <br />
                                                </React.Fragment>
                                            ))
                                        )}
                                    </td>
                                </tr>
                                {mode !== "add" && (
                                    <tr>
                                        <th width="30%">Estado</th>
                                        <td width="70%" align="right">
                                            <Badge value={task.status} severity={renderStatus(task.status)} />
                                        </td>
                                    </tr>
                                )}
                                {["En Proceso", "Pausada", "Terminada"].includes(task.status) && (
                                    <tr>
                                        <th width="30%">Iniciada Por:</th>
                                        <td width="70%" align="right">
                                            {task.iniciadapor && task.iniciadapor.first_name + " " + task.iniciadapor.last_name}
                                        </td>
                                    </tr>
                                )}
                                {task.status === "Terminada" && (
                                    <>
                                        <tr>
                                            <th width="30%">Tiempo Real:</th>
                                            <td width="70%" align="right">
                                                {task.time_real}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th width="30%">Terminada Por:</th>
                                            <td width="70%" align="right">
                                                {task.terminadapor && task.terminadapor.first_name + " " + task.terminadapor.last_name}
                                            </td>
                                        </tr>
                                    </>
                                )}
                                {["En Proceso", "Pausada", "Terminada"].includes(task.status) && (
                                    <tr>
                                        <th width="30%">Comentarios:</th>
                                        <td width="70%" align="right">
                                            {task.comment}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {actors.includes(currentUser.role.name) && (
                            <div className="p-d-flex p-jc-between">
                                {["read"].includes(mode) && editors.includes(currentUser.role.name) && task.status === "Pendiente" && (
                                    <Button
                                        label="Editar"
                                        icon="pi pi-pencil"
                                        iconPos="right"
                                        disabled={loading1}
                                        loading={loading1}
                                        onClick={editTask}
                                    />
                                )}
                                {["add", "edit"].includes(mode) && editors.includes(currentUser.role.name) && task.title.length > 0 && (
                                    <Button
                                        label="Guardar"
                                        icon="pi pi-save"
                                        iconPos="right"
                                        disabled={loading1}
                                        loading={loading1}
                                        onClick={saveTask}
                                    />
                                )}
                                {["read", "edit"].includes(mode) && ["Pendiente"].includes(task.status) && (
                                    <>
                                        <Button
                                            label="Iniciar"
                                            icon="pi pi-play"
                                            iconPos="right"
                                            disabled={loading1}
                                            loading={loading1}
                                            onClick={() =>
                                                confirm(
                                                    "Iniciar Actividad",
                                                    "¿Desea iniciar la actividad " + task.title + "?",
                                                    "Iniciar",
                                                    false,
                                                    "¡Actividad Iniciada Correctamente!",
                                                    "Actividad " + task.title + " iniciada.",
                                                    iniTask
                                                )
                                            }
                                            className="p-button-info"
                                        />
                                        {editors.includes(currentUser.role.name) && (
                                            <Button
                                                label="Borrar"
                                                icon="pi pi-erase"
                                                iconPos="right"
                                                disabled={loading1}
                                                loading={loading1}
                                                onClick={confirm2}
                                                className="p-button-danger"
                                            />
                                        )}
                                    </>
                                )}
                                {["read", "edit"].includes(mode) && ["Pausada"].includes(task.status) && (
                                    <>
                                        <Button
                                            label="Reiniciar"
                                            icon="pi pi-play"
                                            iconPos="right"
                                            disabled={loading1}
                                            loading={loading1}
                                            onClick={() =>
                                                confirm(
                                                    "Reiniciar Actividad",
                                                    "¿Desea Reiniciar la actividad " + task.title + "?",
                                                    "Reiniciar",
                                                    true,
                                                    "¡Actividad Reiniciada Correctamente!",
                                                    "Actividad " + task.title + " reiniciada.",
                                                    ReiniTask
                                                )
                                            }
                                            className="p-button-info"
                                        />
                                    </>
                                )}
                                {["read", "edit"].includes(mode) && task.status === "En Proceso" && (
                                    <>
                                        <Button
                                            label="Pausar"
                                            icon="pi pi-pause"
                                            iconPos="right"
                                            disabled={loading1}
                                            loading={loading1}
                                            onClick={() =>
                                                confirm(
                                                    "Pausar Actividad",
                                                    "¿Desea Pausar la actividad " + task.title + "?",
                                                    "Pausar",
                                                    true,
                                                    "¡Actividad Pausada Correctamente!",
                                                    "Actividad " + task.title + " pausada.",
                                                    pauseTask
                                                )
                                            }
                                            className="p-button-warning"
                                        />
                                        <Button
                                            label="Terminar"
                                            icon="pi pi-check"
                                            iconPos="right"
                                            disabled={loading1}
                                            loading={loading1}
                                            onClick={() =>
                                                confirm(
                                                    "Finalizar Actividad",
                                                    "¿Desea Finalizar la actividad " + task.title + "?",
                                                    "Finalizar",
                                                    true,
                                                    "¡Actividad Finalizada Correctamente!",
                                                    "Actividad " + task.title + " finalizada.",
                                                    finishTask
                                                )
                                            }
                                            className="p-button-success"
                                        />
                                    </>
                                )}
                            </div>
                        )}
                    </ModalBody>
                </Modal>
            )}
            {upload.length > 0 && (
                <Modal isOpen={open2} centered size="xl" zIndex={999}>
                    <ModalHeader toggle={() => setOpen2(false)}>Cargar Actividades</ModalHeader>
                    <ModalBody>
                        {errorsEmails.length > 0 && (
                            <div className="alert alert-danger">
                                <table className="table table-sm">
                                    <thead>
                                        <tr>
                                            <th>Email</th>
                                            <th>Error</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {errorsEmails.map((e, i) => (
                                            <tr key={i}>
                                                <th>{e}</th>
                                                <th>Email no Encontrado.</th>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        <table width="100%" className="table table-sm">
                            <thead>
                                <tr>
                                    <th width="35%">Nombre</th>
                                    <th width="8%">Planificado</th>
                                    <th width="30%">Recursos</th>
                                    <th width="10%">Inicio</th>
                                    <th width="10%">Fin</th>
                                    <th width="7%">Dia</th>
                                </tr>
                            </thead>
                            <tbody>
                                {upload.map((e, i) => (
                                    <tr key={i}>
                                        <td>{e.title}</td>
                                        <td>{e.time_plan}</td>
                                        <td>
                                            {e.resources.map((r, ind) => (
                                                <React.Fragment key={ind}>
                                                    {r.email}
                                                    <br />
                                                </React.Fragment>
                                            ))}
                                        </td>
                                        <td>{moment(e.start).format("DD-MM-YYYY")}</td>
                                        <td>{e.end && moment(e.end).format("DD-MM-YYYY")}</td>
                                        <td>{e.allDay ? "Si" : "No"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div>
                            {
                                <Button
                                    label="Importar"
                                    icon="pi pi-save"
                                    iconPos="right"
                                    disabled={loading1}
                                    loading={loading1}
                                    onClick={importTasks}
                                />
                            }
                        </div>
                    </ModalBody>
                </Modal>
            )}
            {project && project.activities.length > 0 && (
                <Modal isOpen={open3} centered size="xl" zIndex={999}>
                    <ModalHeader toggle={() => setOpen3(false)}>Reporte de Actividades</ModalHeader>
                    <ModalBody>
                        <TableTasksReport project={project} renderStatus={renderStatus} />
                    </ModalBody>
                </Modal>
            )}
        </>
    );
};

export default ProjectTasksDetails;
