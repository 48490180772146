// react
import React, { useState, useRef, useEffect } from "react";
// third-party
import { Helmet } from "react-helmet-async";
import AsyncSelect from "react-select/async";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import { Button } from "reactstrap";
import Select from "react-select";

import jsPDF from "jspdf";
import "jspdf-autotable";
import { getProjectDeliveries, GetProductsSended, getWarehousesComapany } from "../../store/directusSdk";
import PageHeader from "../shared/PageHeader";

function ProductsSended() {
    const [loading, setLoading] = useState(false);
    const [projects, setProjects] = useState([]);
    const [products, setProducts] = useState([]);
    const [whs, setWhs] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [whsSelect, setWhsSelect] = useState(null);
    const sdt = useRef(null);

    useEffect(() => {
        getWarehousesComapany().then(setWarehouses);
    }, []);

    const promiseOptions = (inputValue) => getProjectDeliveries(inputValue);

    const getProducts = () => {
        setProducts([]);

        if (projects.length > 0) {
            setLoading(true);
            return GetProductsSended(
                projects.map((p) => p.id),
                whs.map((w) => w.id)
            )
                .then(setProducts)
                .catch((error) => console.log(error))
                .finally(() => setLoading(false));
        }
    };

    const exportCSV = () => sdt.current.exportCSV();

    const exportPdf = (bodega) => {
        const doc = new jsPDF({ format: "letter" });
        const totalPagesExp = "{total_pages_count_string}";
        const columns = [
            { header: "Código", dataKey: "code" },
            { header: "Producto", dataKey: "name" },
            { header: "Entregado", dataKey: "delivered" },
            { header: "Precio", dataKey: "price" },
            { header: "Total", dataKey: "total" },
        ];
        const items = products.filter((e) => e.whs === bodega);
        const total = items.reduce((total, e) => total + e.total, 0);

        /*   doc.autoTable({
              startY: 63,
              columns,
              body: items,
              rowPageBreak: 'auto',
              styles: { cellPadding: 1, fontSize: 10 },
              didDrawPage: function (data) {
                  // Header
                  doc.setFontSize(11);
                  doc.addImage("images/header.png", 'PNG', 0, 0, 216, 40);
                  doc.text('CÓDIGO: ' + project.code, 20, 46);
                  doc.text('BODEGA: ' + bodega, 150, 46);
                  doc.text('PROYECTO: ' + project.name, 20, 52);
  
                  doc.setFontSize(14);
                  doc.text('LISTA DE MATERIALES ENTREGADOS POR BODEGA', 45, 60);
  
                  // Footer
                  doc.addImage("images/line.png", 'PNG', 0, 262, 216, 4);
                  var str = 'Página ' + doc.internal.getNumberOfPages()
                  // Total page number plugin only available in jspdf v1.0+
                  if (typeof doc.putTotalPages === 'function') {
                      str = str + ' de ' + totalPagesExp
                  }
                  doc.setFontSize(10)
  
                  // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                  var pageSize = doc.internal.pageSize
                  var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
                  doc.text(str, data.settings.margin.left, pageHeight - 10)
              },
              margin: { top: 63 },
          });
          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === 'function') {
              doc.putTotalPages(totalPagesExp)
          }
          doc.setFontSize(10);
          doc.text('__________', 185, doc.lastAutoTable.finalY)
          doc.text('TOTAL', 167, doc.lastAutoTable.finalY + 5)
          doc.text('$ ' + total, 187, doc.lastAutoTable.finalY + 5)
          doc.save(`Materiales Entrgados ${bodega} -- ${moment().format('DD-MM-YYYY hh-mm a')}.pdf`); */
    };

    const onWhsChange = (e) => {
        setWhsSelect(e.value);
        return sdt.current.filter(e.value, "whs", "equals");
    };

    const whss = products.reduce((tempArray, e) => (tempArray.includes(e.whs) ? tempArray : [...tempArray, e.whs]), []);

    const whsFilter = (
        <Dropdown
            style={{ width: "100%" }}
            value={whsSelect}
            options={whss}
            onChange={onWhsChange}
            placeholder="Todos..."
            className="p-column-filter"
            showClear
        />
    );

    const breadcrumb = [
        { title: "Home", url: "/" },
        { title: "Almacén", url: "/almacen" },
        { title: "Productos Entregados por Proyectos", url: "" },
    ];

    const header = (
        <div className="row">
            <div className="col-md-3">
                <h5>{"Productos Entregados"}</h5>
            </div>
            <div className="col-md-9">
                <Button className="mr-2 float-right" color="info" disabled={!products.length > 0} onClick={exportCSV}>
                    <i className="fas fa-file-export mr-2" />
                    CSV
                </Button>
            </div>
        </div>
    );

    return (
        <React.Fragment>
            <Helmet>
                <title> Productos Entregados por Proyectos - Ingeniería y Controles Automatizados</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />
            <div className="container">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <AsyncSelect
                                isClearable
                                cacheOptions
                                loadOptions={promiseOptions}
                                noOptionsMessage={() => "No se Encontraron registros..."}
                                loadingMessage={() => "Buscando..."}
                                placeholder="PRY-XXXX..."
                                value={projects}
                                onChange={setProjects}
                                className="col-md-6"
                                isMulti
                            />
                            <Select
                                isClearable
                                options={warehouses}
                                noOptionsMessage={() => "No se Encontraron registros..."}
                                loadingMessage={() => "Buscando..."}
                                placeholder="Bodegas..."
                                value={whs}
                                onChange={setWhs}
                                className="col-md-4"
                                isMulti
                            />
                            <div className="col-md-2">
                                <Button
                                    color="primary"
                                    className={`${loading && "btn-loading"}`}
                                    disabled={!projects.length > 0 || !whs.length > 0 || loading}
                                    onClick={() => getProducts()}
                                >
                                    <i className="pi pi-search mr-2" />
                                    Buscar
                                </Button>
                            </div>
                        </div>

                        {loading && (
                            <div className="pt-5 pb-5 mt-5 mb-5">
                                <div className="div-loading" />
                            </div>
                        )}
                        {!loading && (
                            <DataTable
                                ref={sdt}
                                header={header}
                                value={products}
                                className="p-datatable-sm"
                                removableSort
                                rowHover
                                paginator
                                rows={20}
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                                rowsPerPageOptions={[10, 20, 50, 100, 200]}
                                emptyMessage="No se han encontrado registros..."
                                exportFilename={`Productos Entregados por proyectos`}
                                csvSeparator=";"
                            >
                                <Column
                                    field="code"
                                    header="Código"
                                    sortable
                                    filter
                                    filterPlaceholder="Todos"
                                    filterHeaderClassName="p-inputtext-sm"
                                    style={{ width: "16%" }}
                                />
                                <Column
                                    field="name"
                                    header="Producto"
                                    sortable
                                    filter
                                    filterPlaceholder="Todos"
                                    filterHeaderClassName="p-inputtext-sm"
                                    style={{ width: "35%" }}
                                />
                                <Column
                                    field="delivered"
                                    header="Entregado"
                                    sortable
                                    filter
                                    style={{ width: "13%", textAlign: "center" }}
                                    filterType="number"
                                    filterPlaceholder="Mayor a"
                                    filterMatchMode="gte"
                                    filterHeaderClassName="p-inputtext-sm"
                                />
                                <Column
                                    field="whs"
                                    header="Bodega"
                                    sortable
                                    filter
                                    style={{ width: "12%", textAlign: "center" }}
                                    filterElement={whsFilter}
                                />
                                <Column
                                    field="price"
                                    header="Precio"
                                    sortable
                                    filter
                                    style={{ width: "12%", textAlign: "right" }}
                                    filterType="number"
                                    filterMatchMode="gte"
                                    filterPlaceholder="Mayor a"
                                />
                                <Column
                                    field="total"
                                    header="Total"
                                    sortable
                                    filter
                                    style={{ width: "12%", textAlign: "right" }}
                                    filterType="number"
                                    filterMatchMode="gte"
                                    filterPlaceholder="Mayor a"
                                />
                            </DataTable>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ProductsSended;
