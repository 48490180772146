import React, { Component } from "react";
import { CSVReader } from "react-papaparse";
import { toast } from "react-toastify";

export default class ImportCsvCruce extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            header: [],
        };
    }

    handleOnDrop = (data) => {
        const { loadItems, setItems, mode } = this.props;
        const items = [];
        const errors = [];

        if (!data.length > 0) {
            loadItems([], setItems);
            return toast.error(`Error! 01, Revise CSV ${mode} e intente nuevamente...`);
        }

        if (mode === 'Contado') {
            if (!data[0].meta.fields.includes('Codigo') || !data[0].meta.fields.includes('conteo 2') || !data[0].meta.fields.includes('ubicaciones 2')) {
                loadItems([], setItems);
                return toast.error(`Error! 03, Revise CSV ${mode} e intente nuevamente...`);
            }

            data.forEach((e) => {
                items.push({ code: e.data['Codigo'], cant: e.data['conteo 2'] === '' ? 0 : e.data['conteo 2'], ubicacion: e.data['ubicaciones 2'] });
            });

            loadItems(items, setItems);
            return toast.info("Procesando productos...");
        }

        if (!data[0].meta.fields.includes('Código') || !data[0].meta.fields.includes('Entregado')) {

            loadItems([], setItems);
            return toast.error(`Error! 03, Revise CSV ${mode} e intente nuevamente...`);
        }

        data.forEach((e) => {
            items.push({ code: e.data['Código'].toString(), cant: parseInt(e.data['Entregado']) });
        });

        loadItems(items, setItems);
        return toast.info("Procesando productos...");
    };

    handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
    };

    handleOnRemoveFile = (data) => {
        return this.setState({ errors: [] });
    };

    render() {
        return (
            <div style={{ width: "100%", height: 200 }}>
                <CSVReader
                    onDrop={this.handleOnDrop}
                    onError={this.handleOnError}
                    addRemoveButton
                    removeButtonColor="#659cef"
                    onRemoveFile={this.handleOnRemoveFile}
                    config={{
                        delimiter: "",  // auto-detect
                        newline: "",  // auto-detect
                        quoteChar: '"',
                        escapeChar: '',
                        header: true,
                        dynamicTyping: false,
                        preview: 0,
                        encoding: "UTF-8",
                        worker: false,
                        skipEmptyLines: true,
                    }}
                >
                    <span>Arrastra y Suelta el archivo CSV aquí o haz clic para cargarlo.</span>
                </CSVReader>
            </div>
        );
    }
}
