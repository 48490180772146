// react
import React, { useEffect, useState } from 'react';

// third-party
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
// application
import CreatableSelect from 'react-select/creatable';
import { UpdateItem, CreateItem, getSuggestedContactsAP, getSuggestedCustomerAP } from '../../store/directusSdk';
import { ContactChange, ContactsChange, CustomerChangeAP } from '../../store/cartAP';
import { Calendar } from 'primereact/calendar';
import {  Label } from "reactstrap";



function SelectContact(props) {
    const { cart: { contact, contacts, customer }, ContactChange, ContactsChange,changeDateExpired, state, setError: setErr, CustomerChangeAP } = props;

    const [email, setEmail] = useState(contact ? contact.email : '');
    const [phone1, setPhone] = useState(contact ? contact.phone1 : '');
    const [error, setError] = useState('');
    const [sending, setSending] = useState(false);
    const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    useEffect(() => {
        document.cookie = "customerid=" + (customer ? customer.code : '0') + ";domain=.icautomatizados.com;path=/;max-age=60*60*24*7";
        if (!customer) {
            setEmail('');
            setPhone('');
        }
        if (contact) {
            setEmail(contact.email);
            setPhone(contact.phone1);
        }
        if (contact && !contact.email) {
            setErr('Campo requerido!');
            setError('Campo requerido!')
        } else {
            setErr(null);
            setError(null)
        }
        if (contact && !emailRegex.test(contact.email)) {
            setErr('Email invalido!');
            return setError('Email invalido!')
        }
    }, [customer, contact]);

    const changeEmail = (e) => {
        setEmail(e.target.value);
        if (emailRegex.test(e.target.value)) {
            setErr(null);
            return setError(null);
        }
    };
    const changePhone = (e) => setPhone(e.target.value);

    const promiseOptions = (inputValue) => getSuggestedCustomerAP(inputValue);

    const handleChange = (newValue, actionMeta) => {
        if (actionMeta.action !== 'create-option') {
            setEmail(newValue.email);
            setPhone(newValue.phone1);
            return ContactChange(newValue);
        }
        setEmail('');
        setPhone('');
        return ContactChange({ label: newValue.label, value: null, name: newValue.label, isNew: newValue.__isNew__ });
    };

    const ChangeCustomer = (customer) => {
        if (customer) {
            CustomerChangeAP(customer, customer.code === 'C-000403' ? 1.40 : 1.75);
            getSuggestedContactsAP(customer.value).then((elements) => {
                ContactChange(elements[0]);
                ContactsChange(elements);
            });
        } else {
            CustomerChangeAP(customer, 1.75);
            ContactChange(null);
            ContactsChange([]);
        }
    }


    const save = () => {
        if (!email) {
            setErr('Campo requerido!');
            return setError('Campo requerido!')
        }
        if (!emailRegex.test(email)) {
            setErr('Email invalido!');
            return setError('Email invalido!')
        }
        setSending(true);
        const body = {};
        if (contact.isNew) {
            body.name = contact.label;
            body.email = email;
            body.phone1 = phone1;
            body.customer_id = customer.value;           
            //crear contact
            return CreateItem('contacts_ap', body).then(({ data }) => {
                getSuggestedContactsAP(customer.value).then((elements) => {
                    ContactChange({ label: data.name, value: data.id, name: data.name, sphone1: data.phone1, email: data.email, sapid: data.sapid });
                    ContactsChange(elements);
                    setSending(false);
                });
            });
        }
        //editar contact
        if (contact.email !== email) {
            body.email = email;
        }
        if (contact.phone1 !== phone1) {
            body.phone1 = phone1;
        }
        return UpdateItem('contacts_ap', contact.value, body, { fields: 'name,id,phone1,email,sapid' }).then(({ data }) => {
            getSuggestedContactsAP(customer.value).then((elements) => {
                ContactChange({ label: data.name, value: data.id, name: data.name, phone1: data.phone1, email: data.email, sapid: data.sapid });
                ContactsChange(elements);
                setSending(false);
            });
        });
    };

    return (
        <div className="container">
            <div className="row mt-4 mb-3">
                <div className="col-md-5 col-xs-12 mb-3">
                    <AsyncSelect
                        isClearable
                        cacheOptions
                        loadOptions={promiseOptions}
                        defaultOptions
                        noOptionsMessage={() => 'No se Encontró ningun cliente.'}
                        loadingMessage={() => 'Buscando...'}
                        placeholder="Ingrese un cliente..."
                        onChange={ChangeCustomer}
                        value={customer}
                    />
                </div>          
                <div className="col-md-5 col-xs-12 mb-3">
                    <CreatableSelect
                        isDisabled={!customer}
                        isSearchable
                        onChange={handleChange}
                        options={contacts}
                        value={contact}
                        placeholder="Seleccione Contacto..."
                    />
                </div>
                <div className="col-md-5 col-xs-12 mb-3">
                    <input className={`form-control ${error && 'is-invalid'}`} value={email} type="text" placeholder="Correo Electrónico..." onChange={changeEmail} disabled={!customer} />
                    {error && <div className="invalid-feedback">{error}</div>}
                </div>
                <div className="col-md-5 col-xs-12 mb-3">
                    <input className="form-control" type="text" value={phone1} placeholder="Telefono..." onChange={changePhone} disabled={!customer} />
                </div>
                <div className="col-md-5  flex-row mb-3 ">
                <Label for="confdate" className="mr-5">Fecha de Vencimiento:</Label>
                <Calendar id="vencimiento" value={state.expirationDate} onChange={(e) => changeDateExpired(e.value)}  minDate={moment().toDate()} maxDate={moment().add(90, 'days').toDate()} dateFormat="dd/mm/yy" placeholder='DD/MM/AAAA' disabled={!customer} />
                </div>
                <div className="col-md-5 col-xs-12 mb-3 ">
                    {contact && ((customer && (email !== contact.email || phone1 !== contact.phone1)) || contact.isNew) && <button type="button" className={`btn btn-info ${sending && 'btn-loading'}`} onClick={save} disabled={sending}>{contact && contact.isNew ? 'Crear' : 'Editar'}</button>}
                </div>
             
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    cart: state.cartAP,
});

const mapDispatchToProps = {
    ContactChange,
    ContactsChange,
    CustomerChangeAP,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SelectContact);
