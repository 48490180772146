// react
import React from 'react';
// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { CardDeck } from 'reactstrap';

export default function Menu({ menu, title }) {
    const menus = menu.map((e, i) => (
        <div className="col-md-4 col-lg-3 mt-3" key={i}>
            <Link to={e.url}>
                <div className="zoom text-center">
                    <img src={e.img} alt={e.label} className="imgRedonda" />
                    <h6>{e.label}</h6>
                </div>

            </Link>
        </div>
    ));

    return (
        <React.Fragment>
            <Helmet>
                <title>Ingeniería y Controles Automatizados</title>
            </Helmet>
            <div className="container mt-5">
                <h3 align="center">{title.toUpperCase()}</h3>
                <CardDeck>
                    {menus}
                </CardDeck>
            </div>
        </React.Fragment>
    );
}
