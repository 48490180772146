// react
import React from 'react';
import { connect } from 'react-redux';

// third-party
import { Helmet } from 'react-helmet-async';
import Menu from '../shared/Menu';

function MenuPrincipal({ menu }) {

    return (
        <React.Fragment>
            <Helmet>
                <title>Ingeniería y Controles Automatizados</title>
            </Helmet>

            <Menu menu={menu} title="Menu" />
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    menu: state.currentUser.menu
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuPrincipal);
