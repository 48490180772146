import fs from "file-saver";
import { Button } from "reactstrap";
import React, { useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { GenerateExcelInventorySupplierById } from "../../components/shared/utilities";

export default function ListInventoriCsvSurplus({ products, type }) {
    const [selectedProducts, setSelectedProducts] = useState([]);

    const exportExcel = () => {
        GenerateExcelInventorySupplierById({ products })
            .then((response) => {
                const blob = new Blob([response]);
                fs.saveAs(blob, `busqueda-proveedores.xlsx`);
            })
            .catch((error) => {});
    };

    const header = (
        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
            <h4>Productos</h4>
            <Button color="info" onClick={() => exportExcel()}>
                <i className="fas fa-file-export mr-2" />
                Exportar
            </Button>
        </div>
    );

    return (
        <>
            <DataTable
                value={products}
                size="small"
                dataKey="id"
                header={header}
                paginator
                rows={10}
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                rowsPerPageOptions={[10, 20, 50, 100]}
                removableSort
                emptyMessage="No se han encontrado registros..."
                onSelectionChange={(e) => setSelectedProducts(e.value.filter((s) => s.qty > 0))}
                selection={selectedProducts}
                className="p-datatable-new-dispatches"
                selectionMode={type === "SL" ? "multiple" : ""}
            >
                {type === "SL" && <Column selectionMode="multiple" headerStyle={{ width: "3%" }} />}

                <Column
                    field="code"
                    header="Código"
                    sortable
                    filter
                    style={{ width: "8%" }}
                    filterPlaceholder="Todos"
                    filterHeaderClassName="p-inputtext-sm"
                />
                <Column
                    field="code_manufacture"
                    header="SKU"
                    sortable
                    filter
                    style={{ width: "8%" }}
                    filterPlaceholder="Todos"
                    filterHeaderClassName="p-inputtext-sm"
                />
                <Column
                    field="name"
                    header="Descripción"
                    sortable
                    filter
                    style={{ width: "20%" }}
                    filterMatchMode="contains"
                    filterPlaceholder="Todos"
                    filterHeaderClassName="p-inputtext-sm"
                />
                <Column
                    field="qty"
                    header="Cantidad"
                    sortable
                    filter
                    style={{ width: "12%" }}
                    filterType="number"
                    filterPlaceholder="Todos"
                    filterHeaderClassName="p-inputtext-sm"
                />
                <Column
                    field="ubication"
                    header="Ubicación"
                    sortable
                    filter
                    style={{ width: "12%" }}
                    filterType="number"
                    filterPlaceholder="Todos"
                    filterHeaderClassName="p-inputtext-sm"
                />
            </DataTable>
        </>
    );
}
