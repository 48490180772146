// react
import React, { Component } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import moment from "moment";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { InputNumber } from "primereact/inputnumber";
import Switch from "react-switch";

// application
import { CreatePrivQuotation, getPrivProduct } from "../../store/directusSdk";
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import PageHeader from "../shared/PageHeader";
import {
    cartRemoveItem,
    ChangePriceItem,
    ChangeQuantity,
    cartUpdateQuantities,
    changeComments,
    cartAddItemServ,
    cartRemoveAllItems,
    TextItemsChange,
    ChangeExemptItbms,
    includeItbms,
    recalculateItemPrice,
} from "../../store/cart";
import { Cross12Svg } from "../../svg";
import SelectContact from "./SelectContact";
import SelectPriceList from "./SelectPriceList";
import { round } from "../../store/cart/cartReducer";
import { Toast } from "reactstrap";

class ShopPageCart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quantities: [],
            sending: false,
            serv: null,
            cant: 1,
            error: "",
            expirationDate: moment().add(7, "days").toDate(),
            incItbms: true,
            error: false,
        };
    }

    componentDidMount() {
        const {
            cart: { items },
        } = this.props;
        items.forEach((item) => delete item.error);
        setTimeout(() => document.querySelector("#cart").click(), 500);
    }

    calculateMin(item, e) {
        const min = (item.product.price / 1.28).toFixed(2);
        if (e) this.validate(e, item, min);
        return min;
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.cart.customer !== prevProps.cart.customer) {
            const incItbms = this.props.cart.customer ? !this.props.cart.customer.exempt_itbms : true;
            return this.setState({ incItbms });
        }
    }

    validate(e, item, value) {
          if (e < value + " $") {
            item.error = true;
        } else {
            item.error = false;
        }
    }

    setError = (error) => {
        this.setState({ error });
    };

    HandleName = (e) => {
        const { serv } = this.state;
        serv.product.name = e.target.value;
        return this.setState({ serv });
    };

    HandleComments = (e) => {
        const { changeComments } = this.props;
        return changeComments(e.target.value);
    };

    changePrice = (value) => {
        const { serv } = this.state;

        serv.price = value;
        serv.product.price = value;
        this.setState({ serv });
    };

    promiseOptions = (inputValue) => new Promise((resolve) => resolve(getPrivProduct(inputValue)));

    GeneratePDF() {
        const { cart, cartRemoveAllItems } = this.props;
        const { expirationDate } = this.state;

        if (!expirationDate) {
            return toast.error("Error, Introduzca la fecha de vencimiento");
        }

        this.setState({ sending: true });

        const quotation = {
            subtotal: cart.subtotal,
            comments: cart.comments,
            itbms: cart.itbms,
            total: cart.total,
            validity: moment(expirationDate).format("YYYY-MM-DD"),
            customer_id: cart.customer ? cart.customer.value : null,
            contact_id: cart.contact ? cart.contact.value : null,
            exempt_itbms: cart.exempt_itbms,
            cart: JSON.stringify(cart),
        };

        return CreatePrivQuotation(quotation)
            .then(() => {
                toast.success("Cotización creada con exito!");
                this.setState({ sending: false });
                cartRemoveAllItems();
            })
            .catch((error) => toast.error(error.message))
            .finally(() => this.setState({ sending: false }));
    }

    changeDateExpired = (expirationDate) => this.setState({ expirationDate });

    renderItems() {
        const {
            cartRemoveItem,
            cart: { items, exempt_itbms },
            TextItemsChange,
            multiplications,
            ChangePriceItem,
            ChangeQuantity,
            includeItbms,
        } = this.props;

        return items.map((item, i) => {
            const removeButton = (
                <AsyncAction
                    action={() => cartRemoveItem(item.id)}
                    render={({ run, loading }) => {
                        const classes = classNames("btn btn-light btn-sm btn-svg-icon", {
                            "btn-loading": loading,
                        });

                        return (
                            <>
                                <button type="button" onClick={run} className={classes}>
                                    <Cross12Svg />
                                </button>
                            </>
                        );
                    }}
                />
            );

            return (
                <React.Fragment key={item.id}>
                    <tr className="cart-table__row">
                        <td className="cart-table__column cart-table__column--product" style={{ width: "30%" }}>
                            {!item.product.isService ? (
                                <Link
                                    to={`/ventas/product/${item.product.code.replace(/\//g, "-")}/${item.product.id}`}
                                    className="cart-table__product-name"
                                >
                                    {item.product.name}
                                    <br />
                                    <span style={{ fontSize: "0.8em" }}>{`Código de Producto: ${item.product.code}`}</span>
                                </Link>
                            ) : (
                                <React.Fragment>
                                    {item.product.name}
                                    <br />
                                    <span style={{ fontSize: "0.8em" }}>{`Código de Producto: ${item.product.code}`}</span>
                                </React.Fragment>
                            )}

                            <br />
                            {item.product.bod01 < 1 && item.product.bod05 < 1 ? (
                                <>
                                    <span className="text-success" style={{ fontSize: "0.8em", width: "100%" }}>{`Para entrega de ${
                                        item.product.days
                                    } a ${item.product.days + 5} días hábiles.`}</span>
                                    <br></br>
                                    {item.product &&
                                        item.product.existence &&
                                        item.product.existence.length > 0 &&
                                        item.product.existence.map((ele) => (
                                            <>
                                                <span
                                                    className="text-success"
                                                    style={{ fontSize: "0.8em", width: "100%" }}
                                                >{` ${ele.inv.toUpperCase()} : disponible en ${ele.time} Días, ${ele.qty} un.`}</span>{" "}
                                                <br></br>
                                            </>
                                        ))}
                                </>
                            ) : (
                                <p>
                                    {item.product.bod01 > 0 ? (
                                        <>
                                            <span
                                                className="text-success"
                                                style={{ fontSize: "0.8em" }}
                                            >{`Disponibilidad Inmediata: ${item.product.bod01} un.`}</span>
                                            <br></br>
                                        </>
                                    ) : null}
                                    {item.product.bod05 > 0 ? (
                                        <>
                                            <span className="text-success" style={{ fontSize: "0.8em" }}>
                                                {item.product.bod01 > 0 ? <br /> : null}
                                                {`Disponible en 5 días hábiles: ${item.product.bod05} un.`}
                                            </span>
                                            <br></br>
                                        </>
                                    ) : null}
                                    {item.product &&
                                        item.product.existence &&
                                        item.product.existence.length > 0 &&
                                        item.product.existence.map((ele) => (
                                            <>
                                                <span
                                                    className="text-success"
                                                    style={{ fontSize: "0.8em", width: "100%" }}
                                                >{`${ele.inv.toUpperCase()} : disponible en ${ele.time} Días, ${ele.qty} un.`}</span>
                                                <br></br>
                                            </>
                                        ))}
                                </p>
                            )}
                        </td>
                        <td className="cart-table__column cart-table__column--price" data-title="Precio" style={{ width: "25%" }}>
                            <div
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <InputNumber
                                    style={{ width: "60%", marginTop: item.error ? "30px" : 0 }}
                                    inputId="currency-us"
                                    value={item.price}
                                    min={this.calculateMin(item)}
                                    maxFracionDigits={2}
                                    minFractionDigits={2}
                                    onValueChange={({ value }) => {
                                        if (!item.error) ChangePriceItem(i, value);
                                        this.calculateMin(item, value);
                                    }}
                                    suffix=" $"
                                />
                                {item.error && (
                                    <span className="text-danger mt-2" style={{ fontSize: "0.8em" }}>{`Precio mínimo: ${this.calculateMin(
                                        item
                                    )} $.`}</span>
                                )}
                            </div>
                        </td>
                        <td className="cart-table__column cart-table__column--price" style={{ width: "5%" }} data-title="Cantidad">
                            <InputNumber onChange={({ value }) => ChangeQuantity(i, value)} value={item.quantity} min={1} showButtons />
                        </td>
                        <td className="cart-table__column cart-table__column--price" style={{ width: "5%" }} data-title="ITBMS">
                            <Switch
                                disabled={exempt_itbms}
                                height={23}
                                width={45}
                                onChange={(checked) => includeItbms(i, checked)}
                                checked={item.incItbms}
                            />
                        </td>
                        <td className="cart-table__column cart-table__column--list" style={{ width: "25%" }} data-title="Lista">
                            {item.product.isService ? (
                                item.priceList.label
                            ) : (
                                <SelectPriceList multiplications={multiplications} i={i} multi={item.priceList} />
                            )}
                        </td>
                        <td className="cart-table__column cart-table__column--total" style={{ width: "5%" }} data-title="Total">
                            <Currency value={item.total} />
                        </td>
                        <td className="cart-table__column cart-table__column--remove" style={{ width: "5%" }}>
                            {removeButton}
                        </td>
                    </tr>
                    <tr className="cart-table__row" style={{ width: "10%" }}>
                        <td colSpan="7" className="cart-table__column">
                            <textarea
                                className="form-control"
                                rows="1"
                                placeholder="Comentarios..."
                                value={items[i].comments}
                                onChange={(e) => TextItemsChange(i, e.target.value)}
                            />
                        </td>
                    </tr>
                </React.Fragment>
            );
        });
    }

    renderTotals() {
        const { cart } = this.props;

        return (
            <React.Fragment>
                <thead className="cart__totals-header">
                    <tr>
                        <th>Subtotal</th>
                        <td>
                            <Currency value={cart.subtotal} />
                        </td>
                    </tr>
                </thead>
                <tbody className="cart__totals-body">
                    <tr>
                        <th>ITBMS</th>
                        <td>
                            {cart.exempt_itbms && <span className="text-success">¡Exonerado!</span>}
                            <Currency value={cart.itbms} />
                        </td>
                    </tr>
                </tbody>
            </React.Fragment>
        );
    }

    renderCart() {
        const {
            cartAddItemServ,
            cart,
            cart: { customer, exempt_itbms },
            multiplications,
            currentUser,
        } = this.props;
        const { sending, serv, cant, error, incItbms } = this.state;

        return (
            <div className="cart block" id="cont">
                <div className="container">
                    <table className="cart__table cart-table">
                        <thead className="cart-table__head">
                            <tr className="cart-table__row">
                                <th className="cart-table__column cart-table__column--product">Producto</th>
                                <th className="cart-table__column cart-table__column--price">
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "5px" }}>Precio</div>
                                </th>
                                <th className="cart-table__column cart-table__column--price">
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "5px" }}>Cantidad</div>
                                </th>
                                <th className="cart-table__column cart-table__column--price">
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "5px" }}>ITBMS</div>
                                </th>
                                <th className="cart-table__column cart-table__column--list">Lista</th>
                                <th className="cart-table__column cart-table__column--total">
                                    {" "}
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "5px" }}>Total</div>
                                </th>
                                <th className="cart-table__column cart-table__column--remove" aria-label="Remove"></th>
                            </tr>
                        </thead>
                        <tbody className="cart-table__body">
                            {this.renderItems()}
                            <tr className="cart-table__row">
                                <td className="cart-table__column cart-table__column--product">
                                    <AsyncSelect
                                        cacheOptions
                                        loadOptions={this.promiseOptions}
                                        defaultOptions
                                        isClearable
                                        noOptionsMessage={() => "No se Encontró ningun producto."}
                                        loadingMessage={() => "Buscando..."}
                                        placeholder="Buscar servicio..."
                                        onChange={(e) => this.setState({ serv: e })}
                                        value={serv}
                                        className="mb-2"
                                    />
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={serv ? serv.product.name : ""}
                                        disabled={!serv}
                                        onChange={this.HandleName}
                                    />
                                </td>
                                <td className="cart-table__column cart-table__column--price" data-title="Precio">
                                    {serv ? (
                                        <InputNumber
                                            value={serv.price}
                                            maxFracionDigits={2}
                                            min={multiplications.find((e) => e.label === "Sucursales Regionales")}
                                            onValueChange={({ value }) => this.changePrice(value)}
                                            mode="currency"
                                            minFractionDigits={2}
                                            currency="USD"
                                            locale="en-US"
                                        />
                                    ) : (
                                        <Currency value={0} />
                                    )}
                                </td>
                                <td className="cart-table__column cart-table__column--price" data-title="Cantidad">
                                    <InputNumber onChange={({ value }) => this.setState({ cant: value })} value={cant} min={1} showButtons />
                                </td>
                                <td className="cart-table__column cart-table__column--price" data-title="ITBMS">
                                    <Switch
                                        disabled={exempt_itbms}
                                        height={23}
                                        width={42}
                                        onChange={(checked) => this.setState({ incItbms: checked })}
                                        checked={incItbms}
                                    />
                                </td>
                                <td className="cart-table__column cart-table__column--list" data-title="Lista">
                                    <SelectPriceList
                                        multiplications={multiplications}
                                        i={-1}
                                        className="cart-table__column--list"
                                        multi={
                                            customer
                                                ? multiplications.find((e) => e.value == customer.multi)
                                                : multiplications.find((e) => e.id == currentUser.multi)
                                        }
                                    />
                                </td>
                                <td className="cart-table__column cart-table__column--total" data-title="Total">
                                    <Currency value={serv ? cant * serv.price : 0.0} />
                                </td>
                                <td className="cart-table__column ml-4">
                                    <AsyncAction
                                        action={() =>
                                            cartAddItemServ(
                                                serv.product,
                                                customer
                                                    ? multiplications.find((e) => e.value == customer.multi)
                                                    : multiplications.find((e) => e.id == currentUser.multi),
                                                cant,
                                                incItbms
                                            ).then(() => this.setState({ serv: null, cant: 1 }))
                                        }
                                        render={({ run, loading }) => (
                                            <button
                                                type="button"
                                                onClick={run}
                                                disabled={!serv || !cant || !serv.price}
                                                className={classNames("btn btn-primary btn-lg", {
                                                    "btn-loading": loading,
                                                })}
                                            >
                                                <i className="fa fa-cart-plus" aria-hidden="true" />
                                            </button>
                                        )}
                                    />
                                </td>
                            </tr>
                            <tr className="cart-table__row">
                                <td colSpan="7" className="cart-table__column cart-table__column--product">
                                    <textarea
                                        className="form-control"
                                        maxLength={254}
                                        onChange={this.HandleComments}
                                        name="comentarios"
                                        rows="3"
                                        placeholder="Observaciones..."
                                        value={cart.comments}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="cart__actions">
                        <form className="cart__coupon-form" />
                        <div className="cart__buttons">
                            <button
                                type="button"
                                className={`btn btn-info ${sending && "btn-loading"}`}
                                onClick={() => this.GeneratePDF()}
                                disabled={sending || !cart.customer || error || (cart.contact && cart.contact.__isNew__) || cart.items.length < 1}
                            >
                                Generar Cotización
                            </button>
                        </div>
                    </div>

                    <div className="row justify-content-end pt-md-5 pt-4">
                        <div className="col-12 col-md-4 col-lg-6 col-xl-7" />
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title">Total Carrito</h3>
                                    <table className="cart__totals">
                                        {this.renderTotals()}
                                        <tfoot className="cart__totals-footer">
                                            <tr>
                                                <th>Total</th>
                                                <td>
                                                    <Currency value={cart.total} />
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {
            cart: { customer, exempt_itbms },
            ChangeExemptItbms,
        } = this.props;
        const breadcrumb = [
            { title: "Home", url: "/" },
            { title: "Carrito de Compras", url: "" },
        ];
        return (
            <React.Fragment>
                <Helmet>
                    <title>Carrito de Compras -- ICAutomatizados S.A</title>
                </Helmet>

                <PageHeader header="Carrito de Compras" breadcrumb={breadcrumb} />
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h5>Cliente: {customer && customer.name} </h5>
                        </div>
                        <div className="offset-md-3 col-md-3">
                            <h5>
                                Exento de ITBMS:
                                <Switch className="ml-3" height={23} width={45} id="exempt" onChange={ChangeExemptItbms} checked={exempt_itbms} />
                            </h5>
                        </div>
                    </div>
                </div>
                <SelectContact setError={this.setError} changeDateExpired={this.changeDateExpired} state={this.state} />
                {this.renderCart()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    currentUser: state.currentUser,
    multiplications: state.pagesContent.multiplications,
});

const mapDispatchToProps = {
    recalculateItemPrice,
    cartRemoveItem,
    cartUpdateQuantities,
    cartAddItemServ,
    changeComments,
    cartRemoveAllItems,
    TextItemsChange,
    ChangePriceItem,
    ChangeQuantity,
    ChangeExemptItbms,
    includeItbms,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCart);
