// react
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { InputNumber } from 'primereact/inputnumber';
import { createMovement, DeliveryInvetoryParticular } from '../../store/directusSdk';

export default function NewDelivery({ currentUser, orderId, table, products, AddMovement, projectId, max, row }) {
    const [cant, setcant] = useState(0);
    const [boton, setboton] = useState(false);
    const [loading, setloading] = useState(false);
    const onChange = ({ value }) => {
        setcant(value);
        if (value > 0) {
            setboton(true);
        } else {
            setboton(false);
        }
    };
    const Delivery = () => {
        setloading(true);
        const movement = { type: 'delivery', engprojectline_id: orderId, delivered_qty: cant, delivered_date: moment().format('YYYY-MM-DD HH:mm:ss'), delivered_by: currentUser.id };
        const i = products.findIndex((p) => p.id === orderId);

        if (row.warehouse_id.code === '-1') {
            return DeliveryInvetoryParticular(table, movement, projectId, row.code)
                .then(() => {
                    movement.delivered_by = { first_name: currentUser.first_name, last_name: currentUser.last_name };
                    AddMovement(table, i, movement);
                    setboton(false);
                    setcant(0);
                    toast.success('¡Entrega creada con exito!')
                }).catch((error) => {
                    if (error.code === '203') {
                        return toast.error('Error, Producto no Encontrado en Inventario Particular.');
                    }
                    return toast.error(`Error, ${error.message}`);
                }).finally(() => {
                    setloading(false);
                });
        }

        return createMovement(table === 'ICA' ? 'engprojectlinesmovementsica' : 'engprojectlinesmovementsap', movement)
            .then(() => {
                movement.delivered_by = { first_name: currentUser.first_name, last_name: currentUser.last_name };
                AddMovement(table, i, movement);
                setloading(false);
                setboton(false);
                setcant(0);
                toast.success('¡Entrega creada con exito!')
            }).catch((error) => {
                setloading(false);
                toast.error(`Error, ${error.message}`);
            });
    }

    return (
        <div className="row">
            <InputNumber disabled={loading} value={cant} min={1} max={max} onValueChange={onChange} showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-info" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" size={1} />
            {boton && <button type="button" className="btn btn-primary ml-3" onClick={Delivery} disabled={loading}><i className={`${loading ? 'fas fa-cog fa-spin' : 'fas fa-truck'} mr-2`} />Enviar</button>}
        </div>
    );
}