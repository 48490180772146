// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

class AccountPageDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { currentUser, history } = this.props;
        if (!currentUser) {
            history.replace('/account/login');
            return null;
        }

        return (
            <div className="dashboard">
                <Helmet>
                    <title>Dashboard — ICAutomatizados</title>
                </Helmet>

                <div className="dashboard__profile card profile-card">
                    <div className="card-body profile-card__body">
                        <div className="profile-card__avatar">
                            <img src="images/avatars/avatar-3.jpg" alt="" />
                        </div>
                        <div className="profile-card__name">{`${currentUser.first_name} ${currentUser.last_name}`}</div>
                        <div className="profile-card__email">{currentUser.email}</div>
                        <div className="profile-card__edit">
                            <Link to="profile" className="btn btn-secondary btn-sm">Editar Perfil</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default AccountPageDashboard;
