// react
import React, { useRef } from 'react';
// third-party
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'reactstrap';

export default function ProductsInventory({ products }) {
    const sdt = useRef(null);

    const exportCSV = () => sdt.current.exportCSV();

    const header = (
        <div className="row">
            <div className="col-md-6">
                <h5 className="mt-2">{'Mi Inventario'}</h5>
            </div>
            <div className="col-md-2 offset-md-4">
                <Button color="info" onClick={exportCSV} disabled={!products.length > 0}><i className="fas fa-file-export mr-2" />Exportar</Button>
            </div>
        </div>
    );
    return (
        <div className="datatable-rowexpansion-demo">
            <DataTable
                ref={sdt}
                value={products}
                header={header}
                paginator={products.length > 0}
                rows={10}
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                rowsPerPageOptions={[10, 20, 50, 100]}
                removableSort
                emptyMessage="No se han encontrado registros..."
                className="p-datatable-customers"
                rowHover
                exportFilename={`Mi-Inventario`}
            >
                <Column field="code" header="Código" sortable filter style={{ width: '15%' }} filterPlaceholder="Todos" filterHeaderClassName="p-inputtext-sm" />
                <Column field="sku" header="SKU" sortable filter style={{ width: '15%' }} filterPlaceholder="Todos" filterHeaderClassName="p-inputtext-sm" />
                <Column field="name" header="Producto" sortable filter style={{ width: '46%' }} filterPlaceholder="Todos" filterHeaderClassName="p-inputtext-sm" />
                <Column field="stock" header="Stock" sortable filter style={{ width: '12%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                <Column field="commited" header="Usado" sortable filter style={{ width: '12%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
            </DataTable>
        </div>
    );
}
