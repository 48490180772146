/*eslint linebreak-style: ["error", "windows"]*/
// react
import React, { Component } from "react";

// third-party
import PropTypes from "prop-types";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { IntlProvider } from "react-intl";
import { ScrollContext } from "react-router-scroll-4";

// application
import languages from "../i18n";
import { localeChange } from "../store/locale";
import { pagesContentChange } from "../store/pagesContent";

// pages
import Layout from "./Layout";
import { getContent } from "../store/directusSdk";
import AccountPageLogin from "./account/AccountPageLogin";

class Root extends Component {
    componentDidMount() {
        const { pagesContentChange } = this.props;
        const preloader = document.querySelector(".site-preloader");

        if (window.location.pathname === "/login" || window.location.pathname === "/") {
            getContent().then((contentPage) => {
                pagesContentChange(contentPage);
                preloader.addEventListener("transitionend", (event) => {
                    if (event.propertyName === "opacity") {
                        preloader.parentNode.removeChild(preloader);
                    }
                });
                preloader.classList.add("site-preloader__fade");
            });
        } else {
            preloader.addEventListener("transitionend", (event) => {
                if (event.propertyName === "opacity") {
                    preloader.parentNode.removeChild(preloader);
                }
            });
            preloader.classList.add("site-preloader__fade");
        }
    }

    render() {
        const { locale, currentUser } = this.props;
        const { messages, direction } = languages[locale];

        return (
            <IntlProvider locale={locale} messages={messages}>
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <HelmetProvider>
                        <Helmet htmlAttributes={{ lang: locale, dir: direction }} />
                        <ScrollContext>
                            <Switch>
                                <Route exact path="/login" render={(props) => <AccountPageLogin {...props} currentUser={currentUser} />} />

                                <Route path="/presupuesto-materiales" render={(props) => <Layout {...props} headerLayout="compact" />} />

                                <Route path="/ventas/cotizacion-ica" render={(props) => <Layout {...props} headerLayout="default" />} />

                                <Route path="/ventas/productos-ica" render={(props) => <Layout {...props} headerLayout="default" />} />
                                <Route path="/ventas/categoria/:category/:id" render={(props) => <Layout {...props} headerLayout="default" />} />
                                <Route path="/ventas/product/:code/:id" render={(props) => <Layout {...props} headerLayout="default" />} />
                                <Route path="/ventas/cart"  render={(props) => <Layout {...props} headerLayout="default" />} />


                                
                                <Route path="/compras" render={(props) => <Layout {...props} headerLayout="compact" />} />
                                <Route path="/logistica" render={(props) => <Layout {...props} headerLayout="compact" />} />
                                <Route path="/almacen" render={(props) => <Layout {...props} headerLayout="compact" />} />
                                <Route path="/proyectos-de-ingeneria" render={(props) => <Layout {...props} headerLayout="compact" />} />
                                <Route path="/cotizacion-ap" render={(props) => <Layout {...props} headerLayout="compact" />} />
                                <Route path="/tasks-manager" render={(props) => <Layout {...props} headerLayout="compact" />} />
                                <Route path="/mis-proyectos" render={(props) => <Layout {...props} headerLayout="compact" />} />
                                <Route path="/mi-inventario" render={(props) => <Layout {...props} headerLayout="compact" />} />
                                <Route path="/" render={(props) => <Layout {...props} headerLayout="compact" />} />

                                <Redirect to="/" />
                            </Switch>
                        </ScrollContext>
                    </HelmetProvider>
                </BrowserRouter>
            </IntlProvider>
        );
    }
}

Root.propTypes = {
    /** current locale */
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    currentUser: state.currentUser,
});

const mapDispatchToProps = {
    localeChange,
    pagesContentChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
