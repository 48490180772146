// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';

// application
import AccountFormLogin from './AccountFormLogin';


function AccountPageLogin(props) {
    const { currentUser, history, location } = props;
    if (currentUser) {
        return <Redirect to="/" />;
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Login - WebConnect</title>
            </Helmet>
            <div className="fondo_login">
                <div style={{ marginTop: 30, paddingTop: 30 }}>
                    <div className="block">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 offset-md-3">
                                    <div className="card">
                                        <div className="card-body">
                                            <AccountFormLogin history={history} location={location} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AccountPageLogin;
