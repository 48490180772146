import React from 'react';
import ReactDOM from 'react-dom';
import { PDFViewer, Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    page: {
        fontSize: 9,
        marginBottom: 20,
    },

    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'baseline',
        width: '100%',
        padding: 10,
    },

    section: {
        flexGrow: 1,
    },

    subtitule: {
        margin: 1,
        padding: 1,
        width: '17%',
        fontWeight: 'bold',
        fontFamily: 'Helvetica-Bold',
    },

    value: {
        margin: 1,
        padding: 1,
        width: '15%',
        textAlign: 'right',
        marginRight: 30,
    },

    thead: {
        flexDirection: 'row',
        border: 1,
        backgroundColor: '#A9D0F5',
        margin: '10 20 0 20',
        textAlign: 'center'

    },

    tbody: {
        flexDirection: 'row',
        textAlign: 'center',
        margin: '10 20 0 20',
    },

    th: {
        margin: '3, 2',
        padding: '3, 2',
        textAlign: 'center',
        fontFamily: 'Helvetica-Bold'
    },

    td: {
        margin: '0, 2',
        padding: '0, 2',
        textAlign: 'center'
    },


});
const project = sessionStorage.getItem('prod-entreg') ? JSON.parse(sessionStorage.getItem('prod-entreg')) : null;

// Create Document Component
const MyDocument = () => (
    <Document title={project && project.project.name}>
        <Page size="LETTER" style={styles.page}>
            <View style={{ ...styles.row, margin: 0, padding: 0 }} fixed>
                <Image src="images/header.png" style={{ width: '100%' }} />
            </View>
            <View style={{ ...styles.row, margin: '2 0', padding: '0 0', width: '100%', fontSize: 11, textAlign: 'center' }} fixed>
                <Text style={{ width: '100%' }}>{project && project.project.label}</Text>
            </View>
            <View style={{ ...styles.row, margin: '2 0', padding: '0 0', width: '100%', fontSize: 11, textAlign: 'center' }} fixed>
                <Text style={{ width: '100%' }}>{project && project.project.name}</Text>
            </View>
            <Text style={{ fontSize: 14, textAlign: 'center', marginTop: 10, fontFamily: 'Helvetica-Bold' }} fixed>Productos Entregados</Text>
            <View style={styles.thead} fixed>
                <Text style={{ ...styles.th, width: '5%' }}>#</Text>
                <Text style={{ ...styles.th, width: '20%' }}>Código</Text>
                <Text style={{ ...styles.th, width: '40%' }}>Producto</Text>
                <Text style={{ ...styles.th, width: '15%' }}>Entregado</Text>
                <Text style={{ ...styles.th, width: '15%' }}>Bodega</Text>
            </View>
            {project && project.products.length > 0 && (
                <React.Fragment>
                    {project.products.map((e, i) => (
                        <View style={styles.tbody} key={i} break={i !== 0 && i % 21 === 0}>
                            <Text style={{ ...styles.td, width: '5%' }}>{i + 1}</Text>
                            <Text style={{ ...styles.td, width: '20%', textAlign: 'left' }}>{e.code}</Text>
                            <Text style={{ ...styles.td, width: '40%', textAlign: 'justify' }}>{e.name}</Text>
                            <Text style={{ ...styles.td, width: '15%' }}>{e.delivered}</Text>
                            <Text style={{ ...styles.td, width: '15%' }}>{e.whs}</Text>
                        </View>
                    ))}
                </React.Fragment>
            )}
        </Page>
    </Document >
);

const App = () => (
    <PDFViewer width="100%" height="100%">
        <MyDocument />
    </PDFViewer>
);

const Render = () => ReactDOM.render(<App />, document.getElementById('root'));

export default Render;