/* eslint-disable default-case */
// react
import React, { Component } from "react";
// third-party
import AsyncSelect from "react-select/async";
import { Step, StepList } from "basic-react-steps";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button, Badge, Modal, ModalHeader, ModalBody, FormGroup, Label, Input } from "reactstrap";
import PageHeader from "../../shared/PageHeader";
import {
    CreateItem,
    getPurchaseOrderByIdAP,
    UpdateItem,
    getPurchaseOrderByInputAP,
    getAllDispatchesForOrderExcelByIdAP,
} from "../../../store/directusSdk";
import {
    isObjEmpty,
    statusColorDispatch,
    statusColorOC,
    statusLabelDispatch,
    statusLabelOC,
    statusStepDispatch,
    GenerateExcel,
} from "../../shared/utilities";
import fs from "file-saver";
import TableEditDispatch from "./TableEditDispatch";
import AddDispatch from "./AddDispatch";
class ManageDispatch extends Component {
    constructor(props) {
        super(props);
        const today = moment().format("YYYY-MM-DD");
        this.state = {
            loading: false,
            loading2: false,
            loadingButton: false,
            purchaseOrder: null,
            open: false,
            step: 1,
            dispatch: null,
            mountOrder: false,
            mountDate: today,
            confirmDate: today,
            despacho: null,
            mode: "",
        };
    }

    generateExelReport = () => {
        const { purchaseOrder } = this.state;
        return getAllDispatchesForOrderExcelByIdAP(purchaseOrder.id)
            .then((data) =>
                GenerateExcel(data)
                    .then(async (response) => {
                        const blob = new Blob([response]);
                        fs.saveAs(blob, `${purchaseOrder.code}.xlsx`);
                    })
                    .catch((error) => {})
            )
            .catch((error) => toast.error(`¡Error!, ${error.message}`))
            .finally(() => this.setState({ loading: false }));
    };

    createdOrUpdateColocado = () => {
        const { mountDate, purchaseOrder } = this.state;
        const { currentUser } = this.props;
        this.setState({ loadingButton: true });

        if (!mountDate) {
            return toast.error("¡Error!, Debe introducir La fecha ");
        }

        if (!purchaseOrder.purchase_request_id) {
            return CreateItem("ap_purchase_request", { placement_date: mountDate, placement_user: currentUser.id })
                .then(async ({ data }) => {
                    await UpdateItem("ap_purchase_orders", purchaseOrder.id, { purchase_request_id: data.id });
                    await this.refreshPurchaseOrder(purchaseOrder.id);
                    toast.success("¡Orden Colocada Exitosamente!");
                })
                .catch((error) => toast.error(`¡Error!, ${error.message}`))
                .finally(() => this.setState({ loadingButton: false }));
        }

        return UpdateItem("ap_purchase_request", purchaseOrder.purchase_request_id.id, {
            placement_date: mountDate,
            placement_user: currentUser.id,
        })
            .then(async () => {
                await this.refreshPurchaseOrder(purchaseOrder.id);
                toast.success("¡Fecha de Colocación Actualzada!");
            })
            .catch((error) => toast.error(`¡Error!, ${error.message}`))
            .finally(() => this.setState({ loading: false, loadingButton: false }));
    };

    createdOrUpdateConfirmate = () => {
        const { confirmDate, purchaseOrder } = this.state;
        const { currentUser } = this.props;
        this.setState({ loadingButton: true });

        if (!confirmDate) {
            return toast.error("¡Error!, Debe introducir La fecha de Confirmación.");
        }

        if (!purchaseOrder.purchase_request_id) {
            return toast.error("¡Error!, Debe Colocar la orden de compra antes de confirmar.");
        }

        if (!["C", "CO"].includes(purchaseOrder.purchase_request_id.status)) {
            return toast.error("¡Error!, Debe Orden debe estar en estado Colocada para poder confirmarla.");
        }

        return UpdateItem("ap_purchase_request", purchaseOrder.purchase_request_id.id, {
            confirmation_date: confirmDate,
            confirmation_user: currentUser.id,
            status: "CO",
        })
            .then(async () => {
                await this.refreshPurchaseOrder(purchaseOrder.id);
                toast.success("¡Orden  de compra Confirmada!");
            })
            .catch((error) => toast.error(`¡Error!, ${error.message}`))
            .finally(() => this.setState({ loadingButton: false }));
    };

    promiseOptions = (inputValue) => getPurchaseOrderByInputAP(inputValue);

    refreshPurchaseOrder = (id) =>
        getPurchaseOrderByIdAP(id).then((purchaseOrder) => {
            this.setState({
                purchaseOrder,
                mountDate:
                    purchaseOrder.purchase_request_id && purchaseOrder.purchase_request_id.placement_date
                        ? purchaseOrder.purchase_request_id.placement_date
                        : moment().format("YYYY-MM-DD"),
                confirmDate:
                    purchaseOrder.purchase_request_id && purchaseOrder.purchase_request_id.confirmation_date
                        ? purchaseOrder.purchase_request_id.confirmation_date
                        : moment().format("YYYY-MM-DD"),
            });
        });

    searchPurchaseOrder = (e) => {
        this.setState({ loading: true });

        return this.refreshPurchaseOrder(e.id)
            .catch((error) => toast.error(`¡Error!, ${error.message}`))
            .finally(() => this.setState({ loading: false }));
    };

    renderFields = (step) => {
        const { despacho, loading2 } = this.state;

        switch (step) {
            case 0:
                return (
                    <div className="row mt-4 p-4">
                        <div className="col-md-6">
                            <FormGroup>
                                <Label for="plandate">Fecha de Planificación:</Label>
                                <Input
                                    type="date"
                                    id="plandate"
                                    placeholder="DD/MM/AAAA"
                                    value={despacho.arrival_date}
                                    onChange={(e) =>
                                        this.setState({
                                            despacho: { ...despacho, arrival_date: e.target.value },
                                        })
                                    }
                                    disabled={loading2}
                                />
                            </FormGroup>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div className="row mt-4 p-4">
                        <div className="col-md-6">
                            <FormGroup>
                                <Label for="confdate">Fecha de Confirmación:</Label>
                                <Input
                                    type="date"
                                    id="confdate"
                                    placeholder="DD/MM/AAAA"
                                    value={despacho.confirm_date}
                                    onChange={(e) =>
                                        this.setState({
                                            despacho: { ...despacho, confirm_date: e.target.value },
                                        })
                                    }
                                    disabled={loading2}
                                />
                            </FormGroup>
                        </div>
                        <div className="col-md-6">
                            <FormGroup>
                                <Label for="guide">Numero de Guía:</Label>
                                <Input
                                    type="text"
                                    id="guide"
                                    placeholder="Ej: ABC123"
                                    value={despacho.guide}
                                    onChange={(e) => this.setState({ despacho: { ...despacho, guide: e.target.value.toUpperCase() } })}
                                    disabled={loading2}
                                />
                            </FormGroup>
                        </div>
                        <div className="col-md-6">
                            <FormGroup>
                                <Label for="invoiceDate">Fecha de Factura:</Label>
                                <Input
                                    type="date"
                                    id="invoiceDate"
                                    placeholder="DD/MM/AAAA"
                                    value={despacho.invoice_date}
                                    onChange={(e) => this.setState({ despacho: { ...despacho, invoice_date: e.target.value } })}
                                    disabled={loading2}
                                />
                            </FormGroup>
                        </div>
                        <div className="col-md-6">
                            <FormGroup>
                                <Label for="invoice">Número de Factura:</Label>
                                <Input
                                    type="text"
                                    id="invoice"
                                    placeholder="Ej: ABC123"
                                    value={despacho.invoice}
                                    onChange={(e) => this.setState({ despacho: { ...despacho, invoice: e.target.value.toUpperCase() } })}
                                    disabled={loading2}
                                />
                            </FormGroup>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="row mt-4 p-4">
                        <div className="col-md-6">
                            <FormGroup>
                                <Label for="notifdate">Fecha de Notificación:</Label>
                                <Input
                                    type="date"
                                    id="notifdate"
                                    placeholder="DD/MM/AAAA"
                                    value={despacho.notification_date}
                                    onChange={(e) => {
                                        this.setState({
                                            despacho: {
                                                ...despacho,
                                                notification_date: e.target.value,
                                            },
                                        });
                                    }}
                                    disabled={loading2}
                                />
                            </FormGroup>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className="row mt-4 p-4">
                        <div className="col-md-6">
                            <FormGroup>
                                <Label for="liberdate">Fecha de Liberación:</Label>
                                <Input
                                    type="date"
                                    id="liberdate"
                                    placeholder="DD/MM/AAAA"
                                    value={despacho.liberation_date}
                                    onChange={(e) =>
                                        this.setState({
                                            despacho: { ...despacho, liberation_date: e.target.value },
                                        })
                                    }
                                    disabled={loading2}
                                />
                            </FormGroup>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    stepActions = (step, paso) => {
        const { despacho, dispatch, purchaseOrder } = this.state;
        let changes = {};

        switch (paso) {
            case 0:
                if (dispatch.arrival_date !== despacho.arrival_date) changes.arrival_date = despacho.arrival_date;
                if (!changes.arrival_date) {
                    return this.setState({ step });
                }
                if (!isObjEmpty(changes)) {
                    this.setState({ loading2: true });

                    return UpdateItem("ap_log_dispatches", dispatch.id, changes, { fields: "arrival_date" })
                        .then(async ({ data }) => {
                            await this.refreshPurchaseOrder(purchaseOrder.id);
                            this.setState({ dispatch: { ...dispatch, ...data } });
                            toast.success("¡Despacho Actualizado Correctamnte!");
                        })
                        .catch((error) => toast.error(`¡Error!, ${error.message}`))
                        .finally(() => this.setState({ loading2: false, step }));
                }
                return this.setState({ step });
            case 1:
                if (dispatch.confirm_date !== despacho.confirm_date) changes.confirm_date = despacho.confirm_date;
                if (dispatch.guide !== despacho.guide) changes.guide = despacho.guide;
                if (dispatch.invoice !== despacho.invoice) changes.invoice = despacho.invoice;
                if (dispatch.invoice_date !== despacho.invoice_date) changes.invoice_date = despacho.invoice_date;

                if (!isObjEmpty(changes)) {
                    this.setState({ loading2: true });
                    if (dispatch.status === "P") {
                        changes.status = "C";
                    }

                    return UpdateItem("ap_log_dispatches", dispatch.id, changes, { fields: "status,confirm_date,guide,invoice,invoice_date" })
                        .then(async ({ data }) => {
                            await this.refreshPurchaseOrder(purchaseOrder.id);
                            this.setState({ dispatch: { ...dispatch, ...data } });
                            toast.success("¡Despacho Actualizado Correctamnte!");
                        })
                        .catch((error) => toast.error(`¡Error!, ${error.message}`))
                        .finally(() => this.setState({ loading2: false, step }));
                }
                return this.setState({ step });
            case 2:
                if (dispatch.notification_date !== despacho.notification_date) changes.notification_date = despacho.notification_date;

                if (!isObjEmpty(changes)) {
                    this.setState({ loading2: true });
                    if (dispatch.status === "C") {
                        changes.status = "N";
                    }

                    return UpdateItem("ap_log_dispatches", dispatch.id, changes, { fields: "status,notification_date" })
                        .then(async ({ data }) => {
                            await this.refreshPurchaseOrder(purchaseOrder.id);
                            this.setState({ dispatch: { ...dispatch, ...data } });
                            toast.success("¡Despacho Actualizado Correctamnte!");
                        })
                        .catch((error) => toast.error(`¡Error!, ${error.message}`))
                        .finally(() => this.setState({ loading2: false, step }));
                }
                return this.setState({ step });
            case 3:
                if (dispatch.liberation_date !== despacho.liberation_date) changes.liberation_date = despacho.liberation_date;

                if (!isObjEmpty(changes)) {
                    this.setState({ loading2: true });
                    if (dispatch.status === "N") {
                        changes.status = "L";
                    }

                    return UpdateItem("ap_log_dispatches", dispatch.id, changes, { fields: "status,liberation_date" })
                        .then(async ({ data }) => {
                            await this.refreshPurchaseOrder(purchaseOrder.id);
                            this.setState({ dispatch: { ...dispatch, ...data } });
                            toast.success("¡Despacho Actualizado Correctamnte!");
                        })
                        .catch((error) => toast.error(`¡Error!, ${error.message}`))
                        .finally(() => this.setState({ loading2: false, step }));
                }
                return this.setState({ step });
            default:
                return null;
        }
    };

    openEdit = (e, dispatch) => {
        e.preventDefault();
        this.setState({ open: true, mode: "E", dispatch });
    };

    closeModal = () => {
        this.setState({ open: false, mode: "" });
    };

    render() {
        const { loading, purchaseOrder, loadingButton, open, mountDate, confirmDate, dispatch, step, loading2, mode } = this.state;
        const { currentUser } = this.props;

        const breadcrumb = [
            { title: "Home", url: "/" },
            { title: "Logística", url: "/logistica" },
            { title: "Logística AP", url: "/logistica/logistica-ap" },
            { title: "Gestionar Despacho", url: "" },
        ];

        return (
            <div className="container">
                <PageHeader breadcrumb={breadcrumb} />
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-10">
                                <AsyncSelect
                                    cacheOptions
                                    loadOptions={this.promiseOptions}
                                    noOptionsMessage={() => "No se Encontró orden de compra."}
                                    loadingMessage={() => "Buscando..."}
                                    placeholder="Orden de Compra..."
                                    onChange={this.searchPurchaseOrder}
                                    value={purchaseOrder}
                                    className="col-md-4"
                                />
                            </div>
                            {purchaseOrder &&
                                purchaseOrder.purchase_request_id &&
                                purchaseOrder.purchase_request_id.status === "CO" &&
                                purchaseOrder.dispatches.length > 0 && (
                                    <Button color="primary" disabled={loading} onClick={() => this.generateExelReport()}>
                                        <i className="far fa-file-alt mr-2"></i>
                                        Reporte
                                    </Button>
                                )}
                        </div>

                        {loading ? (
                            <div className="div-loading m-5 p-5" />
                        ) : (
                            <div className="row mt-2">
                                <div className="col-md-4 mt-1">
                                    <b>Fecha Emisión:</b>
                                    <p>{purchaseOrder && moment(purchaseOrder.date).format("DD-MM-YYYY")}</p>
                                </div>
                                <div className="col-md-3 mt-1">
                                    <b>Fecha Llegada:</b>{" "}
                                    <p>{purchaseOrder && purchaseOrder.llegada && moment(purchaseOrder.llegada).format("DD-MM-YYYY")}</p>
                                </div>
                                <div className="col-md-2 mt-1">
                                    <b>Estado:</b>
                                    <p>
                                        {purchaseOrder && (
                                            <Badge color={statusColorOC(purchaseOrder.status, purchaseOrder.purchase_request_id)}>
                                                {statusLabelOC(purchaseOrder.status, purchaseOrder.purchase_request_id)}
                                            </Badge>
                                        )}
                                    </p>
                                </div>

                                <div className="col-md-4 mt-1">
                                    <b>Proveedor:</b>
                                    <p>{purchaseOrder && purchaseOrder.supplier}</p>
                                </div>
                                <div className="col-md-8 mt-1">
                                    <b>Comentarios:</b>
                                    <p>{purchaseOrder && purchaseOrder.comments}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="card d-flex flex-row mt-3">
                    <div className="p-2 col-sm-3 border-right ">
                        {purchaseOrder && (
                            <FormGroup>
                                {purchaseOrder.purchase_request_id && (
                                    <>
                                        <b>Colocada Por:</b>
                                        <p>
                                            {purchaseOrder.purchase_request_id.placement_user &&
                                                `${purchaseOrder.purchase_request_id.placement_user.first_name} ${purchaseOrder.purchase_request_id.placement_user.last_name}`}
                                        </p>
                                    </>
                                )}

                                <Label for="number">Fecha de Colocación:</Label>
                                <Input
                                    type="date"
                                    id="mountate"
                                    placeholder="DD/MM/AAAA"
                                    disabled={
                                        !["Administrator", "Almacenista AP", "Admin AP", "Compras AP"].includes(currentUser.role.name) ||
                                        purchaseOrder.status === "C" ||
                                        loadingButton
                                    }
                                    value={mountDate}
                                    onChange={(e) => this.setState({ mountDate: e.target.value })}
                                />
                                {["Administrator", "Almacenista AP", "Admin AP", "Compras AP"].includes(currentUser.role.name) &&
                                    purchaseOrder.status !== "C" && (
                                        <Button
                                            className="mt-3"
                                            color="primary"
                                            onClick={this.createdOrUpdateColocado}
                                            disabled={loading || loadingButton}
                                            hidden={
                                                purchaseOrder &&
                                                purchaseOrder.purchase_request_id &&
                                                purchaseOrder.purchase_request_id.placement_date &&
                                                purchaseOrder.purchase_request_id.placement_date === mountDate
                                            }
                                        >
                                            <i className={`${loadingButton ? "fas fa-cog fa-spin" : "fas fa-save"} mr-2`} />
                                            {!purchaseOrder.purchase_request_id ? "Colocar" : "Editar"}
                                        </Button>
                                    )}
                            </FormGroup>
                        )}
                    </div>

                    <div className="p-2 col-sm-3 border-right">
                        {purchaseOrder && (
                            <FormGroup>
                                {purchaseOrder.purchase_request_id && purchaseOrder.purchase_request_id.confirmation_user && (
                                    <>
                                        <b>Confirmada Por:</b>
                                        <p>
                                            {purchaseOrder.purchase_request_id.confirmation_user &&
                                                `${purchaseOrder.purchase_request_id.confirmation_user.first_name} ${purchaseOrder.purchase_request_id.confirmation_user.last_name}`}
                                        </p>
                                    </>
                                )}

                                <Label for="confirmDate">Fecha de Confirmación:</Label>
                                {purchaseOrder && purchaseOrder.purchase_request_id && (
                                    <>
                                        <Input
                                            type="date"
                                            id="confirmDate"
                                            disabled={
                                                !["Administrator", "Almacenista AP", "Admin AP", "Compras AP"].includes(currentUser.role.name) ||
                                                !purchaseOrder.purchase_request_id ||
                                                purchaseOrder.status === "C" ||
                                                loadingButton
                                            }
                                            placeholder="DD/MM/AAAA"
                                            value={confirmDate}
                                            onChange={(e) => this.setState({ confirmDate: e.target.value })}
                                        />
                                        {["Administrator",  "Almacenista AP","Admin AP", "Compras AP"].includes(currentUser.role.name) &&
                                            purchaseOrder.status !== "C" && (
                                                <Button
                                                    className="p-button-sm mt-3"
                                                    color="primary"
                                                    onClick={this.createdOrUpdateConfirmate}
                                                    disabled={loading || loadingButton}
                                                    hidden={
                                                        purchaseOrder &&
                                                        purchaseOrder.purchase_request_id &&
                                                        purchaseOrder.purchase_request_id.confirmation_date &&
                                                        purchaseOrder.purchase_request_id.confirmation_date === confirmDate
                                                    }
                                                >
                                                    <i className={`${loadingButton ? "fas fa-cog fa-spin" : "fas fa-save"} mr-2`} />
                                                    {!purchaseOrder.purchase_request_id.confirmation_date ? "Confirmar" : "Editar"}
                                                </Button>
                                            )}
                                    </>
                                )}
                            </FormGroup>
                        )}
                    </div>

                    <div className="p-2 col-sm-6 ">
                        {purchaseOrder &&
                            purchaseOrder.dispatches.map((dispatch) => (
                                <div className="d-flex flex-row justify-content-between  border-bottom" key={dispatch.id}>
                                    <div className="p-2  d-flex flex-column">
                                        <div>
                                            <b className="mr-3">Código:</b>
                                            <Link to="#" onClick={(e) => this.openEdit(e, dispatch)}>{`${purchaseOrder.code}-${dispatch.n}`}</Link>
                                        </div>
                                        <div>
                                            <b className="mr-3">Estado:</b>
                                            <Badge color={statusColorDispatch(dispatch.status)}>{statusLabelDispatch(dispatch.status)}</Badge>
                                        </div>
                                        <div>
                                            <b className="mr-4">Items:</b>
                                            {dispatch.items.length}
                                        </div>
                                    </div>
                                    <div className="p-2 col-sm-3">
                                        <Button
                                            className="p-button-sm mt-3"
                                            color="primary"
                                            hidden={
                                                !purchaseOrder ||
                                                !purchaseOrder.purchase_request_id ||
                                                purchaseOrder.purchase_request_id.status === "CA"
                                            }
                                            onClick={() => {
                                                this.setState({
                                                    open: true,
                                                    dispatch,
                                                    step: statusStepDispatch(dispatch.status),
                                                    despacho: {
                                                        confirm_date: !dispatch.confirm_date ? moment().format("YYYY-MM-DD") : dispatch.confirm_date,
                                                        guide: dispatch.guide,
                                                        invoice: dispatch.invoice,
                                                        invoice_date: dispatch.invoice_date,
                                                        notification_date: !dispatch.notification_date
                                                            ? moment().format("YYYY-MM-DD")
                                                            : dispatch.notification_date,
                                                        liberation_date: !dispatch.liberation_date
                                                            ? moment().format("YYYY-MM-DD")
                                                            : dispatch.liberation_date,
                                                        received_date: dispatch.received_date,
                                                    },
                                                    mode: "G",
                                                });
                                            }}
                                        >
                                            <i className="fas fa-edit mr-2" />
                                            Editar
                                        </Button>
                                    </div>
                                </div>
                            ))}

                        <div className="d-flex justify-content-end mt-1">
                            {purchaseOrder && purchaseOrder.purchase_request_id && purchaseOrder.purchase_request_id.status === "CO" && (
                                <Button
                                    color="primary"
                                    disabled={loading}
                                    hidden={!["Administrator",  "Almacenista AP","Admin AP", "Almacenista AP"].includes(currentUser.role.name)}
                                    onClick={() => {
                                        this.setState({
                                            open: true,
                                            mode: "A",
                                        });
                                    }}
                                >
                                    <i className="far fa-plus-square mr-2"></i>
                                    Crear Despacho
                                </Button>
                            )}
                        </div>
                    </div>
                </div>

                {purchaseOrder && (
                    <Modal isOpen={open} toggle={() => this.setState({ open: false })} centered size="xl">
                        {dispatch ? (
                            <ModalHeader toggle={() => this.setState({ open: false })}>
                                Despacho Nº {`${purchaseOrder.code}-${dispatch.n}`}
                            </ModalHeader>
                        ) : (
                            <ModalHeader toggle={() => this.setState({ open: false })}>{"Crear Despacho"}</ModalHeader>
                        )}

                        <ModalBody>
                            {mode === "G" && (
                                <>
                                    <StepList stepNumber={step}>
                                        {dispatch.status === "CA" && (
                                            <Step title="CANCELADO" description={moment(dispatch.modified_on).format("DD/MM/YYYY")} />
                                        )}

                                        <Step title="PLANIFICADO" description={moment(dispatch.arrival_date).format("DD/MM/YYYY")} />
                                        <Step
                                            title="CONFIRMADO"
                                            description={dispatch.confirm_date && moment(dispatch.confirm_date).format("DD/MM/YYYY")}
                                        />
                                        <Step
                                            title="NOTIFICADO"
                                            description={dispatch.notification_date && moment(dispatch.notification_date).format("DD/MM/YYYY")}
                                        />
                                        <Step
                                            title="LIBERADO"
                                            description={dispatch.liberation_date && moment(dispatch.liberation_date).format("DD/MM/YYYY")}
                                        />
                                        <Step
                                            title="RECIBIDO"
                                            description={dispatch.received_date && moment(dispatch.received_date).format("DD/MM/YYYY")}
                                        />
                                    </StepList>
                                    <br />
                                    {["Administrator",  "Almacenista AP","Admin AP", "Almacenista AP"].includes(currentUser.role.name) && (
                                        <>
                                            {this.renderFields(step)}
                                            <div className="d-flex flex-row justify-content-between  border-bottom mt-5">
                                                <div className="p-1  d-flex flex-column">
                                                    <Button
                                                        className="p-button-raised p-button-rounded p-button-sm"
                                                        color="primary"
                                                        hidden={step < 1 || step > 4}
                                                        onClick={() => step !== 0 && this.setState({ step: step - 1 })}
                                                        disabled={loading2}
                                                    >
                                                        Atras
                                                    </Button>
                                                </div>
                                                <div className="p-1">
                                                    <Button
                                                        className="p-button-raised p-button-rounded p-button-sm"
                                                        color="primary"
                                                        hidden={step > 3 || step === -1}
                                                        onClick={() => step < 4 && this.stepActions(step + 1, step)}
                                                        disabled={loading2}
                                                    >
                                                        Siguiente
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}

                            {mode === "E" && (
                                <TableEditDispatch
                                    currentUser={currentUser}
                                    dispatch={dispatch}
                                    purchaseOrder={purchaseOrder}
                                    updatePhater={this.refreshPurchaseOrder}
                                />
                            )}

                            {mode === "A" && (
                                <AddDispatch
                                    purchaseOrder={purchaseOrder}
                                    updatePhater={this.refreshPurchaseOrder}
                                    userId={this.props.currentUser.id}
                                    closed={this.closeModal}
                                />
                            )}
                        </ModalBody>
                    </Modal>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ManageDispatch);
