// react
import React, { useEffect, useState } from "react";
// third-party
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { MDBDataTableV5 } from "mdbreact";
import Alert from "sweetalert2";

import swal from "sweetalert";
import {
    getExternalInventory,
    newExternalInventory,
    getExternalInventories,
    updateExternalInventory,
    UpdateItem,
    DeleteItems,
    DeleteItem,
} from "../../store/directusSdk";
import PageHeader from "../shared/PageHeader";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from "reactstrap";
import ImportCsvExternalInventory from "./ImportCsvExternalInventory";
import ListInventoriCsv from "./ListInventoriCsv";
import ErrorListInventoriCsv from "./ErrorListInventoriCsv";
import "./loadingTable.css";

export default function ExternalInventories() {
    const [inventory, setInventory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [products, setProducts] = useState([]);
    const [productsErrors, setProductsErrors] = useState([]);
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("");
    const [timeDelivery, setTimeDelivery] = useState("");
    const [pseudonym, setPseudonym] = useState("");
    const [optionSelected, setOptionSelected] = useState(null);
    const [mode, setMode] = useState("A");
    const [update, setUpdate] = useState(false);
    const [copy, setCopy] = useState({});

    /*Función encargada de solicitar los inventarios externos a la API. */
    const getInventories = () =>
        getExternalInventories()
            .then((inventory) => {
                const inv = inventory.map((e) => {
                    e.actions = (
                        <>
                            <Button
                                outline
                                color="info"
                                title="Gestionar Inventario"
                                onClick={() => {
                                    setOpen(true);
                                    setOptionSelected(e.id);
                                    getInventory(e.id);
                                }}
                            >
                                <i className="fa fa-edit" />
                            </Button>{" "}
                            <Button
                                outline
                                color="danger"
                                title="Gestionar Inventario"
                                onClick={() => {
                                    deleteInventory(e);
                                }}
                            >
                                <i className="fa fa-trash" />
                            </Button>
                        </>
                    );
                    return e;
                });
                setInventory(inv);
            })
            .catch((error) => toast.error("¡Error!, " + error.message))
            .finally(() => setLoading(false));

    /*Función encargada de buscar todos los datos en la API, relacionados con el inventario seleccionado. */
    const getInventory = (id) => {
        setLoading1(true);
        id &&
            getExternalInventory(id)
                .then((data) => {
                    setCopy(data);
                    setMode("U");
                    setOptionSelected(id);
                    setProducts(data.items);
                    setName(data.name);
                    setPseudonym(data.pseudonym);
                    setTimeDelivery(parseInt(data.time_delivery));
                })
                .catch((error) => toast.error("¡Error!, " + error.message))

                .finally(() => setLoading1(false));
    };

    const deleteInventory = (e) => {
        setLoading3(true);

        const bodyAlert = {
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonColor: "#DC3527",
            icon: "question",
            showLoaderOnConfirm: true,
            title: "Eliminar Inventario",
            text: `¿Está seguro que desea eliminar el inventario ${e.name}, con ${e.products} productos que tiene.
            `,
            icon: "warning",
            buttons: true,
            dangerMode: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            allowOutsideClick: () => !Alert.isLoading(),
        };
        Alert.fire(bodyAlert).then((result) => {
            if (result.isConfirmed) {
                DeleteItem("inv_external_inventory", e.id)
                    .then(() => {
                        DeleteItems("inv_external_inventory_items", e.ids)
                            .then(async () => {
                                await getInventories();
                                return swal("¡Exito!", "¡El almacén se ha eliminado!", "success");
                            })
                            .catch((error) => swal("Error!", error.message, "error"))
                            .finally(() => console.log(""));
                    })
                    .catch((error) => swal("Error!", error.message, "error"))
                    .finally(() => setLoading3(false));
            }
            return setLoading3(false);
        });

    
    };

    useEffect(() => {
        setLoading(true);
        getInventories();
    }, []);

    const breadcrumb = [
        { title: "Home", url: "/" },
        { title: "Almacén", url: "/almacen" },
        { title: "Inventario de Proveedores", url: "" },
    ];

    const dataTable = {
        columns: [
            { label: "Nº", field: "id", width: 90 },
            { label: "Nombre", field: "name", width: 200 },
            { label: "Tiempo de Entrega", field: "time", width: 180 },
            { label: "Productos en el inventario", field: "products", width: 180 },
            { label: "Última Actualización", field: "timeAction", width: 180 },
            { label: "Acciones", field: "actions", width: 80 },
        ],
        rows: inventory,
    };

    const resetState = () => {
        setProducts([]);
        setProductsErrors([]);
        setName("");
        setTimeDelivery("");
        setUpdate(false);
        setPseudonym("");
    };

    const createInventory = () => {
        if (!name) return toast.error("Falta el nombre ");
        if (!timeDelivery) return toast.error("Falta el tiempo de entrega");
        if (!pseudonym) return toast.error("Indique un seudónimo");
        if (!products.length > 0) return toast.error("Falta productos");

        const newInventory = {};
        const items = products.map((i) => ({ sku: i.code_manufacture, qty: i.qty }));
        newInventory.name = name;
        newInventory.timeDelivery = timeDelivery;
        newInventory.items = items;
        newInventory.pseudonym = pseudonym;

        if (mode === "U") {
            newInventory.id = optionSelected;
            setLoading2(true);
            return updateExternalInventory(newInventory)
                .then(() => {
                    toast.success("El inventario se ha actualizado exitosamente");
                    setOpen(false);
                    resetState();
                    getInventories();
                })
                .catch((error) => toast.error("¡Error!, " + error.message))
                .finally(() => setLoading2(false));
        }
        return newExternalInventory(newInventory)
            .then(() => {
                toast.success("El inventario se ha registrado exitosamente");
                setOpen(false);
                resetState();
                getInventories();
            })
            .catch((error) => toast.error("¡Error!, " + error.message))
            .finally(() => setLoading2(false));
    };

    const loadingTable = {
        pointerEvents: "none",
        opacity: " 0.5",
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Módulo Almacen - Ingeniería y Controles Automatizados</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />
            <div className="container">
                <div className="card">
                    <div className="card-body">
                        <h5 className="mt-3">Inventarios</h5>
                        <Button
                            color="success"
                            disabled={loading}
                            onClick={() => {
                                setOpen(true);
                                setMode("A");
                            }}
                        >
                            <i className={`${loading1 ? "fas fa-cog fa-spin" : "fa fa-cubes"} mr-2`} />
                            Nuevo
                        </Button>

                        {loading ? (
                            <div className="div-loading" style={{ width: 300 }} />
                        ) : (
                            <div style={loading3 ? loadingTable : { width: "100%" }}>
                                <MDBDataTableV5
                                    entriesOptions={[25, 50, 75, 100]}
                                    entries={10}
                                    pagesAmount={4}
                                    data={dataTable}
                                    pagingTop
                                    searchTop
                                    searchBottom={false}
                                    entriesLabel="Mostar"
                                    searchLabel="Buscar"
                                    infoLabel={["Viendo", "al", "de", "registros"]}
                                    paginationLabel={["Anterior", "Siguiente"]}
                                    noRecordsFoundLabel="No se han encontrado registros..."
                                />
                            </div>
                        )}
                    </div>
                </div>
                {open && (
                    <Modal isOpen={open} centered size="lg" style={{ maxWidth: "1600px", width: "100%" }} zIndex="1000">
                        <ModalHeader
                            toggle={() => {
                                setOpen(false);
                                resetState();
                            }}
                        >
                            Ingresar productos.
                        </ModalHeader>
                        <ModalBody>
                            <div className="row ml-5">
                                <div className="col-md-5">
                                    <FormGroup>
                                        <Label for="guide">Nombre:</Label>
                                        <Input type="text" id="code" disabled={loading2} value={name} onChange={(e) => setName(e.target.value)} />
                                    </FormGroup>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <FormGroup>
                                                <Label for="invoice">Alias:</Label>
                                                <Input
                                                    type="text"
                                                    id="time"
                                                    disabled={loading2}
                                                    value={pseudonym}
                                                    onChange={(e) => setPseudonym(e.target.value)}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-6">
                                            <FormGroup>
                                                <Label for="invoice">Tiempo de Entrega(Días):</Label>
                                                <Input
                                                    id="time"
                                                    disabled={loading2}
                                                    type="number"
                                                    value={timeDelivery}
                                                    onChange={(e) => setTimeDelivery(e.target.value)}
                                                    onInput={(e) => {
                                                        const reg = /[A-Za-z@$?¡\-_*/+=!"·.)%&(`´]/g;
                                                        const str = e.target.value;
                                                        const newStr = str.split(reg);
                                                        return (e.target.value = newStr.join("").slice(0, 7));
                                                    }}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-7">
                                    <ImportCsvExternalInventory
                                        uploadTask={(e) => console.log(e)}
                                        setProducts={setProducts}
                                        setProductsErrors={setProductsErrors}
                                        loading={setLoading1}
                                        setUpdate={setUpdate}
                                    />
                                </div>
                            </div>

                            <div className="row ml-1 mb-3">
                                <div className="col-md-8"></div>
                            </div>

                            <div className="row">
                                <div className="col-md-8 ">
                                    <ListInventoriCsv products={products} loading={loading1} loading2={loading2} />
                                </div>
                                <div className="col-md-4 ">
                                    <ErrorListInventoriCsv productsErrors={productsErrors} loading={loading1} loading2={loading2} />
                                </div>
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                color="secondary"
                                disabled={loading1}
                                onClick={() => {
                                    setOpen(false);
                                    resetState();
                                }}
                            >
                                <i className="fa fa-ban mr-2" />
                                Cancelar
                            </Button>
                            <Button
                                color="primary"
                                disabled={loading1 || !name || !timeDelivery || !(products.length > 0) || (mode === "U" && !update)}
                                onClick={createInventory}
                            >
                                <i className={`${loading1 ? "fas fa-cog fa-spin" : mode === "A" ? "fas fa-save" : "fas fa-edit"} mr-2`} />
                                {mode === "A" ? "Guardar" : "Actualizar"}
                            </Button>
                        </ModalFooter>
                    </Modal>
                )}
            </div>
        </React.Fragment>
    );
}
