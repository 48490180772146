// react
import React from 'react';

// third-party
import { connect } from 'react-redux';

// application
import Select from 'react-select';
import { recalculateItemPrice } from '../../store/cart';

function SelectPriceList(props) {
    const { multiplications, i, multi, recalculateItemPrice, currentUser, customer } = props;
    const multiplicador = customer ? multiplications.find((e) => e.value == customer.multi) : multiplications.find((e) => e.id == currentUser.multi);

    return (
        <div className="input-select">
            <Select
                options={multiplications}
                value={multi}
                onChange={(e) => recalculateItemPrice(i, multiplicador.value, e)}
                isDisabled={i === -1}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    customer: state.cart.customer,
    currentUser: state.currentUser,
});

const mapDispatchToProps = {
    recalculateItemPrice,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SelectPriceList);
