// react
import React, { useState } from 'react';
// third-party
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default ({ loading, dispatches, open, close, rowExpansionTemplate, searchClients }) => {
    const [expandedRows, setExpandedRows] = useState(null);

    return (
        <Modal isOpen={open} centered size="xl">
            <ModalHeader toggle={close}>Despachos</ModalHeader>
            <ModalBody>

                {loading && (
                    <div className="mt-2 mb-5">
                        <br />
                        <br />
                        <div className="div-loading" />
                        <br />
                        <br />
                    </div>
                )}
                {!loading && dispatches.length > 0 && (
                    <DataTable
                        value={dispatches}
                        dataKey="id"
                        paginator
                        rows={10}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        removableSort
                        emptyMessage="No se han encontrado registros..."
                        exportFilename="Despachos"
                        expandedRows={expandedRows}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        rowExpansionTemplate={rowExpansionTemplate}
                        className="p-datatable-dispatches p-datatable-sm mt-1"
                    >
                        <Column expander style={{ width: '5%' }} />
                        <Column field="code" header="Código" sortable filter filterPlaceholder="Todos" filterHeaderClassName="p-inputtext-sm" style={{ width: '20%' }} />
                        <Column field="qty" header="Cantidad" sortable filter style={{ textAlign: 'center', width: '15%' }} filterPlaceholder="Todos" filterHeaderClassName="p-inputtext-sm" />
                        <Column field="purchaseOrder" header="Orden Compra" sortable filter style={{ textAlign: 'center', width: '20%' }} filterPlaceholder="Todos" filterHeaderClassName="p-inputtext-sm" />
                        <Column field="supplier" header="Proveedor" sortable filter style={{ textAlign: 'center', width: '35%' }} filterPlaceholder="Todos" filterHeaderClassName="p-inputtext-sm" />
                        <Column header="Clientes" body={(row) => <Button outline color="info" title="Ver Clientes" onClick={() => searchClients(row)}><i className="fas fa-users" /></Button>} style={{ width: '10%' }} />
                    </DataTable>
                )}
                {!loading && dispatches.length < 1 && <p>Este Producto no esta en ordenes de compra actualmente.</p>}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={close}>OK</Button>
            </ModalFooter>
        </Modal>
    );
}