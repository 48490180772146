// react
import React, { Component } from 'react';
// third-party
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Button } from 'reactstrap';
import moment from 'moment';
import NewDelivery from './NewDelivery';
import { getSuggestedProductsICA, getSuggestedProductsAP } from '../../store/directusSdk';
import SubTable from './SubTable';

class ProductsTable extends Component {
    constructor(props) {
        super(props);
        this.options = [
            { label: 'P1', value: 'P1' },
            { label: 'P2', value: 'P2' },
            { label: 'P3', value: 'P3' },
            { label: 'P4', value: 'P4' },
            { label: 'S/P', value: 'S/P' }
        ]

        this.state = {
            expandedRows: null,
            newChangesLines: [],
            whsSelect: null,
            orderInp: null,
            newMovements: [],
            prioritySelect: null,
            expandAll: false

        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.products !== state.products) {
            return {
                products: props.products,
            };
        }
        return null;
    }

    onColumnToggle = (event) => {
        const selectedColumns = event.value;
        const orderedSelectedColumns = this.columns.filter((col) => selectedColumns.some((sCol) => sCol.header === col.header));
        return this.setState({ selectedColumns: orderedSelectedColumns });
    }

    promiseOptions = (table, inputValue) => new Promise((resolve) => resolve(table === 'ICA' ? getSuggestedProductsICA(inputValue) : getSuggestedProductsAP(inputValue)));

    expandAll = () => {
        const { products } = this.props;
        const expandedRows = {};
        products.forEach((p) => { expandedRows[`${p.id}`] = true; });

        this.setState({ expandedRows, expandAll: true });
    }

    collapseAll = () => this.setState({ expandedRows: null, expandAll: false });

    AddChangeMovements = (e, key, value, line) => {
        const { newChangesLines } = this.state;
        const { SetChangesLines, table, currentUser } = this.props;
        const i = newChangesLines.findIndex((item) => item.id === e.id);

        if (i !== -1) {
            newChangesLines[i][`${key}`] = value;
            newChangesLines[i].confirmed_date = moment().format('YYYY-MM-DD HH:mm:ss');
            newChangesLines[i].confirmed_by = currentUser.id;
            newChangesLines[i].product = line.code;
            return this.setState({ newChangesLines }, () => SetChangesLines(table, newChangesLines));
        }
        const newChange = { id: e.id };
        newChange[`${key}`] = value;
        newChange.confirmed_date = moment().format('YYYY-MM-DD HH:mm:ss');
        newChange.confirmed_by = currentUser.id;
        newChangesLines.product = line.code;
        newChangesLines.push(newChange)
        return this.setState({ newChangesLines }, () => SetChangesLines(table, newChangesLines));
    };

    AddMovement = (table, type, orderId, cant) => {
        const { newMovements } = this.state;
        const { currentUser, AddMovement } = this.props;
        const movement = { type, engprojectline_id: orderId, delivered_qty: cant, delivered_by: currentUser.id };
        const i = newMovements.findIndex((e) => e.engprojectline_id === orderId);
        if (i !== -1) {
            newMovements[i] = movement;
            return this.setState({ newMovements });
        }
        newMovements.push(movement);
        movement.delivered_by = { first_name: currentUser.first_name, last_name: currentUser.last_name };
        AddMovement(table, i, movement);
        return this.setState({ newMovements });
    }

    rowExpansionTemplate = (row) => {
        const { status, currentUser, table, products, AddMovement, projectId } = this.props;

        const max = row.movements.reduce((max, e) => {
            if (e.type === 'delivery') {
                return max - e.delivered_qty;
            } else if (e.type === 'return') {
                return max + e.confirmed_qty;
            }
            return max;
        }, row.order);

        return (
            <div className="orders-subtable">
                {row.movements.length > 0 ? (
                    <SubTable row={row} status={status} AddChangeMovements={this.AddChangeMovements} currentUser={currentUser} table={table} />
                ) :
                    (
                        <p>No se Han Realizado Entregas...</p>
                    )
                }
                {max > 0 && status !== 'finished' && (
                    <div className="row pt-2 pl-4">
                        <strong >Nueva Entrega:</strong>
                        <div className="ml-3 col-4">
                            <NewDelivery max={max} currentUser={currentUser} orderId={row.id} table={table} AddMovement={AddMovement} products={products} projectId={projectId} row={row} />
                        </div>
                    </div>
                )}
            </div>
        );
    }

    onWhsChange = (e) => {
        this.setState({ whsSelect: e.value });
        return this.dt.filter(e.value, 'warehouse_id.value', 'equals');
    };

    onPriorityChange = ({ value }) => {
        this.setState({ prioritySelect: value });
        return this.dt.filter(value, 'priority', 'equals');
    };

    onFilterChange = (e) => {
        this.setState({ filterSelect: e.value });
        return this.dt.filter(e.value, 'filter', 'equals');
    };

    PedidoTemplate = (row) => {
        return row.order;
    }

    WarehouseTemplate = (row) => {
        return row.warehouse_id.label;
    }

    PriorityTemplate = (row) => {
        return row.priority;
    }

    AlertTemplate = (row) => {
        if (!row.movements.length > 0 && row.warehouse_id.code === '-1') {
            if (!row.stockExternal) {
                return { error: '#ea553d', message: 'Item no se Encuentra  en Inventario particular del Cliente.', icon: 'fas fa-exclamation-triangle fa-2x' };
            }
            if (row.stockExternal && row.order > row.stockExternal) {
                return { error: '#ea553d', message: 'Cantidad Solicitada es Mayor a la Existente en Inventario particular del Cliente.', icon: 'fas fa-exclamation-triangle fa-2x' };
            }
        }

        if (!row.movements.length > 0) {
            return { error: '#3bc3e9', message: 'Sin Entregas.', icon: 'fas fa-exclamation-triangle fa-2x' };
        }

        for (let m of row.movements) {
            if (m.delivered_qty !== m.confirmed_qty && m.confirmed_date) {
                return { error: '#ea553d', message: 'Incongruencias entre cantidad Enviada y Confirmada.', icon: 'fas fa-exclamation-triangle fa-2x' };
            }
            if (m.type === 'delivery' && m.delivered_qty > 0 && !m.confirmed_date) {
                return { error: '#ffbb44', message: 'Entregas pendientes por confirmar.', icon: 'fas fa-exclamation-triangle fa-2x' };
            }
            if (m.type === 'return' && m.delivered_qty > 0 && !m.confirmed_date) {
                return { error: '#ffbb44', message: 'Devoluciones pendientes por confirmar.', icon: 'fas fa-exclamation-triangle fa-2x' };
            }
        }

        let delivered = 0;

        row.movements.forEach((item) => {
            if (item.type === 'delivery') {
                delivered += item.confirmed_qty;
            }
        });

        if (delivered > 0 && row.order > delivered) {
            return { error: '#3bc3e9', message: 'Cantidad Recibida es menor a la orden.', icon: 'fas fa-exclamation-triangle fa-2x' };
        }

        if (delivered > 0 && delivered >= row.order) {
            return { error: '#28a765', message: 'Producto Completo!', icon: 'fa fa-check fa-2x' };
        }

        return { error: '', message: '', icon: '' };
    }

    DeleteTemplate = (row) => {
        const alert = this.AlertTemplate(row);
        if (!alert) {
            return '';
        }
        return <i className={alert.icon} title={alert.message} style={{ color: alert.error }} />;
    }

    exportCSV = () => {
        return this.dt.exportCSV();
    }

    render() {
        const { prioritySelect, filterSelect, whsSelect, expandedRows, expandAll } = this.state;
        const { whs, products } = this.props;

        const whsFilter = <Dropdown value={whsSelect} options={whs} onChange={this.onWhsChange} placeholder="Todos..." showClear />
        const priorityFilter = <Dropdown value={prioritySelect} options={this.options} onChange={this.onPriorityChange} placeholder="Todos..." showClear />
        const filter = <Dropdown value={filterSelect} options={[
            { label: 'Sin Entregas', value: 0 },
            { label: 'Por Confirmar', value: 1 },
            { label: 'Incompletos', value: 3 },
            { label: 'Completos', value: 2 }
        ]} onChange={this.onFilterChange} placeholder="Todos..." showClear />

        const header = (
            <div className="p-grid  mt-3">
                <div className="p-col-9">
                    <div className="p-d-flex p-ai-center">
                        {expandAll ? <Button className="p-mr-3" color="warning" onClick={this.collapseAll}><i className="fas fa-angle-double-right mr-2" />Contraer</Button> : <Button color="info" className="p-mr-3" onClick={this.expandAll} ><i className="fas fa-angle-double-down mr-2" />Expandir</Button>}
                    </div>
                </div>
                <div className="p-col-3">
                    <div className="p-d-flex p-jc-end">
                        <Button className="p-mr-3" color="info" onClick={this.exportCSV}><i className="fas fa-file-export mr-2" />Exportar</Button>
                    </div>
                </div>
            </div>
        );

        return (
            <div className="datatable-rowexpansion-demo">
                <div className="card">
                    <DataTable
                        ref={(el) => this.dt = el}
                        value={products}
                        dataKey="id"
                        header={header}
                        expandedRows={expandedRows}
                        onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                        rowExpansionTemplate={this.rowExpansionTemplate}
                        paginator
                        rows={10}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        removableSort
                        emptyMessage="No se han encontrado registros..."
                        className="p-datatable-customers"
                    >
                        <Column expander style={{ width: '4%' }} />
                        <Column field="id" header="ID" style={{ width: '7%', textAlign: 'center' }} sortable filter filterType="number" filterPlaceholder="ID..." />
                        <Column field="code" header="Código" style={{ width: '10%', textAlign: 'left' }} sortable filter filterPlaceholder="Código..." />
                        <Column field="sku" header="SKU" style={{ width: '13%', textAlign: 'left' }} sortable filter filterPlaceholder="SKU..." />
                        <Column field="name" header="Producto" style={{ width: '20%', textAlign: 'justify' }} sortable filter filterMatchMode="contains" filterPlaceholder="Producto..." />
                        <Column field="order" header="Pedido" style={{ width: '11%', textAlign: 'center' }} sortable filter filterMatchMode="gte" filterPlaceholder="Mayor A..." filterType="Number" body={this.PedidoTemplate} />
                        <Column field="priority" header="Prioridad" style={{ width: '11%', textAlign: 'center' }} sortable filter body={this.PriorityTemplate} filterElement={priorityFilter} />
                        <Column field="warehouse_id.value" style={{ width: '13%', textAlign: 'center' }} sortable filter body={this.WarehouseTemplate} filterElement={whsFilter} />
                        <Column field="filter" header="Filtar" style={{ width: '10%', textAlign: 'center' }} filter body={this.DeleteTemplate} filterElement={filter} />
                    </DataTable>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsTable);
