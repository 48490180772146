import React, { Component } from "react";
import { CSVReader } from "react-papaparse";
import { toast } from "react-toastify";
import * as XLSX from "xlsx/xlsx.mjs";

export default class ImportCsv05 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            header: [],
        };
    }

    caseCsc = (data) => {
        const { loadItems, setItems, mode, whs } = this.props;

        if (!data.length > 0) {
            loadItems([], setItems);
            return toast.error(`Error! 01, Revise CSV ${mode} e intente nuevamente...`);
        }

        const header = data.shift();
        const items = [];

        const itemNumber = header.data.findIndex((e) => e.toLowerCase() === "número de artículo" || e.toLowerCase() === "numero de articulo");
        if (itemNumber === -1) {
            return toast.error("¡Error, Debe indicar la columna Número de artículo!");
        }

        if (mode === "SAP") {
            data.forEach((e) => {
                items.push({ code: e.data[itemNumber] });
            });
            loadItems(items, setItems);
            return toast.info("Procesando productos...");
        }
        const qty = header.data.findIndex((e) => e.toLowerCase() === "cantidad");
        if (qty === -1) {
            return toast.error("¡Error, Debe indicar la columna cantidad!");
        }

        data.forEach((e) => {
            items.push({ code: e.data[itemNumber], whs, cant: e.data[qty] === "" ? 0 : e.data[qty] });
        });

        loadItems(items, setItems);
        return toast.info("Procesando productos...");
    };

    //Se encarga de hacer el procesamiento de la información en caso de que el archivo sea un XLSX
    caseXlsx = (file) => {
        if (file) {
            const { loadItems, setItems, mode, whs } = this.props;

            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const dataxlsx = XLSX.utils.sheet_to_json(worksheet);

                if (!dataxlsx.length > 0) {
                    return toast.error(`El archivo ${file.name}, no contiene información o está errónea.`);
                }

                const itemNumber = Object.keys(dataxlsx[0]).findIndex(
                    (e) => e.toLowerCase() === "número de artículo" || e.toLowerCase() === "numero de articulo"
                );
                if (itemNumber === -1) {
                    return toast.error("¡Error, Debe indicar la columna número de artículo!");
                }
                const items = [];
                const code = Object.keys(dataxlsx[0])[itemNumber];
                if (mode === "SAP") {
                    dataxlsx.forEach((data) => {
                        items.push({ code: data[code] });
                    });
                    return loadItems(items, setItems);
                }
                const qty = Object.keys(dataxlsx[0]).findIndex((e) => e.toLowerCase() === "cantidad");

                if (qty === -1) {
                    return toast.error("¡Error, Debe indicar la columna QTY!");
                }
                //ser obtiene el nober de la key del objeto en caso e que se consiga alguna
                const qtyKeyName = Object.keys(dataxlsx[0])[qty];
                dataxlsx.forEach((data) => {
                    items.push({ code: data[code], whs, cant: data[qtyKeyName] ? data[qtyKeyName] : 0 });
                });
                return loadItems(items, setItems);
            };
            reader.readAsArrayBuffer(file);
            return toast.info("Procesando productos...");
        }
    };

    handleOnDrop = (data, file) => {
        switch (file.type) {
            case "application/vnd.ms-excel":
                return this.caseCsc(data);
            case "text/csv":
                return this.caseCsc(data);
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                return this.caseXlsx(file);
            default:
                return "No se acepta este formato";
        }
    };

    handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
    };

    handleOnRemoveFile = (data) => {
        const { loadItems, setItems } = this.props;
        return loadItems([], setItems);
        //return this.setState({ errors: [] });
    };

    render() {
        return (
            <div style={{ width: "100%", height: 200 }}>
                <CSVReader
                    onDrop={this.handleOnDrop}
                    onError={this.handleOnError}
                    addRemoveButton
                    removeButtonColor="#659cef"
                    onRemoveFile={this.handleOnRemoveFile}
                    config={{
                        encoding: "UTF-8",
                    }}
                >
                    <span>Arrastra y Suelta el archivo CSV aquí o haz clic para cargarlo.</span>
                </CSVReader>
            </div>
        );
    }
}
