import React, { Component } from 'react';
import { CSVReader } from 'react-papaparse';
import { toast } from 'react-toastify';
import { productsForCode } from '../../../store/directusSdk';

export default class ImportCsvCodes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: []
        };
    }

    handleOnDrop = (data) => {
        this.setState({ errors: [] });

        const { LoadCodes } = this.props;
        const codes = [];

        data.forEach((e) => {
            if (e.data[0].trim().length > 0) {
                codes.push(e.data[0]);
            }
        });

        productsForCode(codes)
            .then((productos) => {
                const errors = [];
                codes.forEach((code, i) => {
                    const comp = productos.find((e) => e.label === code);

                    if (comp == undefined) {
                        errors.push({ code, error: '¡Código No Encontrado!' });
                    }
                });

                LoadCodes(productos);
                this.setState({ errors });

                return toast.info('Procesando Códigos...');
            });

    };

    handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
    };

    handleOnRemoveFile = (data) => {
        return this.setState({ errors: [] });
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div>
                    {errors.length > 0 && (
                        <div className="alert alert-danger">
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                        <th>Error</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {errors.map((e, i) => (
                                        <tr key={i}>
                                            <td>{e.code}</td>
                                            <td>{e.error}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>)}
                </div>
                <div style={{ width: '100%', height: 200 }}>
                    <CSVReader
                        onDrop={this.handleOnDrop}
                        onError={this.handleOnError}
                        addRemoveButton
                        removeButtonColor='#659cef'
                        onRemoveFile={this.handleOnRemoveFile}
                    >
                        <span>Arrastra y Suelta el archivo CSV aquí o haz clic para cargarlo.</span>
                    </CSVReader>
                </div>
            </div>
        );
    }
}

