// react
import React, { useEffect, useState } from 'react';

// third-party
import { connect } from 'react-redux';

// application
import CreatableSelect from 'react-select/creatable';
import { editPrivContact, CreatePrivContact, getSuggestedContacts } from '../../store/directusSdk';
import { ContactChange, ContactsChange } from '../../store/cart';
import { Calendar } from 'primereact/calendar';
import {  Label } from "reactstrap";
import moment from "moment"
function SelectContact(props) {
    const { cart: { contact, contacts, customer },changeDateExpired, state, ContactChange, ContactsChange, setError: setErr } = props;
    const [email, setEmail] = useState(contact ? contact.email : '');
    const [phone1, setPhone] = useState(contact ? contact.phone1 : '');
    const [error, setError] = useState('');
    const [sending, setSending] = useState(false);
    const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    useEffect(() => {
        if (!customer) {
            setEmail('');
            setPhone('');
        }
        if (contact) {
            setEmail(contact.email);
            setPhone(contact.phone1);
        }
        if (contact && !contact.email) {
            setErr('Campo requerido!');
            return setError('Campo requerido!')
        } else {
            setErr(null);
            setError(null)
        }
        if (contact && !emailRegex.test(contact.email)) {
            setErr('Email invalido!');
            return setError('Email invalido!')
        }
    }, [customer, contact]);

    const changeEmail = (e) => {
        setEmail(e.target.value);
        if (emailRegex.test(e.target.value)) {
            setErr(null);
            return setError(null);
        }
    };
    const changePhone = (e) => setPhone(e.target.value);

    const handleChange = (newValue, actionMeta) => {
        if (actionMeta.action !== 'create-option') {
            ContactChange(newValue);
            setEmail(newValue.email);
            setPhone(newValue.phone1);
        }
        if (actionMeta.action === 'create-option') {
            ContactChange(newValue);
            setEmail('');
            setPhone('');
        }
    };

    const save = () => {
        if (!email) {
            setErr('Campo requerido!');
            return setError('Campo requerido!')
        }
        if (!emailRegex.test(email)) {
            setErr('Email invalido!');
            return setError('Email invalido!')
        }
        setSending(true);
        const body = {};

        if (contact.__isNew__) {
            body.name = contact.label;
            body.email = email;
            body.phone1 = phone1;
            body.customer_id = customer.value;
            //crear contact
            return CreatePrivContact(body).then((cont) => {
                getSuggestedContacts(customer.value).then((elements) => {
                    ContactChange(cont);
                    ContactsChange(elements);
                    setSending(false);
                });
            });
        }
        //editar contact
        if (contact.email !== email) {
            body.email = email;
        }
        if (contact.phone1 !== phone1) {
            body.phone1 = phone1;
        }
        return editPrivContact(contact.value, body).then((cont) => {
            getSuggestedContacts(customer.value).then((elements) => {
                ContactChange(cont);
                ContactsChange(elements);
                setSending(false);
            });
        });
    };
    return (
        <div className="container mb-2">
            <div className="row">
                <div className="col-md-4 col-xs-6 mb-2">
                    <CreatableSelect
                        isDisabled={!customer}
                        isSearchable
                        onChange={handleChange}
                        options={contacts}
                        value={contact}
                        defaultValue={contact}
                        placeholder="Seleccione Contacto..."
                    />
                </div>
                <div className="col-md-3 col-xs-6 mb-2">
                    <input className={`form-control ${error && 'is-invalid'}`} value={email} type="text" placeholder="Correo Electrónico..." onChange={changeEmail} disabled={!customer} />
                    {error && <div className="invalid-feedback">{error}</div>}
                    </div>
                <div className="col-md-3 col-xs-6 mb-2">
                    <input className="form-control" type="text" value={phone1} placeholder="Telefono..." onChange={changePhone} disabled={!customer} />
                </div>
                <div className="col-md-2 col-xs-6 mb-2">
                    {contact && ((customer && (email !== contact.email || phone1 !== contact.phone1)) || contact.__isNew__) && <button type="button" className={`btn btn-info ${sending && 'btn-loading'}`} onClick={save} disabled={sending}>{contact && contact.__isNew__ ? 'Crear' : 'Editar'}</button>}
                </div>
                <div className="col-md-5  flex-row mb-3 ">
                <Label for="confdate" className="mr-5">Fecha de Vencimiento:</Label>
                <Calendar id="vencimiento" value={state.expirationDate} onChange={(e) => changeDateExpired(e.value)}  minDate={moment().toDate()} maxDate={moment().add(90, 'days').toDate()} dateFormat="dd/mm/yy" placeholder='DD/MM/AAAA' disabled={!customer} />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
    cart: state.cart,
});

const mapDispatchToProps = {
    ContactChange,
    ContactsChange,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SelectContact);
