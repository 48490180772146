import React from 'react';
import { Badge } from 'reactstrap';

export const statusBadge = (status) => {
    switch (status) {
        case 'new':
            return <Badge color="info">Nuevo</Badge>
        case 'A':
            return <Badge color="success">En Proceso</Badge>
        case 'P':
            return <Badge color="warning">Pausado</Badge>
        case 'C':
            return <Badge color="danger">Cancelado</Badge>
        default:
            return <Badge color="secondary">Finalizado</Badge>
    }
}