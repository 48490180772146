// react
import React from "react";

// third-party
import { connect } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { Check9x7Svg } from "../../svg";
import { login, afterLogin } from "../../store/directusSdk";
import { currentUserChange } from "../../store/currentUser";

function AccountFormLogin(props) {
    const { currentUserChange, LoginCheckout } = props;
    return (
        <React.Fragment>
            {!LoginCheckout && (
                <React.Fragment>
                    <div className="card-header">
                        <img src="images/logos/logocompleto.png" alt="ICAutomatizados" style={{ display: "block", margin: "auto", width: "90%" }} />
                    </div>
                    <div className="card-divider" />
                </React.Fragment>
            )}
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-12 col-xl-12">
                        <Formik
                            initialValues={{ email: "", password: "" }}
                            validationSchema={Yup.object({
                                email: Yup.string().required("Campo requerido.").email("Correo electrónico invalido."),
                                password: Yup.string().required("Campo requerido.").min(6, "minimo 6 cracteres."),
                            })}
                            onSubmit={async (values, { setSubmitting, setFieldValue, setErrors }) => {
                                if (
                                    !/^([a-zA-Z0-9_\.-]+)@icautomatizados.com/.test(values.email) &&
                                    !/^([a-zA-Z0-9_\.-]+)@automation-power.com/.test(values.email) &&
                                    !/^([a-zA-Z0-9_\.-]+)@e-powerelectric.com/.test(values.email)
                                ) {
                                    setFieldValue("password", "", false);
                                    setErrors({ onSubmit: "Usuario o contraseña invalidos." });
                                    setSubmitting(false);
                                    return;
                                }

                                const usr = await login(values.email, values.password).catch(() => {
                                    setFieldValue("password", "", false);
                                    setErrors({ onSubmit: "Usuario o contraseña invalidos." });
                                    setSubmitting(false);
                                    return false;
                                });
                                if (usr.id) {
                                    afterLogin(usr.id).then((user) => currentUserChange(user));
                                }
                            }}
                        >
                            {({ errors, touched, isSubmitting }) => (
                                <Form>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            {errors.onSubmit && (
                                                <div className="alert alert-danger mb-1" align="center">
                                                    <span>{errors.onSubmit}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label htmlFor="login_email">Correo Electronico</label>
                                            <Field
                                                name="email"
                                                id="login_email"
                                                className={`form-control ${errors.email && touched.email && "is-invalid"}`}
                                                placeholder="alguien@dominio.com"
                                            />
                                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label htmlFor="login_password">Contraseña</label>
                                            <Field
                                                type="password"
                                                name="password"
                                                className={`form-control ${errors.password && touched.password && "is-invalid"}`}
                                                id="login_password"
                                                placeholder="Contraseña..."
                                            />
                                            <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                            <small className="form-text text-muted">
                                                <Link to="/cuenta/recuperar-contrasena">¿Olvidó su Contraseña?</Link>
                                            </small>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-check">
                                            <span className="form-check-input input-check">
                                                <span className="input-check__body">
                                                    <input id="login-remember" type="checkbox" className="input-check__input" />
                                                    <span className="input-check__box" />
                                                    <Check9x7Svg className="input-check__icon" />
                                                </span>
                                            </span>
                                            <label className="form-check-label" htmlFor="login-remember">
                                                Recordarme
                                            </label>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary mt-2" disabled={isSubmitting}>
                                        {isSubmitting ? "Entrando..." : "Entrar"}
                                    </button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    currentUserChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountFormLogin);
