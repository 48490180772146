// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
// application
import moment from 'moment';
import { Modal, Table } from 'reactstrap';
import { Cross20Svg } from '../../svg';
import Pagination from '../shared/Pagination';
import { getSurplus, getSurplusById } from '../../store/directusSdk';
import Dropdowns from './Dropdowns';

export default class AccountPageSurplus extends Component {
    constructor(props) {
        super(props);

        this.state = {
            surplus: [],
            page: 1,
            pages: 1,
            start: 0,
            end: 9,
            loading: true,
            loading2: false,
            open: false,
            surpl: null
        };
    }

    componentDidMount() {
        const { currentUser } = this.props;
        const { page } = this.state;
        getSurplus(currentUser.id)
            .then((surplus) => {
                if (surplus.length > 0) {
                    const pages = Math.ceil(surplus.length / 10);
                    const end = page * 10;
                    const start = end - 10;
                    this.setState({ surplus, pages, end, start });
                }
                this.setState({ loading: false });
            });
    }

    handlePageChange = (page) => {
        const end = page * 10;
        const start = end - 10;
        this.setState({ page, end, start });
    };

    searchSurplus = (id) => {
        this.setState({ loading2: true });
        return getSurplusById(id)
            .then((surpl) => {
                this.setState({ surpl, loading2: false, open: true })
            });
    }

    render() {
        const { page, surplus, surpl, pages, end, start, loading, loading2, open } = this.state;

        const surplusList = surplus.map((s) => {
            const date = moment(s.created_on);
            const dateFormated = date.format('DD/MM/YYYY hh:mm a');
            return (
                <tr key={s.id}>
                    <td>{s.id}</td>
                    <td>{s.project_code}</td>
                    <td>{s.company}</td>
                    <td>{dateFormated}</td>
                    <td>{s.created_by}</td>
                    <td>
                        <Dropdowns report={s} searchReport={this.searchSurplus} type="S" />
                    </td>
                </tr >
            );
        });

        return (
            <div className="card" >
                <Helmet>
                    <title>Mis Sobrantes - ICAutomatizados</title>
                </Helmet>

                <div className="card-header">
                    <h5>Historial Sobrantes de Proyecto</h5>
                </div>
                <div className="card-divider" />
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Código</th>
                                    <th>Company</th>
                                    <th>Fecha</th>
                                    <th>Finalizado por</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading && <tr><td colSpan="6" ><div className="div-loading" style={{ height: 300 }} /></td></tr>}
                                {surplusList.slice(start, end)}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-divider" />
                <div className="card-footer">
                    {!loading && surplus.length < 1 && <strong> Actualmente no tiene Sobrantes... </strong>}
                    {pages > 1 && <Pagination current={page} total={pages} onPageChange={this.handlePageChange} siblings={2} />}
                </div>
                {surpl && (
                    <Modal isOpen={open} toggle={() => this.setState({ open: false })} centered size="xl">
                        <div className="quickview">
                            <button className="quickview__close" type="button" onClick={() => this.setState({ open: false })}>
                                <Cross20Svg />
                            </button>

                            {loading2 ? (
                                <div className="div-loading" style={{ height: 300 }} />
                            ) : (
                                <div>
                                    <h3 align="center">{surpl.project_name}</h3>

                                    {surpl.products_ica.length > 0 && (
                                        <div>
                                            <h5>Sobrantes ICA</h5>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Código</th>
                                                        <th>Descripción</th>
                                                        <th>Cantidad</th>
                                                        <th>Bodega</th>
                                                        <th>Item ID</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {surpl.products_ica.map((p, i) => (
                                                        <tr key={i}>
                                                            <td>{p.code}</td>
                                                            <td>{p.name}</td>
                                                            <td>{p.Qty}</td>
                                                            <td>{p.whs}</td>
                                                            <td>{p.lineId}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    )}
                                    {surpl.products_ap.length > 0 && (
                                        <div>
                                            <h5>Sobrantes AP</h5>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Código</th>
                                                        <th>Descripción</th>
                                                        <th>Cantidad</th>
                                                        <th>Bodega</th>
                                                        <th>Item ID</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {surpl.products_ap.map((p, i) => (
                                                        <tr key={i}>
                                                            <td>{p.code}</td>
                                                            <td>{p.name}</td>
                                                            <td>{p.Qty}</td>
                                                            <td>{p.whs}</td>
                                                            <td>{p.lineId}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </Modal>
                )}
            </div>
        );
    }
}
