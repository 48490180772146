// react
import React, { useState } from 'react';
import { InputNumber } from 'primereact/inputnumber';

export default function NewReturn({ max, currentUser, fila, orderId, table, returns }) {
    const [cant, setcant] = useState(0);

    const Return = ({ value }) => {
        setcant(value);
        const movement = { fila, type: 'return', engprojectline_id: orderId, delivered_qty: value, delivered_by: currentUser.id };
        return returns(table, movement);
    }

    return (
        <div className="row">
            <InputNumber value={cant} max={max} min={0} onValueChange={Return} showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-info" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" size={1} />
        </div>
    );
}