// react
import React, { useState, useRef } from 'react';
// third-party
import { Helmet } from 'react-helmet-async';
import AsyncSelect from 'react-select/async';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button, UncontrolledCollapse, Input, Label } from 'reactstrap';
import { uploadInventory, getCustomerByCode } from '../../store/directusSdk';
import PageHeader from '../shared/PageHeader';
import ImportListProductsCSV from './ImportListProductsCSV';
import { toast } from 'react-toastify';

function ParticularInventory() {
    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [company, setCompany] = useState('ICA');
    const [products, setProducts] = useState([]);

    const sdt = useRef(null);

    const promiseOptions = (inputValue) => getCustomerByCode(company, inputValue);

    const clear = () => {
        setProducts([]);
    }

    const companyChange = (e) => {
        clear();
        setCustomer(null);
        setCompany(e.target.value);
    }

    const ImportProducts = () => {
        setLoading(true);
        const inventory = { items: products };
        if (company === 'ICA') {
            inventory.customer_ica = customer.value;
        } else {
            inventory.customer_ap = customer.value;
        }

        return uploadInventory(inventory)
            .then(() => {
                setCustomer(null);
                clear();
                return toast.success('Inventario Cargado Exitosamente!');
            })
            .catch((error) => toast.error(`Error!, ${error.message}`))
            .finally(() => setLoading(false));
    }

    const breadcrumb = [
        { title: 'Home', url: '/' },
        { title: 'Almacén', url: '/almacen' },
        { title: 'Inventario Particular', url: '' },
    ];

    const header = (
        <div>
            <Button color="info" id="toggler" style={{ marginBottom: '1rem' }}>
                <i className="fas fa-file-csv  mr-2" />
                {'CSV'}
            </Button>
            <UncontrolledCollapse toggler="#toggler">
                <div className="mb-2">
                    <ImportListProductsCSV ImportProducts={setProducts} company={company} />
                </div>
            </UncontrolledCollapse>
            <div className="row">
                <h5 className="col-md-6">{'Inventario de Cliente'}</h5>
                <div className="col-md-2 offset-4">
                    <Button color="info" onClick={clear}>
                        <i className="fa fa-eraser mr-2" />
                        {'Limpiar'}
                    </Button>
                </div>
            </div>
        </div>
    );


    const footer = (
        <div>
            <Button color="primary" disabled={!products.length > 0 || loading} onClick={ImportProducts} >
                <i className={`fas ${loading ? 'fa-cog fa-spin' : 'fa-upload'} mr-2`} />
                {'Cargar Inventario'}
            </Button>
        </div>
    );

    return (
        <React.Fragment>
            <Helmet>
                <title> Productos Entregados - Ingeniería y Controles Automatizados</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />
            <div className="container">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-2">
                                <Label for="comp">Compañia</Label>
                                <Input type="select" name="select" id="comp" value={company} onChange={companyChange}>
                                    <option value="ICA" >ICA</option>
                                    <option value="AP" >AP</option>
                                </Input>
                            </div>
                            <div className="col-md-6">
                                <Label for="cust">Cliente</Label>
                                <AsyncSelect
                                    id="cust"
                                    isClearable
                                    cacheOptions
                                    loadOptions={promiseOptions}
                                    noOptionsMessage={() => 'No se Encontraron registros...'}
                                    loadingMessage={() => 'Buscando...'}
                                    placeholder="Buscar Cliente..."
                                    onChange={setCustomer}
                                    value={customer}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="card-body">
                        {customer && (
                            <DataTable
                                ref={sdt}
                                header={header}
                                value={products}
                                footer={footer}
                                className="p-datatable-sm"                                
                                rowHover
                                paginator
                                rows={20}
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                                rowsPerPageOptions={[10, 20, 50, 100, 200]}
                                removableSort
                                emptyMessage="No se han encontrado registros..."                              
                            >
                                <Column field="code" header="Código" sortable filter filterPlaceholder="Todos" filterHeaderClassName="p-inputtext-sm" style={{ width: '20%' }} />
                                <Column field="sku" header="SKU" sortable filter filterPlaceholder="Todos" filterHeaderClassName="p-inputtext-sm" style={{ width: '20%' }} />
                                <Column field="name" header="Descripción" sortable filter filterPlaceholder="Todos" filterHeaderClassName="p-inputtext-sm" style={{ width: '50%' }} />
                                <Column field="stock" header="Stock" sortable filter filterPlaceholder="Mayor a" filterMatchMode="gte" filterHeaderClassName="p-inputtext-sm" style={{ width: '10%', textAlign: 'center' }} />
                            </DataTable>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}

export default ParticularInventory;