// react
import React from 'react';
// third-party
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { logout } from '../../store/directusSdk';
import { currentUserChange } from '../../store/currentUser';

function Account(props) {
    const { currentUser, currentUserChange: changecurrentUser } = props;

    const dispatches = ['Administrator', 'Almacenista ICA', 'Almacenista AP', 'Admin ICA', 'Admin AP','VendedorICA-Logistica'];
    const returs = ['Administrator', 'Ingeniero ICA', 'Ingeniero AP', 'Admin ICA', 'Admin AP', 'VendedorICA-Logistica'];
    const quotesICA = ['Administrator', 'Vendedor ICA', 'Admin ICA', 'VendedorICA-Logistica'];
    const quotesAP = ['Administrator', 'Vendedor AP', 'Admin AP'];
    const quotesEPE = ['Administrator', 'Vendedor AP', 'Admin EPE'];
    const surplus = ['Administrator', 'Ingeniero ICA', 'Ingeniero AP', 'Admin ICA', 'Admin AP', 'Vendedor ICA', 'VendedorICA', 'VendedorICA-Logistica'];
    const puchaseReports = ['Administrator', 'Admin ICA', 'Compas ICA', 'VendedorICA-Logistica'];

    const sendLogout = (e) => {
        e.preventDefault();
        const result = logout();
        result.then(() => {
            changecurrentUser(null);
            return <Redirect to="/login" />
        });
    }

    return (
        <div>
            <div className="account-menu__divider" />
            <Link to="/account/dashboard" className="account-menu__user">
                <div className="account-menu__user-avatar">
                    <img src="images/avatars/avatar-3.jpg" alt="" />
                </div>
                <div className="account-menu__user-info">
                    <div className="account-menu__user-name">{`${currentUser.first_name} ${currentUser.last_name}`}</div>
                    <div className="account-menu__user-email">{currentUser.email.substr(0, 20) + '...'}</div>
                </div>
            </Link>
            <div className="account-menu__divider" />
            <ul className="account-menu__links">
                <li><Link to="/account/dashboard">Mi Perfil</Link></li>
                {quotesICA.includes(currentUser && currentUser.role.name) && <li><Link to="/account/cotizaciones">Mis Cotizaciones ICA</Link></li>}
                {quotesAP.includes(currentUser && currentUser.role.name) && <li><Link to="/account/cotizaciones-ap">Mis Cotizaciones AP</Link></li>}
                {quotesEPE.includes(currentUser && currentUser.role.name) && <li><Link to="/account/cotizaciones-epe">Mis Cotizaciones EPE</Link></li>}
                {dispatches.includes(currentUser && currentUser.role.name) && <li><Link to="/account/envios">Mis Entregas</Link></li>}
                {returs.includes(currentUser && currentUser.role.name) && <li><Link to="/account/devoluciones">Mis Devoluciones</Link></li>}
                {puchaseReports.includes(currentUser && currentUser.role.name) && <li><Link to="/account/compras">Mis Compras</Link></li>}
                {surplus.includes(currentUser && currentUser.role.name) && <li><Link to="/account/sobrantes">Mis Sobrantes</Link></li>}
                <li><Link to="/account/password">Cambiar Contraseña</Link></li>
            </ul>
            <div className="account-menu__divider" />
            <ul className="account-menu__links">
                <li><Link to="/" onClick={sendLogout}>Salir</Link></li>
            </ul>
        </div>
    );
}
const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
    cart: state.cart,
});

const mapDispatchToProps = {
    currentUserChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
