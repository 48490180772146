// react
import React, { useState } from "react";
// third-party

import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import PageHeader from "../shared/PageHeader";
import ImportCsvCruce from "./ImportCsvCruce";

export default function CruceContado() {
    const [loading, setLoading] = useState(false);
    const [itemsEntregado, setItemsEntregado] = useState([]);
    const [itemsContado, setItemsContado] = useState([]);

    const loadItems = (items, setItems) => setItems(items);

    const saveAsCSVFile = (buffer, fileName) => {
        import("file-saver").then((FileSaver) => {
            let _TYPE = "text/csv;charset=utf-8;";
            let _EXTENSION = ".csv";
            const data = new Blob([buffer], {
                type: _TYPE,
            });
            FileSaver.saveAs(data, fileName + _EXTENSION);
        });
    };

    const exportCSV = async (data) => {
        let csv = "";
        if (!Array.isArray(data) || !data.every((p) => typeof p === "object" && p !== null)) {
            return;
        }
        const heading = Object.keys(data[0]).join(";");
        const body = data.map((j) => Object.values(j).join(";")).join("\n");
        csv += `${heading}\n${body} `;

        saveAsCSVFile(csv, "Resultado Cruce Contado vs Entregado");
    };

    const generarResult = () => {
        setLoading(true);
        const items = [];
        const noEncontrados = [];
        const encontrados = [];

        itemsContado.forEach((item) => {
            const i = itemsEntregado.findIndex((itm) => itm.code == item.code);

            if (i !== -1) {
                items.push({
                    Codigo: item.code,
                    "conteo 2 anterior": item.cant,
                    "conteo 2": parseInt(itemsEntregado[i].cant) + parseInt(item.cant),
                    "ubicaciones 2": item.ubicacion,
                });
                encontrados.push(item.code);
            } else {
                items.push({
                    Codigo: item.code,
                    "conteo 2 anterior": item.cant,
                    "conteo 2": item.cant,
                    "ubicaciones 2": item.ubicacion,
                });
                noEncontrados.push(item.code);
            }
        });
        toast.success("¡Archivo Generado con Exito!.");
        exportCSV(items);
        return setLoading(false);
    };

    const breadcrumb = [
        { title: "Home", url: "/" },
        { title: "Almacén", url: "/almacen" },
        { title: "Cruce con Contado", url: "" },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>Cruce con Contado - Ingeniería y Controles Automatizados</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />
            <div className="container">
                <div className="card">
                    <div className="card-body">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6">
                                    <h5 className="mt-3">Cargar CSV Contado</h5>
                                    <ImportCsvCruce loadItems={loadItems} setItems={setItemsContado} mode="Contado" />
                                </div>
                                <div className="col-6">
                                    <h5 className="mt-3">Cargar CSV Entregado</h5>
                                    <ImportCsvCruce loadItems={loadItems} setItems={setItemsEntregado} mode="Entregado" />
                                </div>
                            </div>
                        </div>
                        <div className="text-center ">
                            <button
                                type="button"
                                className="btn btn-primary mr-3 mt-4"
                                onClick={() => generarResult()}
                                disabled={loading || !itemsContado.length > 0 || !itemsEntregado.length > 0}
                            >
                                <i className={`${loading ? "fas fa-cog fa-spin" : "fas fa-cloud-upload-alt"} mr-2`} />
                                {`${loading ? "Calculando" : "Calcular"}`} Resultado Cruce Contado vs Entregado
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
