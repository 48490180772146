// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { InputSwitch } from 'primereact/inputswitch';
import { InputNumber } from 'primereact/inputnumber';
// application
import { CreateItem, getService } from '../../store/directusSdk';
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import PageHeader from '../shared/PageHeader';
import { cartRemoveItem, ChangePriceItemAP, cartUpdateQuantities, changeComments, cartAddItem, cartRemoveAllItems, TextItemsChange, incItbmsAP, ChangeQuantityAP } from '../../store/cartEPE';
import { Cross12Svg } from '../../svg';
import SelectContact from './SelectContact';

class CartEPE extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quantities: [],
            sending: false,
            serv: null,
            incItbms: true,
            cant: 1,
            error: '',
        };
    }


    setError = (error) => {
        this.setState({ error });
    }

    HandleName = (e) => {
        const { serv } = this.state;
        serv.product.name = e.target.value;
        return this.setState({ serv });
    };

    HandleComments = (e) => {
        const { changeComments } = this.props;
        return changeComments(e.target.value);
    };

    changePrice = (value) => {
        const { serv } = this.state;

        serv.price = value;
        serv.product.price = value;
        this.setState({ serv });
    };

    promiseOptions = (inputValue) => getService(inputValue);

    GeneratePDF() {
        const { cart, cartRemoveAllItems } = this.props;

        this.setState({ sending: true });
        const quotation = {
            subtotal: cart.subtotal,
            shipping: cart.shipping,
            comments: cart.comments,
            itbms: cart.itbms,
            total: cart.total,
            validity: moment().add(30, 'days').format('YYYY-MM-DD'),
            customer_id: cart.customer ? cart.customer.value : null,
            contact_id: cart.contact ? cart.contact.value : null,
        };

        const strQuotation = sessionStorage.getItem('strQuotation');
        const quotationStr = JSON.stringify(quotation);
        quotation.cart = JSON.stringify(cart);

        if (strQuotation !== quotationStr) {
            CreateItem('epe_quotations', quotation)
                .then(() => {
                    toast.success('Cotización creada con exito!');
                    this.setState({ sending: false });
                    cartRemoveAllItems();
                    sessionStorage.setItem('strQuotation', quotationStr);
                });
        } else {
            this.setState({ sending: false });
            toast.success('Cotización ya creada!');
        }
    }

    renderItems() {
        const { cartRemoveItem, cart: { items }, TextItemsChange, incItbmsAP, ChangeQuantityAP, ChangePriceItemAP } = this.props;

        return items.map((item, i) => {
            const removeButton = (
                <AsyncAction
                    action={() => cartRemoveItem(item.id)}
                    render={({ run, loading }) => {
                        const classes = classNames('btn btn-light btn-sm btn-svg-icon', {
                            'btn-loading': loading,
                        });

                        return (
                            <button type="button" onClick={run} className={classes}>
                                <Cross12Svg />
                            </button>
                        );
                    }}
                />
            );

            return (
                <React.Fragment key={item.id}>
                    <tr className="cart-table__row" >
                        <td className="cart-table__column cart-table__column--image">
                            <img src={item.product.images[0]} alt={item.product.code} />
                        </td>
                        <td className="cart-table__column cart-table__column--product">
                            {item.product.name}
                            <br />
                            <span style={{ fontSize: '0.8em' }}>{`Código de Producto: ${item.product.code}`}</span>
                        </td>
                        <td className="cart-table__column cart-table__column--price" data-title="Precio">
                            {item.product.isService ? (
                                <InputNumber inputId="currency-us" value={item.price} maxFracionDigits={2} onValueChange={({ value }) => ChangePriceItemAP(i, value)} mode="currency" currency="USD" locale="en-US" />
                            ) : <Currency value={item.price} />}
                        </td>
                        <td className="cart-table__column cart-table__column--price" data-title="ITBMS">
                            <InputSwitch checked={item.incItbms} onChange={({ target }) => incItbmsAP(i, target.value)} />
                        </td>
                        <td className="cart-table__column cart-table__column--quantity" data-title="Cantidad">
                            <InputNumber
                                onChange={({ value }) => ChangeQuantityAP(i, value)}
                                value={item.quantity}
                                min={1}
                                showButtons
                            />
                        </td>
                        <td className="cart-table__column cart-table__column--total" data-title="Total">
                            <Currency value={item.total} />
                        </td>
                        <td className="cart-table__column cart-table__column--remove">
                            {removeButton}
                        </td>
                    </tr>
                    <tr className="cart-table__row" >
                        <td colSpan="7" className="cart-table__column">
                            <textarea className="form-control" rows="1" placeholder="Comentarios..." value={items[i].comments} onChange={(e) => TextItemsChange(i, e.target.value)} />
                        </td>
                    </tr>
                </React.Fragment>
            );
        });
    }

    renderTotals() {
        const { cart } = this.props;

        return (
            <React.Fragment>
                <thead className="cart__totals-header">
                    <tr>
                        <th>Subtotal</th>
                        <td><Currency value={cart.subtotal} /></td>
                    </tr>
                </thead>
                <tbody className="cart__totals-body">
                    <tr>
                        <th>ITBMS</th>
                        <td>
                            <Currency value={cart.itbms} />
                        </td>
                    </tr>
                </tbody>
            </React.Fragment>
        );
    }

    renderCart() {
        const { cartAddItem, cart } = this.props;
        const { sending, serv, cant, error, incItbms } = this.state;

        return (
            <div className="cart block" id="cont">
                <div className="container">
                    <table className="cart__table cart-table">
                        <thead className="cart-table__head">
                            <tr className="cart-table__row">
                                <th className="cart-table__column cart-table__column--image">Imagen</th>
                                <th className="cart-table__column cart-table__column--product">Producto</th>
                                <th className="cart-table__column cart-table__column--price">Precio</th>
                                <th className="cart-table__column cart-table__column--price">ITBMS</th>
                                <th className="cart-table__column cart-table__column--quantity">Cantidad</th>
                                <th className="cart-table__column cart-table__column--total">Total</th>
                                <th className="cart-table__column cart-table__column--remove" aria-label="Remove" />
                            </tr>
                        </thead>
                        <tbody className="cart-table__body">
                            {this.renderItems()}
                            <tr className="cart-table__row">
                                <td colSpan="2" className="cart-table__column cart-table__column--product">
                                    <AsyncSelect
                                        loadOptions={this.promiseOptions}
                                        isClearable
                                        noOptionsMessage={() => 'No se Encontró ningun producto.'}
                                        loadingMessage={() => 'Buscando...'}
                                        placeholder="Buscar servicio..."
                                        onChange={(e) => this.setState({ serv: e })}
                                        value={serv}
                                        className="mb-2"
                                    />
                                    <input type="text" className="form-control" value={serv ? serv.product.name : ''} disabled={!serv} onChange={this.HandleName} />
                                </td>
                                <td className="cart-table__column cart-table__column--price" data-title="Precio">
                                    {serv ? (
                                        <InputNumber value={serv.price} maxFracionDigits={2} onValueChange={({ value }) => this.changePrice(value)} mode="currency" currency="USD" locale="en-US" />
                                    ) : <Currency value={0} />}
                                </td>
                                <td className="cart-table__column cart-table__column--price" data-title="ITBMS">
                                    <InputSwitch checked={incItbms} onChange={({ target }) => this.setState({ incItbms: target.value })} />
                                </td>
                                <td className="cart-table__column cart-table__column--quantity" data-title="Cantidad">
                                    <InputNumber
                                        onChange={({ value }) => this.setState({ cant: value })}
                                        value={cant}
                                        min={1}
                                        showButtons
                                    />
                                </td>
                                <td className="cart-table__column cart-table__column--total" data-title="Total">
                                    <Currency value={serv ? (cant * serv.price).toFixed(2) : 0.00} />
                                </td>
                                <td className="cart-table__column ml-4">
                                    <AsyncAction
                                        action={() => cartAddItem(serv.product, incItbms, cant).then(() => this.setState({ serv: null, cant: 1, incItbms: true }))}
                                        render={({ run, loading }) => (
                                            <button
                                                type="button"
                                                onClick={run}
                                                disabled={!serv || !cant || !serv.price}
                                                className={classNames('btn btn-primary btn-lg', {
                                                    'btn-loading': loading,
                                                })}
                                            >
                                                <i className="fa fa-cart-plus" aria-hidden="true" />
                                            </button>
                                        )}
                                    />
                                </td>
                            </tr>
                            <tr className="cart-table__row">
                                <td colSpan="7" className="cart-table__column cart-table__column--product">
                                    <br />
                                    <textarea className="form-control" maxLength={254} onChange={this.HandleComments} name="comentarios" rows="3" placeholder="Observaciones..." value={cart.comments} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="cart__actions">
                        <form className="cart__coupon-form" />
                        <div className="cart__buttons">
                            <button type="button" className={`btn btn-info ${sending && 'btn-loading'}`} onClick={() => this.GeneratePDF()} disabled={sending || !cart.customer || error || (cart.contact && cart.contact.isNew) || cart.items.length < 1} >Generar Cotización</button>
                        </div>
                    </div>

                    <div className="row justify-content-end pt-md-5 pt-4">
                        <div className="col-12 col-md-4 col-lg-6 col-xl-7" />
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title">Total Carrito</h3>
                                    <table className="cart__totals">
                                        {this.renderTotals()}
                                        <tfoot className="cart__totals-footer">
                                            <tr>
                                                <th>Total</th>
                                                <td><Currency value={cart.total} /></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const breadcrumb = [
            { title: 'Home', url: '/' },
            { title: 'Cotización EPE', url: '' },
        ];
        return (
            <React.Fragment>
                <Helmet>
                    <title>Cotizacion EPE - ICAutomatizados S.A</title>
                </Helmet>

                <PageHeader breadcrumb={breadcrumb} />
                <SelectContact setError={this.setError} />
                {this.renderCart()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cartEPE,
});

const mapDispatchToProps = {
    cartRemoveItem,
    cartUpdateQuantities,
    cartAddItem,
    changeComments,
    cartRemoveAllItems,
    TextItemsChange,
    incItbmsAP,
    ChangeQuantityAP,
    ChangePriceItemAP,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartEPE);
