import { toast } from "react-toastify";
import { CSVReader } from "react-papaparse";
import React, { Component, Fragment } from "react";
import { getMaterialsForCode } from "../../store/directusSdk";
import * as XLSX from "xlsx/xlsx.mjs";

export default class ImportListProductsCSV extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            header: [],
        };
    }

    //Se encarga de hacer el procesamiento de la información en caso de que el archivo sea un CSV
    caseCsv = (data) => {
        console.log(data);

        const header = data.shift();
        this.setState({ errors: [], header, data });
        console.log(header);
        const code = header.data.findIndex((e) =>
            [("codigo", "code", "numerodearticulos(code)", "nmerodeartculoscode")].includes(
                e
                    .toLocaleLowerCase()
                    .trim()
                    .replace(/[^\w\s]|(\s+)/gi, "")
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
            )
        );
        if (code === -1) {
            return toast.error("¡Error, lista de productos sin Código de Producto!");
        }
        const quantity = header.data.findIndex((e) =>
            ["Cantidad", "cantidad", "stock", "existencia", "qty"].includes(
                e
                    .toLocaleLowerCase()
                    .trim()
                    .replace(/[^\w\s]|(\s+)/gi, "")
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
            )
        );
        if (quantity === -1) {
            return toast.error("¡Error, lista de productos sin Cantidad!");
        }

        const { ImportProducts, company, setLoading } = this.props;
        const codes = [];
        const products = [];
        const prods = [];
        const errors = [];

        data.forEach((e, i) => {
            const stock = parseInt(e.data[quantity]);
            if (stock > 0 && e.data[0] && e.data[0].length > 0) {
                if (!codes.includes(e.data[0])) {
                    codes.push(e.data[0]);
                    products.push({ stock, sku: e.data[1], code: e.data[0] });
                } else {
                    const ind = codes.findIndex((code) => code === e.data[0]);
                    products[ind].stock += stock;
                }
            } else {
                errors.push({ stock, code: e.data[0], sku: e.data[1], line: i + 1 });
            }
        });

        if (codes.length > 0) {
            return getMaterialsForCode("products", codes).then((productos) => {
                products.forEach((p) => {
                    const comp = productos.find((e) => e.code === p.code);
                    if (comp) {
                        prods.push({ ...comp, stock: p.stock, sku: comp.code_manufacture, csv: true });
                    } else {
                        errors.push(p);
                    }
                });

                ImportProducts(prods.sort((a, b) => b.id - a.id));

                if (errors.length > 0) {
                    this.setState({ errors });
                }
                return toast.info("Procesando productos...");
            });
        }
    };

    //Se encarga de hacer el procesamiento de la información en caso de que el archivo sea un XLSX
    caseXlsx = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const dataxlsx = XLSX.utils.sheet_to_json(worksheet);
 
                if (!dataxlsx.length > 0) {
                    return toast.error(`El archivo ${file.name}, no contiene información o está errónea.`);
                }

                const code = Object.keys(dataxlsx[0]).findIndex((e) =>
                    [("codigo", "code", "numerodearticulos(code)")].includes(
                        e
                            .toLocaleLowerCase()
                            .trim()
                            .replace(/\s+/g, "")
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                    )
                );
                if (code === -1) {
                    return toast.error("¡Error, Debe indicar la columna Codigo!");
                }

                const qty = Object.keys(dataxlsx[0]).findIndex((e) =>
                    ["Cantidad", "cantidad", "stock", "existencia", "qty"].includes(
                        e
                            .toLocaleLowerCase()
                            .trim()
                            .replace(/\s+/g, "")
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                    )
                );
                if (qty === -1) {
                    return toast.error("¡Error, Debe indicar la columna Cantidad!");
                }

                //ser obtiene el nober de la key del objeto en caso e que se consiga alguna
                const codeKeyName = Object.keys(dataxlsx[0])[code];
                const qtyKeyName = Object.keys(dataxlsx[0])[qty];

                const { ImportProducts, company, setLoading } = this.props;
                const products = [];
                const productsCodes = [];
                const productsSap = [];
                const errors = [];

                //se recorren los productos dentro de el csv
                dataxlsx.forEach((data, i) => {
                    // Se excluyen todas aquellas líneas que su cantidad sea menos a 1
                    if (data[qtyKeyName] > 0) {
                        if (!productsCodes.includes(data[codeKeyName])) {
                            productsCodes.push(data[codeKeyName]);
                            products.push({ ...data });
                        } else {
                            const i = products.findIndex((code) => code[codeKeyName] === data[codeKeyName]);
                            products[i][qtyKeyName] += data[qtyKeyName];
                        }
                    } else {
                        errors.push({ line: i, code: data[codeKeyName], stock: data[qtyKeyName] });
                    }
                });

                setLoading(true);
                getMaterialsForCode("products", productsCodes)
                    .then((productos) => {
                        products.forEach((p, i) => {
                            const comp = productos.find((e) => e.code === p[codeKeyName]);
                            if (comp) {
                                productsSap.push({ ...comp, stock: p[qtyKeyName], sku: comp.code_manufacture, csv: true });
                            } else {
                                errors.push({ line: i, code: p[codeKeyName], stock: p[qtyKeyName] });
                            }
                        });
                        ImportProducts(productsSap.sort((a, b) => b.id - a.id));

                        toast.success("Productos procesados...");
                    })
                    .finally(() => setLoading(false));

                this.setState({ errors });
            };
            reader.readAsArrayBuffer(file);
            toast.info("Procesando productos...");
        }
    };

    handleOnDrop = (data, file) => {
        switch (file.type) {
            case "application/vnd.ms-excel":
                return this.caseCsv(data);
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                return this.caseXlsx(file);
            case "text/csv":
                return this.caseCsv(data);
            default:
                return toast.error("Formato No Aceptado");
        }
    };

    handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
    };

    handleOnRemoveFile = (data) => {
        const { updateOnClean, setLoading, ImportProducts, Type } = this.props;
        if (Type !== "A") updateOnClean();
        else ImportProducts([]);
        this.setState({ errors: [] });
    };

    render() {
        const { errors, header, data } = this.state;

        return (
            <Fragment>
                {errors.length > 0 && (
                    <div className="alert alert-danger">
                        <h5>Listado de productos no encontrados o con algún error.</h5>
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th width="20%">Linea</th>
                                    <th width="40%">Código</th>
                                    <th width="20%">Stock</th>
                                </tr>
                            </thead>
                            <tbody>
                                {errors.map((e, i) => (
                                    <tr key={i}>
                                        <td>{e.line}</td>
                                        <td>{e.code}</td>
                                        <td>{e.stock}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                <div style={{ width: "100%", height: 200 }}>
                    <CSVReader
                        config={{
                            inputAccept: ".csv,.xlsx",
                        }}
                        onDrop={this.handleOnDrop}
                        onError={this.handleOnError}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={this.handleOnRemoveFile}
                    >
                        <span>Arrastra y Suelta el archivo CSV aquí o haz clic para cargarlo.</span>
                    </CSVReader>
                </div>
            </Fragment>
        );
    }
}
