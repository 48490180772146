import { toast } from "react-toastify";
import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { Button, UncontrolledCollapse } from "reactstrap";
import { ExcelFormatSearch } from "../../../../shared/utilities";
import GetProductsCodesCSV from "../../../GetProductsCodesCSV";
import InventoryClientModalSearch from "./InventoryClientModalSearch";
import { searchProductsAllInventory } from "../../../../../store/directusSdk";

function InventoryClientTop({ setErrors, exportExcel, products, setProducts, company, isAdmin, setCodes, codes, getProductsSearch }) {
    const [openModalSearch, setOpenModalSearch] = useState(false);

    const promiseOptions = (inputValue) => searchProductsAllInventory(inputValue);

    const resetTop = () => {
        setProducts([]);
        setCodes([]);
        setOpenModalSearch(false);
        setErrors([]);
    };

    return (
        <div className="m-4">
            <UncontrolledCollapse toggler="#toggler">
                <div className="mb-2 mb-3">
                    <GetProductsCodesCSV
                        resetTop={resetTop}
                        LoadCodes={(codes) => {
                            setCodes(codes);
                            if (codes.length) getProductsSearch(codes);
                        }}
                    />
                </div>
            </UncontrolledCollapse>
            <div className="row">
                <div className="col-md-10">
                    <Button color="info" id="toggler" className="mb-3">
                        <i className="fas fa-file-upload  mr-2" />
                        Importar
                    </Button>
                    <Button color="info" className="mb-3 ml-2" onClick={ExcelFormatSearch}>
                        <i className="fas fa-file-download  mr-2" /> Formato
                    </Button>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-10">
                    <AsyncSelect
                        isClearable
                        cacheOptions
                        loadOptions={promiseOptions}
                        noOptionsMessage={() => "No se Encontraron registros..."}
                        loadingMessage={() => "Buscando..."}
                        placeholder="Códigos de productos..."
                        value={codes}
                        onChange={(e) => {
                            setCodes(e);
                            if (!e.length) {
                                setProducts([]);
                                setErrors([]);
                            }
                        }}
                        className="ms-md-auto"
                        isMulti
                    />
                </div>
                <div className="col-md-2">
                    <Button color="primary" onClick={() => getProductsSearch()} disabled={!codes.length}>
                        <i className="pi pi-search mr-2" />
                        Buscar
                    </Button>
                </div>
            </div>
            {openModalSearch && (
                <InventoryClientModalSearch
                    exportExcel={exportExcel}
                    resetTop={resetTop}
                    update={getProductsSearch}
                    company={company}
                    setItems={setProducts}
                    open={openModalSearch}
                    setOpen={setOpenModalSearch}
                    isAdmin={isAdmin}
                    items={products}
                    type={"S"}
                />
            )}
        </div>
    );
}

export default InventoryClientTop;
