import React, { useEffect, useState, useRef } from "react";
// third-party
import { Helmet } from "react-helmet-async";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import { getEngprojectsFaltantes, SearhProject, GetProject, CreateItem } from "../../../store/directusSdk";
import PageHeader from "../../shared/PageHeader";
import "./tableStyle.css";

export default function Shopping() {
    const [missingProjectsICA, setMissingProjectsICA] = useState([]);
    const [search, setSearch] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [project, setProject] = useState(null);
    const [open, setOpen] = useState(false);
    const GetEngprojectsFaltantes = (collection, SetProducts) =>
        getEngprojectsFaltantes(collection).then((products) => SetProducts(Object.values(products)));
    const promiseOptions = (inputValue) => SearhProject(inputValue);
    const sdt = useRef();
    const dt = useRef();

    useEffect(() => {
        GetEngprojectsFaltantes("engprojectlinesica", setMissingProjectsICA);
    }, []);

    const getProject = () => {
        if (search) {
            setLoading(true);
            setOpen(true);
            return GetProject(search.value)
                .then((project) => {
                    setProject(project);
                })
                .catch((error) => console.log(error))
                .finally(() => setLoading(false));
        }
    };

    const exportCSV = () => sdt.current.exportCSV();
    const exportCSVTable = () => dt.current.exportCSV();

    const print = () => {
        setLoading2(true);
        return CreateItem("purchase_reports", {
            project_code: project.code,
            project_name: project.name,
            company: "ICA",
            mark_on: moment(project.marcadoFecha).format("DD/MM/YYYY hh:mm a"),
            mark_by: project.marcadoPor,
            items: project.products,
        })
            .then((report) => {
                toast.success("¡Reporte de Compra Genenrado con Exito!");
                return window.open(`${process.env.REACT_APP_API_URL}purchase_report/${report.data.id}.pdf`, "_Blank");
            })
            .catch((error) => {
                console.log(error);
                toast.error("¡Error!, " + error.message);
            })
            .finally(() => setLoading2(false));
    };

    const RenderTableShopping = () => {
        if (project) {
            if (project.products && project.products.length > 0) {
                const header = (
                    <div className="row">
                        <div className="col-md-4">
                            <Button color="success" onClick={print}>
                                <i className="fas fa-print mr-2" />
                                Generar Infome de Compra
                            </Button>
                        </div>
                        <div className="col-md-2 offset-md-6">
                            <Button color="info" onClick={exportCSV}>
                                <i className="fas fa-file-export mr-2" />
                                Exportar
                            </Button>
                        </div>
                        <div className="col-md-12">
                            <dl className="row mt-2 mb-0">
                                <dt className="col-sm-2">Código:</dt>
                                <dd className="col-sm-4" style={{ textAlign: "right" }}>
                                    {project.code}
                                </dd>
                                <dt className="col-sm-2">Proyecto:</dt>
                                <dd className="col-sm-4" style={{ textAlign: "right" }}>
                                    {project.name}
                                </dd>
                                <dt className="col-sm-2">Fecha Entrega:</dt>
                                <dd className="col-sm-4" style={{ textAlign: "right" }}>
                                    {moment(project.fechaEntrega).format("DD-MM-YYYY")}
                                </dd>
                                <dt className="col-sm-2">Encargado:</dt>
                                <dd className="col-sm-4" style={{ textAlign: "right" }}>
                                    {project.encargado}
                                </dd>
                                <dt className="col-sm-2">Marcado por:</dt>
                                <dd className="col-sm-4" style={{ textAlign: "right" }}>
                                    {project.marcadoPor}
                                </dd>
                                <dt className="col-sm-2">Marcado Fecha:</dt>
                                <dd className="col-sm-4" style={{ textAlign: "right" }}>
                                    {moment(project.marcadoFecha).format("DD-MM-YYYY hh:mm a")}
                                </dd>
                            </dl>
                        </div>
                    </div>
                );

                return (
                    <div className="datatable-rowexpansion-demo">
                        <DataTable
                            ref={sdt}
                            header={header}
                            dataKey="id"
                            value={project.products}
                            paginator
                            rows={10}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            removableSort
                            emptyMessage="No se han encontrado registros..."
                            className="p-datatable-customers"
                            rowHover
                            exportFilename={`Faltante Proyecto ${project.code}  ${moment().format("DD-MM-YYYY hh-mm a")}`}
                        >
                            <Column field="code" header="Código" sortable filter style={{ width: "13%" }} filterPlaceholder="Todos" />
                            <Column
                                field="bod01"
                                header="Stock 01"
                                sortable
                                filter
                                style={{ width: "10%", textAlign: "center" }}
                                filterType="number"
                                filterMatchMode="gte"
                                filterPlaceholder="Mayor a"
                            />
                            <Column
                                field="bod05"
                                header="Stock 05"
                                sortable
                                filter
                                style={{ width: "10%", textAlign: "center" }}
                                filterType="number"
                                filterMatchMode="gte"
                                filterPlaceholder="Mayor a"
                            />
                            <Column
                                field="IsCommited"
                                header="Comprometido"
                                sortable
                                filter
                                style={{ width: "12%", textAlign: "center" }}
                                filterType="number"
                                filterMatchMode="gte"
                                filterPlaceholder="Mayor a"
                            />
                            <Column
                                field="sap_onorder"
                                header="Pedidos por Llegar"
                                sortable
                                filter
                                style={{ width: "11%", textAlign: "center" }}
                                filterType="number"
                                filterMatchMode="gte"
                                filterPlaceholder="Mayor a"
                            />
                            <Column
                                field="sap_available"
                                header="Disponible SAP"
                                sortable
                                filter
                                style={{ width: "11%", textAlign: "center" }}
                                filterType="number"
                                filterMatchMode="gte"
                                filterPlaceholder="Mayor a"
                            />
                            <Column
                                field="available"
                                header="Disponible Físico"
                                sortable
                                filter
                                style={{ width: "11%", textAlign: "center" }}
                                filterType="number"
                                filterMatchMode="gte"
                                filterPlaceholder="Mayor a"
                            />
                            <Column
                                field="faltEntregar"
                                header="Por Entregar en Este"
                                sortable
                                filter
                                style={{ width: "11%", textAlign: "center" }}
                                filterType="number"
                                filterMatchMode="gte"
                                filterPlaceholder="Mayor a"
                            />
                            <Column
                                field="faltComprar"
                                header="Por Comprar"
                                sortable
                                filter
                                style={{ width: "11%", textAlign: "center" }}
                                filterType="number"
                                filterMatchMode="gte"
                                filterPlaceholder="Mayor a"
                            />
                            <Column
                                field="priority"
                                header="Prioridad"
                                sortable
                                filter
                                style={{ width: "8%", textAlign: "center" }}
                                filterPlaceholder="P1, P2..."
                            />
                        </DataTable>
                    </div>
                );
            }
            return (
                <div className="mt-5 mb-5">
                    <p align="center">{`EL Proyecto ${project.code}, no se encuentra marcado para su Compra.`}</p>
                </div>
            );
        }
        return (
            <div className="mt-5 mb-5">
                <p align="center">Proyecto No Encontrado.</p>
            </div>
        );
    };

    const breadcrumb = [
        { title: "Home", url: "/" },
        { title: "Compras", url: "/compras" },
        { title: "Compras ICA", url: "/compras-ica" },

    ];

    const header = (
        <div className="row">
            <div className="col-md-3">
                <h5 className="mt-2">{`Proyectos de Ingeneria ICA`}</h5>
            </div>
            <div className="col-md-5"></div>
            <div className="col-md-4">
                <div className="row">
                    <AsyncSelect
                        isClearable
                        cacheOptions
                        loadOptions={promiseOptions}
                        noOptionsMessage={() => "No se Encontró registros..."}
                        loadingMessage={() => "Buscando..."}
                        placeholder="Buscar Proyecto PRY-XXXX..."
                        onChange={setSearch}
                        value={search}
                        className="col-md-8"
                    />
                    <div className="col-md-4">
                        <button className="btn btn-success" disabled={!search || loading} onClick={getProject}>
                            <i className="fa fa-search mr-2" />
                            Buscar
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="ml-1">
                    <Button className color="info" onClick={exportCSVTable}>
                        <i className="fas fa-file-export mr-2" />
                        Exportar
                    </Button>
                </div>
            </div>
        </div>
    );

    return (
        <React.Fragment>
            <Helmet>
                <title>Faltantes - Ingeniería y Controles Automatizados</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />
            <div className="container-fluid">
                <div className="card">
                    <div className="card-body">
                        <div className="datatable-rowexpansion-demo">
                            <DataTable
                                ref={dt}
                                value={missingProjectsICA}
                                header={header}
                                paginator
                                rows={10}
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                removableSort
                                emptyMessage="No se han encontrado registros..."
                                className="p-datatable-customers"
                                rowHover
                                exportFilename={`Faltante proyectos`}
                                onValueChange={(e) => sessionStorage.setItem(`MissingEng`, JSON.stringify(e))}
                            >
                                <Column
                                    field="code"
                                    header="Código"
                                    sortable
                                    filter
                                    style={{ width: "13%" }}
                                    filterPlaceholder="Todos"
                                    filterHeaderClassName="p-inputtext-sm"
                                />
                                <Column
                                    field="bod01"
                                    header="Stock 01"
                                    sortable
                                    filter
                                    style={{ width: "9%", textAlign: "center" }}
                                    filterType="number"
                                    filterMatchMode="gte"
                                    filterPlaceholder="Mayor a"
                                    filterHeaderClassName="p-inputtext-sm"
                                />
                                <Column
                                    field="bod05"
                                    header="Stock 05"
                                    sortable
                                    filter
                                    style={{ width: "9%", textAlign: "center" }}
                                    filterType="number"
                                    filterMatchMode="gte"
                                    filterPlaceholder="Mayor a"
                                    filterHeaderClassName="p-inputtext-sm"
                                />
                                <Column
                                    field="IsCommited"
                                    header="Comprometido"
                                    sortable
                                    filter
                                    style={{ width: "10%", textAlign: "center" }}
                                    filterType="number"
                                    filterMatchMode="gte"
                                    filterPlaceholder="Mayor a"
                                    filterHeaderClassName="p-inputtext-sm"
                                />
                                <Column
                                    field="sap_onorder"
                                    header="Pedidos por Llegar"
                                    sortable
                                    filter
                                    style={{ width: "10%", textAlign: "center" }}
                                    filterType="number"
                                    filterMatchMode="gte"
                                    filterPlaceholder="Mayor a"
                                    filterHeaderClassName="p-inputtext-sm"
                                />
                                <Column
                                    field="sap_available"
                                    header="Disponible SAP"
                                    sortable
                                    filter
                                    style={{ width: "10%", textAlign: "center" }}
                                    filterType="number"
                                    filterMatchMode="gte"
                                    filterPlaceholder="Mayor a"
                                    filterHeaderClassName="p-inputtext-sm"
                                />
                                <Column
                                    field="available"
                                    header="Disponible Físico"
                                    sortable
                                    filter
                                    style={{ width: "10%", textAlign: "center" }}
                                    filterType="number"
                                    filterMatchMode="gte"
                                    filterPlaceholder="Mayor a"
                                    filterHeaderClassName="p-inputtext-sm"
                                />
                                <Column
                                    field="faltEntregar"
                                    header="Por Entregar"
                                    sortable
                                    filter
                                    style={{ width: "10%", textAlign: "center" }}
                                    filterType="number"
                                    filterMatchMode="gte"
                                    filterPlaceholder="Mayor a"
                                    filterHeaderClassName="p-inputtext-sm"
                                />
                                <Column
                                    field="faltComprar"
                                    header="Por Comprar"
                                    sortable
                                    filter
                                    style={{ width: "10%", textAlign: "center" }}
                                    filterType="number"
                                    filterMatchMode="gte"
                                    filterPlaceholder="Mayor a"
                                    filterHeaderClassName="p-inputtext-sm"
                                />
                                <Column
                                    field="pryCodes"
                                    header="Proyectos"
                                    style={{ width: "9%" }}
                                    body={(row) => row.pryCodes.sort().toString().replace(/,/g, ", ")}
                                    filter
                                    filterPlaceholder="Todos"
                                    filterHeaderClassName="p-inputtext-sm"
                                    filterMatchMode="contains"
                                />
                            </DataTable>
                            <div className="card-divider mb-5" />
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={open} toggle={() => setOpen(false)} size="xl" modalClassName="fullscreen-modal" zIndex={1001}>
                <ModalHeader toggle={() => setOpen(false)}>{`Proyecto de Ingeneria ${project && project.code}`}</ModalHeader>
                <ModalBody>
                    {loading ? (
                        <div>
                            <br className="mt-5" />
                            <div className="div-loading" />
                            <br />
                            <br />
                            <br />
                        </div>
                    ) : (
                        RenderTableShopping()
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => setOpen(false)}>
                        OK
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
