import React, { Component } from "react";
import { CSVReader } from "react-papaparse";
import { toast } from "react-toastify";
import { getProductsSurplusProjects } from "../../store/directusSdk";

export default class ImportCsvCodes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
        };
    }

    handleOnDrop = (data) => {
        this.setState({ errors: [] });

        const header = data.shift();
        const { LoadCodes, LoadProductos , loading } = this.props;
        const code = header.data.findIndex((e) => e === "Codigo" || e === "codigo");
        if (code === -1) {
            return toast.error("¡Error, Debe indicar el codigo!");
        }
        const projectsCodes = [];
        //se recorren los productos dentro de el csv
        data.forEach(({ data }) => {
            projectsCodes.push({ label: data[code], value: data[code] });
        });
        LoadCodes(projectsCodes);
        loading(true)
     getProductsSurplusProjects({ ...projectsCodes.map((code) => code.value) }).then((surplus) => LoadProductos(surplus)).finally(() => loading(false));
    };

    handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
    };

    handleOnRemoveFile = (data) => {
        return this.setState({ errors: [] });
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div>
                    {errors.length > 0 && (
                        <div className="alert alert-danger">
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                        <th>Error</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {errors.map((e, i) => (
                                        <tr key={i}>
                                            <td>{e.code}</td>
                                            <td>{e.error}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
                <div style={{ width: "100%", height: 200 }}>
                    <CSVReader
                        onDrop={this.handleOnDrop}
                        onError={this.handleOnError}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={this.handleOnRemoveFile}
                    >
                        <span>Arrastra y Suelta el archivo CSV aquí o haz clic para cargarlo.</span>
                    </CSVReader>
                </div>
            </div>
        );
    }
}
