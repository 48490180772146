export const CART_ADD_ITEM_AP = 'CART_ADD_ITEM_AP';
export const CART_REMOVE_ITEM_AP = 'CART_REMOVE_ITEM_AP';
export const CART_UPDATE_QUANTITIES_AP = 'CART_UPDATE_QUANTITIES_AP';
export const CART_REMOVE_ALL_ITEMS_AP = 'CART_REMOVE_ALL_ITEMS_AP';
export const CART_LOAD_ALL_ITEMS_AP = 'CART_LOAD_ALL_ITEMS_AP';
export const RECALCULATE_PRICES_AP = 'RECALCULATE_PRICES_AP';
export const CHANGE_COMMENTS_AP = 'CHANGE_COMMENTS_AP';
export const CHANGE_CONTACT_AP = 'CHANGE_CONTACT_AP';
export const CHANGE_CONTACTS_AP = 'CHANGE_CONTACTS_AP';
export const CHANGE_CUSTOMER_AP = 'CHANGE_CUSTOMER_AP';
export const CHANGE_TEXT_ITEM_AP = 'CHANGE_TEXT_ITEM_AP';
export const RECALCULATE_ITEM_PRICE_AP = 'RECALCULATE_ITEM_PRICE_AP';
export const CHANGE_INCITBMS_AP = 'CHANGE_INCITBMS_AP';
export const CHANGE_QTY_AP = 'CHANGE_QTY_AP';
export const CHANGE_PRICE_ITEM_AP = 'CHANGE_PRICE_ITEM_AP';
