// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
// application
import moment from 'moment';
import Pagination from '../shared/Pagination';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Modal } from 'reactstrap';
import { getReturnsForUser } from '../../store/directusSdk';
import { Cross20Svg } from '../../svg';
import EditItem from './EditItem';
import DeletItem from './DeleteItem';
import InputDelivery from './InputDelivery';

class AccountPageReturnsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            returs: [],
            page: 1,
            pages: 1,
            start: 0,
            end: 9,
            loading: true,
            loading2: false,
            entregas: null,
            company: null,
            lastId: null,
            open: false,
        };
    }

    componentDidMount() {
        const { currentUser } = this.props;
        const { page } = this.state;
        getReturnsForUser(currentUser.id)
            .then((returs) => {
                if (returs.length > 0) {
                    const pages = Math.ceil(returs.length / 10);
                    const end = page * 10;
                    const start = end - 10;
                    this.setState({ returs, pages, end, start });
                }
                this.setState({ loading: false });
            });
    }

    handlePageChange = (page) => {
        const end = page * 10;
        const start = end - 10;
        this.setState({ page, end, start });
    };

    actions = (row) => {
        if (!row.confirmed_qty) {
            return (
                <React.Fragment>
                    {row.newQty && (row.newQty !== row.delivered_qty) && <EditItem row={row} editItem={this.editItem} />}
                    <DeletItem row={row} deleteItem={this.deleteItem} />
                </React.Fragment>
            );
        }
    };

    entregado = (row) => {
        if (!row.confirmed_qty) {
            return (
                <InputDelivery id={row.id} qty={row.delivered_qty} max={row.orderQty} changeQty={this.changeQty} />
            );
        }
        return row.delivered_qty;
    };

    render() {
        const { page, returs, pages, end, start, loading, loading2, open, entregas } = this.state;

        const retursList = returs.map((delivery) => {
            const date = moment(delivery.created_on);
            const dateFormated = date.add(date.utcOffset(), 'minutes').format('DD/MM/YYYY hh:mm a');
            return (
                <tr key={delivery.id}>
                    <td>{delivery.id}</td>
                    <td>{delivery.pry_code}</td>
                    <td>{delivery.pry_name}</td>
                    <td>{dateFormated}</td>
                    <td>{delivery.company}</td>
                    <td>
                        <a className="btn btn-info mr-3" target="__blank" href={`${process.env.REACT_APP_API_URL}/returns/${delivery.id}.pdf`} title="Ver Soporte."><i className="fas fa-search" /></a>
                    </td>
                </tr >
            );
        });

        return (
            <div className="card" >
                <Helmet>
                    <title>Mis Devoluciones - ICAutomatizados</title>
                </Helmet>

                <div className="card-header">
                    <h5>Historial de Devoluciones de Materiales</h5>
                </div>
                <div className="card-divider" />
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                                <tr>
                                    <th width="10%">ID</th>
                                    <th width="15%">Código</th>
                                    <th width="40%">Proyecto</th>
                                    <th width="15%">Fecha</th>
                                    <th width="10%">Company</th>
                                    <th width="10%">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading && <tr><td colSpan="4" ><div className="div-loading" style={{ height: 300 }} /></td></tr>}
                                {retursList.slice(start, end)}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-divider" />
                <div className="card-footer">
                    {!loading && returs.length < 1 && <strong> Actualmente no tiene Entrgas... </strong>}
                    {pages > 1 && <Pagination current={page} total={pages} onPageChange={this.handlePageChange} siblings={2} />}
                </div>
                <Modal isOpen={open} toggle={() => this.setState({ open: false })} centered size="xl">
                    <div className="quickview">
                        <button className="quickview__close" type="button" onClick={() => this.setState({ open: false })}>
                            <Cross20Svg />
                        </button>

                        {loading2 ? (
                            <div className="div-loading" style={{ height: 300 }} />
                        ) : (
                            <DataTable
                                dataKey="id"
                                value={entregas}
                                paginator
                                rows={10}
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                removableSort
                                emptyMessage="No se han encontrado registros..."
                                className="p-datatable-customers"
                                rowHover
                            >
                                <Column field="pryCode" header="Proyecto" sortable filter style={{ width: '17%' }} filterPlaceholder="Todos" filterHeaderClassName="p-inputtext-sm" />
                                <Column field="code" header="Código" sortable filter style={{ width: '16%' }} filterPlaceholder="Todos" filterHeaderClassName="p-inputtext-sm" />
                                <Column field="orderQty" header="Orden" sortable filter style={{ width: '15%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                <Column field="delivered_qty" header="Entregado" body={this.entregado} sortable filter style={{ width: '15%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                <Column field="warehouse" header="Bodega" sortable filter style={{ width: '17%', textAlign: 'center' }} filterType="number" filterMatchMode="gte" filterPlaceholder="Mayor a" filterHeaderClassName="p-inputtext-sm" />
                                <Column header="Acciones" body={this.actions} style={{ width: '20%', textAlign: 'center' }} />
                            </DataTable>
                        )}
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapDispatchToProps = {
};

export default connect(
    () => ({}),
    mapDispatchToProps,
)(AccountPageReturnsList);