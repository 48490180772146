// react
import React from 'react';
import { connect } from 'react-redux';

// third-party
import { Helmet } from 'react-helmet-async';
import Menu from '../../shared/Menu';

function CashFlow({ menu }) {
    const engMenu = menu.find((e) => e.title === 'Logistica');
    const submenu = engMenu.submenu.menu.find((e) => e.title === 'Logistica AP');
    return (
        <React.Fragment>
            <Helmet>
                <title> Cash Flow - AP</title>
            </Helmet>

            <Menu menu={submenu.submenu} title={submenu.title} />

        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    menu: state.currentUser.menu
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(CashFlow);
