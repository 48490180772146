// reactuctsErrors
import React from "react";
// third-party
import fs from "file-saver";
import { MDBDataTableV5 } from "mdbreact";
import ExcelJS from "exceljs";
import { Button } from "reactstrap";
import { toast } from "react-toastify";

export default function ExternalInventories({ productsErrors, loading, loading2 }) {
    const dataTable = {
        columns: [
            { label: "SKU", field: "code", width: 100 },
            { label: "Error", field: "error", width: 100 },
        ],
        rows: productsErrors,
    };
    const loadingTable = {
        pointerEvents: "none",
        opacity: " 0.5",
    };
    const excelError = (data) => {   
        // CREAMOS LA PRIMERA HOJA
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("Errores de códigos");

        //Se establecen los anchos de las columnas
        const width = 20;
        sheet.getColumn("A").width = width;
        sheet.getColumn("B").width = width + 50;

        //Se le dan los valores al encabezado
        sheet.getCell("A1").value = "SKU";
        sheet.getCell("B1").value = "ERROR";

        let lines = 2;
        data.forEach((error) => {
            sheet.getCell(`A${lines}`).value = error.code;
            sheet.getCell(`B${lines}`).value = error.error;
            lines++;
        });
        return workbook.xlsx.writeBuffer();
    };

    const generateExelReport = (data) => {
        excelError(data)
            .then((response) => {
                const blob = new Blob([response]);
                fs.saveAs(blob, `Errores de códigos de inventario particular.xlsx`);
            })
            .catch((error) => toast.error(`¡Error!, ${error.message}`))
    };

    return (
        <React.Fragment>
            <div className="container">
                <div className="card">
                    <div className="card-body">
                        <h5 className="mt-3">Listado de Errores</h5>
                        <div className="t-loading-table-container" style={loading || loading2 ? loadingTable : { width: "1" }}>
                            {productsErrors.length > 0 && (
                                <Button color="success" disabled={loading} onClick={() => generateExelReport(productsErrors)}>
                                    <i className="far fa-file-alt mr-2"></i> Exportar
                                </Button>
                            )}
                            <MDBDataTableV5
                                hover
                                entriesOptions={[25, 50, 75, 100]}
                                entries={25}
                                pagesAmount={4}
                                data={dataTable}
                                pagingTop
                                searchTop
                                searchBottom={false}
                                entriesLabel="Mostar"
                                searchLabel="Buscar"
                                infoLabel={["Viendo", "al", "de", "registros"]}
                                paginationLabel={["Anterior", "Siguiente"]}
                                noRecordsFoundLabel="No se han encontrado registros..."
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
