/* eslint-disable jsx-a11y/control-has-associated-label */
// react
import React, { Component } from "react";
// third-party
import { Helmet } from "react-helmet-async";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Form } from "reactstrap";
import moment from "moment";
import swal from "sweetalert";
import { toast } from "react-toastify";
import Currency from "../../shared/Currency";
import { CashFlowCreditsCollection, DeleteItem, UpdateItem, CreateItem, GetItems, UpdateItems } from "../../../store/directusSdk";
import PageHeader from "../../shared/PageHeader";
import { cashFlowFilter, planificationMode } from "../../shared/utilities";

export default class CreditsCollection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            invoices: [],
            expandAll: false,
            expandedRows: null,
            loading: false,
            loading2: false,
            open: false,
            open2: false,
            mode: "N",
            planned: null,
            plannedOld: null,
            param: "A",
            invoice: null,
        };
    }

    collapseAll = () => this.setState({ expandedRows: null, expandAll: false });

    expandAll = () => {
        const { invoices } = this.state;
        const expandedRows = {};

        if (invoices.length > 0) {
            invoices.forEach((p) => {
                expandedRows[`${p.id}`] = true;
            });
            return this.setState({ expandedRows, expandAll: true });
        }
    };

    exportCSV = () => this.dt.exportCSV();

    actionsTemplate = (row, invoice) =>
        row.status === "P" ? (
            <i className="fa fa-check fa-2x" title="COBRADO" style={{ color: "#28a765" }} />
        ) : (
            <React.Fragment>
                <Button
                    outline
                    color="success"
                    size="sm"
                    title="Procesar Cobro"
                    onClick={() => this.setState({ planned: { ...row, date: moment().format("YYYY-MM-DD") }, open: true, mode: "P" })}
                >
                    <i className="fa fa-check" />
                </Button>
                <Button
                    outline
                    color="info"
                    size="sm"
                    title="Editar Cobro"
                    className="ml-2"
                    onClick={() =>
                        this.setState({
                            planned: { ...row },
                            plannedOld: row,
                            open: true,
                            mode: "U",
                        })
                    }
                >
                    <i className="fa fa-edit" />
                </Button>
                <Button outline color="danger" size="sm" title="Eliminar Cobro" className="ml-2" onClick={() => this.Remove(row, invoice)}>
                    <i className="fa fa-trash" />
                </Button>
            </React.Fragment>
        );

    Remove = (row, invoice) =>
        swal({
            title: "Eliminar Planificación",
            text: `¿Desea eliminar Planificación # ${row.id} de la Factura #${invoice.number}?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((value) => {
            if (value) {
                return DeleteItem("fnza_debtors_planned", row.id)
                    .then(() => {
                        invoice.planneds = invoice.planneds.filter((e) => e.id !== row.id);
                        this.setState({ invoice, planned: null });
                        return swal("Exito!", "Se ha borrado el registro con exito!", "success");
                    })
                    .catch((error) => swal("Error!", error.message, "error"));
            }
        });

    rowExpansionTemplate = (row) => (
        <div className="orders-subtable">
            {row.planneds.length > 0 ? (
                <DataTable value={row.planneds} className="p-datatable-sm">
                    <Column field="id" header="ID" style={{ width: "6%" }} />
                    <Column field="date" header="FECHA" style={{ width: "19%" }} body={(row) => row.date && moment(row.date).format("DD-MM-YYYY")} />
                    <Column field="amount" header="MONTO" style={{ width: "19%" }} body={(row) => <Currency value={row.amount} />} />
                    <Column
                        field="date_paid"
                        header="FECHA COBRADO"
                        style={{ width: "19%" }}
                        body={(row) => row.date_paid && moment(row.date_paid).format("DD-MM-YYYY")}
                    />
                    <Column
                        field="amount_paid"
                        header="MONTO COBRADO"
                        style={{ width: "19%" }}
                        body={(row) => row.amount_paid && <Currency value={row.amount_paid} />}
                    />
                    <Column header="ACCIONES" body={(line) => this.actionsTemplate(line, row)} style={{ width: "18%" }} />
                </DataTable>
            ) : (
                <p className="mt-3 text-center">No hay Planeación para esta Factura..</p>
            )}

            <Button
                className="mt-3"
                color="primary"
                size="sm"
                title="Nueva Planificación"
                onClick={() =>
                    this.setState({
                        planned: {
                            amount: row.owed > 0 ? row.owed : 0,
                            date: moment().format("YYYY-MM-DD"),
                            invoice: row.id,
                            received: false,
                        },
                        open: true,
                        mode: "A",
                    })
                }
            >
                <i className="fa fa-plus mr-2" />
                Agregar
            </Button>
        </div>
    );

    getOrders = () => {
        const { param } = this.state;
        this.setState({ loading: true, expandAll: false, expandedRows: null });

        return CashFlowCreditsCollection(param)
            .then((invoices) => this.setState({ invoices }))
            .finally(() => this.setState({ loading: false }));
    };

    Change = (key, value) => {
        const { planned } = this.state;
        planned[key] = value;
        return this.setState({ planned });
    };

    save = (mode) => {
        this.setState({ loading2: true });
        const { planned } = this.state;

        switch (mode) {
            case "A":
                const data = { date: planned.date, amount: planned.amount, invoice: planned.invoice };
                if (planned.received) {
                    data.status = "P";
                    data.date_paid = planned.date;
                    data.amount_paid = planned.amount;
                }
                return CreateItem("fnza_debtors_planned", data, { fields: "id" })
                    .then(async () => {
                        await this.getOrders();
                        this.setState({ planned: null });
                        return toast.success("¡Planificación Creada con Exito!");
                    })
                    .catch((error) => toast.error(`¡Error!, ${error.message}`))
                    .finally(() => this.setState({ loading2: false }));
            case "P":
                return UpdateItem(
                    "fnza_debtors_planned",
                    planned.id,
                    { date_paid: planned.date, amount_paid: planned.amount, status: "P" },
                    { fields: "id" }
                )
                    .then(async () => {
                        await this.getOrders();
                        this.setState({ planned: null, open: false });
                        return toast.success("¡Planificación Procesada con Exito!");
                    })
                    .catch((error) => toast.error(`¡Error!, ${error.message}`))
                    .finally(() => this.setState({ loading2: false }));
            default:
                const { plannedOld } = this.state;
                if (planned && (plannedOld.date !== planned.date || plannedOld.amount !== planned.amount)) {
                    const row = {};

                    if (plannedOld.date !== planned.date) {
                        row.date = planned.date;
                    }

                    if (plannedOld.amount !== planned.amount) {
                        row.amount = planned.amount;
                    }

                    return UpdateItem("fnza_debtors_planned", planned.id, row, { fields: "id" })
                        .then(async () => {
                            await this.getOrders();
                            this.setState({ planned: null, open: false });
                            return toast.success("¡Planificación Modificada con Exito!");
                        })
                        .catch((error) => toast.error(`¡Error!, ${error.message}`))
                        .finally(() => this.setState({ loading2: false }));
                }
                this.setState({ open: false });
        }
    };

    ChangeParam = (e) => this.setState({ param: e.target.value, expandAll: false, expandedRows: null }, this.getOrders);

    componentDidMount() {
        this.getOrders();
    }

    searchPlanneds = (row) => {
        if (row.sale_id.code == "0") {
            return this.setState({ open2: true, invoice: row });
        }
        this.setState({ loading3: true });

        return GetItems("fnza_sales_orders_planned", {
            fields: "id,date,amount,date_paid,amount_paid,status",
            filter: { sale_id: { eq: row.sale_id.id }, used: { eq: false } },
        })
            .then(({ data }) => this.setState({ invoice: { ...row, plannedsOrder: data }, open2: true }))
            .catch((error) => toast.error(`¡Error!, ${error.message}`))
            .finally(() => this.setState({ loading3: false }));
    };

    copyToInvoice = (row, invoice) => {
        const plannedId = row.id;
        delete row.id;
        return CreateItem(
            "fnza_debtors_planned",
            {
                ...row,
                invoice: invoice.id,
            },
            { fields: "id,date,amount,date_paid,amount_paid,status" }
        )
            .then(async ({ data }) => {
                await UpdateItem("fnza_sales_orders_planned", plannedId, { used: true, status: "deleted" });

                invoice.planneds = [...invoice.planneds, data];
                return this.searchPlanneds(invoice);
            })
            .catch((error) => toast.error(`¡Error!, ${error.message}`))
            .finally(() => this.setState({ loading2: false }));
    };

    render() {
        const { loading, invoices, expandAll, expandedRows, open, loading2, mode, planned, param, invoice, open2 } = this.state;

        const breadcrumb = [
            { title: "Home", url: "/" },
            { title: "Finanzas", url: "/finanzas" },
            { title: "Cash Flow ICA", url: "/finanzas/cash-flow" },
            { title: "Crédito y Cobro", url: "" },
        ];

        const header = (
            <div className="row">
                <div className="col-md-3 ">
                    <h5 className="mt-2">FACTURAS DEUDORES</h5>
                    {cashFlowFilter(param, this.ChangeParam)}
                </div>
                <div className="col-md-2 offset-md-4">
                    {expandAll ? (
                        <Button color="warning" onClick={this.collapseAll}>
                            <i className="fas fa-angle-double-right mr-2" />
                            Contraer
                        </Button>
                    ) : (
                        <Button color="info" className="mr-2" onClick={this.expandAll}>
                            <i className="fas fa-angle-double-down mr-2" />
                            Expandir
                        </Button>
                    )}
                </div>
                <div className="col-md-2">
                    <Button color="info" onClick={this.exportCSV}>
                        <i className="fas fa-file-export mr-2" />
                        Exportar
                    </Button>
                </div>
            </div>
        );

        return (
            <React.Fragment>
                <Helmet>
                    <title>FACTURAS DEUDORES - Ingeniería y Controles Automatizados</title>
                </Helmet>

                <PageHeader breadcrumb={breadcrumb} />
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            {loading ? (
                                <div>
                                    <br />
                                    <br />
                                    <div className="div-loading" />
                                    <br />
                                    <br />
                                </div>
                            ) : (
                                <DataTable
                                    ref={(el) => {
                                        this.dt = el;
                                    }}
                                    value={invoices}
                                    header={header}
                                    dataKey="id"
                                    paginator
                                    rows={10}
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                    removableSort
                                    emptyMessage="No se han encontrado registros..."
                                    exportFilename="FACTURAS-DEUDORES "
                                    expandedRows={expandedRows}
                                    onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                                    rowExpansionTemplate={this.rowExpansionTemplate}
                                    className="p-datatable-dispatches p-datatable-sm mt-1 mt-4"
                                >
                                    <Column expander style={{ width: "4%" }} />
                                    <Column field="number" header="Num. #" sortable filter style={{ width: "11%" }} filterPlaceholder="Todos" />
                                    <Column
                                        field="customer"
                                        header="CLIENTE"
                                        sortable
                                        filter
                                        style={{ width: "35%" }}
                                        filterMatchMode="contains"
                                        filterPlaceholder="Todos"
                                    />
                                    <Column
                                        field="sale_id.code"
                                        header="O.V."
                                        sortable
                                        filter
                                        style={{ width: "12%" }}
                                        filterMatchMode="contains"
                                        filterPlaceholder="Todos"
                                    />
                                    <Column
                                        field="date_expiration"
                                        header="F. VENC."
                                        body={(row) => moment(row.date_expiration).format("DD-MM-YYYY")}
                                        sortable
                                        filter
                                        style={{ width: "12%" }}
                                        filterPlaceholder="Todos"
                                    />
                                    <Column
                                        field="subscriber"
                                        header="ABONADO"
                                        body={(row) => row.subscriber && <Currency value={row.subscriber} />}
                                        sortable
                                        filter
                                        style={{ width: "13%" }}
                                        filterType="number"
                                        filterPlaceholder="Mayor a"
                                        filterMatchMode="gte"
                                    />
                                    <Column
                                        field="owed"
                                        header="ADEUDADO"
                                        body={(row) => row.owed && <Currency value={row.owed} />}
                                        sortable
                                        filter
                                        style={{ width: "13%" }}
                                        filterType="number"
                                        filterPlaceholder="Mayor a"
                                        filterMatchMode="gte"
                                    />
                                    <Column
                                        header=""
                                        body={(row) => (
                                            <Button
                                                outline
                                                color="info"
                                                size="sm"
                                                title="Detalles de Factura"
                                                onClick={() => {
                                                    this.setState({ open2: true, invoice: row });
                                                    this.searchPlanneds(row);
                                                }}
                                            >
                                                <i className="fa fa-search" />
                                            </Button>
                                        )}
                                        style={{ width: "5%" }}
                                    />
                                </DataTable>
                            )}
                        </div>
                    </div>
                </div>

                {planned && (
                    <Modal isOpen={open} centered size="md">
                        <ModalHeader toggle={() => this.setState({ open: false, planned: null })}>
                            {" "}
                            {planificationMode(mode, planned.id, "COBRO")}{" "}
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label for="exampleDate">Fecha</Label>
                                <Input
                                    type="date"
                                    name="date"
                                    id="exampleDate"
                                    placeholder="DD/MM/AAAA"
                                    value={planned.date}
                                    onChange={(e) => this.Change("date", e.target.value)}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="currency-us">Monto</Label>
                                <Input
                                    type="number"
                                    name="amount"
                                    id="currency-us"
                                    placeholder="Monto"
                                    value={planned.amount}
                                    onChange={(e) => this.Change("amount", e.target.value)}
                                />
                            </FormGroup>
                            {mode == "A" && (
                                <FormGroup check inline>
                                    <Label check>
                                        <Input type="checkbox" value={planned.received} onChange={(e) => this.Change("received", e.target.checked)} />
                                        Recibido
                                    </Label>
                                </FormGroup>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => this.setState({ open: false, planned: null })}>
                                Cancelar
                            </Button>
                            <Button color="primary" disabled={loading2 || !planned.amount > 0 || !planned.date} onClick={() => this.save(mode)}>
                                {" "}
                                Guardar
                            </Button>
                        </ModalFooter>
                    </Modal>
                )}

                {invoice && (
                    <Modal isOpen={open2} centered size="xl">
                        <ModalHeader> Detalles de la Factura # {invoice.number}</ModalHeader>
                        <ModalBody>
                            <div className="container">
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <table width="100%" className="table table-sm">
                                            <tbody>
                                                <tr>
                                                    <th width="30%">TIPO</th>
                                                    <td width="70%" align="right">
                                                        {invoice.type == "D" ? "Deudor" : "Reserva"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th width="30%">FECHA</th>
                                                    <td width="70%" align="right">
                                                        {moment(invoice.date).format("DD-MM-YYYY")}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th width="30%">ABONADO</th>
                                                    <td width="70%" align="right">
                                                        <Currency value={invoice.subscriber} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-6">
                                        <table width="100%" className="table table-sm">
                                            <tbody>
                                                <tr>
                                                    <th width="30%">CLIENTE</th>
                                                    <td width="70%" align="right">
                                                        {invoice.customer}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th width="50%">FECHA VENCIMIENTO</th>
                                                    <td width="50%" align="right">
                                                        {moment(invoice.date_expiration).format("DD-MM-YYYY")}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th width="30%">ADEUDADO</th>
                                                    <td width="70%" align="right">
                                                        <Currency value={invoice.owed} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-3">
                                        <table width="100%" className="table table-sm">
                                            <tbody>
                                                <tr>
                                                    <th width="50%">ORDEN VENTA</th>
                                                    <td width="50%" align="right">
                                                        {invoice.sale_id.code}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th width="30%">SALDO</th>
                                                    <td width="70%" align="right">
                                                        <Currency value={invoice.amount} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th width="30%">TOTAL</th>
                                                    <td width="70%" align="right">
                                                        <Currency value={invoice.total} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-12">
                                        <table width="100%" className="table table-sm">
                                            <tbody>
                                                <tr>
                                                    <th width="10%">COMENTARIOS</th>
                                                    <td width="90%">{invoice.comments}</td>
                                                </tr>
                                                <tr>
                                                    <th width="10%" />
                                                    <td width="90%" />
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <h4>Planificación en Orden de Venta</h4>
                                {invoice.plannedsOrder && invoice.plannedsOrder.length > 0 ? (
                                    <DataTable value={invoice.plannedsOrder} className="p-datatable-sm">
                                        <Column field="id" header="ID" style={{ width: "6%" }} />
                                        <Column
                                            field="date"
                                            header="FECHA"
                                            style={{ width: "19%" }}
                                            body={(row) => row.date && moment(row.date).format("DD-MM-YYYY")}
                                        />
                                        <Column
                                            field="amount"
                                            header="MONTO"
                                            style={{ width: "19%" }}
                                            body={(row) => <Currency value={row.amount} />}
                                        />
                                        <Column
                                            field="date_paid"
                                            header="FECHA PAGADO"
                                            style={{ width: "19%" }}
                                            body={(row) => row.date_paid && moment(row.date_paid).format("DD-MM-YYYY")}
                                        />
                                        <Column
                                            field="amount_paid"
                                            header="MONTO PAGADO"
                                            style={{ width: "19%" }}
                                            body={(row) => row.amount_paid && <Currency value={row.amount_paid} />}
                                        />
                                        <Column
                                            header="COPIAR A FACTURA"
                                            body={(row) => (
                                                <Button
                                                    outline
                                                    color="success"
                                                    size="sm"
                                                    title="Copiar a Factura"
                                                    onClick={() => this.copyToInvoice(row, invoice)}
                                                >
                                                    <i className="fa fa-copy" />
                                                </Button>
                                            )}
                                            style={{ width: "18%" }}
                                        />
                                    </DataTable>
                                ) : (
                                    <p className="text-center mt-5">Orden de Compra sin Planeación de Pagos Disponible...</p>
                                )}

                                <h4>Planificación en Factura</h4>
                                {invoice.planneds.length > 0 ? (
                                    <DataTable value={invoice.planneds} className="p-datatable-sm mt-2">
                                        <Column field="id" header="ID" style={{ width: "12%" }} />
                                        <Column
                                            field="date"
                                            header="FECHA"
                                            style={{ width: "20%" }}
                                            body={(row) => row.date && moment(row.date).format("DD-MM-YYYY")}
                                        />
                                        <Column
                                            field="amount"
                                            header="MONTO"
                                            style={{ width: "20%" }}
                                            body={(row) => <Currency value={row.amount} />}
                                        />
                                        <Column
                                            field="date_paid"
                                            header="FECHA PAGADO"
                                            style={{ width: "20%" }}
                                            body={(row) => row.date_paid && moment(row.date_paid).format("DD-MM-YYYY")}
                                        />
                                        <Column
                                            field="amount_paid"
                                            header="MONTO PAGADO"
                                            style={{ width: "20%" }}
                                            body={(row) => row.amount_paid && <Currency value={row.amount_paid} />}
                                        />
                                        <Column
                                            header=""
                                            style={{ width: "8%" }}
                                            body={(row) =>
                                                row.status === "P" && <i className="fa fa-check fa-2x" title="PAGADO" style={{ color: "#28a765" }} />
                                            }
                                        />
                                    </DataTable>
                                ) : (
                                    <p className="mt-3 text-center">No hay Planeación para esta Factura..</p>
                                )}
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => this.setState({ open2: false, planned: null })}>
                                Cerrar
                            </Button>
                        </ModalFooter>
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}
