import moment from "moment";
import Swal from "sweetalert2";
import "../../../loadingTable.css";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import { takeProductsInventoriesCustomer, takeProductsInventoriesSurplus, takeProductsInventoriesSurplusAp } from "../../../../../store/directusSdk";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Col, Row, Table } from "reactstrap";

export default function InventoryTakeProduct({ getProductsSearch,open, setOpen, type, selectedProducts, takeQty, setSelectedProducts }) {
    const [note, setNote] = useState("");
    const [inventory, sertInventory] = useState(null);
    const [mainLoading, setMainLoading] = useState(false);
    const [retirementDate, setRetirementDate] = useState(moment().format("YYYY-MM-DD"));

    const takeProductInventories = () => {
        if (!retirementDate) toast.error("Se debe indicar una fecha de retiro.");
        if (!note) toast.error("Se debe indicar una nota de retiro.");
        const surplus = [];
        const surplusAp = [];
        const customer = [];
        const customerAp = [];

        selectedProducts.forEach((item) => {
            const div = item.id.split("-");
            if (div[1] === "cus") {
                if (item.company === "ICA") {
                    customer.push({
                        id: div[0],
                        stock: item.takeQty,
                        code: item.code,
                        note,
                        retirementDate,
                    });
                } else {
                    customerAp.push({
                        id: div[0],
                        stock: item.takeQty,
                        code: item.code,
                        note,
                        retirementDate,
                    });
                }
            } else if (div[1] === "sp") {
                if (item.company === "ICA") {
                    surplus.push({
                        id: div[0],
                        qty: item.takeQty,
                        code: item.code,
                        note,
                        retirementDate,
                    });
                } else {
                    surplusAp.push({
                        id: div[0],
                        qty: item.takeQty,
                        code: item.code,
                        note,
                        retirementDate,
                    });
                }
            }
        });
   

        Swal.fire({
            title: "Retirar productos.",
            text: `¿Está seguro que desea tomar estos productos?. `,
            icon: "warning",
            confirmButtonColor: "#0059BD",
            cancelButtonColor: "#3d464d",
            buttons: true,
            dangerMode: true,
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: "Tomar",
            preConfirm: () => {
                const promises = [];
                if (customer.length) promises.push(takeProductsInventoriesCustomer({ items: customer }));
                if (customerAp.length) promises.push(takeProductsInventoriesCustomer({ items: customerAp }));

                if (surplus.length) promises.push(takeProductsInventoriesSurplus({ items: surplus }));
                if (surplusAp.length) promises.push(takeProductsInventoriesSurplusAp({ items: surplusAp }));

                return Promise.all(promises)
                    .then(() => {
                        setOpen(false);
                        setSelectedProducts([]);
                        setNote("");
                        getProductsSearch()
                    })
                    .catch((error) => toast.error(error));
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: "success",
                    title: `¡Se han retirado los productos!`,
                });
            }
        });
    };

    return (
        <Modal isOpen={open} centered size="xl" zIndex="1000">
            <ModalHeader>
                {mainLoading ? <div className="spinner-border text-primary" role="status" /> : inventory && inventory.inventoryName}
            </ModalHeader>
            <ModalBody>
                <div className="container">
                    <Row form>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="retirementDateLabel">Fecha Retiro</Label>
                                <Input
                                    type="date"
                                    autoComplete="off"
                                    id="retirementDate"
                                    value={retirementDate}
                                    placeholder="DD/MM/AAAA"
                                    onChange={(e) => setRetirementDate(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={8}>
                            <FormGroup>
                                <Label for="noteLabel">Nota de retiro</Label>
                                <Input
                                    autoComplete="off"
                                    type="text"
                                    id="note"
                                    value={note}
                                    placeholder="Inserte una nota de retiro…"
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12} style={{ maxHeight: "600px", overflow: "auto" }}>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th width="20%">Código</th>
                                        <th width="50%">Inventario</th>
                                        <th width="15%">Cantidad</th>
                                        <th width="15%">Cantidad A Tomar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedProducts.map((item, i) => (
                                        <tr key={i}>
                                            <td>{item.code}</td>
                                            <td>{item.inventoryName}</td>
                                            {type === "SG" ? <td>{item.qty}</td> : <td>{item.stock}</td>}
                                            <td>
                                                <InputNumber
                                                    disabled={mainLoading}
                                                    max={type === "SG" ? item.qty : item.stock}
                                                    min={1}
                                                    value={type === "SG" ? item.qty : item.stock}
                                                    onValueChange={(e) => takeQty(item.id, e.target.value)}                                                 
                                                    size={5}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
            </ModalBody>
            <ModalFooter>
                <>
                    <button
                        className="btn btn-primary mt-2"
                        type="button"
                        disabled={mainLoading || !note || !retirementDate}
                        onClick={() => takeProductInventories()}
                    >
                        {mainLoading ? (
                            <div>
                                <span className="spinner-border spinner-border-sm mr-1 " role="status" aria-hidden="true"></span>
                                <span className="visually-hidden">Cargando...</span>
                            </div>
                        ) : (
                            <div>
                                Tomar
                                <i className="fas fa-shopping-cart ml-2" />
                            </div>
                        )}
                    </button>

                    <Button color="secondary" onClick={() => setOpen(false)}>
                        <i className="fa fa-ban mr-2" />
                        Cancelar
                    </Button>
                </>
            </ModalFooter>
        </Modal>
    );
}
