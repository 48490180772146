import { Button } from "reactstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Column } from "primereact/column";
import Swal from "sweetalert2";
import swal from "sweetalert";
import { MDBDataTableV5 } from "mdbreact";
import { Helmet } from "react-helmet-async";
import { DataTable } from "primereact/datatable";
import React, { useState, useEffect } from "react";
import InventoryClientTop from "./InventoryClientTop";
import PageHeader from "../../../../shared/PageHeader";
import InventoryClientModal from "./InventoryClientModal";
import { getInventoryCustomers, DeleteItems, DeleteItem, deleteInventoryCustomer } from "../../../../../store/directusSdk";

function InventoryManagementClient({ currentUser }) {
    const company = "ICA";
    const roles = ["Administrator"];
    company === "ICA" ? roles.push("Admin ICA") : roles.push("Admin AP");
    const isAdmin = roles.includes(currentUser.role.name);

    const [type, setType] = useState("");
    const [codes, setCodes] = useState([]);
    const [products, setProducts] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [inventories, setInventories] = useState([]);
    const [inventoryId, setInventoryId] = useState(null);
    const [loadingMainPage, setLoadingMainPage] = useState(false); //Loading para la tabla principal

    const getInventories = () =>
        getInventoryCustomers(company)
            .then((inventory) => {
                const inv = inventory.map((e) => {
                    e.actions = (
                        <>
                            <Button
                                color="info"
                                outline
                                className="mr-1"
                                title="Ver Detalles del Inventario"
                                onClick={() => {
                                    setType("U");
                                    setOpenModal(true);
                                    setInventoryId(e.id);
                                }}
                            >
                                <i className="fas fa-search" />
                            </Button>

                            {isAdmin && (
                                <Button outline color="danger" title="Eliminar Inventario" onClick={() => deleteInventory(e)}>
                                    <i className="fas fa-trash"></i>
                                </Button>
                            )}
                        </>
                    );
                    return e;
                });
                setInventories(inv);
            })
            .catch((error) => toast.error(error))
            .finally(() => setLoadingMainPage(false));

    const deleteInventory = (row) => {
        Swal.fire({
            title: "Eliminar Inventario",
            text: `¿Está seguro que desea eliminar el inventario ${row.name}, con ${row.stock} productos que tiene.`,
            icon: "warning",
            confirmButtonColor: "#DC3527",
            cancelButtonColor: "#3d464d",
            buttons: true,
            dangerMode: true,
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: "Eliminar",
            preConfirm: () => {
                return deleteInventoryCustomer({ company, id: row.id })
                    .then(() => getInventories())
                    .catch((error) => swal("Error!", error.message, "error"));
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: "success",
                    title: `¡El Inventatio se ha eliminado!`,
                });
            }
        });
    };

    const dataTable = {
        columns: [
            { label: "Nº", field: "id", width: 90 },
            { label: "Nombre", field: "name", width: 300 },
            { label: "Stock", field: "stock", width: 150 },
            { label: "Acciones", field: "actions", width: 80 },
        ],
        rows: inventories,
    };

    useEffect(() => {
        setLoadingMainPage(true);
        getInventories(company);
    }, []);

    const header = (
        <div>
            <h3>Listado de inventarios de clientes.</h3>
        </div>
    );

    const footer = <div></div>;

    const breadcrumb = [
        { title: "Home", url: "/" },
        { title: "Inventarios", url: "/inventarios" },
        { title: "Gestion De Inventarios ICA", url: "/inventarios/gestion-de-inventarios-ica" },
        { title: "Inventarios Clientes", url: "" },
    ];

    const loadingTable = {
        pointerEvents: "none",
        opacity: " 0.5",
    };

    return (
        <React.Fragment>
            <Helmet>
                <title> Productos Entregados - Ingeniería y Controles Automatizados</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />
            <div className="container">
                <div className="card">
                    {/*Parte superior del componente */}
                    <InventoryClientTop
                        products={products}
                        setProducts={setProducts}
                        setOpenModalNew={setOpenModal}
                        isAdmin={isAdmin}
                        codes={codes}
                        setCodes={setCodes}
                        company={company}
                        setType={setType}
                        update={getInventories}
                    />

                    <div className="card-body">
                        {loadingMainPage ? (
                            <>
                                <br />
                                <br />
                                <div className="div-loading" style={{ width: 300 }} />
                                <br />
                                <br />
                            </>
                        ) : (
                            <>
                                <div style={loadingMainPage ? loadingTable : { width: "100%" }}>
                                    <MDBDataTableV5
                                        entriesOptions={[25, 50, 75, 100]}
                                        entries={10}
                                        pagesAmount={4}
                                        data={dataTable}
                                        pagingTop
                                        searchBottom={false}
                                        entriesLabel="Mostar"
                                        searchLabel="Buscar"
                                        infoLabel={["Viendo", "al", "de", "registros"]}
                                        paginationLabel={["Anterior", "Siguiente"]}
                                        noRecordsFoundLabel="No se han encontrado registros..."
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {openModal && (
                <InventoryClientModal
                    company={company}
                    open={openModal}
                    setOpen={setOpenModal}
                    isAdmin={isAdmin}
                    type={type}
                    inventoryId={inventoryId}
                    update={getInventories}
                />
            )}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryManagementClient);
