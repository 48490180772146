// react
import React from 'react';
// third-party
import moment from 'moment';
import { Button, Table, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default ({ loading, clients, open, close, lastRow, calculateDate }) => {
    return (
        <Modal isOpen={open} centered size="lg">
            <ModalHeader toggle={close}>Clientes</ModalHeader>
            <ModalBody>
                {loading && (
                    <div className="mt-2 mb-5">
                        <br />
                        <br />
                        <div className="div-loading" />
                        <br />
                        <br />
                    </div>
                )}
                {!loading && clients.length > 0 && (
                    <Table responsive >
                        <thead>
                            <tr>
                                <th width="45%">Cliente</th>
                                <th width="12%">Cant.</th>
                                <th width="18%">Ord. Venta</th>
                                <th width="15%">F. Entrega</th>
                                <th width="10%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {clients.map((e, i) => (
                                <tr key={i}>
                                    <td>{e.customer}</td>
                                    <td>{e.qty}</td>
                                    <td>{e.saleOrder}</td>
                                    <td>{moment(e.date).format('DD/MM/YYYY')}</td>
                                    <td>{calculateDate(e, lastRow)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
                {!loading && clients.length < 1 && <p>No se han Encontrados Clientes para este Producto.</p>}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={close}>OK</Button>
            </ModalFooter>
        </Modal>
    );
}