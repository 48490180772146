// react
import React, { Component } from "react";
// third-party
import { Helmet } from "react-helmet-async";
import AsyncSelect from "react-select/async";
import { Label, CustomInput, Button, FormGroup, Input } from "reactstrap";
import {
    getSearhForProductAP,
    getSearhForCustomerAP,
    getPurchaseOrderOnlyAP,
    getDispatchesProductsAP,
    getDispatchesOCAP,
    getDispatchesClientAP,
    getSearhForProviderAP,
    getDispatchesProviderAP,
    getAllDispatchesForOrderExcelAP,
} from "../../../store/directusSdk";
import { BagdeStatus } from "../../shared/utilities";
import PageHeader from "../../shared/PageHeader";
import ConsultProduct from "./ConsultProduct";
import ConsultClient from "./ConsultClient";
import fs from "file-saver";
import { toast } from "react-toastify";
import ConsultOC from "./ConsultOC";
import ConsultProvider from "./ConsultProvider";
import "./tableStyle.css";
import { GenerateAllDispatchExcel } from "../../shared/utilities";
import moment from "moment";
export default class ListDispatches extends Component {
    constructor(props) {
        super(props);
        const thisMoment = moment();
        this.state = {
            search: null,
            dispatches: [],
            mode: "product",
            loading: false,
            from: moment(thisMoment).startOf("month").format("YYYY-MM-DD"),
            to: moment(thisMoment).endOf("month").format("YYYY-MM-DD"),
        };
    }

    generateExelReport = () => {
        const { from, to } = this.state;
        if (!from || !to) {
            return toast.error("Se deben establecer la fecha de inicio y la de final");
        }
        return getAllDispatchesForOrderExcelAP(from, to)
            .then((data) =>
                GenerateAllDispatchExcel(data).then((response) => {
                    const blob = new Blob([response]);
                    fs.saveAs(blob, `Reporte General de Despachos.xlsx`);
                })
            )
            .catch((error) => toast.error(`¡Error!, ${error.message}`))
            .finally(() => this.setState({ loading: false }));
    };

    promiseOptions = (mode, inputValue) => {
        switch (mode) {
            case "product":
                return getSearhForProductAP(inputValue);
            case "client":
                return getSearhForCustomerAP(inputValue);
            case "oc":
                return getPurchaseOrderOnlyAP(inputValue);
            default:
                return getSearhForProviderAP(inputValue);
        }
    };

    searchDispatches = (mode, e) => {
        console.log(e);
        if (!e) {
            return this.setState({ search: null, dispatches: [] });
        }
        this.setState({ search: e, loading: true });
        switch (mode) {
            case "product":
                return getDispatchesProductsAP(e.value)
                    .then((dispatches) => this.setState({ dispatches }))
                    .catch((error) => console.log(error))
                    .finally(() => this.setState({ loading: false }));
            case "client":
                return getDispatchesClientAP(e.value)
                    .then((dispatches) => this.setState({ dispatches }))
                    .catch((error) => console.log(error))
                    .finally(() => this.setState({ loading: false }));
            case "oc":
                return getDispatchesOCAP(e.value)
                    .then((dispatches) => this.setState({ dispatches }))
                    .catch((error) => console.log(error))
                    .finally(() => this.setState({ loading: false }));
            default:
                return getDispatchesProviderAP(e.value)
                    .then((dispatches) => this.setState({ dispatches }))
                    .catch((error) => console.log(error))
                    .finally(() => this.setState({ loading: false }));
        }
    };

    renderMode = (mode, search, dispatches) => {
        switch (mode) {
            case "product":
                return <ConsultProduct search={search} dispatches={dispatches} />;
            case "client":
                return <ConsultClient search={search} dispatches={dispatches} />;
            case "oc":
                return <ConsultOC search={search} dispatches={dispatches} />;
            default:
                return <ConsultProvider search={search} dispatches={dispatches} />;
        }
    };
    renderHeader = (mode, search) => {
        switch (mode) {
            case "product":
                return (
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <table width="100%" className="table table-sm">
                                <tbody>
                                    <tr>
                                        <th width="20%">Código</th>
                                        <td width="80%" align="right">
                                            {search.code}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="25%">Bodega 01</th>
                                        <td width="80%" align="right">
                                            {search.whs01.length > 0 ? search.whs01.map((lc) => `${lc.shelf_id.code}${lc.level_id.code} `) : "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="25%">Bodega 05</th>
                                        <td width="80%" align="right">
                                            {search.whs05.length > 0 ? search.whs05.map((lc) => `${lc.shelf_id.code}${lc.level_id.code} `) : "-"}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6">
                                    <table width="100%" className="table table-sm">
                                        <tbody>
                                            <tr>
                                                <th width="30%">Stock</th>
                                                <td width="70%" align="right">
                                                    {search.on_hand}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Comprometido</th>
                                                <td width="70%" align="right">
                                                    {search.IsCommited}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-6">
                                    <table width="100%" className="table table-sm">
                                        <tbody>
                                            <tr>
                                                <th width="30%">Transito </th>
                                                <td width="70%" align="right">
                                                    {search.sap_onorder}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Disponible</th>
                                                <td width="70%" align="right">
                                                    {search.sap_available}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="col-md">
                            <table width="100%" className="table table-sm">
                                <tbody>
                                    <tr width="100%">
                                        <th>Descripción </th>
                                        <td width="100%" align="right">
                                            {search.name}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                );
            case "client":
                return (
                    <div className="row mt-2">
                        <div className="col-md-6">
                            <table width="100%" className="table table-sm">
                                <tbody>
                                    <tr>
                                        <th width="30%">Código</th>
                                        <td width="70%" align="right">
                                            {search.value}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="30%" />
                                        <td width="70%" align="right" />
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-6">
                            <table width="100%" className="table table-sm">
                                <tbody>
                                    <tr>
                                        <th width="30%">Cliente</th>
                                        <td width="70%" align="right">
                                            {search.customer}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="30%" />
                                        <td width="70%" align="right" />
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                );
            case "oc":
                return (
                    <div className="row mt-2">
                        <div className="col-md-6">
                            <table width="100%" className="table table-sm">
                                <tbody>
                                    <tr>
                                        <th width="30%">Código</th>
                                        <td width="70%" align="right">
                                            {search.code}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="30%">Proveedor</th>
                                        <td width="70%" align="right">
                                            {search.supplier}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="30%">Comentarios</th>
                                        <td width="70%" align="right">
                                            {search.comments}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="30%" />
                                        <td width="70%" align="right" />
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-6">
                            <table width="100%" className="table table-sm">
                                <tbody>
                                    <tr>
                                        <th width="30%">Estado</th>
                                        <td width="70%" align="right">
                                            {BagdeStatus(search.status)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="30%">Fecha Emisión</th>
                                        <td width="70%" align="right">
                                            {search.date && moment(search.date).format("DD-MM-YYYY")}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="30%">Fecha Llegada</th>
                                        <td width="70%" align="right">
                                            {search.llegada && moment(search.llegada).format("DD-MM-YYYY")}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="30%" />
                                        <td width="70%" align="right" />
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                );

            default:
                return (
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <table width="100%" className="table table-sm">
                                <tbody>
                                    <tr>
                                        <th width="30%">Código</th>
                                        <td width="70%" align="right">
                                            {search.value}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="30%" />
                                        <td width="70%" align="right" />
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                );
        }
    };

    render() {
        const { loading, mode, search, dispatches, from, to } = this.state;
        console.log(from, to);
        const breadcrumb = [
            { title: "Home", url: "/" },
            { title: "Logística", url: "/logistica-ap" },
            { title: "Logística AP", url: "/logistica/logistica-ap" },
            { title: "Consultar Despachos", url: "" },
        ];

        return (
            <React.Fragment>
                <Helmet>
                    <title>Consultar Despachos - Ingeniería y Controles Automatizados</title>
                </Helmet>

                <PageHeader header="Estado de Equipos Comprometidos" breadcrumb={breadcrumb} />
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <div className="p-grid">
                                <div className="p-col-9">
                                    <FormGroup>
                                        <Label className="mr-3">Buscar Por: </Label>
                                        <CustomInput
                                            type="radio"
                                            id="mode1"
                                            label="Producto"
                                            inline
                                            value="product"
                                            checked={mode === "product"}
                                            onChange={(e) => this.setState({ mode: e.target.value, search: null, dispatches: [] })}
                                        />
                                        <CustomInput
                                            type="radio"
                                            id="mode2"
                                            label="Cliente"
                                            inline
                                            value="client"
                                            checked={mode === "client"}
                                            onChange={(e) => this.setState({ mode: e.target.value, search: null, dispatches: [] })}
                                        />
                                        <CustomInput
                                            type="radio"
                                            id="mode3"
                                            label="Orden de Compra"
                                            inline
                                            value="oc"
                                            checked={mode === "oc"}
                                            onChange={(e) => this.setState({ mode: e.target.value, search: null, dispatches: [] })}
                                        />
                                        <CustomInput
                                            type="radio"
                                            id="mode4"
                                            label="Proveedor"
                                            inline
                                            value="proveedor"
                                            checked={mode === "proveedor"}
                                            onChange={(e) => this.setState({ mode: e.target.value, search: null, dispatches: [] })}
                                        />
                                        <AsyncSelect
                                            isClearable
                                            loadOptions={(input) => this.promiseOptions(mode, input)}
                                            noOptionsMessage={() => "No se Encontró registros..."}
                                            loadingMessage={() => "Buscando..."}
                                            placeholder="Buscar..."
                                            onChange={(e) => this.searchDispatches(mode, e)}
                                            value={search}
                                        />
                                    </FormGroup>
                                    {search && this.renderHeader(mode, search)}
                                </div>

                                <div className="p-col-2 offset-sm-1">
                                    <FormGroup>
                                        <Label for="from">Desde</Label>
                                        <Input
                                            type="date"
                                            id="from"
                                            defaultValue={from}
                                            placeholder="DD/MM/AAAA"
                                            onChange={(e) => this.setState({ from: e.target.value })}
                                        />
                                        <Label for="from">Hasta</Label>
                                        <Input
                                            type="date"
                                            id="to"
                                            defaultValue={to}
                                            placeholder="DD/MM/AAAA"
                                            onChange={(e) => this.setState({ to: e.target.value })}
                                        />
                                        <Button color="primary" className="mt-2" disabled={loading} onClick={() => this.generateExelReport()}>
                                            <i className="far fa-file-alt mr-2"></i>
                                            Reporte
                                        </Button>
                                    </FormGroup>
                                </div>
                            </div>

                            {loading ? (
                                <div>
                                    <br />
                                    <br />
                                    <div className="div-loading" />
                                    <br />
                                    <br />
                                </div>
                            ) : (
                                this.renderMode(mode, search, dispatches)
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
