// react
import React from 'react';

// third-party
import classNames from 'classnames';
import {
    Link,
    matchPath,
    Redirect,
    Switch,
    Route,
} from 'react-router-dom';
import { connect } from 'react-redux';

// application
import PageHeader from '../shared/PageHeader';

// pages
import AccountPageDashboard from './AccountPageDashboard';
import AccountPageQuotationList from './AccountPageQuotationList';
import AccountPageDeliveriesList from './AccountPageDeliveriesList';
import AccountPageReturnsList from './AccountPageReturnsList';
import AccountPageQuotationListAP from './AccountPageQuotationListAP';
import AccountPageQuotationListEPE from './AccountPageQuotationListEPE';
import AccountPageSurplus from './AccountPageSurplus';
import AccountPagePurchaseReports from './AccountPagePurchaseReports';
import AccountPagePassword from './AccountPagePassword';

import {
    logout,
    getPrivQuotations,
    getQuotationsAP,
    getQuotationsEPE
} from '../../store/directusSdk';
import { currentUserChange } from '../../store/currentUser';


function AccountLayout(props) {
    const { match, location, currentUser, currentUserChange } = props;

    if (!currentUser) {
        return <Redirect to="/account/login" />;
    }
    const sendLogout = (e) => {
        e.preventDefault();
        Promise.all([currentUserChange(null), logout()]);
    };

    const breadcrumb = [
        { title: 'Home', url: '/' },
        { title: 'Mi Cuenta', url: '' },
    ];

    const accountLinks = [
        { title: 'Dashboard', url: 'dashboard' },
        { title: 'Cambiar Contraseña', url: 'password' },
        { title: 'Salir', url: 'login' },
    ];

    const dispatches = ['Administrator', 'Almacenista ICA', 'Almacenista AP', 'Admin ICA', 'Admin AP', 'VendedorICA-Logistica'];
    const returs = ['Administrator', 'Ingeniero ICA', 'Ingeniero AP', 'Admin ICA', 'Admin AP',  'VendedorICA-Logistica'];
    const quotesICA = ['Administrator', 'Vendedor ICA', 'Admin ICA', 'VendedorICA-Logistica'];
    const quotesAP = ['Administrator', 'Vendedor AP', 'Admin AP'];
    const quotesEPE = ['Administrator', 'Vendedor EPE', 'Admin EPE'];
    const surplus = ['Administrator', 'Ingeniero ICA', 'Ingeniero AP', 'Admin ICA', 'Admin AP', 'Vendedor ICA', 'VendedorICA-Logistica'];
    const puchaseReports = ['Administrator', 'Admin ICA', 'Compas ICA',  'VendedorICA-Logistica'];

    if (surplus.includes(currentUser && currentUser.role.name)) {
        accountLinks.splice(1, 0, { title: 'Mis Sobrantes', url: 'sobrantes' });
    }
    if (puchaseReports.includes(currentUser && currentUser.role.name)) {
        accountLinks.splice(1, 0, { title: 'Mis Compras', url: 'compras' });
    }
    if (dispatches.includes(currentUser && currentUser.role.name)) {
        accountLinks.splice(1, 0, { title: 'Mis Entregas', url: 'envios' });
    }
    if (returs.includes(currentUser && currentUser.role.name)) {
        accountLinks.splice(1, 0, { title: 'Mis Devoluciones', url: 'devoluciones' });
    }
    if (quotesAP.includes(currentUser && currentUser.role.name)) {
        accountLinks.splice(1, 0, { title: 'Mis Cotizaciones AP', url: 'cotizaciones-ap' });
    }
    if (quotesICA.includes(currentUser && currentUser.role.name)) {
        accountLinks.splice(1, 0, { title: 'Mis Cotizaciones ICA', url: 'cotizaciones' });
    }
  
    const links = accountLinks.map((link) => {
        const url = `${match.url}/${link.url}`;
        const isActive = matchPath(location.pathname, { path: url, exact: true });
        const classes = classNames('account-nav__item', {
            'account-nav__item--active': isActive,
        });

        return (
            <li key={link.url} className={classes}>
                {link.url === 'login' ? <Link to="/account/logout" onClick={sendLogout}>{link.title}</Link> : <Link to={url}>{link.title}</Link>}
            </li>
        );
    });
    return (
        <React.Fragment>
            <PageHeader breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-3 d-flex">
                            <div className="account-nav flex-grow-1">
                                <h4 className="account-nav__title">Navegación</h4>
                                <ul>{links}</ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                            <Switch>
                                <Redirect exact from={match.path} to={`${match.path}/dashboard`} />
                                <Route exact path={`${match.path}/dashboard`} component={(props) => (<AccountPageDashboard {...props} currentUser={currentUser} />)} />
                                <Route exact path={`${match.path}/cotizaciones`} component={(props) => (<AccountPageQuotationList {...props} currentUser={currentUser} getPrivQuotations={getPrivQuotations} />)} />
                                <Route exact path={`${match.path}/cotizaciones-ap`} component={(props) => (<AccountPageQuotationListAP {...props} currentUser={currentUser} getPrivQuotations={getQuotationsAP} />)} />
                                <Route exact path={`${match.path}/cotizaciones-epe`} component={(props) => (<AccountPageQuotationListEPE {...props} currentUser={currentUser} getPrivQuotations={getQuotationsEPE} />)} />
                                <Route exact path={`${match.path}/envios`} component={(props) => (<AccountPageDeliveriesList {...props} currentUser={currentUser} />)} />
                                <Route exact path={`${match.path}/devoluciones`} component={(props) => (<AccountPageReturnsList {...props} currentUser={currentUser} />)} />
                                <Route exact path={`${match.path}/compras`} component={(props) => (<AccountPagePurchaseReports {...props} currentUser={currentUser} />)} />
                                <Route exact path={`${match.path}/sobrantes`} component={(props) => (<AccountPageSurplus {...props} currentUser={currentUser} />)} />
                                <Route exact path={`${match.path}/password`} component={AccountPagePassword} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    currentUser: state.currentUser,
});

const mapDispatchToProps = {
    currentUserChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountLayout);