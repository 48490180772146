// third-party
import React from "react";
import { MDBDataTableV5 } from "mdbreact";
import { Button } from "reactstrap";

export default function ExternalInventories({ products, loading , loading2}) {
    const dataTable = {
        columns: [
            { label: "SKU", field: "code_manufacture", width: 100 },
            { label: "Código", field: "code", width: 100 },
            { label: "Descripción", field: "name", width: 300 },
            { label: "Cantidad", field: "qty", width: 30 },
        ],
        rows: products,
    };

    const loadingTable = {
        pointerEvents: "none",
        opacity: " 0.5",
    };

    const exportExcelFormat = () => {
        const csvContent = "codigo;sku;qty;\r\n";
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", `${"Formato_inventario_de_proveedores".toUpperCase()}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <React.Fragment>
            <div className="container">
                <div className="card">
                    <div className="card-body">
                        <div style={{display:'flex' , alignContent:'center', justifyContent:'space-between'}}>
                            <h5 className="mt-3">Listado de productos</h5>
                            <Button color="info" className="mb-3 ml-2" onClick={exportExcelFormat}>
                                <i className="fas fa-file-download  mr-2" /> Formato
                            </Button>
                        </div>
                        <div className="t-loading-table-container" style={loading || loading2 ? loadingTable : { width: "1" }}>
                            <MDBDataTableV5
                                hover
                                entriesOptions={[25, 50, 75, 100]}
                                entries={25}
                                pagesAmount={4}
                                data={dataTable}
                                pagingTop
                                searchTop
                                searchBottom={false}
                                entriesLabel="Mostar"
                                searchLabel="Buscar"
                                infoLabel={["Viendo", "al", "de", "registros"]}
                                paginationLabel={["Anterior", "Siguiente"]}
                                noRecordsFoundLabel="No se han encontrado registros..."
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
