import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import PageHeader from '../shared/PageHeader';
import { getMyTasksByPry } from '../../store/directusSdk';

const MyTasks = ({ currentUser }) => {
    const [engprojects, setEngprojects] = useState([]);
    const [loading, setLoading] = useState(true);

    const searchData = () => {
        setLoading(true);
        return getMyTasksByPry(currentUser.id)
            .then((projects) => {
                setEngprojects(projects);
                setLoading(false);
            });
    }

    useEffect(() => {
        searchData();
        const interval = setInterval(() => searchData(), 120000);
        return () => clearInterval(interval);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps 

    useEffect(function setupListener() {
        function handleResize() {
            if (!document.hidden) {
                return searchData();
            }
        }
        window.addEventListener('visibilitychange', handleResize)

        return function cleanupListener() {
            window.removeEventListener('visibilitychange', handleResize)
        }
    });

    const searchPriory = (priory) => {
        switch (priory) {
            case 1:
                return '#67a8e4';
            case 2:
                return '#ffbb44';
            default:
                return '#90a4ae';
        }
    }

    const breadcrumb = [
        { title: 'Home', url: '/' },
        { title: 'Tareas', url: '/tasks-manager' },
        { title: 'Mis Tareas', url: '' },
    ];

    if (loading) {
        return (
            <div className="container">
                <div style={{ marginTop: '20em', marginBottom: '20em' }}>
                    <div className="div-loading" />
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Tareas por Proyecto - Ingeniería y Controles Automatizados</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="container">

                {engprojects.length < 1 ? (
                    <h5 align="center" className="mt-4">Actualmente No Posee Tareas Asignadas</h5>
                ) : (
                    <div className="p-grid mt-3">
                        {engprojects.map((eng, i) => (
                            <div key={i + 1} className="p-col-4 p-md-3 p-lg-2">
                                <Link to={`/tasks-manager/mi-tareas-por-proyecto/${eng.id}`}>
                                    <div className={`box p-shadow-${i + 1} `} style={{ height: 90, fontSize: '0.9em', background: searchPriory(eng.priory) }}>
                                        <div className="p-text-center p-text-bold" style={{ paddingTop: 35 }}>{eng.code}</div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export default MyTasks;