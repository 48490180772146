// react
import React, { useEffect, useState } from 'react';
// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { MDBDataTableV5 } from 'mdbreact';
import { Button, Badge } from 'reactstrap';
import { getEngprojectsTasks } from '../../store/directusSdk';
import PageHeader from '../shared/PageHeader';

export default function EngprojectList() {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);

    const GetProjects = () => getEngprojectsTasks()
        .then((engprojects) => {
            const proyectos = engprojects.map((e) => {
                let pendientes = 0;
                let pausadas = 0;
                let enPRoceso = 0;
                let terminadas = 0;
                e.activities.forEach((a) => {
                    if (a.status === 'Pendiente') {
                        pendientes++;
                    } else if (a.status === 'Pausada') {
                        pausadas++;
                    } else if (a.status === 'En Proceso') {
                        enPRoceso++;
                    } else if (a.status === 'Terminada') {
                        terminadas++;
                    }
                });
                e.code = e.main_project.code;
                e.client = e.main_project.name_cliente;
                e.name = e.main_project.name;
                e.activities = (<>
                    <Badge color="info">Pendientes: {pendientes}</Badge><br />
                    <Badge color="warning">Pausadas: {pausadas}</Badge><br />
                    <Badge color="primary">En Proceso: {enPRoceso}</Badge><br />
                    <Badge color="success">Terminadas: {terminadas}</Badge>
                </>);
                e.actions = <Link to={`/tasks-manager/engproject/${e.id}`}><Button outline color="info" title="Gestionar Proyecto"><i className="fa fa-edit" /></Button></Link>
                return e;
            });
            setProjects(proyectos);
        }).finally(() => setLoading(false));

    useEffect(() => {
        GetProjects();
    }, []);

    if (loading) {
        return (
            <div className="container">
                <div style={{ marginTop: '20em', marginBottom: '20em' }}>
                    <div className="div-loading" />
                </div>
            </div>
        );
    }

    const breadcrumb = [
        { title: 'Home', url: '/' },
        { title: 'Tareas', url: '/tasks-manager' },
        { title: 'Lista Proyectos', url: '' },
    ];

    const data = {
        columns: [
            { label: 'Código', field: 'code', width: 150 },
            { label: 'Proyecto', field: 'name', width: 300 },
            { label: 'Cliente', field: 'client', width: 200 },
            { label: 'Tareas', field: 'activities', width: 200 },
            { label: 'Acciones', field: 'actions', width: 70 },
        ],
        rows: projects,
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Lista de Proyectos - Ingeniería y Controles Automatizados</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />
            <div className="container">
                <div className="card">
                    <div className="card-body">
                        <h5 className="mt-3">Proyectos de Ingeneria</h5>
                        <MDBDataTableV5
                            responsive
                            hover
                            entriesOptions={[10, 25, 50]}
                            entries={10}
                            pagesAmount={4}
                            data={data}
                            pagingTop
                            searchTop
                            searchBottom={false}
                            entriesLabel="Mostar"
                            searchLabel="Buscar"
                            infoLabel={['Viendo', 'al', 'de', 'registros']}
                            paginationLabel={['Anterior', 'Siguiente']}
                            noRecordsFoundLabel="No se han encontrado registros..."
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}