// react
import React from "react";

// third-party
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { Redirect, Route, Switch } from "react-router-dom";

import { ToastContainer } from "react-toastify";

// application
import Footer from "./footer";
import Header from "./header";
import MenuPrincipal from "./menu/MenuPrincipal";
import MobileHeader from "./mobile/MobileHeader";
import MobileMenu from "./mobile/MobileMenu";
import Quickview from "./shared/Quickview";

// pages
import AccountLayout from "./account/AccountLayout";
import PageCart from "./shop/ShopPageCart";
import PageCartEdit from "./shop/ShopPageCartEdit";
import PageWishlist from "./shop/ShopPageWishlist";
import ShopPageCategory from "./shop/ShopPageCategory";
import ShopPage from "./shop/ShopPage";
import ShopPageSubCategory from "./shop/ShopPageSubCategory";
import ShopSearchProducts from "./shop/ShopSearchProducts";
import ShopPageProduct from "./shop/ShopPageProduct";
import SitePageNotFound from "./site/SitePageNotFound";
import ForgetPassword from "./account/ForgetPassword";
import ResetPassword from "./account/ResetPassword";
import ResponsePassword from "./account/ResponsePassword";

//panel
import pageManu from "./shop/index";

import Finance from "./finance";
import CashFlow from "./finance/cash-flow";
import SalesOrders from "./finance/cash-flow/SalesOrders";
import PurchaseOrders from "./finance/cash-flow/PurchaseOrders";
import BillPayment from "./finance/cash-flow/BillPayment";
import CreditsCollection from "./finance/cash-flow/CreditsCollection";

import CashFlowAp from "./finance/cash-flow-ap";
import SalesOrdersAp from "./finance/cash-flow-ap/SalesOrders";
import PurchaseOrdersAp from "./finance/cash-flow-ap/PurchaseOrders";
import BillPaymentAp from "./finance/cash-flow-ap/BillPayment";
import CreditsCollectionAp from "./finance/cash-flow-ap/CreditsCollection";

import Logistic from "./logistics";
import LogisticIca from "./logistics/logistics-ica/";
import Consult from "./logistics/logistics-ica/Consult";
import ConsultComprometidos from "./logistics/logistics-ica/ConsultComprometidos";
import newDispatch from "./logistics/logistics-ica/newDispatch";
import ManageDispatching from "./logistics/logistics-ica/ManageDispatching";

import LogisticAp from "./logistics/logistics-ap/index";
import newDispatchAp from "./logistics/logistics-ap/newDispatch";
import ConsultAp from "./logistics/logistics-ap/Consult";
import ConsultComprometidosAp from "./logistics/logistics-ap/ConsultComprometidos";
import ManageDispatchingAp from "./logistics/logistics-ap/ManageDispatching";

import HomeEngprojects from "./engprojects/index";
import ConfirmDelivery from "./engprojects/ConfirmDelivery";
import EngprojectList from "./engprojects/EngprojectList";
import NewEngproject from "./engprojects/NewEngproject";
import EngprojectManage from "./engprojects/EngprojectManage";
import EngprojectPrint from "./engprojects/GeneratePDF";

import HomeAlmacen from "./warehouses/index";
import EngprojectListA from "./warehouses/EngprojectList";
import EngprojectManageA from "./warehouses/EngprojectManage";
import Orders from "./warehouses/Orders";
import ProductsSended from "./warehouses/ProductsSended";
import PrintProductsDelivery from "./warehouses/PrintProductsDelivery";
import ParticularInventory from "./warehouses/ParticularInventory";
import ReceiveDispatches from "./logistics/logistics-ica/ReceiveDispatches";
import ResultInventory from "./warehouses/ResultInventory";
import ResultInventory05 from "./warehouses/ResultInventory05";
import CruceContado from "./warehouses/CruceContado";
import ExternalInventories from "./warehouses/ExternalInventories";
import InventorySurplus from "./warehouses/inventories/inventories-ica/InventoryManagement";
import ConsultSurplus from "./warehouses/ConsultSurplus";
import HomaInventories from "./warehouses/inventories/index";

import homeInventoriesIca from "./warehouses/inventories/inventories-ica/index";
import InventoryManagementSurplus from "./warehouses/inventories/inventories-ap/InventoryManagementSurplus";
import InventoryManagementClient from "./warehouses/inventories/inventories-ap/InventoryManagementClient";
import InventoryManagementExternal from "./warehouses/inventories/inventories-ap/InventoryManagementExternal";



import homeInventoriesAp from "./warehouses/inventories/inventories-ap/index";
import InventoryManagementSurplusIca from "./warehouses/inventories/inventories-ica/InventoryManagementSurplus";
import InventoryManagementClientIca from "./warehouses/inventories/inventories-ica/Inventories-customer/InventoryManagementClient";
import InventoryManagementExternaltIca from "./warehouses/inventories/inventories-ica/InventoryManagementExternal";
import InventorySearch from "./warehouses/inventories/inventories-ica/inventories-Search/InventorySearch";

import ReceiveDispatchesAp from "./logistics/logistics-ap/ReceiveDispatches";

import buy from "./buy/index";

import Shopping from "./buy/buy-ica/Shopping";

import MissingSAPPDF from "./buy/buy-ica/MissingSAPPDF";
import MissingEngprojectPDF from "./buy/buy-ica/MissingEngprojectPDF";

import ShoppingAp from "./buy/buy-ap/Shopping";

import CartAP from "./quotation_ap/CartAP";

import CusEngproject from "./cus_engprojects/EngprojectList";
import CusEngprojectManage from "./cus_engprojects/EngprojectManage";
import CusInventory from "./cus_warehouses/Inventory";

import HomeTask from "./tasks/index";
import ListEngprojects from "./tasks/ListEngprojects";
import MyTasks from "./tasks/MyTasks";
import MyTasksByPry from "./tasks/MyTasksByPry";
import ListProjectTasks from "./tasks/ListProjectTasks";
import ProjectTasksDetails from "./tasks/ProjectTasksDetails";
import TasksShared from "./tasks/TasksShared";

import CartEPE from "./quotation_epe/CartEPE";

function Layout(props) {
    const { headerLayout, pagesContent, currentUser, history } = props;

    return (
        <React.Fragment>
            <Helmet>
                <title>ICAutomatizados S.A</title>
                <meta name="description" content="ICAutomatizados S.A" />
            </Helmet>

            <ToastContainer autoClose={5000} hideProgressBar />

            <Quickview />

            <MobileMenu mobileMenu={currentUser ? currentUser.menu : []} />

            <div className="site">
                <header className="site__header d-lg-none">
                    <MobileHeader layout={headerLayout} currentUser={currentUser} />
                </header>

                <header className="site__header d-lg-block d-none">
                    <Header layout={headerLayout} currentUser={currentUser} history={history} pagesContent={pagesContent} />
                </header>

                <div className="site__body">
                    <Switch>
                        {!currentUser && <Redirect to="/login" />}
                        {/* Menu */}
                        <Route exact path="/" component={MenuPrincipal} />
                        {/* presupuesto de materiales */}
                        <Route
                            exact
                            path="/presupuesto-materiales"
                            render={(props) => <ShopPage {...props} columns={3} viewMode="grid" sidebarPosition="start" />}
                        />
                        {/* ventas */}

                        <Route exact path="/ventas" component={pageManu} />
                        <Route exact path="/ventas/cotizacion-ica" component={PageCart} />
                        <Route
                            exact
                            path="/ventas/productos-ica"
                            render={(props) => <ShopPage {...props} columns={3} viewMode="grid" sidebarPosition="start" />}
                        />
                        <Route exact path="/ventas/cart" component={PageCart} />
                        <Route exact path="/ventas/cotizacion-ap" component={CartAP} />
                        <Route exact path="/ventas/cart-edit" component={PageCartEdit} />
                        <Route exact path="/ventas/wishlist" component={PageWishlist} />
                        <Route exact path="/ventas/product/:code/:id" component={ShopPageProduct} />
                        <Route
                            exact
                            path="/ventas/search/:string"
                            render={(props) => <ShopSearchProducts {...props} columns={4} viewMode="list" />}
                        />
                        <Route exact path="/ventas/categoria/:category/:id" component={ShopPageCategory} />
                        <Route
                            exact
                            path="/ventas/categoria/:category/subcategoria/:subcategory/:cat_id/:subcat_id"
                            render={(props) => <ShopPageSubCategory {...props} columns={4} viewMode="list" sidebarPosition="start" />}
                        />

                        {/* Finanzas */}
                        <Route exact path="/finanzas" component={Finance} />
                        <Route exact path="/finanzas/cash-flow" component={CashFlow} />
                        <Route exact path="/finanzas/cash-flow/ordenes-de-compra" component={PurchaseOrders} />
                        <Route exact path="/finanzas/cash-flow/pago-a-proveedores" component={BillPayment} />
                        <Route exact path="/finanzas/cash-flow/ordenes-de-venta" component={SalesOrders} />
                        <Route exact path="/finanzas/cash-flow/credito-y-cobro" component={CreditsCollection} />

                        {/* Finanzas AP*/}
                        <Route exact path="/finanzas/cash-flow-ap" component={CashFlowAp} />
                        <Route exact path="/finanzas/cash-flow-ap/ordenes-de-compra" component={PurchaseOrdersAp} />
                        <Route exact path="/finanzas/cash-flow-ap/pago-a-proveedores" component={BillPaymentAp} />
                        <Route exact path="/finanzas/cash-flow-ap/ordenes-de-venta" component={SalesOrdersAp} />
                        <Route exact path="/finanzas/cash-flow-ap/credito-y-cobro" component={CreditsCollectionAp} />

                        {/* compras */}
                        <Route exact path="/compras" component={buy} />

                        <Route exact path="/compras/compras-ica" component={Shopping} />

                        <Route exact path="/compras/imprimir/faltantesSAP" component={MissingSAPPDF} />
                        <Route exact path="/compras/imprimir/faltantesProyectos" component={MissingEngprojectPDF} />

                        {/* compras */}
                        <Route exact path="/compras/compras-ap" component={ShoppingAp} />

                        {/* PRoyectos de ingeneria */}
                        <Route exact path="/proyectos-de-ingeneria" component={HomeEngprojects} />
                        <Route exact path="/proyectos-de-ingeneria/confirmar-entregas" component={ConfirmDelivery} />
                        <Route exact path="/proyectos-de-ingeneria/listado" component={EngprojectList} />
                        <Route exact path="/proyectos-de-ingeneria/nuevo-proyecto" component={NewEngproject} />
                        <Route
                            exact
                            path="/proyectos-de-ingeneria/:id"
                            component={(props) => <EngprojectManage {...props} currentUser={currentUser} />}
                        />
                        <Route exact path="/proyectos-de-ingeneria/imprimir/:id" component={EngprojectPrint} />
                        {/* Logistica */}
                        <Route exact path="/logistica" component={Logistic} />

                        <Route exact path="/logistica/logistica-ica" component={LogisticIca} />
                        <Route exact path="/logistica/logistica-ica/nuevo-despacho" component={newDispatch} />
                        <Route exact path="/logistica/logistica-ica/consultar-despachos" component={Consult} />
                        <Route exact path="/logistica/logistica-ica/gestionar-despacho" component={ManageDispatching} />
                        <Route exact path="/logistica/logistica-ica/consultar-comprometidos" component={ConsultComprometidos} />

                        {/* Logistica AP */}
                        <Route exact path="/logistica/logistica-ap" component={LogisticAp} />
                        <Route exact path="/logistica/logistica-ap/nuevo-despacho" component={newDispatchAp} />
                        <Route exact path="/logistica/logistica-ap/consultar-despachos" component={ConsultAp} />
                        <Route exact path="/logistica/logistica-ap/consultar-comprometidos" component={ConsultComprometidosAp} />
                        <Route exact path="/logistica/logistica-ap/gestionar-despacho" component={ManageDispatchingAp} />

                        {/* Almacen */}
                        <Route exact path="/almacen" component={HomeAlmacen} />
                        <Route exact path="/almacen/materiales-por-entregar" render={(props) => <Orders {...props} currentUser={currentUser} />} />
                        <Route exact path="/almacen/proyectos-de-ingeneria/listado" component={EngprojectListA} />
                        <Route exact path="/almacen/proyectos-de-ingeneria/:id" component={EngprojectManageA} />
                        <Route exact path="/almacen/productos-entregados" component={ProductsSended} />
                        <Route exact path="/almacen/productos-entregados/imprimir" component={PrintProductsDelivery} />
                        <Route exact path="/almacen/inventario-particular" component={ParticularInventory} />
                        <Route exact path="/almacen/recibir-despachos-ica" component={ReceiveDispatches} />
                        <Route exact path="/almacen/recibir-despachos-ap" component={ReceiveDispatchesAp} />
                        <Route exact path="/almacen/resultado-de-inventario" component={ResultInventory} />
                        <Route exact path="/almacen/resultado-de-inventario-05" component={ResultInventory05} />
                        <Route exact path="/almacen/cruce-con-contado" component={CruceContado} />
                        <Route exact path="/almacen/inventarios-externos" component={ExternalInventories} />
                        <Route exact path="/almacen/inventario-sobrante" component={InventorySurplus} />
                        <Route exact path="/almacen/consultar-sobrantes" component={ConsultSurplus} />

                        <Route exact path="/inventarios" component={HomaInventories} />
                        {/*inventarios-ap*/}
                        <Route exact path="/inventarios/gestion-de-inventarios-ap" component={homeInventoriesAp} />
                        <Route exact path="/inventarios/gestion-de-inventarios-ap/inventarios-sobrantes" component={InventoryManagementSurplus} />
                        <Route exact path="/inventarios/gestion-de-inventarios-ap/inventario-de-clientes" component={InventoryManagementClient} />

                        <Route exact path="/inventarios/gestion-de-inventarios-ap/inventarios-proveedores" component={InventoryManagementExternal} />
                

                        {/*inventarios-ICA*/}

                        <Route exact path="/inventarios/gestion-de-inventarios-ica" component={homeInventoriesIca} />

                        <Route exact path="/inventarios/gestion-de-inventario-ica/inventarios-sobrantes" component={InventoryManagementSurplusIca} />

                        <Route exact path="/inventarios/gestion-de-inventario-ica/inventarios-de-clientes" component={InventoryManagementClientIca} />
                        <Route
                            exact
                            path="/inventarios/gestion-de-inventario-ica/inventarios-proveedores"
                            component={InventoryManagementExternaltIca}
                        />

                        <Route
                            exact
                            path="/inventarios/buscar-en-inventarios"
                            component={InventorySearch}
                        />

                        {/* Cotización AP */}

                        {/* Cotización EPE */}
                        <Route exact path="/cotizacion-epe" component={CartEPE} />
                        {/* Tasks Manager */}
                        <Route exact path="/tasks-manager" component={(props) => <HomeTask {...props} currentUser={currentUser} />} />
                        <Route
                            exact
                            path="/tasks-manager/listado-de-proyectos"
                            component={(props) => <ListEngprojects {...props} currentUser={currentUser} />}
                        />
                        <Route
                            exact
                            path="/tasks-manager/tareas-pendientes"
                            component={(props) => <ListProjectTasks {...props} currentUser={currentUser} />}
                        />
                        <Route exact path="/tasks-manager/mis-tareas" component={(props) => <MyTasks {...props} currentUser={currentUser} />} />
                        <Route
                            exact
                            path="/tasks-manager/mi-tareas-por-proyecto/:id"
                            component={(props) => <MyTasksByPry {...props} currentUser={currentUser} />}
                        />
                        <Route
                            exact
                            path="/tasks-manager/tareas-compartidas"
                            component={(props) => <TasksShared {...props} currentUser={currentUser} />}
                        />
                        <Route
                            exact
                            path="/tasks-manager/engproject/:id"
                            component={(props) => <ProjectTasksDetails {...props} currentUser={currentUser} />}
                        />
                        {/* Account */}
                        <Route path="/account" component={AccountLayout} />
                        {/* Site */}
                        <Route exact path="/site/not-found" component={SitePageNotFound} />
                        <Route exact path="/cuenta/recuperar-contrasena" component={(props) => <ForgetPassword {...props} />} />
                        <Route exact path="/cuenta/reestablecer-contrasena" component={(props) => <ResetPassword {...props} />} />
                        <Route exact path="/cuenta/reestablecer-contrasena/:result" component={(props) => <ResponsePassword {...props} />} />
                        {/* Mis Proyectos */}
                        <Route exact path="/mis-proyectos" component={(props) => <CusEngproject {...props} currentUser={currentUser} />} />
                        <Route exact path="/mis-proyectos/:id" component={(props) => <CusEngprojectManage {...props} currentUser={currentUser} />} />
                        {/* Mi Inventario */}
                        <Route exact path="/mi-inventario" component={(props) => <CusInventory {...props} currentUser={currentUser} />} />
                        {/* Page Not Found */}
                        <Route component={SitePageNotFound} />
                    </Switch>
                </div>

                <footer className="site__footer">
                    <Footer />
                </footer>
            </div>
        </React.Fragment>
    );
}

Layout.propTypes = {
    /**
     * header layout (default: 'classic')
     * one of ['classic', 'compact']
     */
    headerLayout: PropTypes.oneOf(["default", "compact"]),
};
const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
    pagesContent: state.pagesContent,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
