// react
import React, { Component } from 'react';
// third-party
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import swal from 'sweetalert';
import { InputNumber } from 'primereact/inputnumber';
import { Column } from 'primereact/column';
import { Button } from 'reactstrap';
import moment from 'moment';
import { UpdateItems } from '../../store/directusSdk';
import './tableStyle.css';

class ProductsTableConfirm extends Component {
    constructor(props) {
        super(props);
        this.options = [
            { label: 'P1', value: 'P1' },
            { label: 'P2', value: 'P2' },
            { label: 'P3', value: 'P3' },
            { label: 'P4', value: 'P4' }
        ]
        this.state = {
            selectedRows: [],
            newChangesLines: [],
            whsSelect: null,
            loading: false,
            prioritySelect: null
        };
    }

    AddChangeMovements = (e, key, value) => {
        const { newChangesLines } = this.state;
        const { SetChangesLines, table, currentUser } = this.props;
        const i = newChangesLines.findIndex((item) => item.id === e.id);

        if (i !== -1) {
            newChangesLines[i][`${key}`] = value;
            newChangesLines[i].confirmed_date = moment().format('YYYY-MM-DD HH:mm:ss');
            newChangesLines[i].confirmed_by = currentUser.id;
            return this.setState({ newChangesLines }, () => SetChangesLines(table, newChangesLines));
        }
        const newChange = { id: e.id };
        newChange[`${key}`] = value;
        newChange.confirmed_date = moment().format('YYYY-MM-DD HH:mm:ss');
        newChange.confirmed_by = currentUser.id;
        newChangesLines.push(newChange)
        return this.setState({ newChangesLines }, () => SetChangesLines(table, newChangesLines));
    };

    PedidoTemplate = (row) => {
        const { status } = this.props;
        if (row.movements.length > 0 || status === 'finished') {
            return row.order;
        }
        return <InputNumber value={row.order} onValueChange={(e) => this.AddChange(row, 'cant_pedido', e.value)} min={1} showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-info" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" size={1} />
    }

    exportCSV = () => {
        return this.dt.exportCSV();
    }

    ChangeQty = (value, i) => {
        const { products, setProducts } = this.props;
        products[i].confirmed_qty = value;
        setProducts(products);
    }

    confirmDeliveries = () => {
        const { selectedRows } = this.state;

        if (selectedRows.length > 0) {
            this.setState({ loading: true });
            const { currentUser, table, GetProjects, setProducts } = this.props;
            const confirmMayor = [];
            const confirmOwner = [];

            const deliveries = selectedRows.map((e) => {
                if (e.confirmed_qty > e.delivered_qty) {
                    confirmMayor.push(e.id);
                }

                if (e.owner != currentUser.id) {
                    confirmOwner.push(e.id);
                }

                return ({ id: e.id, confirmed_date: moment().format('YYYY-MM-DD HH:mm:ss'), confirmed_qty: e.confirmed_qty, confirmed_by: currentUser.id });
            });
            const collection = table === 'ICA' ? 'engprojectlinesmovementsica' : 'engprojectlinesmovementsap';
            if (confirmMayor.length > 0 || confirmOwner.length > 0) {
                let text = '';
                if (confirmMayor.length > 0) {
                    text += `En ${confirmMayor.length} registro${confirmMayor.length > 1 ? 's' : ''}, la cantidad confirmada es mayor a la Enviada.\n\n`;
                }
                if (confirmOwner.length > 0) {
                    text += `Esta intentando confirmar ${confirmOwner.length} envios que no ha solicitado.\n\n`;
                }
                text += '¿Desea continuar?';

                return swal({
                    title: 'Confirmar Entregas',
                    text,
                    icon: 'warning',
                    buttons: true,
                    dangerMode: true,
                })
                    .then((value) => {
                        if (value) {
                            return UpdateItems(collection, deliveries)
                                .then(() => {
                                    GetProjects(collection, setProducts).then(() => toast.success('¡Entrgas confirmadas con Exito!'))
                                })
                                .catch((error) => toast.error(`¡Error!, ${error.message}`))
                                .finally(() => this.setState({ loading: false, selectedRows: [] }));
                        }
                        this.setState({ loading: false });
                    });
            }

            return UpdateItems(collection, deliveries)
                .then(() => {
                    GetProjects(collection, setProducts).then(() => toast.success('¡Entrgas confirmadas con Exito!'))
                })
                .catch((error) => toast.error(`¡Error!, ${error.message}`))
                .finally(() => this.setState({ loading: false, selectedRows: [] }));
        }
    }

    onWhsChange = (e) => {
        this.setState({ whsSelect: e.value });
        return this.dt.filter(e.value, 'warehouse', 'equals');
    };

    onPriorityChange = (e) => {
        this.setState({ prioritySelect: e.value });
        return this.dt.filter(e.value, 'priority', 'equals');
    };



    render() {
        const { selectedRows, loading, whsSelect, prioritySelect } = this.state;
        const { products, table } = this.props;
        const warehouses = products.reduce((tempArray, e) => (tempArray.includes(e.warehouse) ? tempArray : [...tempArray, e.warehouse]), []);
        const whsFilter = <Dropdown style={{ width: '100%' }} value={whsSelect} options={warehouses} onChange={this.onWhsChange} placeholder="Todos..." className="p-column-filter" showClear />
        const whsPriority = <Dropdown style={{ width: '100%' }} value={prioritySelect} options={this.options} onChange={this.onPriorityChange} placeholder="Todos..." showClear />

        const header = (
            <div className="row">
                <div className="col-md-6">
                    <h5>{`Entregas por Confirmar ${table}`}</h5>
                </div>
                <div className="col-md-2 offset-md-4">
                    <Button color="info" onClick={this.exportCSV}><i className="fas fa-file-export mr-2" />Exportar</Button>
                </div>
            </div>
        );

        const footer = (
            <div className="row">
                <div className="col-md-4 ">
                    <Button color="primary" disabled={selectedRows.length < 1 || loading} onClick={this.confirmDeliveries}><i className={`${loading ? 'fas fa-cog fa-spin' : 'fas fa-check-double'} mr-2`} />Confirmar</Button>
                </div>
            </div>
        );

        return (
            <div className="datatable-rowexpansion-demo">
                <div className="card">
                    <DataTable
                        ref={(el) => this.dt = el}
                        value={products}
                        dataKey="id"
                        header={header}
                        footer={footer}
                        paginator
                        rows={10}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        removableSort
                        emptyMessage="No se han encontrado registros..."
                        className="p-datatable-customers"
                        rowHover
                        selection={selectedRows}
                        onSelectionChange={(e) => this.setState({ selectedRows: e.value })}
                    >
                        <Column selectionMode="multiple" style={{ width: '3em' }} />
                        <Column field="pry_code" header="Proyecto" sortable filter style={{ width: '10%' }} filterPlaceholder="Todos" />
                        <Column field="code" header="Código" sortable filter style={{ width: '14%' }} filterPlaceholder="Todos" />
                        <Column field="name" header="Producto" sortable filter style={{ width: '25%' }} filterMatchMode="contains" filterPlaceholder="Todos" />
                        <Column field="priority" header="Prioridad" sortable filter style={{ width: '12%' }} filterPlaceholder="Todos" filterElement={whsPriority} />
                        <Column field="warehouse" header="Bodega" sortable filter style={{ width: '14%' }} filterPlaceholder="Todos" filterElement={whsFilter} />
                        <Column field="delivered_qty" header="Entregado" sortable filter style={{ width: '10%' }} filterType="number" filterPlaceholder="Todos" />
                        <Column field="confirmed_qty" header="Recibido" style={{ width: '12%' }} body={(row, i) => <InputNumber inputStyle={{ width: '3em' }} value={row.confirmed_qty} onValueChange={({ value }) => this.ChangeQty(value, i.rowIndex)} min={1} showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-info" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" size={1} />} />
                    </DataTable>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsTableConfirm);
