// react
import React, { Component } from "react";
// third-party
import { Helmet } from "react-helmet-async";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from "reactstrap";
import moment from "moment";
import Currency from "../../shared/Currency";
import { CashFlowSalesOrders, DeleteItem, UpdateItem, CreateItem } from "../../../store/directusSdk";
import swal from "sweetalert";
import PageHeader from "../../shared/PageHeader";
import { toast } from "react-toastify";
import { cashFlowFilter, planificationMode } from "../../shared/utilities";

export default class SalesOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            expandAll: false,
            expandedRows: null,
            loading: false,
            loading2: false,
            open: false,
            planned: null,
            plannedOld: null,
            mode: "N",
            param: "A",
        };
    }

    collapseAll = () => this.setState({ expandedRows: null, expandAll: false });

    expandAll = () => {
        const { orders } = this.state;
        const expandedRows = {};

        if (orders.length > 0) {
            orders.forEach((p) => {
                expandedRows[`${p.id}`] = true;
            });
            return this.setState({ expandedRows, expandAll: true });
        }
    };

    exportCSV = () => {
        return this.dt.exportCSV();
    };

    actionsTemplate = (row, ov) => {
        return row.status === "P" ? (
            <i className="fa fa-check fa-2x" title="COBRADO" style={{ color: "#28a765" }} />
        ) : (
            <>
                <Button
                    outline
                    color="success"
                    size="sm"
                    title="Procesar Cobro"
                    onClick={() => this.setState({ planned: { ...row, date: moment().format("YYYY-MM-DD") }, open: true, mode: "P" })}
                >
                    <i className="fa fa-check" />
                </Button>
                <Button
                    outline
                    color="info"
                    size="sm"
                    title="Editar Cobro"
                    className="ml-2"
                    onClick={() => this.setState({ planned: { ...row }, plannedOld: row, open: true, mode: "N" })}
                >
                    <i className="fa fa-edit" />
                </Button>
                <Button outline color="danger" size="sm" title="Eliminar Cobro" className="ml-2" onClick={() => this.Remove(row, ov)}>
                    <i className="fa fa-trash" />
                </Button>
            </>
        );
    };

    Remove = (row, ov) =>
        swal({
            title: "Eliminar Planificación",
            text: `¿Desea eliminar Planificación # ${row.id} de la Orden de Venta #${ov}?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((value) => {
            if (value) {
                return DeleteItem("fnza_sales_orders_planned", row.id)
                    .then(async () => {
                        await this.getOrders();
                        return swal("Exito!", "Se ha borrado el registro con exito!", "success");
                    })
                    .catch((error) => swal("Error!", error.message, "error"));
            }
        });

    rowExpansionTemplate = (row) => (
        <div className="orders-subtable">
            {row.planneds.length > 0 ? (
                <DataTable value={row.planneds} className="p-datatable-sm">
                    <Column field="id" header="ID" style={{ width: "6%" }} />
                    <Column field="date" header="FECHA" style={{ width: "19%" }} body={(row) => row.date && moment(row.date).format("DD-MM-YYYY")} />
                    <Column field="amount" header="MONTO" style={{ width: "19%" }} body={(row) => <Currency value={row.amount} />} />
                    <Column
                        field="date_paid"
                        header="FECHA COBRADO"
                        style={{ width: "19%" }}
                        body={(row) => row.date_paid && moment(row.date_paid).format("DD-MM-YYYY")}
                    />
                    <Column
                        field="amount_paid"
                        header="MONTO COBRADO"
                        style={{ width: "19%" }}
                        body={(row) => row.amount_paid && <Currency value={row.amount_paid} />}
                    />
                    <Column header="ACCIONES" body={(line) => this.actionsTemplate(line, row.code)} style={{ width: "18%" }} />
                </DataTable>
            ) : (
                <p className="mt-3 text-center">No hay Planificación para esta Orden de Venta.</p>
            )}

            <Button
                className="mt-3"
                color="primary"
                size="sm"
                title="Nueva Planificación"
                onClick={() =>
                    this.setState({
                        planned: { amount: row.owed > 0 ? row.owed : 0, date: moment().format("YYYY-MM-DD"), sale_id: row.id, received: false },
                        open: true,
                        mode: "A",
                    })
                }
            >
                <i className="fa fa-plus mr-2" />
                Agregar
            </Button>
        </div>
    );

    getOrders = () => {
        const { param } = this.state;
        this.setState({ loading: true, expandAll: false, expandedRows: null });

        return CashFlowSalesOrders(param)
            .then((orders) => this.setState({ orders }))
            .finally(() => this.setState({ loading: false }));
    };

    Change = (key, value) => {
        const { planned } = this.state;
        planned[key] = value;
        return this.setState(planned);
    };

    save = (mode) => {
        this.setState({ loading2: true });
        const { planned } = this.state;

        switch (mode) {
            case "A":
                const data = { date: planned.date, amount: planned.amount, sale_id: planned.sale_id };

                if (planned.received) {
                    data.status = "P";
                    data.date_paid = planned.date;
                    data.amount_paid = planned.amount;
                }
                return CreateItem("fnza_sales_orders_planned", data)
                    .then(async () => {
                        await this.getOrders();
                        this.setState({ open: false });
                        return toast.success("¡Planificación Creada con Exito!");
                    })
                    .catch((error) => toast.error("¡Error!, " + error.message))
                    .finally(() => this.setState({ loading2: false }));
            case "P":
                return UpdateItem("fnza_sales_orders_planned", planned.id, { date_paid: planned.date, amount_paid: planned.amount, status: "P" })
                    .then(async () => {
                        await this.getOrders();
                        this.setState({ open: false });
                        return toast.success("¡Planificación Procesada con Exito!");
                    })
                    .catch((error) => toast.error("¡Error!, " + error.message))
                    .finally(() => this.setState({ loading2: false }));
            default:
                const { plannedOld } = this.state;
                if (planned && (plannedOld.date !== planned.date || plannedOld.amount !== planned.amount)) {
                    const row = {};

                    if (plannedOld.date !== planned.date) {
                        row.date = planned.date;
                    }

                    if (plannedOld.amount !== planned.amount) {
                        row.amount = planned.amount;
                    }

                    return UpdateItem("fnza_sales_orders_planned", planned.id, row)
                        .then(async () => {
                            await this.getOrders();
                            this.setState({ open: false });
                            return toast.success("¡Planificación Modificada con Exito!");
                        })
                        .catch((error) => toast.error("¡Error!, " + error.message))
                        .finally(() => this.setState({ loading2: false }));
                }
                this.setState({ open: false });
        }
    };

    componentDidMount() {
        this.getOrders();
    }

    ChangeParam = (e) => this.setState({ param: e.target.value }, this.getOrders);

    render() {
        const { loading, orders, expandAll, expandedRows, planned, open, loading2, mode, param } = this.state;

        const breadcrumb = [
            { title: "Home", url: "/" },
            { title: "Finanzas", url: "/finanzas" },
            { title: "Cash Flow ICA", url: "/finanzas/cash-flow" },
            { title: "Ordenes de Venta", url: "" },
        ];

        const header = (
            <div className="row">
                <div className="col-md-3">
                    <h5 className="mt-2">ORDENES DE VENTA</h5>
                    {cashFlowFilter(param, this.ChangeParam)}
                </div>
                <div className="col-md-2 offset-md-4">
                    {expandAll ? (
                        <Button color="warning" onClick={this.collapseAll}>
                            <i className="fas fa-angle-double-right mr-2" />
                            Contraer
                        </Button>
                    ) : (
                        <Button color="info" className="mr-2" onClick={this.expandAll}>
                            <i className="fas fa-angle-double-down mr-2" />
                            Expandir
                        </Button>
                    )}
                </div>
                <div className="col-md-2">
                    <Button color="info" onClick={this.exportCSV}>
                        <i className="fas fa-file-export mr-2" />
                        Exportar
                    </Button>
                </div>
            </div>
        );

        return (
            <React.Fragment>
                <Helmet>
                    <title>ORDENES DE VENTA - Ingeniería y Controles Automatizados</title>
                </Helmet>

                <PageHeader breadcrumb={breadcrumb} />
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            {loading ? (
                                <div>
                                    <br />
                                    <br />
                                    <div className="div-loading" />
                                    <br />
                                    <br />
                                </div>
                            ) : (
                                <DataTable
                                    ref={(el) => {
                                        this.dt = el;
                                    }}
                                    value={orders}
                                    header={header}
                                    dataKey="id"
                                    paginator
                                    rows={10}
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                    removableSort
                                    emptyMessage="No se han encontrado registros..."
                                    exportFilename="ordenes-de-venta"
                                    expandedRows={expandedRows}
                                    onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                                    rowExpansionTemplate={this.rowExpansionTemplate}
                                    className="p-datatable-dispatches p-datatable-sm mt-1 mt-4"
                                >
                                    <Column expander style={{ width: "4%" }} />
                                    <Column field="code" header="ORDEN #" sortable filter style={{ width: "14%" }} filterPlaceholder="Todos" />
                                    <Column
                                        field="customer"
                                        header="CLIENTE"
                                        sortable
                                        filter
                                        style={{ width: "42%" }}
                                        filterMatchMode="contains"
                                        filterPlaceholder="Todos"
                                    />
                                    <Column
                                        field="subscriber"
                                        header="ABONADO"
                                        body={(row) => row.subscriber && <Currency value={row.subscriber} />}
                                        sortable
                                        filter
                                        style={{ width: "13%" }}
                                        filterType="number"
                                        filterPlaceholder="Mayor a"
                                        filterMatchMode="gte"
                                    />
                                    <Column
                                        field="owed"
                                        header="ADEUDADO"
                                        body={(row) => row.owed && <Currency value={row.owed} />}
                                        sortable
                                        filter
                                        style={{ width: "13%" }}
                                        filterType="number"
                                        filterPlaceholder="Mayor a"
                                        filterMatchMode="gte"
                                    />
                                    <Column
                                        field="doctotal"
                                        header="TOTAL"
                                        body={(row) => row.doctotal && <Currency value={row.doctotal} />}
                                        sortable
                                        filter
                                        style={{ width: "12%" }}
                                        filterType="number"
                                        filterPlaceholder="Mayor a"
                                        filterMatchMode="gte"
                                    />
                                </DataTable>
                            )}
                        </div>
                    </div>
                </div>
                {planned && (
                    <Modal isOpen={open} centered size="md">
                        <ModalHeader toggle={() => this.setState({ open: false, planned: null })}>
                            {planificationMode(mode, planned.id, "PAGO")}
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label for="exampleDate">Fecha</Label>
                                <Input
                                    type="date"
                                    name="date"
                                    id="exampleDate"
                                    placeholder="DD/MM/AAAA"
                                    value={planned.date}
                                    onChange={(e) => this.Change("date", e.target.value)}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="currency-us">Monto</Label>
                                <Input
                                    type="number"
                                    name="amount"
                                    id="currency-us"
                                    placeholder="Monto"
                                    value={planned.amount}
                                    onChange={(e) => this.Change("amount", e.target.value)}
                                />
                            </FormGroup>
                            {mode == "A" && (
                                <FormGroup check inline>
                                    <Label check>
                                        <Input type="checkbox" value={planned.received} onChange={(e) => this.Change("received", e.target.checked)} />
                                        Recibido
                                    </Label>
                                </FormGroup>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => this.setState({ open: false, planned: null })}>
                                Cancelar
                            </Button>
                            <Button color="primary" disabled={loading2} onClick={() => this.save(mode)}>
                                {" "}
                                Guardar
                            </Button>
                        </ModalFooter>
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}
