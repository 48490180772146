// react
import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export default function Dropdowns({ report, searchReport, type }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const downloadPdf = (id) => {
        return window.open(`${process.env.REACT_APP_API_URL}${type === 'S' ? 'surplus' : 'purchase_report'}/${id}.pdf`, '_blank');
    }

    return (
        <React.Fragment>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle className="btn btn-ligth" caret>
                    <i className="fa fa-cogs fa-lg mr-2" />
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header>Acciones</DropdownItem>
                    <DropdownItem onClick={() => searchReport(report.id)}>
                        <i className="fa fa-search fa-lg mr-2" />
                            Ver
                        </DropdownItem>
                    <DropdownItem onClick={() => downloadPdf(report.id)}>
                        <i className="fa fa-download fa-lg mr-2" />
                            Descargar PDF
                        </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );

}