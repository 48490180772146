// react
import React, { useState } from 'react';

import { Button } from 'reactstrap';

export default ({ onclick, delivery }) => {
    const [loading, setLoading] = useState(false);

    const click = () => {
        setLoading(true);
        onclick(delivery.id, delivery.company);
        setLoading(false);
    };

    return (
        <Button onClick={click} color="primary" title="Editar Entrega." disabled={loading}>
            <i className={`fas ${loading ? 'fa-cog fa-spin' : 'fa-edit'}`} />
        </Button>
    );

}