import React from "react";
import { Badge } from "reactstrap";
import { Label, Input } from "reactstrap";
import ExcelJS from "exceljs";
import moment from "moment";

export function CartItemsId(cart) {
    const itemsId = cart.items.map((e) => e.product.id);
    return itemsId;
}

export function FormattedDifference(moment1, moment2) {
    let seconds = moment1.diff(moment2) / 1000;
    let minutes = 0;
    if (seconds >= 60) {
        minutes = Math.trunc(seconds / 60);
        seconds = Math.round(seconds - minutes * 60);
    }
    let hour = 0;
    if (minutes >= 60) {
        hour = Math.trunc(minutes / 60);
        minutes = minutes - hour * 60;
    }

    return `${hour < 10 ? "0" : ""}${hour.toString()}:${minutes < 10 ? "0" : ""}${minutes.toString()}:${
        seconds < 10 ? "0" : ""
    }${seconds.toString()}`;
}

export function msToTime(duration) {
    let isNegative = duration < 0 ? true : false;

    if (isNegative) {
        duration = duration * -1;
    }

    let seconds = parseInt(Math.floor((duration / 1000) % 60)),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return (isNegative ? "-" : "") + hours + ":" + minutes + ":" + seconds;
}

export function BagdeStatus(status) {
    switch (status) {
        case "O":
            return <Badge color="info">ABIERTA</Badge>;
        case "C":
            return <Badge color="success">CERRADA</Badge>;
        default:
            return <Badge color="danger">------</Badge>;
    }
}

export function planificationMode(mode, id, action) {
    switch (mode) {
        case "A":
            return `NUEVA PLANIFICACIÓN DE ${action}`;
        case "P":
            return `PROCESAR PLANIFICACIÓN DE ${action} N# ${id}`;
        default:
            return `ACTUALIZAR PLANIFICACIÓN DE ${action} N# ${id}`;
    }
}

export function cashFlowFilter(param, change) {
    return (
        <React.Fragment>
            <Label for="type">Modo</Label>
            <Input type="select" name="type" id="type" value={param} onChange={change}>
                <option value="V">VENCIDOS</option>
                <option value="P">POR VENCER</option>
                <option value="NP">NO PROGRAMADOS</option>
                <option value="A">TODOS</option>
            </Input>
        </React.Fragment>
    );
}

export const statusLabelDispatch = (status) => {
    switch (status) {
        case "P":
            return "PLANIFICADO";
        case "C":
            return "CONFIRMADO";
        case "N":
            return "NOTIFICADO";
        case "L":
            return "LIBERADO";
        case "R":
            return "RECIBIDO";
        default:
            return "CANCELADO";
    }
};

export const statusStepDispatch = (status) => {
    switch (status) {
        case "P":
            return 1;
        case "C":
            return 2;
        case "N":
            return 3;
        case "L":
            return 4;
        case "R":
            return 5;
        default:
            return -1;
    }
};

export const statusColorDispatch = (status) => {
    switch (status) {
        case "P":
            return "secondary";
        case "C":
            return "info";
        case "N":
            return "primary";
        case "L":
            return "warning";
        case "R":
            return "success";
        default:
            return "danger";
    }
};

export const statusLabelOC = (status, request) => {
    switch (true) {
        case status === "C":
            return "CERRADA";
        case status === "O" && !request:
            return "ABIERTA";
        case status === "O" && request && request.status === "C":
            return "COLOCADA";
        case status === "O" && request && request.status === "CO":
            return "CONFIRMADA";
        default:
            return "CANCELADA";
    }
};
export const statusColorOC = (status, request) => {
    switch (true) {
        case status === "C":
            return "warning";
        case status === "O" && !request:
            return "success";
        case status === "O" && request && request.status === "C":
            return "info";
        case status === "O" && request && request.status === "CO":
            return "primary";
        default:
            return "danger";
    }
};
export const statusLabelCF = (status, request) => {
    switch (true) {
        case status === "O" && !request:
            return "ABIERTA";
        case status === "C":
            return "COLOCADA";
        case status === "CO":
            return "CONFIRMADA";
        default:
            return "-";
    }
};
export const statuscOLORCF = (status, request) => {
    switch (true) {
        case status === "O" && !request:
            return "success";
        case status === "C":
            return "info";
        case status === "CO":
            return "primary";
        default:
            return "secondary";
    }
};

export const isObjEmpty = (obj) => {
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
};

export const disabledNext = (step, dispatch) => {
    switch (true) {
        case step === 1 && dispatch.confirm_date !== null:
            return false;
        case step === 2 && dispatch.notification_date !== null:
            return false;
        case step === 3 && dispatch.liberation_date !== null:
            return false;
        case step === 4 && dispatch.received_date !== null:
            return false;
        default:
            return true;
    }
};

export function GenerateExcelSurplus(data) {
    // CREAMOS LA PRIMERA HOJA
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(`Sobrantes-${data.length}`);

    //AGREGAMOS UN TITULO
    sheet.mergeCells("A3:M3");

    //Se establece el alineamiento dentro de las celdas
    ["A3", "B3", "C3", "D3", "E3", "F3", "G3", "H3", "I3", "J3", "K3", "L3", "M3"].forEach((cell) => {
        sheet.getCell(cell).style = {
            fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "B3C6FF" },
            },
            border: {
                top: { style: "thin", color: { argb: "000000" } },
                left: { style: "thin", color: { argb: "000000" } },
                bottom: { style: "thin", color: { argb: "000000" } },
                right: { style: "thin", color: { argb: "000000" } },
            },
            font: { size: 22, color: { argb: "000000" } },
            alignment: { horizontal: "center" },
        };
    });

    const titleCell = sheet.getCell("A3");
    titleCell.value = "LISTADO DE PRODUCTOS SOBRANTES";

    //Se establecen los anchos de las columnas
    const width = 15;
    sheet.getColumn("A").width = width;
    sheet.getColumn("B").width = width + 10;
    sheet.getColumn("C").width = width + 70;
    sheet.getColumn("D").width = width;
    sheet.getColumn("E").width = width;
    sheet.getColumn("F").width = width;
    sheet.getColumn("G").width = width;
    sheet.getColumn("H").width = width;
    sheet.getColumn("I").width = width;
    sheet.getColumn("J").width = width;
    sheet.getColumn("K").width = width + 5;

    //Se establece el alineamiento dentro de las celdas
    ["A6", "B6", "C6", "D6", "E6", "F6", "G6", "H6", "I6", "J6", "K6", "L6", "M6"].forEach((cell) => {
        sheet.getCell(cell).style = {
            font: { size: 11, color: { argb: "FFFFFF" } },
            alignment: { horizontal: "centerContinuous", vertical: "middle", wrapText: true },
            border: {
                top: { style: "thin", color: { argb: "000000" } },
                left: { style: "thin", color: { argb: "000000" } },
                bottom: { style: "thin", color: { argb: "000000" } },
                right: { style: "thin", color: { argb: "000000" } },
            },
            fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "333F4F" },
            },
        };
    });

    //Se le dan los valores al encabezado
    sheet.getCell("A6").value = "Codigo Proyecto";
    sheet.getCell("B6").value = "Codigo del producto";
    sheet.getCell("C6").value = "Descripcion";
    sheet.getCell("D6").value = "Solicitado";
    sheet.getCell("E6").value = "Entregados";
    sheet.getCell("F6").value = "Sobrante Devoluciones";
    sheet.getCell("G6").value = "Sobrante no Entregado";
    sheet.getCell("H6").value = "Total Sobrante";
    sheet.getCell("I6").value = "Disponible";
    sheet.getCell("J6").value = "Precio";
    sheet.getCell("K6").value = "Bodega";
    sheet.getCell("L6").value = "Linea ID";
    sheet.getCell("M6").value = "Producto ID";

    let lines = 7;

    data.forEach((item) => {
        sheet.getCell(`A${lines}`).value = item.pryCode;
        sheet.getCell(`B${lines}`).value = item.code;
        sheet.getCell(`C${lines}`).value = item.name;
        sheet.getCell(`D${lines}`).value = item.qtyOrdered >= 0 ? item.qtyOrdered : "";
        sheet.getCell(`E${lines}`).value = item.send >= 0 ? item.send : "";
        sheet.getCell(`F${lines}`).value = item.surplusReturn >= 0 ? item.surplusReturn : "";
        sheet.getCell(`G${lines}`).value = item.surplusNoSend >= 0 ? item.surplusNoSend : "";
        sheet.getCell(`H${lines}`).value = item.Qty >= 0 ? item.Qty : "";
        sheet.getCell(`I${lines}`).value = item.available >= 0 ? item.available : "";
        sheet.getCell(`J${lines}`).value = item.price;
        sheet.getCell(`K${lines}`).value = item.whs;
        sheet.getCell(`L${lines}`).value = item.lineId >= 0 ? item.lineId : "";
        sheet.getCell(`M${lines}`).value = item.productId >= 0 ? item.productId : "";
        lines++;
    });
    return workbook.xlsx.writeBuffer();
}

export function GenerateExcel(data) {
    // CREAMOS LA PRIMERA HOJA
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(`OC-${data.code}`);

    //AGREGAMOS UN TITULO
    sheet.mergeCells("A3:N3");

    //Se establece el alineamiento dentro de las celdas
    ["A3", "B3", "C3", "D3", "E3", "F3", "G3", "H3", "I3", "J3", "K3", "L3", "M3", "N3", "O3", "P3", "Q3", "R3", "S3"].forEach((cell) => {
        sheet.getCell(cell).style = {
            fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "B3C6FF" },
            },
            border: {
                top: { style: "thin", color: { argb: "000000" } },
                left: { style: "thin", color: { argb: "000000" } },
                bottom: { style: "thin", color: { argb: "000000" } },
                right: { style: "thin", color: { argb: "000000" } },
            },
            font: { size: 22, color: { argb: "000000" } },
            alignment: { horizontal: "center" },
        };
    });

    const titleCell = sheet.getCell("A3");
    titleCell.value = "INFORME DE FECHAS DE DESPACHOS LOGISTICOS EN TRANSITO";

    //Se establecen los anchos de las columnas
    const width = 15;
    sheet.getColumn("A").width = width;
    sheet.getColumn("B").width = width;
    sheet.getColumn("C").width = width + 42;
    sheet.getColumn("D").width = width;
    sheet.getColumn("E").width = width;
    sheet.getColumn("F").width = width;
    sheet.getColumn("G").width = width;
    sheet.getColumn("H").width = width;
    sheet.getColumn("I").width = width;
    sheet.getColumn("J").width = width;
    sheet.getColumn("K").width = width;
    sheet.getColumn("L").width = width;
    sheet.getColumn("M").width = width;
    sheet.getColumn("N").width = width;
    sheet.getColumn("O").width = width;
    sheet.getColumn("P").width = width;
    sheet.getColumn("Q").width = width;
    sheet.getColumn("R").width = width;
    sheet.getColumn("S").width = width;

    //Se establece el alineamiento dentro de las celdas
    ["A6", "B6", "C6", "D6", "E6", "F6", "G6", "H6", "I6", "J6", "K6", "L6", "M6", "N6", "O6", "P6", "Q6", "R6", "S6"].forEach((cell) => {
        sheet.getCell(cell).style = {
            font: { size: 11, color: { argb: "FFFFFF" } },
            alignment: { horizontal: "centerContinuous", vertical: "middle", wrapText: true },
            border: {
                top: { style: "thin", color: { argb: "000000" } },
                left: { style: "thin", color: { argb: "000000" } },
                bottom: { style: "thin", color: { argb: "000000" } },
                right: { style: "thin", color: { argb: "000000" } },
            },
            fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "333F4F" },
            },
        };
    });

    //Se le dan los valores al encabezado
    sheet.getCell("A6").value = "#OC";
    sheet.getCell("B6").value = "ID Despacho";
    sheet.getCell("C6").value = "Proveedor";
    sheet.getCell("D6").value = "Fecha de OC";
    sheet.getCell("E6").value = "Fecha de Requerida";
    sheet.getCell("F6").value = "Fecha de Orden Colocada";
    sheet.getCell("G6").value = "Fecha de Confirmación Orden";
    sheet.getCell("H6").value = "Fecha planificada de Despacho";
    sheet.getCell("I6").value = "Fecha planificada de Llegada";
    sheet.getCell("J6").value = "Fecha de Confirmación de Despacho";
    sheet.getCell("K6").value = "Guía";
    sheet.getCell("L6").value = "# de Factura del Proveedor";
    sheet.getCell("M6").value = "Fecha de Facturación Proveedor";
    sheet.getCell("N6").value = "Fecha de Notif. Arribo";
    sheet.getCell("O6").value = "Fecha de Liberación";
    sheet.getCell("P6").value = "Fecha de Recibido en Almacen WC";
    sheet.getCell("Q6").value = "Días de proceso";
    sheet.getCell("R6").value = "Días de retraso ";
    sheet.getCell("S6").value = "Estados";

    let lines = 7;
    data.dispatches.forEach((dispatch) => {
        sheet.getCell(`A${lines}`).value = data.code;
        sheet.getCell(`B${lines}`).value = `${data.code}-${dispatch.n}`;
        sheet.getCell(`C${lines}`).value = data.supplier;
        sheet.getCell(`D${lines}`).value = emptyDate(data.date);
        sheet.getCell(`E${lines}`).value = emptyDate(data.llegada);
        sheet.getCell(`F${lines}`).value = data.purchase_request_id && emptyDate(data.purchase_request_id.placement_date);
        sheet.getCell(`G${lines}`).value = data.purchase_request_id && emptyDate(data.purchase_request_id.confirmation_date);
        sheet.getCell(`H${lines}`).value = emptyDate(dispatch.dispatch_date); //
        sheet.getCell(`I${lines}`).value = emptyDate(dispatch.arrival_date); //
        sheet.getCell(`J${lines}`).value = emptyDate(dispatch.confirm_date);
        sheet.getCell(`K${lines}`).value = dispatch.guide ? dispatch.guide : "-";
        sheet.getCell(`L${lines}`).value = dispatch.invoice ? dispatch.invoice : "-";
        sheet.getCell(`M${lines}`).value = emptyDate(dispatch.invoice_date);
        sheet.getCell(`N${lines}`).value = emptyDate(dispatch.notification_date);

        sheet.getCell(`O${lines}`).value = emptyDate(dispatch.liberation_date);

        sheet.getCell(`P${lines}`).value = emptyDate(dispatch.received_date);

        sheet.getCell(`Q${lines}`).value =
            processDays(dispatch.status, dispatch.received_date, data.purchase_request_id.placement_date) < 0
                ? 0
                : processDays(dispatch.status, dispatch.received_date, data.purchase_request_id.placement_date);

        const color =
            processDays(dispatch.status, dispatch.received_date, data.purchase_request_id.placement_date) !== "ERROR"
                ? daysDelayColor(data, dispatch)
                : "FF0000";

        [
            `A${lines}`,
            `B${lines}`,
            `C${lines}`,
            `D${lines}`,
            `E${lines}`,
            `F${lines}`,
            `G${lines}`,
            `H${lines}`,
            `I${lines}`,
            `J${lines}`,
            `K${lines}`,
            `L${lines}`,
            `M${lines}`,
            `N${lines}`,
            `O${lines}`,
            `P${lines}`,
            `Q${lines}`,
            `R${lines}`,
            `S${lines}`,
        ].forEach((cell) => {
            sheet.getCell(cell).style = {
                fill: {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: color },
                },
                border: {
                    top: { style: "thin", color: { argb: "000000" } },
                    left: { style: "thin", color: { argb: "000000" } },
                    bottom: { style: "thin", color: { argb: "000000" } },
                    right: { style: "thin", color: { argb: "000000" } },
                },
            };
        });

        sheet.getCell(`R${lines}`).value = data.purchase_request_id && daysDelay(data.llegada, dispatch.status, dispatch.received_date);
        sheet.getCell(`S${lines}`).value = statusLabelDispatch(dispatch.status);

        lines++;
    });

    return workbook.xlsx.writeBuffer();
}

export function GenerateExcelInventorySurplusById(data) {
    // CREAMOS LA PRIMERA HOJA
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("hoja 1");
    //Se establecen los anchos de las columnas
    const width = 15;
    sheet.getColumn("A").width = width + 10;
    sheet.getColumn("B").width = width + 10;
    sheet.getColumn("C").width = width + 42;
    sheet.getColumn("D").width = width;
    sheet.getColumn("E").width = width;
    sheet.getColumn("F").width = width;
    sheet.getColumn("G").width = width;
    sheet.getColumn("H").width = width;
    sheet.getColumn("I").width = width + 30;
    if (data.products[0].inventoryName) sheet.getColumn("J").width = width;
    //Se establece el alineamiento dentro de las celdas
    const arr = data.products[0].inventoryName
        ? ["A1", "B1", "C1", "D1", "E1", "F1", "G1", "H1", "I1", "J1"]
        : ["A1", "B1", "C1", "D1", "E1", "F1", "G1", "H1", "I1"];

    arr.forEach((cell) => {
        sheet.getCell(cell).style = {
            font: { size: 11, color: { argb: "FFFFFF" } },
            alignment: { horizontal: "centerContinuous", vertical: "middle", wrapText: true },
            border: {
                top: { style: "thin", color: { argb: "000000" } },
                left: { style: "thin", color: { argb: "000000" } },
                bottom: { style: "thin", color: { argb: "000000" } },
                right: { style: "thin", color: { argb: "000000" } },
            },
            fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "333F4F" },
            },
        };
    });

    //Se le dan los valores al encabezado
    sheet.getCell("A1").value = "Número de artículos (Code)";
    sheet.getCell("B1").value = "Código de Fabricante (SKU)";
    sheet.getCell("C1").value = "Descripción";
    sheet.getCell("D1").value = "Categoría";
    sheet.getCell("E1").value = "Sub-categoría";
    sheet.getCell("F1").value = "Marca";
    sheet.getCell("G1").value = "Cantidad";
    sheet.getCell("H1").value = "Precio";
    sheet.getCell("I1").value = "Ubicación";
    if (data.products[0].inventoryName) sheet.getCell("J1").value = "Inventario";

    let lines = 2;
    data.products.forEach((product) => {
        sheet.getCell(`A${lines}`).value = product.code;
        sheet.getCell(`B${lines}`).value = product.code_manufacture;
        sheet.getCell(`C${lines}`).value = product.name;
        sheet.getCell(`D${lines}`).value = product.category;
        sheet.getCell(`E${lines}`).value = product.subCategoria ? product.subCategoria : product.sub_category;
        sheet.getCell(`F${lines}`).value = product.brand;
        sheet.getCell(`G${lines}`).value = product.qty;
        sheet.getCell(`H${lines}`).value = product.price;
        sheet.getCell(`I${lines}`).value = product.ubication;
        if (data.products[0].inventoryName) sheet.getCell(`J${lines}`).value = product.inventoryName;
        lines++;
    });

    return workbook.xlsx.writeBuffer();
}

export function GenerateFormatExcelInventorySurplus() {
    // CREAMOS LA PRIMERA HOJA
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("hoja 1");
    //Se establecen los anchos de las columnas
    const width = 15;
    sheet.getColumn("A").width = width + 10;
    sheet.getColumn("B").width = width + 10;
    sheet.getColumn("C").width = width + 42;
    sheet.getColumn("D").width = width;
    sheet.getColumn("E").width = width;
    sheet.getColumn("F").width = width;
    sheet.getColumn("G").width = width;
    sheet.getColumn("H").width = width;

    //Se establece el alineamiento dentro de las celdas
    const arr = ["A1", "B1", "C1", "D1", "E1", "F1", "G1", "H1"];

    arr.forEach((cell) => {
        sheet.getCell(cell).style = {
            font: { size: 11, color: { argb: "FFFFFF" } },
            alignment: { horizontal: "centerContinuous", vertical: "middle", wrapText: true },
            border: {
                top: { style: "thin", color: { argb: "000000" } },
                left: { style: "thin", color: { argb: "000000" } },
                bottom: { style: "thin", color: { argb: "000000" } },
                right: { style: "thin", color: { argb: "000000" } },
            },
            fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "333F4F" },
            },
        };
    });

    //Se le dan los valores al encabezado
    sheet.getCell("A1").value = "Número de artículos (Code)";
    sheet.getCell("B1").value = "Código de Fabricante (SKU)";
    sheet.getCell("C1").value = "Descripción";
    sheet.getCell("D1").value = "Categoría";
    sheet.getCell("E1").value = "Sub-categoría";
    sheet.getCell("F1").value = "Marca";
    sheet.getCell("G1").value = "Cantidad";
    sheet.getCell("H1").value = "Precio";

    return workbook.xlsx.writeBuffer();
}

export function GenerateExcelInventorySupplierById(data) {
    // CREAMOS LA PRIMERA HOJA
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("hoja 1");

    //Se establecen los anchos de las columnas
    const width = 15;
    sheet.getColumn("A").width = width + 10;
    sheet.getColumn("B").width = width + 10;
    sheet.getColumn("C").width = width + 42;
    sheet.getColumn("D").width = width;
    sheet.getColumn("E").width = width;
    sheet.getColumn("F").width = width;
    sheet.getColumn("G").width = width;
    sheet.getColumn("H").width = width;
    sheet.getColumn("I").width = width;
    if (data.products[0].inventoryName) sheet.getColumn("J").width = width + 20;
    //Se establece el alineamiento dentro de las celdas
    const arr = data.products[0].inventoryName
        ? ["A1", "B1", "C1", "D1", "E1", "F1", "G1", "H1", "I1", "J1"]
        : ["A1", "B1", "C1", "D1", "E1", "F1", "G1", "H1", "I1"];

    arr.forEach((cell) => {
        sheet.getCell(cell).style = {
            font: { size: 11, color: { argb: "FFFFFF" } },
            alignment: { horizontal: "centerContinuous", vertical: "middle", wrapText: true },
            border: {
                top: { style: "thin", color: { argb: "000000" } },
                left: { style: "thin", color: { argb: "000000" } },
                bottom: { style: "thin", color: { argb: "000000" } },
                right: { style: "thin", color: { argb: "000000" } },
            },
            fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "333F4F" },
            },
        };
    });

    //Se le dan los valores al encabezado
    sheet.getCell("A1").value = "Número de artículos (Code)";
    sheet.getCell("B1").value = "Código de Fabricante (SKU)";
    sheet.getCell("C1").value = "Descripción";
    sheet.getCell("D1").value = "Categoría";
    sheet.getCell("E1").value = "Sub-categoría";
    sheet.getCell("F1").value = "Marca";
    sheet.getCell("G1").value = "Cantidad";
    sheet.getCell("H1").value = "Precio";
    sheet.getCell("I1").value = "Inventario";

    let lines = 2;
    data.products.forEach((product) => {
        sheet.getCell(`A${lines}`).value = product.code;
        sheet.getCell(`B${lines}`).value = product.code_manufacture;
        sheet.getCell(`C${lines}`).value = product.name;
        sheet.getCell(`D${lines}`).value = product.category;
        sheet.getCell(`E${lines}`).value = product.subCategoria ? product.subCategoria : product.sub_category;
        sheet.getCell(`F${lines}`).value = product.brand;
        sheet.getCell(`G${lines}`).value = product.qty;
        sheet.getCell(`H${lines}`).value = product.price;
        sheet.getCell(`I${lines}`).value = product.ps;
        lines++;
    });

    return workbook.xlsx.writeBuffer();
}

export function GenerateExcelInventorySearchAll(data) {
    // CREAMOS LA PRIMERA HOJA

    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("hoja 1");

    //Se establecen los anchos de las columnas
    const width = 15;
    sheet.getColumn("A").width = width + 10;
    sheet.getColumn("B").width = width + 10;
    sheet.getColumn("C").width = width + 42;
    sheet.getColumn("D").width = width;
    sheet.getColumn("E").width = width + 40;

    //Se establece el alineamiento dentro de las celdas
    const arr = ["A1", "B1", "C1", "D1", "E1"];

    arr.forEach((cell) => {
        sheet.getCell(cell).style = {
            font: { size: 11, color: { argb: "FFFFFF" } },
            alignment: { horizontal: "centerContinuous", vertical: "middle", wrapText: true },
            border: {
                top: { style: "thin", color: { argb: "000000" } },
                left: { style: "thin", color: { argb: "000000" } },
                bottom: { style: "thin", color: { argb: "000000" } },
                right: { style: "thin", color: { argb: "000000" } },
            },
            fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "333F4F" },
            },
        };
    });

    //Se le dan los valores al encabezado
    sheet.getCell("A1").value = "Número de artículos (Code)";
    sheet.getCell("B1").value = "Código de Fabricante (SKU)";
    sheet.getCell("C1").value = "Descripción";
    sheet.getCell("D1").value = "Cantidad";
    sheet.getCell("E1").value = "Inventario";

    let lines = 2;
    data.forEach((product) => {
        sheet.getCell(`A${lines}`).value = product.code;
        sheet.getCell(`B${lines}`).value = product.sku;
        sheet.getCell(`C${lines}`).value = product.name;
        sheet.getCell(`D${lines}`).value = product.qty;
        sheet.getCell(`E${lines}`).value = product.inventoryName;
        lines++;
    });

    return workbook.xlsx.writeBuffer();
}

export function showTypeMovements(value) {
    const types = {
        A: "Adición",
        U: "Actualización",
        E: "Extracción",
        D: "Elimino",
    };
    return types[value];
}

export function GenerateExcelInventorySurplusMovements(data) {
    // CREAMOS LA PRIMERA HOJA
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("hoja 1");

    //Se establecen los anchos de las columnas
    const width = 15;
    sheet.getColumn("A").width = width;
    sheet.getColumn("B").width = width + 50;
    sheet.getColumn("C").width = width + 10;
    sheet.getColumn("D").width = width + 45;
    sheet.getColumn("E").width = width + 15;
    sheet.getColumn("F").width = width;
    sheet.getColumn("G").width = width;
    sheet.getColumn("H").width = width;
    //Se establece el alineamiento dentro de las celdas
    const arr = ["A1", "B1", "C1", "D1", "E1", "F1", "G1", "H1"];

    arr.forEach((cell) => {
        sheet.getCell(cell).style = {
            font: { size: 11, color: { argb: "FFFFFF" } },
            alignment: { horizontal: "centerContinuous", vertical: "middle", wrapText: true },
            border: {
                top: { style: "thin", color: { argb: "000000" } },
                left: { style: "thin", color: { argb: "000000" } },
                bottom: { style: "thin", color: { argb: "000000" } },
                right: { style: "thin", color: { argb: "000000" } },
            },
            fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "333F4F" },
            },
        };
    });

    //Se le dan los valores al encabezado
    sheet.getCell("A1").value = "Acción";
    sheet.getCell("B1").value = "Descripción";
    sheet.getCell("C1").value = "Código del producto ";
    sheet.getCell("D1").value = "Nombre del producto";
    sheet.getCell("E1").value = "Usuario";
    sheet.getCell("F1").value = "Cantidad";
    sheet.getCell("G1").value = "Precio";
    sheet.getCell("H1").value = "Fecha";
        
    let lines = 2;
    data.forEach((product) => {
        sheet.getCell(`A${lines}`).value = showTypeMovements(product.type);
        sheet.getCell(`B${lines}`).value = product.note;
        sheet.getCell(`C${lines}`).value = product.code;
        sheet.getCell(`D${lines}`).value = product.name;
        sheet.getCell(`E${lines}`).value = product.user;
        sheet.getCell(`F${lines}`).value = product.qty;
        sheet.getCell(`G${lines}`).value = product.price;
        sheet.getCell(`H${lines}`).value = product.retiremtDate ? moment(product.retiremtDate).format("DD/MM/YYYY") : "-";
        lines++;
    });

    return workbook.xlsx.writeBuffer();
}

export function GenerateAllDispatchExcel(data) {
    // CREAMOS LA PRIMERA HOJA
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Reporte General de Despachos");

    //AGREGAMOS UN TITULO
    sheet.mergeCells("A3:Q3");

    //Se establece el alineamiento dentro de las celdas
    ["A3", "B3", "C3", "D3", "E3", "F3", "G3", "H3", "I3", "J3", "K3", "L3", "M3", "N3", "O3", "P3", "Q3", "R3", "S3"].forEach((cell) => {
        sheet.getCell(cell).style = {
            fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "B3C6FF" },
            },
            border: {
                top: { style: "thin", color: { argb: "000000" } },
                left: { style: "thin", color: { argb: "000000" } },
                bottom: { style: "thin", color: { argb: "000000" } },
                right: { style: "thin", color: { argb: "000000" } },
            },
            font: { size: 22, color: { argb: "000000" } },
            alignment: { horizontal: "center" },
        };
    });

    const titleCell = sheet.getCell("A3");
    titleCell.value = "INFORME DE FECHAS DE DESPACHOS LOGISTICOS EN TRANSITO";

    //Se establecen los anchos de las columnas
    const width = 15;
    sheet.getColumn("A").width = width;
    sheet.getColumn("B").width = width;
    sheet.getColumn("C").width = width + 42;
    sheet.getColumn("D").width = width;
    sheet.getColumn("E").width = width;
    sheet.getColumn("F").width = width;
    sheet.getColumn("G").width = width;
    sheet.getColumn("H").width = width;
    sheet.getColumn("I").width = width;
    sheet.getColumn("J").width = width;
    sheet.getColumn("K").width = width;
    sheet.getColumn("L").width = width;
    sheet.getColumn("M").width = width;
    sheet.getColumn("N").width = width;
    sheet.getColumn("O").width = width;
    sheet.getColumn("P").width = width;
    sheet.getColumn("Q").width = width;
    sheet.getColumn("R").width = width;
    sheet.getColumn("S").width = width;

    //Se establece el alineamiento dentro de las celdas
    ["A6", "B6", "C6", "D6", "E6", "F6", "G6", "H6", "I6", "J6", "K6", "L6", "M6", "N6", "O6", "P6", "Q6", "R6", "S6"].forEach((cell) => {
        sheet.getCell(cell).style = {
            font: { size: 11, color: { argb: "FFFFFF" } },
            alignment: { horizontal: "centerContinuous", vertical: "middle", wrapText: true },
            border: {
                top: { style: "thin", color: { argb: "000000" } },
                left: { style: "thin", color: { argb: "000000" } },
                bottom: { style: "thin", color: { argb: "000000" } },
                right: { style: "thin", color: { argb: "000000" } },
            },
            fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "333F4F" },
            },
        };
    }); //llegada
    //Se le dan los valores al encabezado
    sheet.getCell("A6").value = "#OC";
    sheet.getCell("B6").value = "ID Despacho";
    sheet.getCell("C6").value = "Proveedor";
    sheet.getCell("D6").value = "Fecha de OC";
    sheet.getCell("E6").value = "Fecha de Requerida";
    sheet.getCell("F6").value = "Fecha de Orden Colocada";
    sheet.getCell("G6").value = "Fecha de Confirmación Orden";
    sheet.getCell("H6").value = "Fecha planificada de Despacho";
    sheet.getCell("I6").value = "Fecha planificada de Llegada";
    sheet.getCell("J6").value = "Fecha de Confirmación de Despacho";
    sheet.getCell("K6").value = "Guía";
    sheet.getCell("L6").value = "# de Factura del Proveedor";
    sheet.getCell("M6").value = "Fecha de Facturación Proveedor";
    sheet.getCell("N6").value = "Fecha de Notif. Arribo";
    sheet.getCell("O6").value = "Fecha de Liberación";
    sheet.getCell("P6").value = "Fecha de Recibido en Almacen WC";
    sheet.getCell("Q6").value = "Días de proceso";
    sheet.getCell("R6").value = "Días de retraso ";
    sheet.getCell("S6").value = "Estados";

    let lines = 7;
    data.forEach((order) => {
        //Se valida que la orden tenga despaco o no y que tenga purchase_request_id
        if (order.dispatches.length > 0) {
            order.dispatches.forEach((dispatch) => {
                sheet.getCell(`A${lines}`).value = order.code;
                sheet.getCell(`B${lines}`).value = `${order.code}-${dispatch.n}`;
                sheet.getCell(`C${lines}`).value = order.supplier;
                sheet.getCell(`D${lines}`).value = emptyDate(order.date);
                sheet.getCell(`E${lines}`).value = emptyDate(order.llegada);
                sheet.getCell(`F${lines}`).value =
                    order.purchase_request_id && order.purchase_request_id.placement_date && emptyDate(order.purchase_request_id.placement_date);
                sheet.getCell(`G${lines}`).value =
                    order.purchase_request_id &&
                    order.purchase_request_id.confirmation_date &&
                    emptyDate(order.purchase_request_id.confirmation_date);
                sheet.getCell(`H${lines}`).value = emptyDate(dispatch.dispatch_date);
                sheet.getCell(`I${lines}`).value = emptyDate(dispatch.arrival_date);
                sheet.getCell(`J${lines}`).value = emptyDate(dispatch.confirm_date);
                sheet.getCell(`K${lines}`).value = dispatch.guide ? dispatch.guide : "-";
                sheet.getCell(`L${lines}`).value = dispatch.invoice ? dispatch.invoice : "-";
                sheet.getCell(`M${lines}`).value = emptyDate(dispatch.invoice_date);
                sheet.getCell(`N${lines}`).value = emptyDate(dispatch.notification_date);
                sheet.getCell(`O${lines}`).value = emptyDate(dispatch.liberation_date);
                sheet.getCell(`P${lines}`).value = emptyDate(dispatch.received_date);
                let color;
                //Se valida que exista la fecha de planificado

                sheet.getCell(`Q${lines}`).value =
                    processDays(dispatch.status, dispatch.received_date, order.purchase_request_id.placement_date) < 0
                        ? 0
                        : processDays(dispatch.status, dispatch.received_date, order.purchase_request_id.placement_date);

                color =
                    processDays(dispatch.status, dispatch.received_date, order.purchase_request_id.placement_date) !== "ERROR"
                        ? daysDelayColor(order, dispatch)
                        : "FF0000";

                [
                    `A${lines}`,
                    `B${lines}`,
                    `C${lines}`,
                    `D${lines}`,
                    `E${lines}`,
                    `F${lines}`,
                    `G${lines}`,
                    `H${lines}`,
                    `I${lines}`,
                    `J${lines}`,
                    `K${lines}`,
                    `L${lines}`,
                    `M${lines}`,
                    `N${lines}`,
                    `O${lines}`,
                    `P${lines}`,
                    `Q${lines}`,
                    `R${lines}`,
                    `S${lines}`,
                ].forEach((cell) => {
                    sheet.getCell(cell).style = {
                        fill: {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: color },
                        },
                        border: {
                            top: { style: "thin", color: { argb: "000000" } },
                            left: { style: "thin", color: { argb: "000000" } },
                            bottom: { style: "thin", color: { argb: "000000" } },
                            right: { style: "thin", color: { argb: "000000" } },
                        },
                    };
                });

                sheet.getCell(`R${lines}`).value = daysDelay(order, dispatch);
                sheet.getCell(`S${lines}`).value = order.canceled === "Y" ? "CANCELADA" : statusLabelDispatch(dispatch.status);
                lines++;
            });
            return;
        }

        sheet.getCell(`A${lines}`).value = order.code;
        sheet.getCell(`B${lines}`).value = "-";
        sheet.getCell(`C${lines}`).value = order.supplier;
        sheet.getCell(`D${lines}`).value = emptyDate(order.date);
        sheet.getCell(`E${lines}`).value = emptyDate(order.llegada);
        sheet.getCell(`F${lines}`).value =
            order.purchase_request_id && order.purchase_request_id.placement_date && order.purchase_request_id.placement_date
                ? emptyDate(order.purchase_request_id.placement_date)
                : "-";
        sheet.getCell(`G${lines}`).value =
            order.purchase_request_id && order.purchase_request_id.placement_date && order.purchase_request_id.confirmation_date
                ? emptyDate(order.purchase_request_id.confirmation_date)
                : "-";
        sheet.getCell(`H${lines}`).value = "-";
        sheet.getCell(`I${lines}`).value = "-";
        sheet.getCell(`J${lines}`).value = "-";
        sheet.getCell(`K${lines}`).value = "-";
        sheet.getCell(`L${lines}`).value = "-";
        sheet.getCell(`M${lines}`).value = "-";
        sheet.getCell(`N${lines}`).value = "-";
        let status;

        //si existe purchase_request_id && el estado es igual a Colocado(C)
        if (order.canceled !== "Y") {
            if (order.purchase_request_id && order.purchase_request_id.status === "C") {
                //se calcula la diferencia apartir del dia de colocacion
                sheet.getCell(`Q${lines}`).value = moment().diff(order.purchase_request_id.placement_date, "days");
                status = "COLOCADA";
                //si existe purchase_request_id y su estado es Confirmado(CO)
            } else if (order.purchase_request_id && order.purchase_request_id.status === "CO") {
                //se calcula la diferencia apartir de la fecha de confirmacion
                moment(order.purchase_request_id.confirmation_date).diff(order.purchase_request_id.placement_date, "days");
                status = "CONFIRMADA";
            } else {
                //si no existe purchase_request_id y/o el estado es direfente a Colocado(C) o Confirmado(CO)
                sheet.getCell(`Q${lines}`).value = "-";
                if (order.purchase_request_id && order.status === "O") {
                    //si purchase_request_id tiene estatus Cancelada(CA) retora el estado de se algo no contemplado se devuelve lo que halla
                    status = order.purchase_request_id.status === "CA" ? "CANCELADA" : order.purchase_request_id.status;
                } else if (order.status === "O") {
                    status = "ABIERTA";
                } else {
                    //si el estado esta Cerrado se devuelve cerrado si no se devuelve el valor que venga en el estado
                    status = order.status === "C" ? "CERRADA" : order.status;
                }
            }
        } else {
            status = "CANCELADA";
        }

        const color = daysDelayColor(order);

        [
            `A${lines}`,
            `B${lines}`,
            `C${lines}`,
            `D${lines}`,
            `E${lines}`,
            `F${lines}`,
            `G${lines}`,
            `H${lines}`,
            `I${lines}`,
            `J${lines}`,
            `K${lines}`,
            `L${lines}`,
            `M${lines}`,
            `N${lines}`,
            `O${lines}`,
            `P${lines}`,
            `Q${lines}`,
            `R${lines}`,
            `S${lines}`,
        ].forEach((cell) => {
            sheet.getCell(cell).style = {
                fill: {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: color },
                },
                border: {
                    top: { style: "thin", color: { argb: "000000" } },
                    left: { style: "thin", color: { argb: "000000" } },
                    bottom: { style: "thin", color: { argb: "000000" } },
                    right: { style: "thin", color: { argb: "000000" } },
                },
            };
        });

        sheet.getCell(`R${lines}`).value = daysDelay(order);
        sheet.getCell(`S${lines}`).value = status;
        lines++;
    });

    return workbook.xlsx.writeBuffer();
}

export function GenerateExcelInventoryCustomerById(data) {
    // CREAMOS LA PRIMERA HOJA

    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("hoja 1");
    //Se establecen los anchos de las columnas
    const width = 15;
    sheet.getColumn("A").width = width + 10;
    sheet.getColumn("B").width = width + 10;
    sheet.getColumn("C").width = width + 42;
    sheet.getColumn("D").width = width;
    sheet.getColumn("E").width = width;
    if (data.inventoryName) sheet.getColumn("F").width = width + 20;
    //Se establece el alineamiento dentro de las celdas
    const arr = ["A1", "B1", "C1", "D1", "E1"];
    if (data.inventoryName) arr.push("F1");

    arr.forEach((cell) => {
        sheet.getCell(cell).style = {
            font: { size: 11, color: { argb: "FFFFFF" } },
            alignment: { horizontal: "centerContinuous", vertical: "middle", wrapText: true },
            border: {
                top: { style: "thin", color: { argb: "000000" } },
                left: { style: "thin", color: { argb: "000000" } },
                bottom: { style: "thin", color: { argb: "000000" } },
                right: { style: "thin", color: { argb: "000000" } },
            },
            fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "333F4F" },
            },
        };
    });

    //Se le dan los valores al encabezado
    sheet.getCell("A1").value = "Número de artículos (Code)";
    sheet.getCell("B1").value = "Código de Fabricante (SKU)";
    sheet.getCell("C1").value = "Descripción";
    sheet.getCell("D1").value = "Comprometido";
    sheet.getCell("E1").value = "Existencia";
    if (data.inventoryName) sheet.getCell("F1").value = "Inventario";
    let lines = 2;
    data.items.forEach((product) => {
        sheet.getCell(`A${lines}`).value = product.code;
        sheet.getCell(`B${lines}`).value = product.sku;
        sheet.getCell(`C${lines}`).value = product.name;
        sheet.getCell(`D${lines}`).value = product.commited;
        sheet.getCell(`E${lines}`).value = product.stock;
        if (product.inventoryName) sheet.getCell(`F${lines}`).value = product.inventoryName;
        lines++;
    });

    return workbook.xlsx.writeBuffer();
}

export function GenerateExcelInventoryCustomerMovements(data) {
    // CREAMOS LA PRIMERA HOJA
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("hoja 1");

    //Se establecen los anchos de las columnas
    const width = 15;
    sheet.getColumn("A").width = width;
    sheet.getColumn("B").width = width + 50;
    sheet.getColumn("C").width = width + 10;
    sheet.getColumn("D").width = width + 45;
    sheet.getColumn("E").width = width;
    sheet.getColumn("F").width = width + 15;
    sheet.getColumn("G").width = width;
    //Se establece el alineamiento dentro de las celdas
    const arr = ["A1", "B1", "C1", "D1", "E1", "F1", "G1"];

    arr.forEach((cell) => {
        sheet.getCell(cell).style = {
            font: { size: 11, color: { argb: "FFFFFF" } },
            alignment: { horizontal: "centerContinuous", vertical: "middle", wrapText: true },
            border: {
                top: { style: "thin", color: { argb: "000000" } },
                left: { style: "thin", color: { argb: "000000" } },
                bottom: { style: "thin", color: { argb: "000000" } },
                right: { style: "thin", color: { argb: "000000" } },
            },
            fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "333F4F" },
            },
        };
    });

    //Se le dan los valores al encabezado
    sheet.getCell("A1").value = "Acción";
    sheet.getCell("B1").value = "Descripción";
    sheet.getCell("C1").value = "Código del producto ";
    sheet.getCell("D1").value = "Nombre del producto";
    sheet.getCell("E1").value = "Stock";
    sheet.getCell("F1").value = "Usuario";
    sheet.getCell("G1").value = "Fecha";

    let lines = 2;
    data.forEach((product) => {
        sheet.getCell(`A${lines}`).value = showTypeMovements(product.type);
        sheet.getCell(`B${lines}`).value = product.note;
        sheet.getCell(`C${lines}`).value = product.code;
        sheet.getCell(`D${lines}`).value = product.name;
        sheet.getCell(`E${lines}`).value = product.qty;
        sheet.getCell(`F${lines}`).value = product.user;
        sheet.getCell(`G${lines}`).value = product.retiremtDate ? moment(product.retiremtDate).format("DD/MM/YYYY") : "-";
        lines++;
    });

    return workbook.xlsx.writeBuffer();
}

export function GenerateFormatInventoryClient() {
    // CREAMOS LA PRIMERA HOJA

    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("hoja 1");
    //Se establecen los anchos de las columnas
    const width = 15;
    sheet.getColumn("A").width = width + 10;
    sheet.getColumn("B").width = width;

    //Se establece el alineamiento dentro de las celdas
    const arr = ["A1", "B1"];

    arr.forEach((cell) => {
        sheet.getCell(cell).style = {
            font: { size: 11, color: { argb: "FFFFFF" } },
            alignment: { horizontal: "centerContinuous", vertical: "middle", wrapText: true },
            border: {
                top: { style: "thin", color: { argb: "000000" } },
                left: { style: "thin", color: { argb: "000000" } },
                bottom: { style: "thin", color: { argb: "000000" } },
                right: { style: "thin", color: { argb: "000000" } },
            },
            fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "333F4F" },
            },
        };
    });
    //Se le dan los valores al encabezado
    sheet.getCell("A1").value = "Número de artículos (Code)";
    sheet.getCell("B1").value = "Existencia";
    return workbook.xlsx.writeBuffer();
}

export function GenerateFormatSearchInventoryClient() {
    // CREAMOS LA PRIMERA HOJA

    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("hoja 1");
    //Se establecen los anchos de las columnas
    const width = 15;
    sheet.getColumn("A").width = width + 10;

    //Se establece el alineamiento dentro de las celdas
    const arr = ["A1"];

    arr.forEach((cell) => {
        sheet.getCell(cell).style = {
            font: { size: 11, color: { argb: "FFFFFF" } },
            alignment: { horizontal: "centerContinuous", vertical: "middle", wrapText: true },
            border: {
                top: { style: "thin", color: { argb: "000000" } },
                left: { style: "thin", color: { argb: "000000" } },
                bottom: { style: "thin", color: { argb: "000000" } },
                right: { style: "thin", color: { argb: "000000" } },
            },
            fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "333F4F" },
            },
        };
    });
    //Se le dan los valores al encabezado
    sheet.getCell("A1").value = "codigo";
    return workbook.xlsx.writeBuffer();
}

export function ExcelFormatSearch() {
    const csvContent = "codigo\n";
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `${"Formato de búsqueda".toUpperCase()}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};


export const emptyDate = (date) => {
    return date ? moment(date).format("DD/MM/YYYY") : "-";
};

export const daysDelay = (order, dispatch) => {
    const today = moment().format("YYYY-MM-DD");
    let delay = "";

    switch (true) {
        //si la orden esta abierta y no esta colcada
        case !dispatch && order.status === "O" && !order.purchase_request_id:
            if (!order.llegada) {
                return "ERROR";
            }
            delay = moment(order.llegada).diff(today, "days");
            return delay < 0 ? delay * -1 : "-";

        // si la esta colocada
        case !dispatch && order.purchase_request_id && order.purchase_request_id.status === "C":
            if (!order.llegada || !order.purchase_request_id.placement_date) {
                return "ERROR";
            }
            delay = moment(order.llegada).diff(order.purchase_request_id.placement_date, "days");
            return delay < 0 ? delay * -1 : "-";

        //si la orden esta confirmada
        case !dispatch && order.purchase_request_id && order.purchase_request_id.status === "CO":
            if (!order.llegada || !order.purchase_request_id.confirmation_date) {
                return "ERROR";
            }
            delay = moment(order.llegada).diff(order.purchase_request_id.confirmation_date, "days");
            return delay < 0 ? delay * -1 : "-";

        // si la orden esta confirmada y el despacho en estado planificado
        case order.purchase_request_id && dispatch && dispatch.status === "P":
            if (!order.llegada || !dispatch.arrival_date) {
                return "ERROR";
            }
            delay = moment(order.llegada).diff(dispatch.arrival_date, "days");
            return delay < 0 ? delay * -1 : "-";

        // si la orden esta confirmada y el despacho en estado confirmado
        case order.purchase_request_id && dispatch && dispatch.status === "C":
            if (!order.llegada || !dispatch.confirm_date) {
                return "ERROR";
            }
            delay = moment(order.llegada).diff(dispatch.confirm_date, "days");
            return delay < 0 ? delay * -1 : "-";

        // si la orden esta confirmada y el despacho en estado Notificado
        case order.purchase_request_id && dispatch && dispatch.status === "N":
            if (!order.llegada || !dispatch.notification_date) {
                return "ERROR";
            }
            delay = moment(order.llegada).diff(dispatch.notification_date, "days");
            return delay < 0 ? delay * -1 : "-";

        //si la orden esta confirmada y el despacho esta Liberado
        case order.purchase_request_id && dispatch && dispatch.status === "L":
            if (!order.llegada || !dispatch.liberation_date) {
                return "ERROR";
            }
            delay = moment(order.llegada).diff(dispatch.liberation_date, "days");
            return delay < 0 ? delay * -1 : "-";

        // si la orden esta confirmada y el despacho en estado Recivido
        case order.purchase_request_id && dispatch && dispatch.status === "R":
            if (!order.llegada || !dispatch.received_date) {
                return "ERROR";
            }
            delay = moment(order.llegada).diff(dispatch.received_date, "days");
            return delay > 0 ? "-" : delay * -1;
        default:
            return "-";
    }
};

export const daysDelayColor = (order, dispatch) => {
    const red = "ea553d";
    const orange = "ffbb44";
    const greed = "4ac18e";
    const error = "FF0000";
    const today = moment().format("YYYY-MM-DD");

    let delay = null;

    if (order.canceled === "Y") {
        return "EA4637";
    }
    switch (true) {
        //si la orden esta abierta y no esta colcada
        case !dispatch && order.status === "O" && !order.purchase_request_id:
            if (!order.llegada) {
                return error;
            }
            delay = moment(order.llegada).diff(today, "days");
            return delay > 0 ? orange : red;

        case !dispatch && !order.purchase_request_id && order.status === "C":
            return "ffffff";
        // si la esta colocada
        case !dispatch && order.purchase_request_id && order.purchase_request_id.status === "C":
            if (!order.llegada || !order.purchase_request_id.placement_date) {
                return error;
            }
            delay = moment(order.llegada).diff(order.purchase_request_id.placement_date, "days");
            return delay > 0 ? orange : red;

        //si la orden esta confirmada
        case !dispatch && order.purchase_request_id && order.purchase_request_id.status === "CO":
            if (!order.llegada || !order.purchase_request_id.confirmation_date) {
                return error;
            }
            delay = moment(order.llegada).diff(order.purchase_request_id.confirmation_date, "days");
            return delay > 0 ? orange : red;

        // si la orden esta confirmada y el despacho en estado planificado
        case order.purchase_request_id && dispatch && dispatch.status === "P":
            if (!order.llegada || !dispatch.arrival_date) {
                return error;
            }
            delay = moment(order.llegada).diff(dispatch.arrival_date, "days");
            console.log(delay);
            return delay > 0 ? orange : red;

        // si la orden esta confirmada y el despacho en estado confirmado
        case order.purchase_request_id && dispatch && dispatch.status === "C":
            if (!order.llegada || !dispatch.confirm_date) {
                return error;
            }
            delay = moment(order.llegada).diff(dispatch.confirm_date, "days");
            return delay > 0 ? orange : red;

        // si la orden esta confirmada y el despacho en estado Notificado
        case order.purchase_request_id && dispatch && dispatch.status === "N":
            if (!order.llegada || !dispatch.notification_date) {
                return error;
            }
            delay = moment(order.llegada).diff(dispatch.notification_date, "days");
            return delay > 0 ? orange : red;

        //si la orden esta confirmada y el despacho esta Liberado
        case order.purchase_request_id && dispatch && dispatch.status === "L":
            if (!order.llegada || !dispatch.liberation_date) {
                return error;
            }
            delay = moment(order.llegada).diff(dispatch.liberation_date, "days");
            return delay > 0 ? orange : red;

        // si la orden esta confirmada y el despacho en estado Recivido
        case order.purchase_request_id && dispatch && dispatch.status === "R":
            if (!order.llegada || !dispatch.received_date) {
                return error;
            }
            delay = moment(order.llegada).diff(dispatch.received_date, "days");
            return delay >= 0 ? greed : red;

        default:
            return red;
    }
};

export const processDays = (status, received_date, placementDate) => {
    switch (status) {
        case "R":
            return received_date && placementDate ? moment(received_date).diff(placementDate, "days") : "ERROR";
        default:
            return placementDate ? moment().diff(placementDate, "days") : "ERROR";
    }
};

export function isTimeFormar(value) {
    if (!value) {
        return "00:00:00";
    }

    const arr = value.split(":");

    if (arr.length === 1) {
        if (isNaN(arr[0])) {
            return "00:00:00";
        }
        return arr[0] + ":00:00";
    }
    let str = "";
    arr.forEach((elem) => {
        if (!elem || isNaN(elem)) {
            str += "00";
        } else {
            str += elem;
        }
        str += ":";
    });

    return str.substring(0, str.length - 1);
}
