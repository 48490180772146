import { toast } from "react-toastify";
import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { Button, UncontrolledCollapse } from "reactstrap";
import GetProductsCodesCSV from "../../../GetProductsCodesCSV";
import InventoryClientModalSearch from "./InventoryClientModalSearch";
import { searchProductsCustomerForCode, searchCustomerProducsByCodes } from "../../../../../store/directusSdk";

function InventoryClientTop({ exportExcel, products, setProducts, company, setOpenModalNew, isAdmin, setCodes, codes, setType }) {
    const [openModalSearch, setOpenModalSearch] = useState(false);

    const promiseOptions = (inputValue) => searchProductsCustomerForCode(inputValue, company);

    const getProductsSearch = () => {
        searchCustomerProducsByCodes({ codes: [...codes.map((code) => code.value)] })
            .then((data) => {
                setProducts(data);
                setOpenModalSearch(true);
            })
            .catch((error) => toast.error("¡Error!, " + error.message));
    };

    const exportExcelFormat = () => {
        const csvContent = "codigo\n";
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", `${"Formato de búsqueda".toUpperCase()}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const resetTop = () => {
        setProducts([]);
        setCodes([]);
        setOpenModalSearch(false);
    };

    return (
        <div className="ml-4 mt-4 mr-4">
            <UncontrolledCollapse toggler="#toggler">
                <div className="mb-2 mb-3">
                    <GetProductsCodesCSV LoadCodes={(codes) => setCodes(codes)} />
                </div>
            </UncontrolledCollapse>
            <div className="row">
                <div className="col-md-10">
                    <Button color="info" id="toggler" className="mb-3">
                    <i className="fas fa-file-upload  mr-2" /> Importar
                    </Button>
                    {isAdmin && (
                        <Button
                            width="100%"
                            color="info"
                            className="mb-3 ml-2"
                            onClick={() => {
                                setType("A");
                                setOpenModalNew(true);
                            }}
                        >
                            <i className="fas fa-plus-circle mr-2" />
                            Nuevo &nbsp;
                        </Button>
                    )}
                    <Button color="info" className="mb-3 ml-2" onClick={exportExcelFormat}>
                    <i className="fas fa-file-download  mr-2" /> Formato
                    </Button>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-10">
                    <AsyncSelect
                        isClearable
                        cacheOptions
                        loadOptions={promiseOptions}
                        noOptionsMessage={() => "No se Encontraron registros..."}
                        loadingMessage={() => "Buscando..."}
                        placeholder="Códigos de productos..."
                        value={codes}
                        onChange={(e) => setCodes(e)}
                        className="ms-md-auto"
                        isMulti
                    />
                </div>
                <div className="col-md-2">
                    <Button color="primary" onClick={() => getProductsSearch()} disabled={!codes.length}>
                        <i className="pi pi-search mr-2" />
                        Buscar
                    </Button>
                </div>
            </div>
            {openModalSearch && (
                <InventoryClientModalSearch
                    exportExcel={exportExcel}
                    resetTop={resetTop}
                    update={getProductsSearch}
                    company={company}
                    setItems={setProducts}
                    open={openModalSearch}
                    setOpen={setOpenModalSearch}
                    isAdmin={isAdmin}
                    items={products}
                    type={"S"}
                />
            )}
        </div>
    );
}

export default InventoryClientTop;
