// react
import React, { Component } from "react";
// third-party
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button, Badge } from "reactstrap";
import { getDispatchesProducts, searchClients } from "../../../store/directusSdk";
import ModalClients from "./ModalClients";
import { statusColorDispatch, statusLabelDispatch } from "../../shared/utilities";

export default class ListDispatches extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expandAll: false,
            expandedRows: null,
            open: false,
            open2: false,
            mode: "product",
            loading: false,
            clients: [],
            lastRow: null,
        };
    }

    exportCSV = () => {
        return this.dt.exportCSV();
    };

    expandAll = () => {
        const { dispatches } = this.props;
        const expandedRows = {};

        if (dispatches.length > 0) {
            dispatches.forEach((p) => {
                expandedRows[`${p.id}`] = true;
            });
            return this.setState({ expandedRows, expandAll: true });
        }
    };

    collapseAll = () => this.setState({ expandedRows: null, expandAll: false });

    rowExpansionTemplate = (row) => (
        <div className="orders-subtable">
            {row.dispatches.length > 0 ? (
                <DataTable value={row.dispatches} className="p-datatable-sm">
                    <Column field="n" header="Número Despacho" style={{ width: "15%" }} />
                    <Column field="guide" header="Guía" style={{ width: "15%" }} />
                    <Column
                        field="dispatch_date"
                        header="Fecha Despacho"
                        body={(row) => moment(row.dispatch_date).format("DD/MM/YYYY")}
                        style={{ width: "20%" }}
                    />
                    <Column
                        field="arrival_date"
                        header="Fecha Llegada"
                        body={(row) => moment(row.arrival_date).format("DD/MM/YYYY")}
                        style={{ width: "20%" }}
                    />
                    <Column field="qty" header="Cantidad" style={{ width: "18%" }} />
                    <Column
                        header="Estado"
                        body={(row) => <Badge color={statusColorDispatch(row.status)}>{statusLabelDispatch(row.status)}</Badge>}
                        style={{ width: "12%" }}
                    />
                </DataTable>
            ) : (
                <p>No se Han Realizado Despachos...</p>
            )}
        </div>
    );

    searchClients = (row) => {
        this.setState({ loading: true, open: true });
        return searchClients(row.code)
            .then((clients) => this.setState({ clients, lastRow: row }))
            .catch((error) => console.log(error))
            .finally(() => this.setState({ loading: false }));
    };

    getDispatches = (row) => {
        this.setState({ loading3: true, open2: true });
        return getDispatchesProducts(row.code)
            .then((dispatches) => this.setState({ dispatches }))
            .catch((error) => console.log(error))
            .finally(() => this.setState({ loading3: false }));
    };

    calculateDate = (row, lastRow) => {
        if (lastRow.dispatches && lastRow.dispatches.length > 0) {
            const nearestDate = lastRow.dispatches.reduce((n, e) => {
                if (moment(e.arrival_date).isSameOrBefore(n)) {
                    return e.arrival_date;
                }
                return n;
            }, moment(lastRow.dispatches[0].arrival_date));
            if (moment(nearestDate).isSameOrBefore(row.date)) {
                return <i className="fas fa-calendar-check fa-2x" style={{ color: "#4ac18e" }} title="Vigente" />;
            }
            return <i className="fas fa-exclamation-triangle fa-2x" style={{ color: "#ffbb44" }} title="Vencido" />;
        }
        return "";
    };

    render() {
        const { expandAll, expandedRows, clients, lastRow, loading, open } = this.state;
        const { dispatches, search } = this.props;

        if (!search) {
            return null;
        }

        const header = (
            <div className="row">
                <div className="col-md-4 ">
                    <h5 className="mt-2">Despachos</h5>
                </div>
                <div className="col-md-2 offset-md-4">
                    {expandAll ? (
                        <Button color="warning" onClick={this.collapseAll}>
                            <i className="fas fa-angle-double-right mr-2" />
                            Contraer
                        </Button>
                    ) : (
                        <Button color="info" className="mr-2" onClick={this.expandAll}>
                            <i className="fas fa-angle-double-down mr-2" />
                            Expandir
                        </Button>
                    )}
                </div>
                <div className="col-md-2">
                    <Button color="info" onClick={this.exportCSV}>
                        <i className="fas fa-file-export mr-2" />
                        Exportar
                    </Button>
                </div>
            </div>
        );

        return (
            <React.Fragment>
      

                <DataTable
                    ref={(el) => {
                        this.dt = el;
                    }}
                    value={dispatches}
                    header={header}
                    dataKey="id"
                    paginator
                    rows={10}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    removableSort
                    emptyMessage="No se han encontrado registros..."
                    exportFilename="Despachos-ICA"
                    expandedRows={expandedRows}
                    onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                    rowExpansionTemplate={this.rowExpansionTemplate}
                    className="p-datatable-dispatches p-datatable-sm mt-1"
                >
                    <Column expander style={{ width: "5%" }} />
                    <Column
                        field="code"
                        header="Código"
                        sortable
                        filter
                        filterPlaceholder="Todos"
                        filterHeaderClassName="p-inputtext-sm"
                        style={{ width: "15%" }}
                    />
                    <Column
                        field="name"
                        header="Producto"
                        sortable
                        filter
                        filterPlaceholder="Todos"
                        filterHeaderClassName="p-inputtext-sm"
                        style={{ width: "25%" }}
                    />
                    <Column
                        field="qty"
                        header="Cantidad"
                        sortable
                        filter
                        style={{ textAlign: "center", width: "13%" }}
                        filterPlaceholder="Todos"
                        filterHeaderClassName="p-inputtext-sm"
                    />
                    <Column
                        field="purchaseOrder"
                        header="Orden Compra"
                        sortable
                        filter
                        style={{ textAlign: "center", width: "12%" }}
                        filterPlaceholder="Todos"
                        filterHeaderClassName="p-inputtext-sm"
                    />
                    <Column
                        field="supplier"
                        header="Proveedor"
                        sortable
                        filter
                        style={{ textAlign: "center", width: "25%" }}
                        filterPlaceholder="Todos"
                        filterHeaderClassName="p-inputtext-sm"
                    />
                    <Column
                        header="Clientes"
                        body={(row) => (
                            <Button outline color="info" title="Ver Clientes" onClick={() => this.searchClients(row)}>
                                <i className="fas fa-users" />
                            </Button>
                        )}
                        style={{ width: "10%" }}
                    />
                </DataTable>

                <ModalClients
                    loading={loading}
                    open={open}
                    close={() => this.setState({ open: false })}
                    lastRow={lastRow}
                    calculateDate={this.calculateDate}
                    clients={clients}
                />
            </React.Fragment>
        );
    }
}
