// react
import React, { useState } from "react";
// third-party
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import PageHeader from "../shared/PageHeader";
import ImportCsv05 from "./ImportCsv05";
import { Input } from "reactstrap";
import fs from "file-saver";
import ExcelJS from "exceljs";
import { resultCountedInventory } from "../../store/directusSdk";
export default function ResultIventory05() {
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [itemsSAP, setItemsSAP] = useState([]);
    const [itemsContado, setItemsContado] = useState([]);
    const [whs, setWhs] = useState("01"); //Contiene la bodega que se seleccione por defecto estará seleccionada la bodega 01.
    const loadItems = (items, setItems) => setItems(items);

    const saveAsCSVFile = (buffer, fileName) => {
        import("file-saver").then((FileSaver) => {
            let _TYPE = "text/csv;charset=utf-8;";
            let _EXTENSION = ".csv";
            const data = new Blob([buffer], {
                type: _TYPE,
            });

            FileSaver.saveAs(data, fileName + _EXTENSION);
        });
    };

    const exportCsvAfter = async (data) => {
        let csv = "";
        if (!Array.isArray(data) || !data.every((p) => typeof p === "object" && p !== null)) {
            return;
        }
        const heading = Object.keys(data[0]).join(";");
        const body = data.map((j) => Object.values(j).join(";")).join("\n");
        csv += `${heading}\n${body} `;

        saveAsCSVFile(csv, "CSV Contado");
    };

    const exportCsv = () => {
        // CREAMOS LA PRIMERA HOJA
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("Página01");

        //Se establecen los anchos de las columnas
        const width = 20;
        sheet.getColumn("A").width = width + 50;
        sheet.getColumn("B").width = width + 50;
        sheet.getColumn("C").width = width + 50;

        //Se le dan los valores al encabezado
        sheet.getCell("A1").value = "Número del artículo";
        sheet.getCell("B1").value = "Almacén";
        sheet.getCell("C1").value = "Cantidad contada";

        let lines = 2;

        itemsSAP.forEach((item) => {
            const i = itemsContado.findIndex((itm) => itm.code == item.code);
            sheet.getCell(`A${lines}`).value = item.code;
            sheet.getCell(`B${lines}`).value = whs;
            sheet.getCell(`C${lines}`).value = i !== -1 ? itemsContado[i].cant : 0;
            lines++;
        });
        return workbook.csv.writeBuffer();
    };

    const generatecsvReport = () => {
        exportCsv()
            .then((response) => {
                const blob = new Blob([response]);
                fs.saveAs(blob, `Resultado De Comparacion.csv`);
            })
            .catch((error) => toast.error(`¡Error!, ${error.message}`));
    };

    const generatecsvContado = (mode) =>
        resultCountedInventory(mode, whs)
            .then((data) => exportCsvAfter(data))
            .catch((error) => toast.error(`¡Error!, ${error.message}`))
            .finally(() => setLoading2(false));

    const loadingTable = {
        pointerEvents: "none",
        opacity: " 0.5",
    };

    const breadcrumb = [
        { title: "Home", url: "/" },
        { title: "Almacén", url: "/almacen" },
        { title: "Resultado de Inventario", url: "" },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>Módulo Almacén - Ingeniería y Controles Automatizados</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />
            <div className="container">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-6">
                                <h5 className="mt-3">Seleccione la Bodega.</h5>
                                <Input
                                    type="select"
                                    name="type"
                                    id="type"
                                    style={{ width: "50%" }}
                                    value={whs}
                                    onChange={(e) => setWhs(e.target.value)}
                                >
                                    <option value="01">Bodega 01</option>
                                    <option value="05">Bodega 05</option>
                                </Input>
                            </div>

                            <div className="col-6 mt-2">
                                <h5 className="mt-5">Paso 1 —Seleccione La bodega.</h5>
                                <button
                                        className="mt-2 ml-5 btn btn-primary"
                                        type="button"
                                        onClick={() => generatecsvContado("all")}
                                        disabled={loading2}
                                    >
                                        <i className={`${loading2 ? "fas fa-cog fa-spin" : "fas fa-cloud-download-alt"} mr-2`} />
                                        {`${loading2 ? "Descargando" : "Descargar"}`} Reporte de Conteo
                                    </button>
                            </div>
                            <div className="col-6">
                                <h5 className="mt-5">Cargar CSV SAP</h5>
                                <ImportCsv05 loadItems={loadItems} setItems={setItemsSAP} mode="SAP" whs={whs} />
                            </div>

                            <div className="col-6">
                                <h5 className="mt-1" style={{ paddingTop: "31%" }}>
                                    Paso 2 —Arrastre el archivo CSV generado por SAP.
                                </h5>
                            </div>
                            <div className="col-6 mt-3">
                                <h5 className="mt-3">Cargar CSV Contado</h5>
                                <div className="t-loading-table-container" style={!itemsSAP.length > 0 ? loadingTable : { width: "100%" }}>
                                    <ImportCsv05 loadItems={loadItems} setItems={setItemsContado} mode="Contado" whs={whs} />
                                </div>
                            </div>
                            <div className="col-6 stepper stepper-horizontal">
                                <h5 className="mt-1" style={{ paddingTop: "25%" }}>
                                    Paso 3 —Arrastre el archivo CSV contado.
                                    <button
                                        className="mt-2 ml-5 btn btn-primary"
                                        type="button"
                                        onClick={() => generatecsvContado("sum")}
                                        disabled={loading2}
                                    >
                                        <i className={`${loading2 ? "fas fa-cog fa-spin" : "fas fa-cloud-download-alt"} mr-2`} />
                                        {`${loading2 ? "Descargando" : "Descargar"}`} CSV Contado
                                    </button>
                                </h5>
                            </div>
                            <div className="col-6 mt-3 text-center">
                                <button
                                    type="button"
                                    className="btn btn-primary mr-3 mt-4"
                                    onClick={() => generatecsvReport(itemsSAP)}
                                    disabled={loading || !itemsSAP.length > 0 || !itemsContado.length > 0}
                                >
                                    <i className={`${loading ? "fas fa-cog fa-spin" : "fas fa-cloud-upload-alt"} mr-2`} />
                                    {`${loading ? "Calculando" : "Calcular"}`} Resultado Comparativo
                                </button>
                            </div>
                            <div className="col-6 stepper stepper-horizontal">
                                <h5 className="mt-5">Paso 4 —Generar comparativo</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
