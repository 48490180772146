import React from 'react';
import ReactDOM from 'react-dom';
import { PDFViewer, Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    page: {
        fontSize: 9,
        marginBottom: 20,
    },

    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'baseline',
        width: '100%',
        padding: 10,
    },

    section: {
        flexGrow: 1,
    },

    subtitule: {
        margin: 1,
        padding: 1,
        width: '17%',
        fontWeight: 'bold',
        fontFamily: 'Helvetica-Bold',
    },

    value: {
        margin: 1,
        padding: 1,
        width: '15%',
        textAlign: 'right',
        marginRight: 30,
    },

    thead: {
        flexDirection: 'row',
        border: 1,
        backgroundColor: '#A9D0F5',
        margin: '10 20 0 20',
        textAlign: 'center'

    },

    tbody: {
        flexDirection: 'row',
        textAlign: 'center',
        margin: '10 20 0 20',
    },

    th: {
        margin: '3, 2',
        padding: '3, 2',
        textAlign: 'center',
        fontFamily: 'Helvetica-Bold'
    },

    td: {
        margin: '0, 2',
        padding: '0, 2',
        textAlign: 'center'
    },


});
const project = sessionStorage.getItem('PrintEngpr') ? JSON.parse(sessionStorage.getItem('PrintEngpr')) : null;

// Create Document Component
const MyDocument = () => (
    <Document title={project && project.mainProject.name}>
        <Page size="LETTER" style={styles.page}>
            <View style={{ ...styles.row, margin: 0, padding: 0 }} fixed>
                <Image src="images/header.png" style={{ width: '100%' }} />
            </View>
            <View style={{ ...styles.row, margin: '5 10 ', padding: '5 10', width: '100%', fontSize: 11, textAlign: 'center' }} fixed>
                <Text style={{ width: '100%' }}>{project && project.mainProject.name}</Text>
            </View>
            <View style={{ ...styles.row, margin: '3 10', padding: '3 10' }}>
                <View style={styles.section}>
                    <Text style={styles.subtitule}>Empresa Encargada:</Text>
                    <Text style={styles.subtitule}>Ingeniero Encaragado:</Text>
                    <Text style={styles.subtitule}>Código:</Text>
                    <Text style={styles.subtitule}>Estado:</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.value}>{project && project.company.name}</Text>
                    <Text style={styles.value}>{project && project.engineer}</Text>
                    <Text style={styles.value}>{project && project.mainProject.code}</Text>
                    <Text style={styles.value}>{project && project.status === 'finished' ? 'Finalizado' : 'En Proceso'}</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.subtitule}>Cód. Cliente:</Text>
                    <Text style={styles.subtitule}>Cliente:</Text>
                    <Text style={styles.subtitule}>Orden de Venta:</Text>
                    <Text style={styles.subtitule}>Fecha Entrega:</Text>

                </View>
                <View style={styles.section}>
                    <Text style={styles.value}>{project && project.mainProject.code_cliente}</Text>
                    <Text style={styles.value}>{project && project.mainProject.name_cliente}</Text>
                    <Text style={styles.value}>{project && project.mainProject.orden_venta}</Text>
                    <Text style={styles.value}>{project && project.dateFinish}</Text>

                </View>
                <View style={styles.section}>
                    <Text style={styles.subtitule}>Fecha Inicio:</Text>
                    <Text style={styles.subtitule}>Fecha Finalizado:</Text>
                    <Text style={styles.subtitule}>Subproyecto:</Text>
                    <Text style={styles.subtitule}>Orden de Venta:</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.value}>{project && project.dateCreated}</Text>
                    <Text style={styles.value}>{project && project.status === 'finished' && project.dateFinished}</Text>
                    <Text style={styles.value}>{project && project.subProject && project.subProject.code}</Text>
                    <Text style={styles.value}>{project && project.subProject && project.subProject.orden_venta}</Text>
                </View>
            </View>
            {project && project.productsICA.length > 0 && (
                <React.Fragment>
                    <Text style={{ fontSize: 14, textAlign: 'center', marginTop: 10, fontFamily: 'Helvetica-Bold' }}>Lista Materiales ICA</Text>
                    <View style={styles.thead}>
                        <Text style={{ ...styles.th, width: '5%' }}>#</Text>
                        <Text style={{ ...styles.th, width: '15%' }}>Código</Text>
                        <Text style={{ ...styles.th, width: '40%' }}>Producto</Text>
                        <Text style={{ ...styles.th, width: '9%' }}>Cantidad</Text>
                        <Text style={{ ...styles.th, width: '14%' }}>Bodega</Text>
                        <Text style={{ ...styles.th, width: '13%' }}>Prioridad</Text>
                    </View>
                    {project.productsICA.map((e, i) => (
                        <View style={styles.tbody} key={i} break={i !== 0 && i % 17 === 0}>
                            <Text style={{ ...styles.td, width: '5%' }}>{i + 1}</Text>
                            <Text style={{ ...styles.td, width: '15%', textAlign: 'left' }}>{e.code}</Text>
                            <Text style={{ ...styles.td, width: '40%', textAlign: 'justify' }}>{e.name}</Text>
                            <Text style={{ ...styles.td, width: '9%' }}>{e.order}</Text>
                            <Text style={{ ...styles.td, width: '14%' }}>{e.warehouse_id.label}</Text>
                            <Text style={{ ...styles.td, width: '13%' }}>{e.priority}</Text>
                        </View>
                    ))}
                </React.Fragment>
            )}

            {project && project.productsAP.length > 0 && (
                <React.Fragment>
                    <Text style={{ fontSize: 14, textAlign: 'center', marginTop: 15, fontFamily: 'Helvetica-Bold' }}>Lista Materiales AP</Text>
                    <View style={styles.thead}>
                        <Text style={{ ...styles.th, width: '5%' }}>#</Text>
                        <Text style={{ ...styles.th, width: '15%' }}>Código</Text>
                        <Text style={{ ...styles.th, width: '40%' }}>Producto</Text>
                        <Text style={{ ...styles.th, width: '9%' }}>Cantidad</Text>
                        <Text style={{ ...styles.th, width: '14%' }}>Bodega</Text>
                        <Text style={{ ...styles.th, width: '13%' }}>Prioridad</Text>
                    </View>
                    {project.productsAP.map((e, i) => (
                        <View style={styles.tbody} key={i} break={i !== 0 && i % 17 === 0}>
                            <Text style={{ ...styles.td, width: '5%' }}>{i + 1}</Text>
                            <Text style={{ ...styles.td, width: '15%', textAlign: 'left' }}>{e.code}</Text>
                            <Text style={{ ...styles.td, width: '40%', textAlign: 'justify' }}>{e.name}</Text>
                            <Text style={{ ...styles.td, width: '9%' }}>{e.order}</Text>
                            <Text style={{ ...styles.td, width: '14%' }}>{e.warehouse_id.label}</Text>
                            <Text style={{ ...styles.td, width: '13%' }}>{e.priority}</Text>
                        </View>
                    ))}
                </React.Fragment>
            )}
        </Page>
    </Document >
);

const App = () => (
    <PDFViewer width="100%" height="100%">
        <MyDocument />
    </PDFViewer>
);

const Render = () => ReactDOM.render(<App />, document.getElementById('root'));

export default Render;