// react
import "./tableStyle.css";
import fs from "file-saver";
import React, { Component } from "react";
// third-party
import { Column } from "primereact/column";
import { Helmet } from "react-helmet-async";
import AsyncSelect from "react-select/async";
import ImportCsvCodes from "./ImportCsvCodes";
import PageHeader from "../shared/PageHeader";
import { DataTable } from "primereact/datatable";
import { GenerateExcelSurplus } from "../shared/utilities";
import { Button, UncontrolledCollapse } from "reactstrap";
import { searchProjectsIca, getProductsSurplusProjects } from "../../store/directusSdk";

export default class ConsultSurplus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: null,
            surplus: [],
            codes: [],
            expandAll: false,
            expandedRows: null,
            loading: false,
        };
    }

    promiseOptions = (inputValue) => searchProjectsIca(inputValue);

    collapseAll = () => this.setState({ expandedRows: null, expandAll: false });

    exportCSV = () => {
        const { surplus } = this.state;
        GenerateExcelSurplus(surplus)
            .then((response) => {
                const blob = new Blob([response]);
                fs.saveAs(blob, `sobrantes.xlsx`);
            })
            .catch((error) => {});
    };

    getSurplus = () => {
        const { codes } = this.state;
        this.setState({ loading: true });

        if (codes.length > 0) {
            return getProductsSurplusProjects({ ...codes.map((code) => code.value) })
                .then((surplus) => this.setState({ surplus }))
                .finally(() => this.setState({ loading: false }));
        }
    };

    render() {
        const { loading, surplus: surplus, codes } = this.state;

        const breadcrumb = [
            { title: "Home", url: "/" },
            { title: "Almacén", url: "/almacen" },
            { title: "Consultar Sobrantes", url: "" },
        ];

        const header = (
            <div className="row">
                <div className="col-md-10 ">
                    <h5 className="mt-2">Sobrantes</h5>
                </div>
                <div className="col-md-2">
                    <Button color="success" disabled={!surplus.length} onClick={this.exportCSV}>
                        <i className="fas fa-file-excel mr-2" />
                        Exportar
                    </Button>
                </div>
            </div>
        );

        return (
            <React.Fragment>
                <Helmet>
                    <title>Consultar Sobrantes - Ingeniería y Controles Automatizados</title>
                </Helmet>

                <PageHeader breadcrumb={breadcrumb} />
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <Button color="secondary" id="toggler" style={{ marginBottom: "1rem" }}>
                                Importar .CSV
                            </Button>
                            <UncontrolledCollapse toggler="#toggler">
                                <div className="mb-2">
                                    <ImportCsvCodes
                                        LoadCodes={(codes) => this.setState({ codes })}
                                        LoadProductos={(surplus) => this.setState({ surplus })}
                                        loading={(loading) => this.setState({ loading: loading })}
                                    />
                                </div>
                            </UncontrolledCollapse>
                            <div className="row">
                                <AsyncSelect
                                    isClearable
                                    cacheOptions
                                    loadOptions={this.promiseOptions}
                                    noOptionsMessage={() => "No se Encontraron registros..."}
                                    loadingMessage={() => "Buscando..."}
                                    placeholder="Códigos de Proyectos..."
                                    value={codes}
                                    onChange={(e) => {
                                        this.setState({ codes: e, surplus: e.length === 0 ? [] : surplus });
                                    }}
                                    className="col-md-10 mt-3"
                                    isMulti
                                />
                                <div className="col-md-2 mt-3">
                                    <Button
                                        color="primary"
                                        className={`${loading && "btn-loading"}`}
                                        disabled={loading || !codes.length > 0}
                                        onClick={this.getSurplus}
                                    >
                                        <i className="pi pi-search mr-2" />
                                        Buscar
                                    </Button>
                                </div>
                            </div>

                            {loading ? (
                                <div>
                                    <br />
                                    <br />
                                    <div className="div-loading" />
                                    <br />
                                    <br />
                                </div>
                            ) : (
                                <DataTable
                                    ref={(el) => {
                                        this.dt = el;
                                    }}
                                    value={surplus}
                                    header={header}
                                    dataKey="id"
                                    paginator
                                    rows={10}
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                    removableSort
                                    emptyMessage="No se han encontrado registros..."
                                    exportFilename="Comprometidos"
                                    className="p-datatable-dispatches p-datatable-sm mt-1 mt-4"
                                >
                                    <Column
                                        field="pryCode"
                                        header="Proyecto"
                                        sortable
                                        filter
                                        style={{ width: "15%" }}
                                        filterType="number"
                                        filterPlaceholder="Mayor a"
                                        filterMatchMode="gte"
                                    />
                                    <Column field="code" header="Código" sortable filter style={{ width: "20%" }} filterPlaceholder="Todos" />
                                    <Column
                                        field="name"
                                        header="Descripción"
                                        sortable
                                        filter
                                        style={{ width: "60%" }}
                                        filterMatchMode="contains"
                                        filterPlaceholder="Todos"
                                    />
                                    <Column
                                        field="Qty"
                                        header="Cantidad"
                                        sortable
                                        filter
                                        style={{ width: "12%" }}
                                        filterType="number"
                                        filterPlaceholder="Mayor a"
                                        filterMatchMode="gte"
                                    />
                                </DataTable>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
