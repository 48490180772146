// react
import React, { useEffect, useState, useRef } from "react";
// third-party
import { Helmet } from "react-helmet-async";
import { DataTable } from "primereact/datatable";
import moment from "moment";
import { Column } from "primereact/column";
import PageHeader from "../../shared/PageHeader";
import { InputNumber } from "primereact/inputnumber";
import Select from "react-select";

import { getDispatchesReleasedAp, getDispatchItemsAP, approveDispatchAp } from "../../../store/directusSdk";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge } from "reactstrap";
import { FormGroup, Label, Input, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export function ReceiveDispatches() {
    const [dispatches, setDispatches] = useState([]);
    const [dataModal, setDataModal] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [loading4, setLoading4] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [open, setOpen] = useState(false);
    const [warehouses, setWarehouses] = useState([]); // arreglo de objectos que contiene la informacion de la bodega seleccionada
    const [recivedDate, setRecivedDate] = useState(moment().format("YYYY-MM-DD"));
    const [whs, setWhs] = useState(1); // estado que contiene el id seleccionado para la creacion de la nueva ubicacion
    const [shelf, setShelf] = useState(null);
    const [level, setLevel] = useState(null);
    const sdt = useRef();

    const GetDispatches = () =>
        getDispatchesReleasedAp()
            .then(setDispatches)
            .finally(() => setLoading(false));

    useEffect(() => {
        setLoading(true);
        GetDispatches();
    }, []);

    const getItemsDispacth = (row) => {
        setLoading3(true);
        setOpen(true);
        return getDispatchItemsAP(row.id)
            .then(async (dispatches) => {
                const items = [];
                dispatches.forEach((e) => {
                    if (e.id && e.code && e.code != "FLETE" && e.code != "MULTA" && e.code.substr(0, 2) != "WV") {
                        items.push(e);
                    }
                });
                setDataModal({ ...row, dispatches: items });
            })
            .catch((error) => toast.error(`¡Error!, ${error.message}`))
            .finally(() => setLoading3(false));
    };

    const changeQty = (row, value) => {
        row.qtyReceived = value;
        const i = dataModal.dispatches.findIndex((r) => r.id === row.id);
        if (i >= 0) {
            dataModal.dispatches[i] = row;
            setDataModal({ ...dataModal });
        }
    };

    /*Función que controla la selección de los niveles en los ítems de la tabla  */
    const selectedlevel = (row, e) => {
        const value = e.value.toString().split("-");
        if (value.length > 1) {
            row.rLocation = value[1];
            row.shelfSelected = e.value;
            row.lvlSelected = e.value;
            row.lvlEdit = false;
        } else {
            row.lvlSelected = e.value;
            row.lvlEdit = true;
        }

        const i = dataModal.dispatches.findIndex((r) => r.id === row.id);
        if (i >= 0) {
            dataModal.dispatches[i] = row;
            setDataModal({ ...dataModal });
        }
    };

    /*Función encargada de llenar los seleccionables de los estantes en los ítems de la tabla*/
    const selectedWhs = (e) => {
        setWhs(e);
        setShelf(e[0] ? e[0].shelfs : null);
        setLevel(e[0] && e[0].shelfs[0] ? e[0].shelfs[0].levels[0] : null);
        setDisabled(true);
        setLoading3(true);
        return (
            e &&
            getDispatchItemsAP(dataModal.id)
                .then(async (dispatches) => {
                    const items = [];
                    dispatches.forEach((e) => {
                        if (e.id && e.code && e.code != "FLETE" && e.code != "MULTA" && e.code.substr(0, 2) != "WV") {
                            items.push(e);
                        }
                    });
                    setDataModal({ ...dataModal, dispatches: items });
                })
                .catch((error) => toast.error(`¡Error!, ${error.message}`))
                .finally(() => setLoading3(false))
        );
    };

    /*Funciòn encargada de cargar los niveles en base al estante seleccionado en el item de la tabla  */
    const loadLevel = (e, row) => {
        const newLevels = [];
        const warehause = whs;
        if (warehause) {
            const shelfs = warehause.shelfs.find((s) => s.id === e.value);
            if (shelfs) {
                shelfs.levels.map((lvls) => {
                    return newLevels.push({ label: lvls.code, value: lvls.id });
                });
            }
        }
        const value = e.value.toString().split("-");

        if (value.length > 1) {
            row.rLocation = value[1];
            row.shelfSelected = e.value;
            row.lvlSelected = e.value;
            row.lvlEdit = false;
        } else {
            row.shelfSelected = e.value;
            row.lvlEdit = true;
            row.lvlSelected = null;
        }

        row.location.levels[row.location.levels[1] ? 1 : 0].items = newLevels;
        const i = dataModal.dispatches.findIndex((r) => r.id === row.id);
        if (i >= 0) {
            dataModal.dispatches[i] = row;
            setDataModal({ ...dataModal });
        }
    };

    const loadBathLevel = (value) => {
        const newDispatches = [];
        dataModal.dispatches &&
            dataModal.dispatches.forEach((dp) => {
                const location = dp.location;
                const index = location.levels.findIndex((d) => d.code === "N");
                location.levels[index].items = value.levels.map((lvls) => ({ label: lvls.code, value: lvls.id }));
                dp.shelfSelected = value.id;
                dp.lvlEdit = true;
                dp.lvlSelected = value.levels[0].id;
                newDispatches.push({ ...dp, location: location });
            });
        dataModal.dispatches = newDispatches;
        setShelf(value);
        setLevel(null);
        setDataModal(dataModal);
        setDisabled(false);
    };

    const selectedBathLevel = (value) => {
        const dispatches = dataModal.dispatches;
        dataModal.dispatches = dispatches.map((dp) => ({ ...dp, lvlSelected: value.id }));
        setDataModal(dataModal);
        setLevel(value);
    };

    /*Función que válida si alguno de los ítems le falta ubicación  */
    const isValidityItem = (items) => dataModal.dispatches && items.every((e) => e.shelfSelected && e.lvlSelected);

    const exportCSV = () => sdt.current.exportCSV();

    const breadcrumb = [
        { title: "Home", url: "/" },
        { title: "Almacén", url: "/almacen" },
        { title: "Recibir Despachos AP", url: "" },
    ];

    const header = (
        <div className="row">
            <div className="col-md-6">
                <h5>Recibir Despachos</h5>
            </div>
            <div className="col-md-2 offset-md-4">
                <Button color="info" onClick={exportCSV}>
                    <i className="fas fa-file-export mr-2" />
                    Exportar
                </Button>
            </div>
        </div>
    );

    const createDispatch = () => {
        if (!recivedDate) {
            return toast.error("Error, Debe introducir la fecha de recibido");
        }

        const lote = {};

        /*Busca en la colección para saber si existe el lote */

        Swal.fire({
            title: `Recibir despacho`,
            text: `¿Desea recibir el despacho Nº ${dataModal.code} ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#004FBD",
            cancelButtonColor: "#3d464d",
            cancelButtonText: "Cancelar",
            confirmButtonText: "Aceptar",
            showLoaderOnConfirm: true,
            reverseButtons: true,
            preConfirm: () => {
                setLoading4(true);
                const items = [];

                dataModal.dispatches.forEach((item) =>
                    items.push({
                        id: item.id,
                        code: item.code,
                        qty: item.qtyReceived,
                    })
                );

                if (!items.length > 0) {
                    return toast.error("Error,Un despacho sin productos no puede ser marcado como recibido");
                }

                lote.dispatchId = dataModal.id;
                lote.items = items;
                lote.recivedDate = recivedDate;
                return approveDispatchAp(lote)
                    .then(async () => {
                        toast.success(`¡Se ha recibido el despacho Nº ${dataModal.code} de manera exitosa!`);
                        GetDispatches();
                        setDisabled(true);
                        setShelf(null);
                        setLevel(null);
                        setOpen(false);
                        setWhs(warehouses[0]);
                    })
                    .catch((error) => {
                        toast.error(`${error.message}`);
                        Swal.showValidationMessage(`${error.message}`);
                    })
                    .finally(() => setLoading4(false));
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "¡Despacho recibido con éxito!",
                    icon: "success",
                });
            }
        });
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Recibir Despachos - Ingeniería y Controles Automatizados</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />
            {loading ? (
                <div className="div-loading m-5 p-5" />
            ) : (
                <div className="container">
                    <DataTable
                        ref={sdt}
                        value={dispatches}
                        header={header}
                        paginator
                        rows={10}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        removableSort
                        emptyMessage="No se han encontrado registros..."
                        className="p-datatable-customers mt-3"
                    >
                        <Column
                            field="code"
                            header="Despacho"
                            sortable
                            filter
                            style={{ width: "14%" }}
                            filterPlaceholder="Todos"
                            filterHeaderClassName="p-inputtext-sm"
                        />
                        <Column
                            field="supplier"
                            header="Proveedor"
                            sortable
                            filter
                            style={{ width: "30%" }}
                            filterPlaceholder="Todos"
                            filterHeaderClassName="p-inputtext-sm"
                        />
                        <Column
                            field="date"
                            header="Fecha"
                            sortable
                            style={{ width: "16%" }}
                            filterType="date"
                            filterPlaceholder="Todos"
                            filterHeaderClassName="p-inputtext-sm"
                            body={(row) => moment(row.date).format("DD-MM-YYYY")}
                        />
                        <Column
                            style={{ width: "16%" }}
                            body={(row) => {
                                if (row.supplier === "-") {
                                    return <Badge color="danger"> ERROR</Badge>;
                                }

                                return (
                                    <Button color="primary" disabled={loading || row.code === "-" || !row.code} onClick={() => getItemsDispacth(row)}>
                                        <i className={`${loading ? "fas fa-cog fa-spin" : "fas fa-check-double"} mr-2`} />
                                        Recibir
                                    </Button>
                                );
                            }}
                        />
                    </DataTable>
                </div>
            )}

            {open && (
                <Modal isOpen={open} centered  size="lg" style={{ maxWidth: "1500px", width: "90%" }} zIndex="1000">
                    <ModalHeader
                        toggle={() => {
                            //(1);
                            !loading && setOpen(false);
                            setDisabled(true);
                            setShelf(null);
                            setLevel(null);
                            setWhs(warehouses[0]);
                        }}
                    >
                        {loading3 ? "Cargando..." : `Recibir despacho ${dataModal.code}`}
                    </ModalHeader>
                    <ModalBody>
                        {loading3 ? (
                            <div className="div-loading m-5 p-5" />
                        ) : (
                            <>
                                <Row form>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="recivedDate">Fecha Recibido</Label>
                                            <Input
                                                disabled={loading | loading4}
                                                type="date"
                                                id="recivedDate"
                                                placeholder="DD/MM/AAAA"
                                                defaultValue={recivedDate}
                                                onChange={(e) => setRecivedDate(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                
                                <div className="orders-subtable">
                                    {dataModal.dispatches && dataModal.dispatches.length > 0 ? (
                                        <DataTable value={dataModal.dispatches} className="p-datatable-sm" removableSort rowHover>
                                            <Column
                                                field="code"
                                                header="Código"
                                                sortable
                                                filter
                                                filterPlaceholder="Todos"
                                                filterHeaderClassName="p-inputtext-sm"
                                                style={{ width: "9%" }}
                                            />
                                            <Column
                                                field="name"
                                                header="Producto"
                                                sortable
                                                filter
                                                filterPlaceholder="Todos"
                                                filterHeaderClassName="p-inputtext-sm"
                                                style={{ width: "40%" }}
                                            />
                                            <Column
                                                field="qtyDispatched"
                                                header="Despachado"
                                                sortable
                                                filter
                                                style={{ textAlign: "center", width: "8%" }}
                                                filterType="number"
                                                filterPlaceholder="Todos"
                                                filterHeaderClassName="p-inputtext-sm"
                                            />
                                            <Column
                                                field="qtyReceived"
                                                header="Confirmado"
                                                sortable
                                                body={(row) => (
                                                    <InputNumber
                                                        disabled={loading3}
                                                        max={row.qtyDispatched}
                                                        min={0}
                                                        value={row.qtyReceived}
                                                        onValueChange={(e) => changeQty(row, e.target.value)}
                                                        showButtons
                                                        buttonLayout="horizontal"
                                                        decrementButtonClassName="p-button-secondary"
                                                        incrementButtonClassName="p-button-info"
                                                        incrementButtonIcon="pi pi-plus"
                                                        decrementButtonIcon="pi pi-minus"
                                                        size={1}
                                                    />
                                                )}
                                                style={{ width: "10%" }}
                                                filterType="number"
                                                filterPlaceholder="Todos"
                                                filterHeaderClassName="p-inputtext-sm"
                                            />
                                        </DataTable>
                                    ) : (
                                        <Label>No se encontró información correcta para este despacho…</Label>
                                    )}
                                </div>
                            </>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            disabled={loading || loading3}
                            onClick={() => {
                                setOpen(false);
                                setDisabled(true);
                                setShelf(null);
                                setLevel(null);
                                setWhs(warehouses[0]);
                            }}
                        >
                            <i className="fa fa-ban mr-2" />
                            Cancelar
                        </Button>

                        <Button
                            color="primary"
                            disabled={loading4 || loading || loading3 || !(dataModal.dispatches.length > 0)}
                            onClick={() => createDispatch()}
                        >
                            <i className={`${loading3 ? "fas fa-cog fa-spin" : "fas fa-check-double"} mr-2`} />
                            Recibir
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ReceiveDispatches);
