// react
import React, { Component } from "react";

// third-party
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Cookies from "js-cookie";
// application
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import AsyncSelect from "react-select/async";
import NavPanel from "./NavPanel";
import Search from "./Search";
import Topbar from "./Topbar";
import { getSuggestedCustomer, getSuggestedContacts } from "../../store/directusSdk";
import { ContactChange, ContactsChange, CustomerChange } from "../../store/cart";

class Header extends Component {
    constructor(props) {
        super(props);
        const {
            cart: { customer },
        } = props;
        Cookies.set("companyid", !customer ? 0 : customer.value, { path: "/", domain: ".icautomatizados.com", expires: 7 });

        this.state = {
            open2: false,
            select: customer,
        };
    }

    promiseOptions = (inputValue) => getSuggestedCustomer(inputValue);

    render() {
        const { layout, pagesContent, currentUser, history, cart, ContactChange, ContactsChange, CustomerChange, multiplications } = this.props;

        const { open2, select } = this.state;

        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                color: state.isSelected ? "white" : "blue",
            }),
        };

        let bannerSection;
        if (layout === "default") {
            bannerSection = (
                <div className="site-header__middle container mt-2 mb-2">
                    <div className="site-header__logo col-3">
                        <Link to="/">
                            <img src="/images/logos/logoblanco.png" alt="ICAutomatizados" height="80px" />
                        </Link>
                    </div>
                    <div className="site-header__search col-5">
                        <br />
                        <Search context="header" />
                        <br />
                        <AsyncSelect
                            cacheOptions
                            loadOptions={this.promiseOptions}
                            defaultOptions
                            isClearable
                            noOptionsMessage={() => "No se Encontró ningun cliente."}
                            loadingMessage={() => "Buscando..."}
                            placeholder="Ingrese un cliente..."
                            onChange={(e) => this.setState({ select: e, open2: true })}
                            value={cart.customer}
                            styles={customStyles}
                            defaultValue={cart.customer}
                        />
                    </div>
                    <div className="col-4">
                        <div className="site-header__phone-title">Cliente Seleccionado:</div>
                        <div className="site-header__phone-number">{cart.customer ? cart.customer.label : ""}</div>
                    </div>
                </div>
            );
        }

        return (
            <div className="site-header">
                {layout === "default" && <Topbar currentUser={currentUser} history={history} cart={cart} />}
                {bannerSection}
                <div className="site-header__nav-panel">
                    <NavPanel layout={layout} history={history} pagesContent={pagesContent} />
                </div>
                <Modal isOpen={open2} toggle={() => this.setState({ select: cart.customer, open2: false })} centered>
                    <ModalHeader toggle={() => this.setState({ select: cart.customer, open2: false })}>Cambiar Cliente</ModalHeader>
                    <ModalBody><h4>¿Esta seguro que desea cambiar el cliente?</h4>
                    <p>¿Tenga en cuenta que si ha modificado algún precio de la lista, el precio se reiniciará.?</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => {
                                Cookies.set("companyid", !select ? 0 : select.value, { path: "/", domain: ".icautomatizados.com", expires: 7 });
                                CustomerChange(
                                    select,
                                    select
                                        ? multiplications.find((e) => e.value == select.multi)
                                        : multiplications.find((e) => e.id == currentUser.multi)
                                );

                                if (!select) {
                                    ContactChange(null);
                                }
                                if (select) {
                                    getSuggestedContacts(select.value).then((elements) => {
                                        ContactChange(elements[0]);
                                        ContactsChange(elements);
                                    });
                                }
                                this.setState({ open2: false });
                            }}
                        >
                            Continuar
                        </Button>
                        <Button color="secondary" onClick={() => this.setState({ select: cart.customer, open2: false })}>
                            Cancelar
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

Header.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(["default", "compact"]),
};

Header.defaultProps = {
    layout: "default",
};

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
    cart: state.cart,
    multiplications: state.pagesContent.multiplications,
});

const mapDispatchToProps = {
    ContactChange,
    ContactsChange,
    CustomerChange,
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
