// react
import React, { Component } from 'react';
// third-party
import { Helmet } from 'react-helmet-async';
import AsyncSelect from 'react-select/async';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button, UncontrolledCollapse } from 'reactstrap';
import { searchProductsForCodeAP, getCommitedsAP } from '../../../store/directusSdk';
import PageHeader from '../../shared/PageHeader';
import ImportCsvCodes from './ImportCsvCodes';
import './tableStyle.css';

export default class ConsultComprometidos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: null,
            products: [],
            codes: [],
            expandAll: false,
            expandedRows: null,
            loading: false
        };
    }

    promiseOptions = (inputValue) => searchProductsForCodeAP(inputValue);

    collapseAll = () => this.setState({ expandedRows: null, expandAll: false });

    expandAll = () => {
        const { products } = this.state;
        const expandedRows = {};

        if (products.length > 0) {
            products.forEach((p) => { expandedRows[`${p.id}`] = true; });
            return this.setState({ expandedRows, expandAll: true });
        }
    }

    exportCSV = () => {
        return this.dt.exportCSV();
    }

    rowExpansionTemplate = (row) => (
        <div className="orders-subtable">
            {row.lines.length > 0 && (
                <DataTable value={row.lines} className="p-datatable-sm">
                    <Column field="type" header="Tipo" style={{ width: '15%' }} />
                    <Column field="code" header="Código" style={{ width: '25%' }} />
                    <Column field="qty" header="Cantidad" style={{ width: '20%' }} />
                    <Column field="line" header="Item" style={{ width: '20%' }} />
                    <Column field="whs" header="Bodega / Cliente" style={{ width: '25%' }} />
                </DataTable>
            )}
        </div>
    );

    getPoducts = () => {
        const { codes } = this.state;
        this.setState({ loading: true });
        if (codes.length > 0) {
            return getCommitedsAP(codes)
                .then((products) => this.setState({ products }))
                .finally(() => this.setState({ loading: false }));
        }

    }

    render() {

        const { loading, products, expandAll, codes, expandedRows } = this.state;

        const breadcrumb = [
            { title: 'Home', url: '/' },
            { title: 'Logística', url: '/logistica' },
            { title: "Logística AP", url: "/logistica/logistica-ap" },
            { title: 'Consultar Comprometidos', url: '' },
        ];

        const header = (
            <div className="row">
                <div className="col-md-4 ">
                    <h5 className="mt-2">Comprometidos</h5>
                </div>
                <div className="col-md-2 offset-md-4">
                    {expandAll ? <Button color="warning" onClick={this.collapseAll}><i className="fas fa-angle-double-right mr-2" />Contraer</Button> : <Button color="info" className="mr-2" onClick={this.expandAll} ><i className="fas fa-angle-double-down mr-2" />Expandir</Button>}
                </div>
                <div className="col-md-2">
                    <Button color="info" onClick={this.exportCSV}><i className="fas fa-file-export mr-2" />Exportar</Button>
                </div>
            </div >
        );

        return (
            <React.Fragment>
                <Helmet>
                    <title>Consultar Comprometidos - Ingeniería y Controles Automatizados</title>
                </Helmet>

                <PageHeader breadcrumb={breadcrumb} />
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <Button color="secondary" id="toggler" style={{ marginBottom: '1rem' }}> Importar .CSV</Button>
                            <UncontrolledCollapse toggler="#toggler">
                                <div className="mb-2">
                                    <ImportCsvCodes LoadCodes={(codes) => this.setState({ codes })} />
                                </div>
                            </UncontrolledCollapse>
                            <div className="row">
                                <AsyncSelect
                                    isClearable
                                    cacheOptions
                                    loadOptions={this.promiseOptions}
                                    noOptionsMessage={() => 'No se Encontraron registros...'}
                                    loadingMessage={() => 'Buscando...'}
                                    placeholder="Códigos de Productos..."
                                    value={codes}
                                    onChange={(e) => this.setState({ codes: e, products: [] })}
                                    className="col-md-10 mt-3"
                                    isMulti
                                />

                                <div className="col-md-2 mt-3">
                                    <Button color="primary" className={`${loading && 'btn-loading'}`} disabled={loading || !codes.length > 0} onClick={this.getPoducts}><i className="pi pi-search mr-2" />Buscar</Button>
                                </div>
                            </div>

                            {loading ? (
                                <div>
                                    <br />
                                    <br />
                                    <div className="div-loading" />
                                    <br />
                                    <br />
                                </div>
                            ) : (
                                <DataTable
                                    ref={(el) => { this.dt = el }}
                                    value={products}
                                    header={header}
                                    dataKey="id"
                                    paginator
                                    rows={10}
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                    removableSort
                                    emptyMessage="No se han encontrado registros..."
                                    exportFilename="Comprometidos"
                                    expandedRows={expandedRows}
                                    onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                                    rowExpansionTemplate={this.rowExpansionTemplate}
                                    className="p-datatable-dispatches p-datatable-sm mt-1 mt-4"
                                >
                                    <Column expander style={{ width: '4%' }} />
                                    <Column field="code" header="Código" sortable filter style={{ width: '25%' }} filterPlaceholder="Todos" />
                                    <Column field="name" header="Descripción" sortable filter style={{ width: '60%' }} filterMatchMode="contains" filterPlaceholder="Todos" />
                                    <Column field="qty" header="Cantidad" sortable filter style={{ width: '11%' }} filterType="number" filterPlaceholder="Mayor a" filterMatchMode="gte" />
                                </DataTable>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}
