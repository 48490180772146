// react
import React, { useState, useEffect } from "react";
// third-party
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment/moment";
import swal from "sweetalert";
import { toast } from "react-toastify";
import Select from "react-select";
import { InputNumber } from "primereact/inputnumber";
import { Button, FormGroup, Label, Input, Col, Row } from "reactstrap";
import { getPurchaseOrderLinesAP, UpdateItem, CreateItem } from "../../../store/directusSdk";

const today = moment().format("YYYY-MM-DD");

export default ({ purchaseOrder, updatePhater, userId, closed }) => {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [item, setItem] = useState(null);
    const [maxQty, setmaxQty] = useState(0);
    const [qty, setQty] = useState(1);
    const [options, setOptions] = useState([]);
    const [optionsCopy, setOptionsCopy] = useState([]);
    const [dispatchDate, setDispatchDate] = useState(today);
    const [arrivalDate, setArrivalDate] = useState(today);

    const update = () => {
        getPurchaseOrderLinesAP(purchaseOrder.id)
            .then((data) => {
                setOptions(data);
                setOptionsCopy(data.map((i) => ({ ...i, qty: i.qty, dispatched: i.dispatched })));
            })
            .catch((error) => toast.error(`¡Error!, ${error.message}`))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        setLoading(true);
        update();
    }, [purchaseOrder]);

    const buttonTemplate = (row) => (
        <div className="col-md-5">
            <Button color="danger" onClick={() => deleteItems(row)}>
                <i className="fas fa-trash"></i>
            </Button>
        </div>
    );

    const deleteItems = (row) => {
        if (items.length > 0) {
            const i = items.findIndex((item) => item.id === row.id);
            const restoreOption = optionsCopy.find((opt) => opt.id === row.id);
            const indexOption = options.findIndex((opt) => opt.id === row.id);
            delete items[i];

            if (indexOption >= 0) {
                const newOptions = [...options];
                newOptions[indexOption] = restoreOption;
                setItems(items.filter((e) => e != null));
                return setOptions(newOptions);
            }

            setOptions([...options, restoreOption]);
            return setItems(items.filter((e) => e != null));
        }

        return setItems([]);
    };

    const deleteOption = (row) => {
        if (options.length > 0) {
            const i = options.findIndex((item) => item.value === row.value);
            delete options[i];

            if (options.length > 0) {
                return setOptions(options.filter((e) => e != null));
            }
        }
    };

    const addItems = () => {
        const newItems = [...items];
        const index = newItems.findIndex((e) => e.id === item.id);

        if (qty === maxQty) {
            if (index >= 0) {
                newItems[index].qty = qty + newItems[index].qty;
                deleteOption(item);
                setItems([...newItems]);
                setItem(null);
                return setQty(1);
            }
            deleteOption(item);
            setItems([
                ...items,
                {
                    ...item,
                    qty: qty,
                },
            ]);
            setItem(null);
            return setQty(1);
        }

        changeQtyOption(item.id);

        if (index > 0) {
            newItems[index].qty = qty + newItems[index].qty;
            setItems([...newItems]);
            setItem({});
            return setQty(1);
        }

        setItems([
            ...items,
            {
                ...item,
                qty: qty,
            },
        ]);
        setItem({});
        setQty(1);
    };

    const changeQtyOption = (id) => {
        const newOptions = [...options];
        const i = newOptions.findIndex((i) => i.id === id);
        if (i >= 0) {
            newOptions[i].dispatched = newOptions[i].dispatched + qty;
            setOptions([...newOptions]);
        }
    };

    const changeQtys = (id, qty) => {
        const newItems = [...items];
        const i = items.findIndex((item) => item.id === id);
        if (i >= 0) {
            items[i].qty = qty;
            setItems([...newItems]);
        }
    };

    const mountItemResult = (e) => {
        const max = e && e.qty - e.dispatched >= 0 ? e.qty - e.dispatched : 0;
        setmaxQty(max);
        setQty(max);
        setItem(e);
    };

    const mountMaxResultOnTable = (e) => {
        const copyRow = optionsCopy.find((ele) => ele.id === e.id);

        if (copyRow) {
            return copyRow.qty - copyRow.dispatched >= 0 ? copyRow.qty - copyRow.dispatched : 0;
        }
    };

    const createDispatch = () => {
        if (!dispatchDate || !arrivalDate) {
            return toast.error("¡Error!, ¡Debe introducir las la fecha de despacho y la de llegada!");
        }

        if (!(items.length > 0)) {
            return toast.error("¡Error!, ¡Debe introducir productos al despacho!");
        }

        swal({
            title: `Crear un Despacho Nuevo a la Orden de Compra Nº ${purchaseOrder.code}`,
            text: `¿Desea Crear el Despacho Nº ${purchaseOrder.code}-${purchaseOrder.dispatches.length + 1} ?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((value) => {
            if (value) {
                setLoading(true);
                const disp = {};
                const itemsSave = [];
                disp.arrival_date = arrivalDate;
                disp.arrival_marked_user = userId;
                disp.dispatch_date = dispatchDate;
                disp.order_id = purchaseOrder.id;
                disp.n = purchaseOrder.dispatches.length + 1;
                items.forEach((i) => {
                    const itemFilter = {};
                    itemFilter.qty = i.qty;
                    itemFilter.item_id = i.value;
                    itemsSave.push(itemFilter);
                });
                disp.items = itemsSave;

                CreateItem("ap_log_dispatches", disp)
                    .then(({ data }) => {
                        UpdateItem("ap_purchase_orders", purchaseOrder.id, { dispatches: data.id })
                            .then(() => {
                                toast.success("¡Se ha creado un despacho de manera exitosa!");
                                updatePhater(purchaseOrder.id);
                                closed();
                            })
                            .catch((error) => {
                                toast.error(`¡Error!, ${error.message}`);
                            });
                    })
                    .catch((error) => toast.error(`¡Error!, ${error.message}`))
                    .finally(() => setLoading(false));
            }
        });
    };

    const header = (
        <div className="row">
            <div className="col-md-8">
                <Select
                    className="basic-single"
                    classNamePrefix="select qweqeqw"
                    place
                    value={item}
                    isDisabled={loading || !(options.length > 0)}
                    isLoading={loading}
                    name="color"
                    onChange={mountItemResult}
                    options={options}
                    placeholder={
                        options.length > 0
                            ? `Listado de productos para el Despacho Nº ${purchaseOrder.code}`
                            : `El Despacho Nº ${purchaseOrder.code}, tiene todos sus productos seleccionados`
                    }
                />
            </div>
            <div className="col-md-2">
                <InputNumber
                    disabled={loading || !(options.length > 0)}
                    width="50px"
                    id="exampleNumber"
                    min={1}
                    max={maxQty}
                    value={qty}
                    onChange={(e) => setQty(e.value)}
                    showButtons
                    buttonLayout="horizontal"
                    decrementButtonClassName="p-button-secondary"
                    incrementButtonClassName="p-button-info"
                    incrementButtonIcon="pi pi-plus"
                    decrementButtonIcon="pi pi-minus"
                    size={5}
                />
            </div>
            <div className="col-md-2">
                <Button color="info" disabled={loading || !item || !(options.length > 0)} onClick={() => addItems()}>
                    <i className="fas fa-save mr-2"></i>
                    Agregar
                </Button>
            </div>
        </div>
    );

    return (
        <div className="orders-subtable">
            {loading ? (
                <div className="div-loading m-5 p-5" />
            ) : (
                <>
                    <Row form>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="dispatchDate">Fecha Despacho</Label>
                                <Input
                                    disabled={loading}
                                    type="date"
                                    value={dispatchDate}
                                    id="dispatchDate"
                                    onChange={(e) => setDispatchDate(e.target.value)}
                                />
                            </FormGroup>
                        </Col>

                        <Col md={2}>
                            <FormGroup>
                                <Label for="arrivalDate">Fecha Llegada</Label>
                                <Input
                                    disabled={loading}
                                    type="date"
                                    id="arrivalDate"
                                    value={arrivalDate}
                                    placeholder="DD/MM/AAAA"
                                    onChange={(e) => setArrivalDate(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <DataTable value={items} className="p-datatable-sm" removableSort rowHover header={header}>
                        <Column
                            field="code"
                            header="Código"
                            sortable
                            filterPlaceholder="Todos"
                            filterHeaderClassName="p-inputtext-sm"
                            style={{ width: "14%" }}
                        />
                        <Column
                            field="name"
                            header="Producto"
                            sortable
                            filterPlaceholder="Todos"
                            filterHeaderClassName="p-inputtext-sm"
                            style={{ width: "37%" }}
                        />
                        <Column
                            field="qty"
                            header="Cantidad"
                            sortable
                            style={{ textAlign: "center", width: "12%" }}
                            filterType="number"
                            filterPlaceholder="Todos"
                            body={(row) => {
                                return (
                                    <InputNumber
                                        disabled={loading}
                                        width="50px"
                                        id="exampleNumber"
                                        min={1}
                                        max={row && mountMaxResultOnTable(row)}
                                        value={row && row.qty}
                                        onChange={row && ((e) => changeQtys(row.id, e.value))}
                                        showButtons
                                        buttonLayout="horizontal"
                                        decrementButtonClassName="p-button-secondary"
                                        incrementButtonClassName="p-button-info"
                                        incrementButtonIcon="pi pi-plus"
                                        decrementButtonIcon="pi pi-minus"
                                        size={5}
                                    />
                                );
                            }}
                            filterHeaderClassName="p-inputtext-sm"
                        />
                        <Column header="Acciones" style={{ width: "10%" }} body={buttonTemplate} />
                    </DataTable>
                    <Button color="primary" onClick={() => createDispatch()} disabled={items.length <= 0} className="mt-4">
                        <i className="fas fa-save mr-2" />
                        Crear
                    </Button>
                </>
            )}
        </div>
    );
};
