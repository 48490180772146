import fs from "file-saver";
import "../../../loadingTable.css";
import React, { useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import InventoryTakeProduct from "./InventoryTakeProduct";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { GenerateExcelInventoryCustomerById } from "../../../../shared/utilities";

export default function InventoryClientModalSearch({ resetTop, isAdmin, items, setItems, open, setOpen, type, company, update }) {
    const [openTakeModal, setOpenTakeModal] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);

    const takeQtysIds = (id, qty) => {
        const newItems = [...items];
        const i = newItems.findIndex((item) => item.id === id);
        if (i >= 0) {
            newItems[i].takeQty = qty;
            setItems(newItems);
        }
    };

    const exportExcelInventoryProducts = () => {
        GenerateExcelInventoryCustomerById({ items, inventoryName: true })
            .then((response) => {
                const blob = new Blob([response]);
                fs.saveAs(blob, `LISTADO_DE_PRODUCTOS_EN_CONTRADOS.xlsx`);
            })
            .catch((error) => {});
    };

    const header = (
        <div className="row">
            <h4 className={"col-md-10"}>Lista de Productos</h4>
            {["U", "S"].includes(type) && (
                <Button color="info" className="mr-3" onClick={() => exportExcelInventoryProducts()}>
                    <i className=" fas fa-file-excel mr-2" />
                    Productos
                </Button>
            )}
            <div className="row"></div>
        </div>
    );

    const footer = (
        <div>
            {["U", "S"].includes(type) && isAdmin && (
                <Button
                    color="primary"
                    disabled={selectedProducts.length < 1}
                    onClick={() => {
                        selectedProducts.forEach((product) => {
                            takeQtysIds(product.id, product.stock);
                        });
                        setOpenTakeModal(true);
                    }}
                >
                    <i className={"fas fa-shopping-basket"}></i>
                    Tomar materiales
                </Button>
            )}
        </div>
    );

    return (
        <Modal isOpen={open} centered size="lg" style={{ maxWidth: "1600px", width: "100%" }} zIndex="1000">
            <ModalHeader>Listado de productos encontrados</ModalHeader>
            <ModalBody>
                <div className="row mt-5">
                    <div className="col-md-12 ">
                        <DataTable
                            value={items}
                            size="small"
                            dataKey="id"
                            header={header}
                            footer={footer}
                            paginator
                            rows={10}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            removableSort
                            selection={selectedProducts}
                            onSelectionChange={(e) => setSelectedProducts(e.value.filter((s) => s.stock > 0 && !s.csv))}
                            emptyMessage="No se han encontrado registros..."
                            className="p-datatable-new-dispatches"
                            selectionMode={isAdmin ? "checkbox" : "null"}
                        >
                            {isAdmin && <Column selectionMode="multiple" headerStyle={{ width: "3%" }}></Column>}
                            <Column
                                field="code"
                                header="Código"
                                headerStyle={{ width: "10%" }}
                                sortable
                                filter
                                filterPlaceholder="Todos"
                                filterHeaderClassName="p-inputtext-sm"
                            />
                            <Column
                                field="sku"
                                header="SKU"
                                headerStyle={{ width: "10%" }}
                                sortable
                                filter
                                filterPlaceholder="Todos"
                                filterHeaderClassName="p-inputtext-sm"
                            />
                            <Column
                                field="name"
                                headerStyle={{ width: "25%" }}
                                header="Descripción"
                                sortable
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder="Todos"
                                filterHeaderClassName="p-inputtext-sm"
                            />
                                <Column
                                field="qty"
                                headerStyle={{ width: "10%" }}
                                header="Cantidad"
                                sortable
                                filter
                                filterType="number"
                                filterPlaceholder="Todos"
                                filterHeaderClassName="p-inputtext-sm"
                            />
                            <Column
                                field="inventoryName"
                                headerStyle={{ width: "15%" }}
                                header="Iventario"
                                sortable
                                filter
                                filterType="number"
                                filterPlaceholder="Todos"
                                filterHeaderClassName="p-inputtext-sm"
                            />
                        </DataTable>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => setOpen(false)}>
                    <i className="fa fa-ban mr-2" />
                    Cancelar
                </Button>
            </ModalFooter>
            {openTakeModal && (
                <InventoryTakeProduct
                    resetTop={resetTop}
                    update={update}
                    company={company}
                    open={openTakeModal}
                    takeQty={takeQtysIds}
                    setOpen={setOpenTakeModal}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                />
            )}
        </Modal>
    );
}
