import React from 'react';
import ReactDOM from 'react-dom';
import { PDFViewer, Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import queryString from 'query-string';
import moment from 'moment';
// Create styles
const styles = StyleSheet.create({
    page: {
        fontSize: 9,
        marginBottom: 20,
    },

    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'baseline',
        width: '100%',
        padding: 10,
    },

    section: {
        flexGrow: 1,
    },

    subtitule: {
        margin: 1,
        padding: 1,
        width: '17%',
        fontWeight: 'bold',
        fontFamily: 'Helvetica-Bold',
    },

    value: {
        margin: 1,
        padding: 1,
        width: '15%',
    },

    thead: {
        flexDirection: 'row',
        border: 1,
        backgroundColor: '#A9D0F5',
        margin: '10 20 0 20',
        textAlign: 'center'

    },

    tbody: {
        flexDirection: 'row',
        textAlign: 'center',
        margin: '10 20 0 20',
    },

    th: {
        margin: '3, 2',
        padding: '3, 2',
        textAlign: 'center',
        fontFamily: 'Helvetica-Bold'
    },

    td: {
        margin: '0, 2',
        padding: '0, 2',
        textAlign: 'center'
    },


});
const { tab } = queryString.parse(window.location.search);
const rows = sessionStorage.getItem(`MissingSAP${tab}`) ? JSON.parse(sessionStorage.getItem(`MissingSAP${tab}`)) : null;
const state = sessionStorage.getItem(`tableState${tab}`) ? JSON.parse(sessionStorage.getItem(`tableState${tab}`)) : null;
// Create Document Component
const MyDocument = () => (
    <Document title={`Faltantes SAP ${tab}`}>
        <Page size="LETTER" style={styles.page}>
            <View style={{ ...styles.row, margin: 0, padding: 0 }} fixed>
                <Image src="images/header.png" style={{ width: '100%' }} />
            </View>
            <View style={{ ...styles.row, margin: '3 10', padding: '3 10' }}>
                <View style={styles.section}>
                    <Text style={styles.subtitule}>Código:</Text>
                    <Text style={styles.subtitule}>En Inventario:</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.value}>{state && state.filters.code && state.filters.code.value}</Text>
                    <Text style={styles.value}>{state && state.filters.onhand && state.filters.onhand.value}</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.subtitule}>Ordenados:</Text>
                    <Text style={styles.subtitule}>Comprometidos:</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.value}>{state && state.filters.onorder && state.filters.onorder.value}</Text>
                    <Text style={styles.value}>{state && state.filters.iscommited && state.filters.iscommited.value}</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.subtitule}>Disponibles:</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.value}>{state && state.filters.sap_available && state.filters.sap_available.value}</Text>
                </View>
            </View>
            <Text style={{ fontSize: 13, textAlign: 'center', marginTop: 10, fontFamily: 'Helvetica-Bold' }}>{`Faltantes SAP ${tab}`} </Text>
            <View style={styles.thead}>
                <Text style={{ ...styles.th, width: '5%', textAlign: 'center' }}>#</Text>
                <Text style={{ ...styles.th, width: '18%', textAlign: 'left' }}>Código</Text>
                <Text style={{ ...styles.th, width: '13%', textAlign: 'center' }}>En Inventario</Text>
                <Text style={{ ...styles.th, width: '13%', textAlign: 'center' }}>Ordenados</Text>
                <Text style={{ ...styles.th, width: '13%', textAlign: 'center' }}>Comprometidos</Text>
                <Text style={{ ...styles.th, width: '13%', textAlign: 'center' }}>Disponibles</Text>
                <Text style={{ ...styles.th, width: '20%' }}>F. Actualización</Text>
            </View>
            {rows && rows.map((e, i) => (
                <View style={styles.tbody} key={i} break={i !== 0 && i % 27 === 0}>
                    <Text style={{ ...styles.td, width: '5%' }}>{i + 1}</Text>
                    <Text style={{ ...styles.td, width: '18%', textAlign: 'left' }}>{e.code}</Text>
                    <Text style={{ ...styles.td, width: '13%', textAlign: 'center' }}>{e.onhand}</Text>
                    <Text style={{ ...styles.td, width: '13%', textAlign: 'center' }}>{e.onorder}</Text>
                    <Text style={{ ...styles.td, width: '13%', textAlign: 'center' }}>{e.iscommited}</Text>
                    <Text style={{ ...styles.td, width: '13%', textAlign: 'center' }}>{e.sap_available}</Text>
                    <Text style={{ ...styles.td, width: '20%' }}>{moment(e.updateddate).format('DD/MM/YYYY HH:mm:ss a')}</Text>
                </View>
            ))}
        </Page>
    </Document >
);

const App = () => (
    <PDFViewer width="100%" height="100%">
        <MyDocument />
    </PDFViewer>
);

const Render = () => ReactDOM.render(<App />, document.getElementById('root'));

export default Render;