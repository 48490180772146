// react
import React, { Component } from "react";
// third-party
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button, Badge } from "reactstrap";
import { getDispatchesProductsAP, searchClientsAP } from "../../../store/directusSdk";
import SubtableItems from "./SubtableItems";
import { BagdeStatus, statusColorDispatch, statusLabelDispatch } from "../../shared/utilities";

export default class ListDispatches extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expandAll: false,
            expandedRows: null,
            open: false,
            loading: false,
            clients: [],
            lastRow: null,
            despachos: [],
        };
    }

    exportCSV = () => {
        return this.dt.exportCSV();
    };

    expandAll = () => {
        const { dispatches } = this.props;
        const expandedRows = {};

        if (dispatches.length > 0) {
            dispatches.forEach((p) => {
                expandedRows[`${p.id}`] = true;
            });
            return this.setState({ expandedRows, expandAll: true });
        }
    };

    collapseAll = () => this.setState({ expandedRows: null, expandAll: false });

    rowExpansionTemplateClients = (row) => <SubtableItems row={row} getDispatches={this.getDispatches} searchClients={this.searchClients} />;

    searchClients = (row) => {
        this.setState({ loading: true, open: true });
        return searchClientsAP(row.code)
            .then((clients) => this.setState({ clients, lastRow: row }))
            .catch((error) => console.log(error))
            .finally(() => this.setState({ loading: false }));
    };

    getDispatches = (row) => {
        this.setState({ loading2: true, open2: true });
        return getDispatchesProductsAP(row.code)
            .then((despachos) => this.setState({ despachos }))
            .catch((error) => console.log(error))
            .finally(() => this.setState({ loading2: false }));
    };

    calculateDate = (row, lastRow) => {
        if (lastRow.dispatches && lastRow.dispatches.length > 0) {
            const nearestDate = lastRow.dispatches.reduce((n, e) => {
                if (moment(e.arrival_date).isSameOrBefore(n)) {
                    return e.arrival_date;
                }
                return n;
            }, moment(lastRow.dispatches[0].arrival_date));
            if (moment(nearestDate).isSameOrBefore(row.date)) {
                return <i className="fas fa-calendar-check fa-2x" style={{ color: "#4ac18e" }} title="Vigente" />;
            }
            return <i className="fas fa-exclamation-triangle fa-2x" style={{ color: "#ffbb44" }} title="Vencido" />;
        }
        return "";
    };

    rowExpansionTemplate = (row) => (
        <div className="orders-subtable">
            {row.dispatches.length > 0 ? (
                <DataTable value={row.dispatches} className="p-datatable-sm">
                    <Column field="n" header="Número Despacho" style={{ width: "15%" }} />
                    <Column field="guide" header="Guía" style={{ width: "15%" }} />
                    <Column
                        field="dispatch_date"
                        header="Fecha Despacho"
                        body={(row) => moment(row.dispatch_date).format("DD/MM/YYYY")}
                        style={{ width: "20%" }}
                    />
                    <Column
                        field="arrival_date"
                        header="Fecha Llegada"
                        body={(row) => moment(row.arrival_date).format("DD/MM/YYYY")}
                        style={{ width: "20%" }}
                    />
                    <Column field="qty" header="Cantidad" style={{ width: "18%" }} />
                    <Column
                        header="Estado"
                        body={(row) => <Badge color={statusColorDispatch(row.status)}>{statusLabelDispatch(row.status)}</Badge>}
                        style={{ width: "12%" }}
                    />
                </DataTable>
            ) : (
                <p>No se Han Realizado Despachos...</p>
            )}
        </div>
    );

    close = (modal) => {
        if (modal === "C") {
            return this.setState({ open: false });
        }
        return this.setState({ open2: false });
    };

    dipatchedTemplate = (row) => {
        return row.dispatches.reduce((total, item) => total + item.qty, 0);
    };

    statusTemplate = (row) => {
        return BagdeStatus(row.status);
    };

    render() {
        const { expandAll, expandedRows } = this.state;
        const { dispatches, search } = this.props;

        if (!search) {
            return null;
        }

        const header = (
            <div className="row">
                <div className="col-md-4 ">
                    <h5 className="mt-2">Despachos</h5>
                </div>
                <div className="col-md-2 offset-md-4">
                    {expandAll ? (
                        <Button color="warning" onClick={this.collapseAll}>
                            <i className="fas fa-angle-double-right mr-2" />
                            Contraer
                        </Button>
                    ) : (
                        <Button color="info" className="mr-2" onClick={this.expandAll}>
                            <i className="fas fa-angle-double-down mr-2" />
                            Expandir
                        </Button>
                    )}
                </div>
                <div className="col-md-2">
                    <Button color="info" onClick={this.exportCSV}>
                        <i className="fas fa-file-export mr-2" />
                        Exportar
                    </Button>
                </div>
            </div>
        );

        return (
            <React.Fragment>
                <DataTable
                    ref={(el) => {
                        this.dt = el;
                    }}
                    value={dispatches}
                    header={header}
                    dataKey="id"
                    paginator
                    rows={10}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    removableSort
                    emptyMessage="No se han encontrado registros..."
                    exportFilename={`Consulta_OC_Items}`}
                    expandedRows={expandedRows}
                    onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                    rowExpansionTemplate={this.rowExpansionTemplate}
                    className="p-datatable-dispatches p-datatable-sm mt-1"
                >
                    <Column expander style={{ width: "4%" }} />
                    <Column field="code" header="Código" sortable filter style={{ width: "18%" }} filterPlaceholder="Todos" />
                    <Column
                        field="name"
                        header="Descripción"
                        sortable
                        filter
                        style={{ width: "38%" }}
                        filterMatchMode="contains"
                        filterPlaceholder="Todos"
                    />
                    <Column
                        field="qty"
                        header="Cant."
                        sortable
                        filter
                        style={{ width: "12%" }}
                        filterType="number"
                        filterPlaceholder="Mayor a"
                        filterMatchMode="gte"
                    />
                    <Column
                        header="Despachado"
                        style={{ width: "12%" }}
                        body={this.dipatchedTemplate}
                        filterType="number"
                        filterPlaceholder="Mayor a"
                        filterMatchMode="gte"
                    />
                    <Column field="status" header="Estado" sortable style={{ width: "12%" }} body={this.statusTemplate} />
                </DataTable>
            </React.Fragment>
        );
    }
}
