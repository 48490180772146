import fs from "file-saver";
import moment from "moment";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { Column } from "primereact/column";
import { Helmet } from "react-helmet-async";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import InventoryClientTop from "./InventoryClientTop";
import PageHeader from "../../../../shared/PageHeader";
import InventoryTakeProduct from "./InventoryTakeProduct";
import { GenerateExcelInventorySearchAll } from "../../../../shared/utilities";
import { toast } from "react-toastify";
import { searchAllrProducsByCodes } from "../../../../../store/directusSdk";

function InventorySearch({ currentUser, company }) {
    //const company = "ICA";
    const roles = ["Administrator"];
    const isAdmin = roles.includes(currentUser.role.name);

    const [type, setType] = useState("");
    const [codes, setCodes] = useState([]);
    const [products, setProducts] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [loadingMainPage, setLoadingMainPage] = useState(false); //Loading para la tabla principal
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [openTakeModal, setOpenTakeModal] = useState(false);
    const [errors, setErrors] = useState([]);

    const generateExcel = () => {
        GenerateExcelInventorySearchAll(products)
            .then((response) => {
                const blob = new Blob([response]);
                fs.saveAs(blob, `Listado_de_productos${moment().format("MMDDHHmmss")}`.toUpperCase() + ".xlsx");
            })
            .catch((error) => {});
    };

    const getProductsSearch = (param) => {
        setLoadingMainPage(true);
        searchAllrProducsByCodes({ codes: param ? [...param.map((code) => code.value)] : [...codes.map((code) => code.value)] })
            .then((data) => {
                setErrors(data.errors);
                setProducts(data.products);
            })
            .catch((error) => toast.error("¡Error!, " + error.message))
            .finally(() => setLoadingMainPage(false));
    };

    const takeQtysIds = (id, qty) => {
        const newItems = [...products];
        const i = newItems.findIndex((item) => item.id === id);
        if (i >= 0) {
            newItems[i].takeQty = qty;
            setProducts(newItems);
        }
    };

    const header = (
        <div className="row" style={{ display: "flex", justifyContent: "space-between" }}>
            <h4 className="ml-2"> Lista de Productos</h4>
            <Button className="mr-2" color="success" disabled={!products.length} onClick={() => generateExcel()}>
                <i className=" fas fa-file-excel mr-2" />
                Exportar
            </Button>
        </div>
    );

    const footer = (
        <div>
            {isAdmin && (
                <Button
                    color="primary"
                    disabled={selectedProducts.length < 1}
                    onClick={() => {
                        selectedProducts.forEach((product) => {
                            takeQtysIds(product.id, product.qty);
                        });
                        setOpenTakeModal(true);
                    }}
                >
                    <i className={`fas fa-shopping-basket mr-2`}></i>
                    Tomar materiales
                </Button>
            )}
        </div>
    );

    const breadcrumb = [
        { title: "Home", url: "/" },
        { title: "Inventarios", url: "/inventarios" },
        { title: "Buscar Productos", url: "" },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title> Productos Entregados - Ingeniería y Controles Automatizados</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />
            <div className="container">
                <div className="card">
                    {/*Parte superior del componente */}
                    <InventoryClientTop
                        products={products}
                        setProducts={setProducts}
                        setOpenModalNew={setOpenModal}
                        isAdmin={isAdmin}
                        setLoadingMainPage={setLoadingMainPage}
                        codes={codes}
                        setCodes={setCodes}
                        company={company}
                        setType={setType}
                        setErrors={setErrors}
                        getProductsSearch={getProductsSearch}
                    />

                    <div className="card-body">
                        {loadingMainPage ? (
                            <>
                                <br />
                                <br />
                                <div className="div-loading" style={{ width: 300 }} />
                                <br />
                                <br />
                            </>
                        ) : (
                            <>
                                {errors && errors.length > 0 && (
                                    <div className="alert alert-danger">
                                        <table className="table table-sm">
                                            <thead>
                                                <tr>
                                                    <th width="5%">N°</th>
                                                    <th width="20%">Código</th>
                                                    <th width="20%">SKU</th>
                                                    <th width="55%">Error</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {errors.map((e, i) => (
                                                    <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>{e.code}</td>
                                                        <td>{e.sku}</td>
                                                        <td>{e.error}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}

                                <DataTable
                                    dataKey="id"
                                    id="mainTableLoading"
                                    header={header}
                                    value={products}
                                    footer={footer}
                                    className="p-datatable-sm"
                                    rowHover
                                    paginator
                                    rows={20}
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                                    rowsPerPageOptions={[10, 20, 50, 100, 200]}
                                    removableSort
                                    emptyMessage="No se han encontrado registros..."
                                    selection={selectedProducts}
                                    onSelectionChange={(e) => setSelectedProducts(e.value.filter((s) => s.qty > 0 && !s.csv && s.isTake))}
                                    selectionMode={isAdmin ? "checkbox" : "null"}
                                >
                                    {isAdmin && <Column selectionMode="multiple" headerStyle={{ width: "3%" }}></Column>}
                                    <Column
                                        field="code"
                                        header="Código"
                                        headerStyle={{ width: "10%" }}
                                        sortable
                                        filter
                                        filterPlaceholder="Todos"
                                        filterHeaderClassName="p-inputtext-sm"
                                    />
                                    <Column
                                        field="sku"
                                        header="SKU"
                                        headerStyle={{ width: "10%" }}
                                        sortable
                                        filter
                                        filterPlaceholder="Todos"
                                        filterHeaderClassName="p-inputtext-sm"
                                    />
                                    <Column
                                        field="name"
                                        headerStyle={{ width: "25%" }}
                                        header="Descripción"
                                        sortable
                                        filter
                                        filterMatchMode="contains"
                                        filterPlaceholder="Todos"
                                        filterHeaderClassName="p-inputtext-sm"
                                    />
                                    <Column
                                        field="qty"
                                        headerStyle={{ width: "10%" }}
                                        header="Disponible"
                                        sortable
                                        filter
                                        filterType="number"
                                        filterPlaceholder="Todos"
                                        filterHeaderClassName="p-inputtext-sm"
                                    />
                                    <Column
                                        field="type"
                                        headerStyle={{ width: "10%" }}
                                        header="Tipo"
                                        sortable
                                        filter
                                        filterType="number"
                                        filterPlaceholder="Todos"
                                        filterHeaderClassName="p-inputtext-sm"
                                    />
                                    <Column
                                        field="inventoryName"
                                        headerStyle={{ width: "15%" }}
                                        header="Inventario"
                                        sortable
                                        filter
                                        filterType="number"
                                        filterPlaceholder="Todos"
                                        filterHeaderClassName="p-inputtext-sm"
                                    />
                                    <Column
                                        field="company"
                                        headerStyle={{ width: "10%" }}
                                        header="Compañia"
                                        sortable
                                        filter
                                        filterType="text"
                                        filterPlaceholder="Todos"
                                        filterHeaderClassName="p-inputtext-sm"
                                    />
                                </DataTable>
                            </>
                        )}
                    </div>
                </div>
                {openTakeModal && (
                    <InventoryTakeProduct
                        update={setProducts}
                        company={company}
                        open={openTakeModal}
                        takeQty={takeQtysIds}
                        setOpen={setOpenTakeModal}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        type={"SG"}
                        getProductsSearch={getProductsSearch}
                    />
                )}
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InventorySearch);
