import { toast } from 'react-toastify';
import {
    CART_ADD_ITEM,
    CART_ADD_ITEM_SERV,
    CART_REMOVE_ITEM,
    CART_UPDATE_QUANTITIES,
    CART_REMOVE_ALL_ITEMS,
    CHANGE_PRICE_ITEM,
    CART_LOAD_ALL_ITEMS,
    RECALCULATE_PRICES,
    CHANGE_COMMENTS,
    CHANGE_CONTACT,
    CHANGE_CONTACTS,
    CHANGE_CUSTOMER,
    CHANGE_TEXT_ITEM,
    RECALCULATE_ITEM_PRICE,
    CHANGE_QTY,
    CHANGE_VALIDITY,
    CHANGE_CONDITIONS,
    CHANGE_EXEMPT_ITBMS, 
    CHANGE_INCITBMS
} from './cartActionTypes';

export function recalculatePrices(products = [], services = []) {
    return {
        type: RECALCULATE_PRICES,
        products,
        services,
    };
}

export function cartLoadItemsSuccess(cart) {
    toast.success('Productos agregados al Carrito!');
    return {
        type: CART_LOAD_ALL_ITEMS,
        cart,
    };
}

export function changeComments(comments) {
    return {
        type: CHANGE_COMMENTS,
        comments,
    };
}

export function cartAddItemSuccess(product, priceList, quantity = 1) {
    toast.success(`Producto "${product.name}" agregado al Carrito!`);

    return {
        type: CART_ADD_ITEM,
        product,
        quantity,
        priceList,
    };
}

export function cartAddItemServSuccess(product, priceList, quantity = 1, incItbms) {
    toast.success(`Producto "${product.name}" agregado al Carrito!`);

    return {
        type: CART_ADD_ITEM_SERV,
        product,
        quantity,
        priceList,
        incItbms
    };
}

export function cartRemoveItemSuccess(itemId) {
    return {
        type: CART_REMOVE_ITEM,
        itemId,
    };
}

export function cartUpdateQuantitiesSuccess(quantities) {
    return {
        type: CART_UPDATE_QUANTITIES,
        quantities,
    };
}

export function cartAddItem(product, priceList, quantity = 1) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartAddItemSuccess(product, priceList, quantity));
                resolve();
            }, 500);
        })
    );
}

export function cartAddItemServ(product, priceList, quantity = 1, incItbms) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartAddItemServSuccess(product, priceList, quantity, incItbms));
                resolve();
            }, 500);
        })
    );
}

export function cartRemoveItem(itemId) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartRemoveItemSuccess(itemId));
                resolve();
            }, 500);
        })
    );
}

export function cartUpdateQuantities(quantities) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartUpdateQuantitiesSuccess(quantities));
                resolve();
            }, 500);
        })
    );
}

export function cartRemoveAllItems() {
    return {
        type: CART_REMOVE_ALL_ITEMS,
    };
}
export const ContactChange = (contact) => ({
    type: CHANGE_CONTACT,
    contact,
});

export const ContactsChange = (contacts) => ({
    type: CHANGE_CONTACTS,
    contacts,
});

export const CustomerChange = (customer, priceList) => ({
    type: CHANGE_CUSTOMER,
    customer,
    priceList,
});

export const TextItemsChange = (index, text) => ({
    type: CHANGE_TEXT_ITEM,
    index,
    text,
});
export const recalculateItemPrice = (index, oldFactor, newFactor) => ({
    type: RECALCULATE_ITEM_PRICE,
    index,
    oldFactor,
    newFactor,
});

export const ChangeQuantity = (index, qty = 1) => ({
    type: CHANGE_QTY,
    index,
    qty,
});

export const ChangePriceItem = (index, price = 1) => ({
    type: CHANGE_PRICE_ITEM,
    index,
    price
});

export const ChangeValidity = (validity) => ({
    type: CHANGE_VALIDITY,
    validity
});

export const ChangeConditions = (conditions) => ({
    type: CHANGE_CONDITIONS,
    conditions
});

export const ChangeExemptItbms = (exempt = false) => ({
    type: CHANGE_EXEMPT_ITBMS,
    exempt
});

export const includeItbms = (index, value) => ({
    type: CHANGE_INCITBMS,
    index,
    value,
});