// react
import React, { Component } from 'react';
// third-party
import moment from 'moment';
import { UncontrolledCollapse, Alert, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { InputSwitch } from 'primereact/inputswitch';
import { getEngprojectId, getWarehouses, UpdateItems, UpdateItem, GenerateComprobantDelivery, getProjectByCode, CreateItems, updateProjectOnSAP, FinishedProject, PauseShopping } from '../../store/directusSdk';
import PageHeader from '../shared/PageHeader';
import ProductsTableManager from './ProductsTableManager';
import { statusBadge } from '../engprojects/utils';
import { statusProgress } from './utils';

export default class EngprojectManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: null,
            loading: false,
            loading1: false,
            loading2: false,
            loading3: false,
            whsICA: [],
            whsAP: [],
            newChangesICA: [],
            newChangesAP: [],
            newChangesLinesICA: [],
            newChangesLinesAP: [],
            returnICA: [],
            returnAP: [],
            showListMain: true,
            showListSec: false,
        };
    }

    componentDidMount() {
        this.getProject();
        getWarehouses().then(({ whsICA, whsAP }) => this.setState({ whsICA, whsAP }));
    }

    getProject = () => {
        this.setState({ loading3: true });
        const { match: { params: { id } } } = this.props;
        return getEngprojectId(id)
            .then((project) => this.setState({ project }))
            .finally(() => this.setState({ loading3: false }));
    }
    printProject = (project) => {
        sessionStorage.setItem('PrintEngpr', JSON.stringify(project));
        return window.open(`/proyectos-de-ingeneria/imprimir/${project.id}`, '_blank');
    }

    render() {
        const { showListMain, showListSec, project, loading3, whsICA, whsAP } = this.state;
        const breadcrumb = [
            { title: 'Home', url: '/' },
            { title: 'Proyectos de Ingeneria', url: '/mis-proyectos' },
            { title: 'Gestionar Proyecto', url: '' },
        ];

        return (
            <React.Fragment>
                <PageHeader breadcrumb={breadcrumb} />
                <div className="container">
                    <div className="row mt-4">
                        {loading3 && (
                            <div className="col-md-12 mt-5 mb-5 pt-5 pb-5">
                                <div className="div-loading" />
                            </div>
                        )}
                        <div className="col-md-6">
                            {project && (
                                <React.Fragment>
                                    <table width="100%" className="table table-sm">
                                        <tbody>
                                            <tr>
                                                <th width="30%">Empresa Encargada</th>
                                                <td width="70%" align="right">{project.company.name}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Ingeniero Encargado</th>
                                                <td width="70%" align="right">{project.engineer}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Código</th>
                                                <td width="70%" align="right">{project.mainProject.code}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Proyecto</th>
                                                <td width="70%" align="justify">{project.mainProject.name}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Estado</th>
                                                <td width="70%" align="right">{statusBadge(project.status)}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Estado Cliente</th>
                                                <td width="70%" align="right">{statusProgress(project.status, (project.p1 || project.p2 || project.p3 || project.p4), project.test, project.todeliver)}</td>
                                            </tr>
                                            {project && project.subProject && (
                                                <React.Fragment>
                                                    <tr>
                                                        <th width="30%">Subproyecto</th>
                                                        <td width="70%" align="right">{project.subProject.code}</td>
                                                    </tr>
                                                    <tr>
                                                        <th width="30%">Orden de Venta</th>
                                                        <td width="70%" align="right">{!project.subProject.orden_venta ? <span className="badge badge-danger">Proyecto sin Orden de Venta</span> : project.subProject.orden_venta}</td>
                                                    </tr>
                                                </React.Fragment>
                                            )}
                                            <tr>
                                                <th width="30%"></th>
                                                <td width="70%" align="right"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <UncontrolledDropdown>
                                        <DropdownToggle caret>
                                            <i className="fa fa-bars mr-3" />
                                            {'Acciones'}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem header>Acciones</DropdownItem>
                                            <DropdownItem onClick={() => this.printProject(project)} >
                                                <i className="fa fa-print mr-3" />
                                                {'Imprimir'}
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="col-md-6">
                            {project && (
                                <React.Fragment>
                                    <table width="100%" className="table table-sm">
                                        <tbody>
                                            <tr>
                                                <th width="30%">Cód. Cliente</th>
                                                <td width="70%" align="right">{project.mainProject.code_cliente}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Cliente</th>
                                                <td width="70%" align="right">{project.mainProject.name_cliente}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Orden de Venta</th>
                                                <td width="70%" align="right">{project.mainProject.orden_venta}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Fecha Inicio</th>
                                                <td width="70%" align="right">{project.dateCreated}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Fecha Entrega</th>
                                                <td width="70%" align="right">{project.dateFinish}</td>
                                            </tr>
                                            {project && project.status === 'finished' && (
                                                <tr>
                                                    <th width="30%">Fecha Finalizado</th>
                                                    <td width="70%" align="right">{project.dateFinished}</td>
                                                </tr>
                                            )}
                                            <tr>
                                                <th width="30%">{`Productos ${project.company.code}`}</th>
                                                <td width="70%" align="right"><InputSwitch checked={showListMain} onChange={(e) => this.setState({ showListMain: e.value })} /></td>
                                            </tr>
                                            {project && project.subProject && (
                                                <tr>
                                                    <th width="30%">{`Productos ${project.company.code !== 'ICA' ? 'ICA' : 'AP'}`}</th>
                                                    <td width="70%" align="right"><InputSwitch checked={showListSec} onChange={(e) => this.setState({ showListSec: e.value })} /></td>
                                                </tr>
                                            )}
                                            <tr>
                                                <th width="30%"></th>
                                                <td width="70%" align="right"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                    <br />
                    {project && showListMain && (
                        <React.Fragment>
                            <div className="row">
                                <h5 className="col-md-5">Listado de Materiales de Proyecto</h5>
                                <p className="offset-md-3 col-md-4 text-right">
                                    Ultima Actualización:
                                    {
                                        project.company.code === 'ICA' ? (
                                            project.updated_sap_ica ?
                                                moment(project.updated_sap_ica).format('DD-MM-YY hh:mm A')
                                                :
                                                '  No Actualizado'
                                        ) :
                                            (
                                                project.updated_sap_ap ?
                                                    moment(project.updated_sap_ap).format('DD-MM-YY hh:mm A')
                                                    :
                                                    '  No Actualizado'
                                            )
                                    }
                                </p>
                            </div>
                            <ProductsTableManager
                                projectId={project.id}
                                whs={project.company.code === 'ICA' ? whsICA : whsAP}
                                products={project.company.code === 'ICA' ? project.productsICA : project.productsAP}
                                table={project.company.code}
                                whsSelect={project.company.code === 'ICA' ? { code: '01', label: 'Bodega 01', value: 1, email: 'warehouse01@icautomatizados.com' } : { code: '01', label: 'Insumos', value: 4, email: '' }}
                                status={project.status}
                                project={project.mainProject}
                            />
                            <br />
                        </React.Fragment>
                    )}
                    {project && showListSec && (
                        <React.Fragment>
                            {project.company.code !== 'ICA' ? (
                                project.error_update_ica && <div className="alert alert-danger">{project.error_update_ica}</div>
                            ) :
                                (
                                    project.error_update_ap && <div className="alert alert-danger">{project.error_update_ap}</div>
                                )
                            }
                            <div className="row">
                                <h5 className="col-md-5">Listado de Materiales Subproyecto</h5>
                                <p className="offset-md-3 col-md-4 text-right">
                                    Ultima Actualización:
                                    {
                                        project.company.code !== 'ICA' ? (
                                            project.updated_sap_ica ?
                                                moment(project.updated_sap_ica).format('DD-MM-YY hh:mm A')
                                                :
                                                '  No Actualizado'
                                        ) :
                                            (
                                                project.updated_sap_ap ?
                                                    moment(project.updated_sap_ap).format('DD-MM-YY hh:mm A')
                                                    :
                                                    '  No Actualizado'
                                            )
                                    }
                                </p>
                            </div>
                            <ProductsTableManager
                                projectId={project.id}
                                whs={project.company.code !== 'ICA' ? whsICA : whsAP}
                                products={project.company.code !== 'ICA' ? project.productsICA : project.productsAP}
                                table={project.company.code !== 'ICA' ? 'ICA' : 'AP'}
                                whsSelect={project.company.code !== 'ICA' ? { code: '01', label: 'Bodega 01', value: 1, email: 'warehouse01@icautomatizados.com' } : { code: '01', label: 'Insumos', value: 4, email: '' }}
                                status={project.status}
                                project={project.subProject}
                            />
                            <br />
                        </React.Fragment>
                    )}
                </div>
            </React.Fragment >
        );
    }
}