// react
import React, { Component } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
// application
import moment from "moment";
import "moment/locale/es-us";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getAvailable, getAvailablePrices } from "../../store/directusSdk";
import { cartLoadItemsSuccess } from "../../store/cart";
import Pagination from "../shared/Pagination";
import { Cross20Svg } from "../../svg";
import Dropdown from "../shared/Dropdowns";

class AccountPageQuotationList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            quotations: [],
            page: 1,
            pages: 1,
            start: 0,
            end: 9,
            sinQuotations: false,
            open: false,
            open2: false,
            quote: null,
            loading: true,
        };
    }

    componentDidMount() {
        const { currentUser, getPrivQuotations } = this.props;
        const { page } = this.state;
        getPrivQuotations(currentUser.id).then((res) => {
            if (res.data.length > 0) {
                const quotations = res.data;
                const pages = Math.ceil(quotations.length / 10);
                const end = page * 10;
                const start = end - 10;
                this.setState(() => ({ quotations, pages, end, start, loading: false }));
            } else {
                this.setState(() => ({ sinQuotations: true, loading: false }));
            }
        });
    }

    handlePageChange = (page) => {
        const end = page * 10;
        const start = end - 10;
        this.setState(() => ({ page, end, start }));
    };

    ViewQuotation = (quote) => {
        this.setState({ open: true, quote: quote.items });
    };

    editQuote = (quote) => {
        const { history } = this.props;
        this.setState({ loading: true });
        const { cartLoadItemsSuccess } = this.props;
        const cart = JSON.parse(quote.cart);
        cart.mode = "edit";
        cart.id = quote.id;
        cart.sapId = quote.sap_id;
        cart.validity = quote.validity;
        cart.paymentConditions = quote.payment_conditions;
        cartLoadItemsSuccess(cart);
        this.setState({ loading: false, quote: null });
        return history.push("/ventas/cart-edit");
    };

    GeneratePDF(e) {
        window.open(`${process.env.REACT_APP_API_URL}cotizaciones/${e.sap_id}.pdf`, "_blank");
    }

    LoadToCart(quote) {
        const { history } = this.props;
        this.setState({ loading: true });
        const { cartLoadItemsSuccess } = this.props;

        if (quote.isValidity) {
            getAvailable(JSON.parse(quote.cart), "ICA").then((cart) => {
                cartLoadItemsSuccess(cart);
                this.setState({ loading: false, quote: null });
                history.push("/ventas/cart");
            });
        } else {
            getAvailablePrices(JSON.parse(quote.cart), "ICA").then((cart) => {
                cartLoadItemsSuccess(cart);
                this.setState({ loading: false, quote: null });
                console.log("vamos al cart -2");
                history.push("/ventas/cart");
            });
        }
    }

    uploadCart = (quotation) => this.setState({ open2: true, quote: quotation });

    getStatus = (quotation) => {
        if (quotation.status === "published" && !quotation.sap_id) {
            return <div className="badge badge-info">{"En espera"}</div>;
        }
        if (quotation.status === "published" && quotation.sap_id) {
            return <div className="badge badge-success">{"Disponible"}</div>;
        }
        if (quotation.status === "failed") {
            return <div className="badge badge-danger">{"Fallida"}</div>;
        }
        return "";
    };

    render() {
        const { page, quotations, pages, end, start, sinQuotations, open, open2, quote, loading } = this.state;

        const quotationsList = quotations.map((quotation) => {
            const date = moment(quotation.created_on);
            const dateFormated = date.add(date.utcOffset(), "minutes").format("DD/MM/YYYY");
            quotation.isValidity = moment().isSameOrBefore(quotation.validity);
            return (
                <tr key={quotation.id}>
                    <td>{`# ${quotation.sap_id ? quotation.sap_id : ""}`}</td>
                    <td>{`${quotation.customer_id && quotation.customer_id.name}`}</td>
                    <td>{dateFormated}</td>
                    <td>{this.getStatus(quotation)}</td>
                    <td>
                        <Dropdown
                            editQuote={this.editQuote}
                            quotation={quotation}
                            ViewQuotation={this.ViewQuotation}
                            GeneratePDF={this.GeneratePDF}
                            uploadCart={this.uploadCart}
                            company=""
                        />
                    </td>
                </tr>
            );
        });

        return (
            <div className="card">
                <Helmet>
                    <title>Mis Cotizaciones ICA - ICAutomatizados</title>
                </Helmet>

                <div className="card-header">
                    <h5>Historial de Cotizaciones</h5>
                </div>
                <div className="card-divider" />
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                                <tr>
                                    <th>Cotización</th>
                                    <th>Cliente</th>
                                    <th>Fecha</th>
                                    <th>Estado</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading && (
                                    <tr>
                                        <td colSpan="5">
                                            <div className="div-loading" style={{ height: 300 }} />
                                        </td>
                                    </tr>
                                )}
                                {quotationsList.slice(start, end)}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-divider" />
                <div className="card-footer">
                    {sinQuotations && <strong> Actualmente no tiene Cotizaciones... </strong>}
                    {pages > 1 && <Pagination current={page} total={pages} onPageChange={this.handlePageChange} siblings={2} />}
                </div>

                <Modal isOpen={open} toggle={() => this.setState({ open: false })} centered size="xl">
                    <div className="quickview">
                        <button className="quickview__close" type="button" onClick={() => this.setState({ open: false })}>
                            <Cross20Svg />
                        </button>
                        <div className="document__content typography" dangerouslySetInnerHTML={{ __html: quote }} />
                    </div>
                </Modal>

                <Modal isOpen={open2} toggle={() => this.setState({ open2: false })} centered>
                    <ModalHeader toggle={() => this.setState({ open2: false })}>Cargar Productos al Carrito</ModalHeader>
                    <ModalBody>
                        Estimado cliente nuestra web es 100% en línea; La disponibilidad de sus productos puede haber cambiado, le recomendamos
                        revisar su compra.
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" className={`${loading && "btn-loading"}`} disabled={loading} onClick={() => this.LoadToCart(quote)}>
                            Continuar
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    multiplications: state.pagesContent.multiplications,
});

const mapDispatchToProps = {
    cartLoadItemsSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageQuotationList);
