// react
import React, { Component } from 'react';
// third-party
import moment from 'moment';
import { Button } from 'primereact/button';
import { UncontrolledCollapse, Alert, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody } from 'reactstrap';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { InputSwitch } from 'primereact/inputswitch';
import { getEngprojectId, getWarehouses, UpdateItems, UpdateItem, GenerateComprobantDelivery, getProjectByCode, CreateItems, updateProjectOnSAP, FinishedProject, PauseShopping } from '../../store/directusSdk';
import PageHeader from '../shared/PageHeader';
import ProductsTableManager from './ProductsTableManager';
import ImportCsv from './ImportCsv';
import { statusBadge } from './utils';
import { statusProgress } from '../cus_engprojects/utils';

export default class EngprojectManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: null,
            loading: false,
            loading1: false,
            loading2: false,
            loading3: false,
            loading4: false,
            whsICA: [],
            whsAP: [],
            newChangesICA: [],
            newChangesAP: [],
            newChangesLinesICA: [],
            newChangesLinesAP: [],
            returnICA: [],
            returnAP: [],
            showListMain: true,
            showListSec: false,
            p1: false,
            p2: false,
            p3: false,
            p4: false,
            open: false,
            products: [],
            errors: [],
            table: null
        };
    }

    componentDidMount() {
        this.getProject();
        getWarehouses().then(({ whsICA, whsAP }) => this.setState({ whsICA, whsAP }));
    }

    getProject = () => {
        this.setState({ loading3: true });
        const { match: { params: { id } } } = this.props;
        return getEngprojectId(id)
            .then((project) => this.setState({ project, p1: project.p1, p2: project.p2, p3: project.p3, p4: project.p4 }))
            .finally(() => this.setState({ loading3: false }));
    }

    Save = async () => {
        this.setState({ loading: true });
        const { newChangesICA, newChangesLinesICA, newChangesAP, newChangesLinesAP, project } = this.state;

        if (newChangesICA.length > 0) {
            await UpdateItems('engprojectlinesica', newChangesICA);
        }
        if (newChangesLinesICA.length > 0) {
            await UpdateItems('engprojectlinesmovementsica', newChangesLinesICA);
        }
        if (newChangesAP.length > 0) {
            await UpdateItems('engprojectlinesap', newChangesAP);
        }
        if (newChangesLinesAP.length > 0) {
            await UpdateItems('engprojectlinesmovementsap', newChangesLinesAP);
        }

        await new Promise((resolve) => {
            this.getProject();
            this.setState({ loading: false, newChangesICA: [], newChangesAP: [], newChangesLinesICA: [], newChangesLinesAP: [] });
            toast.success('¡Cambios Realizados con Exito!');
            updateProjectOnSAP(project.id);
            resolve();
        });
    };

    SendReturns = () => {
        this.setState({ loading: true });
        const { returnICA, returnAP, project } = this.state;

        const prom = [];
        const itemsICA = [];
        const itemsAP = [];
        if (returnICA.length > 0) {
            const returnsICA = returnICA.map((e) => {
                itemsICA.push({ warehouse: e.fila.warehouse_id.label, code: e.fila.code, name: e.fila.name, orderQty: e.fila.order, delivered_qty: e.delivered_qty });
                return { delivered_date: moment().format('YYYY-MM-DD HH:mm:ss'), type: e.type, engprojectline_id: e.engprojectline_id, delivered_qty: e.delivered_qty, delivered_by: e.delivered_by };
            });
            prom.push(CreateItems('engprojectlinesmovementsica', returnsICA), GenerateComprobantDelivery('returns', { items: itemsICA, company: 'ICA', pry_code: project.mainProject.code, pry_name: project.mainProject.name }))
        }
        if (returnAP.length > 0) {
            const returnsAP = returnAP.map((e) => {
                itemsAP.push({ warehouse: e.fila.warehouse_id.label, code: e.fila.code, name: e.fila.name, orderQty: e.fila.order, delivered_qty: e.delivered_qty });
                return { delivered_date: moment().format('YYYY-MM-DD HH:mm:ss'), type: e.type, engprojectline_id: e.engprojectline_id, delivered_qty: e.delivered_qty, delivered_by: e.delivered_by };
            });
            prom.push(CreateItems('engprojectlinesmovementsap', returnsAP), GenerateComprobantDelivery('returns', { items: itemsAP, company: 'AP', pry_code: project.mainProject.code, pry_name: project.mainProject.name }))
        }

        if (prom.length > 0) {
            Promise.all(prom)
                .then((res) => {
                    this.getProject();
                    this.setState({ returnICA: [], returnAP: [] });
                    toast.success('¡Devoluciones Realizados con Exito!');
                    if (returnICA.length > 0 && returnAP.length > 0) {
                        window.open(`${process.env.REACT_APP_API_URL}returns/${res[1].id}.pdf`, '_blank');
                        window.open(`${process.env.REACT_APP_API_URL}returns/${res[3].id}.pdf`, '_blank');
                    }
                    else if (returnICA.length > 0) {
                        window.open(`${process.env.REACT_APP_API_URL}returns/${res[1].id}.pdf`, '_blank');
                    }
                    else if (returnAP.length > 0) {
                        window.open(`${process.env.REACT_APP_API_URL}returns/${res[1].id}.pdf`, '_blank');
                    }
                })
                .catch((error) => toast.error(`¡Error!, ${error.message}`))
                .finally(() => this.setState({ loading: false }));
        }
    };

    SetChanges = (table, Changes) => {
        if (table === 'ICA') {
            return this.setState({ newChangesICA: Changes })
        }
        return this.setState({ newChangesAP: Changes })
    };

    SetChangesLines = (table, Changes) => {
        if (table === 'ICA') {
            return this.setState({ newChangesLinesICA: Changes })
        }
        return this.setState({ newChangesLinesAP: Changes })
    };

    SetReturn = (table, returns) => {
        let { returnICA, returnAP } = this.state;
        if (table === 'ICA') {
            const i = returnICA.findIndex((p) => p.engprojectline_id === returns.engprojectline_id);
            if (i !== -1) {
                if (returns.delivered_qty < 1) {
                    returnICA.splice(i, 1);
                } else {
                    returnICA[i] = returns;
                }
            } else {
                if (returns.delivered_qty > 0) {
                    returnICA.push(returns);
                }
            }

            return this.setState({ returnICA });
        }

        const i = returnAP.findIndex((p) => p.engprojectline_id === returns.engprojectline_id);
        if (i !== -1) {
            if (returns.delivered_qty < 1) {
                returnAP.splice(i, 1);
            } else {
                returnAP[i] = returns;
            }
        } else {
            if (returns.delivered_qty > 0) {
                returnAP.push(returns);
            }
        }

        return this.setState({ returnAP })
    };

    RemoveLine = (table, products) => {
        const { project } = this.state;
        if (table === 'ICA') {
            return this.setState({ project: { ...project, productsICA: products } });
        }
        return this.setState({ project: { ...project, productsAP: products } });
    };

    AddLine = (table, product) => {
        const { project } = this.state;
        if (table === 'ICA') {
            project.productsICA.push(product);
            project.productsICA.sort((a, b) => a.priority >= b.priority ? 1 : -1);
            return this.setState({ project });
        }
        project.productsAP.push(product);
        project.productsAP.sort((a, b) => a.priority >= b.priority ? 1 : -1);
        return this.setState({ project: { ...project } });
    };

    AddMovement = (table, i, movement) => {
        const { project } = this.state;
        if (table === 'ICA') {
            project.productsICA[i].movements.push(movement);
            return this.setState({ project: { ...project } });
        }
        project.productsAP[i].movements.push(movement);
        return this.setState({ project: { ...project } });
    };

    Check = (project) => {
        if (project.status !== 'A') {
            return ({ msg: 'No puede finalizar este proyecto, porque no esta En Proceso.', go: false });
        };

        if (!project.productsICA > 0 && !project.productsAP > 0) {
            return ({ msg: 'No puede finalizar este proyecto, porque no tiene materiales.', go: false });
        }

        const { currentUser } = this.props;
        if (!['Administrator', 'Admin ICA', 'Admin AP'].includes(currentUser.role.name) && currentUser.id !== project.engineer_id) {
            return ({ msg: 'No puede finalizar este proyecto, porque no es el ingeniero encargado ni es administrador de sistema.', go: false });
        }

        let deliveredICA = 0;
        for (let p of project.productsICA) {
            const confirm = p.movements.reduce((total, item) => total + item.confirmed_qty, 0);
            const delivery = p.movements.reduce((total, item) => total + item.delivered_qty, 0);
            if (confirm !== delivery) {
                return ({ msg: 'No puede finalizar este proyecto, porque exiten entregas o confirmacionnes pendientes en ICA.', go: false });
            }
            deliveredICA += delivery;
        }

        let deliveredAP = 0;

        for (let p of project.productsAP) {
            const confirm = p.movements.reduce((total, item) => total + item.confirmed_qty, 0);
            const delivery = p.movements.reduce((total, item) => total + item.delivered_qty, 0);
            if (confirm !== delivery) {
                return ({ msg: 'No puede finalizar este proyecto, porque exiten entregas o confirmacionnes pendientes en AP.', go: false });
            }
            deliveredAP += delivery;
        }

        return ({ msg: null, go: true });
    };

    AddSubproject = () => {
        this.setState({ loading1: true });
        const { project } = this.state;
        return getProjectByCode(`${project.mainProject.code}-${project.company.code === 'ICA' ? 'AP' : 'ICA'}`)
            .then((projectSecondary) => {
                if (!projectSecondary) {
                    return toast.error('¡Error!, subproyecto no creado en SAP.')
                }
                return swal({
                    title: 'Agregar Subproyecto',
                    text: `¿Esta seguro de agregar subproyecto a ${project.mainProject.code}?`,
                    icon: 'warning',
                    buttons: true,
                    dangerMode: true,
                })
                    .then((value) => {
                        if (value) {
                            return UpdateItem('engprojects', project.id, { secondary_project: projectSecondary.id })
                                .then(() => {
                                    this.getProject();
                                    if (!projectSecondary.orden_venta) {
                                        return swal('¡Exito!', 'Subproyecto Agregado, pero le falta Orden de Venta.', 'warning')
                                    }
                                    return swal('¡Exito!', '¡Subproyecto Agregado con Exito!', 'success');
                                })
                                .catch((error) => swal('Error!', error.message, 'error'))
                                .finally(() => this.setState({ loading2: false }));
                        }
                    });
            })
            .catch((error) => toast.error(`¡Error!, ${error.message}`))
            .finally(() => this.setState({ loading1: false }));
    }

    LoadProducts = (table, products, errors) => {
        this.setState({ table, open: true, products, errors });
    };

    ImportProducts = (table, products) => {
        this.setState({ loading4: true });
        const { project } = this.state;
        const orders = products.map((e) => ({ engproject_id: project.id, product_id: e.id, cant_pedido: e.cant, warehouse_id: e.warehouse.value, priority: e.priority }));
        CreateItems(table === 'ICA' ? 'engprojectlinesica' : 'engprojectlinesap', orders)
            .then(() => {
                this.setState({ table: null, products: [], errors: [], open: false });
                this.getProject();
                if (project.status === 'A') {
                    updateProjectOnSAP(project.id);
                }
                return toast.success('¡Productos Procesados con Exito!');
            })
            .catch((error) => {
                console.log(error);
                return toast.error('¡Error al procesar productos!, ' + error.message);
            })
            .finally(() => this.setState({ loading4: false }));
    };


    printProject = (project) => {
        sessionStorage.setItem('PrintEngpr', JSON.stringify(project));
        return window.open(`/proyectos-de-ingeneria/imprimir/${project.id}`, '_blank');
    }

    markForShopping = () => {
        const { project } = this.state;

        return swal({
            title: 'Marcar para Comprar',
            text: `¿Desea marcar el proyecto ${project.mainProject.code} para su compra?`,
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        })
            .then((value) => {
                if (value) {
                    this.setState({ loading2: true });
                    return updateProjectOnSAP(project.id, true)
                        .then(() => {
                            this.getProject();
                            return swal('¡Exito!', '¡Proyecto Marcado para Comprar con Exito!', 'success');
                        })
                        .catch((error) => swal('Error!', error.message, 'error'))
                        .finally(() => this.setState({ loading2: false }));
                }
            });
    }

    orderProducts = (p, value) => {
        this.setState({ loading2: true })
        const { project } = this.state;
        const priority = {};
        priority[p] = value;

        return UpdateItem('engprojects', project.id, priority)
            .then(() => {
                this.setState(priority);
                return toast.success('¡Solicitud de Materiales ' + p.toUpperCase() + ' Modificada con Exito!');
            })
            .catch((error) => {
                return toast.error('¡Error al Modificar Solicitud Materiales ' + p.toUpperCase() + '!, ' + error.message);
            })
            .finally(() => this.setState({ loading2: false }));
    }

    pauseShopping = () => {
        const { project } = this.state;
        return swal({
            title: 'Desmarcar para Compras',
            text: `¿Desea desmarcar el proyecto ${project.mainProject.code} para su compra?`,
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        })
            .then((value) => {
                if (value) {
                    this.setState({ loading2: true });
                    return PauseShopping(project.id)
                        .then(() => {
                            this.getProject();
                            return swal('¡Exito!', '¡Proyecto Desmarcado para Compras con Exito!', 'success');
                        })
                        .catch((error) => swal('Error!', error.message, 'error'))
                        .finally(() => this.setState({ loading2: false }));
                }
            });
    }

    pauseProject = () => {
        const { project } = this.state;

        return swal({
            title: 'Pausar Proyecto',
            text: `Desea pausar el proyecto código ${project.mainProject.code}?`,
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        })
            .then((value) => {
                if (value) {
                    this.setState({ loading2: true });
                    const { currentUser: { id: userId } } = this.props;
                    return UpdateItem('engprojects', project.id, { p1: false, p2: false, p3: false, p4: false, test: false, todeliver: false, shopping: false, status: 'P', pausado_por: userId, pausado_fecha: moment().format('YYYY-MM-DD HH:mm:ss') })
                        .then(() => {
                            this.getProject();
                            return swal('¡Exito!', '¡Proyecto Pausado realizado con Exito!', 'success');
                        })
                        .catch((error) => swal('Error!', error.message, 'error'))
                        .finally(() => this.setState({ loading2: false }));
                }
            });
    }

    PlayProject = () => {
        this.setState({ loading2: true })
        const { project } = this.state;

        return updateProjectOnSAP(project.id)
            .then(() => {
                this.getProject();
                return toast.success('Proyecto Reactivado con Exito!');
            })
            .catch((error) => {
                return toast.error('¡Error al Reactivar Proyecto!, ' + error.message);
            })
            .finally(() => this.setState({ loading2: false }));
    }

    FinishProject = (project) => {
        return swal({
            title: 'Finalizar Proyecto',
            text: `¿Desea finalizar el proyecto código ${project.mainProject.code}?`,
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        })
            .then((value) => {
                if (value) {
                    this.setState({ loading: true });
                    return FinishedProject(project.id)
                        .then(() => {
                            this.getProject();
                            return swal('¡Proyecto Finalizado!', 'Proyecto Finalizado Exitosamente!', 'success');
                        })
                        .catch((error) => swal('Error!', error.message, 'error'))
                        .finally(() => this.setState({ loading: false }));
                }
            });
    }

    cancelProject = () => {
        const { project } = this.state;

        return swal({
            title: 'Cancelar Proyecto',
            text: `Desea cancelar el proyecto código ${project.mainProject.code}?`,
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        })
            .then((value) => {
                if (value) {
                    this.setState({ loading2: true });
                    const { currentUser: { id: userId } } = this.props;
                    return UpdateItem('engprojects', project.id, { test: false, todeliver: false, status: 'C', needupdate: true, prodcuts_ica: [], products_ap: [], canceled_by: userId, canceled_on: moment().format('YYYY-MM-DD HH:mm:ss') })
                        .then(() => {
                            this.getProject();
                            return swal('¡Exito!', '¡Proyecto Cancelado con Exito!', 'success');
                        })
                        .catch((error) => swal('Error!', error.message, 'error'))
                        .finally(() => this.setState({ loading2: false }));
                }
            });
    }

    markTest = (project) => {
        this.setState({ loading2: true })

        return UpdateItem('engprojects', project.id, { test: true })
            .then(() => {
                this.getProject();
                return toast.success('Proyecto Marcado para Pruebas con Exito!');
            })
            .catch((error) => {
                return toast.error('¡Error al Marcar para Pruebas!, ' + error.message);
            })
            .finally(() => this.setState({ loading2: false }));
    }

    markToDeliver = (project) => {
        this.setState({ loading2: true })

        return UpdateItem('engprojects', project.id, { todeliver: true })
            .then(() => {
                this.getProject();
                return toast.success('Proyecto Marcado para Entrega con Exito!');
            })
            .catch((error) => {
                console.log(error);
                return toast.error('¡Error al Marcar para Entrega!, ' + error.message);
            })
            .finally(() => this.setState({ loading2: false }));
    }

    render() {
        const { table, products, errors, open, showListMain, showListSec, project, loading, p1, p2, p3, p4, returnICA, returnAP, loading2, loading3, loading4, whsICA, whsAP, newChangesICA, newChangesLinesICA, newChangesAP, newChangesLinesAP } = this.state;
        const err = project ? this.Check(project) : null;
        const breadcrumb = [
            { title: 'Home', url: '/' },
            { title: 'Proyectos de Ingeneria', url: '/proyectos-de-ingeneria/listado' },
            { title: 'Gestionar Proyecto', url: '' },
        ];

        return (
            <React.Fragment>
                <PageHeader breadcrumb={breadcrumb} />
                <div className="container">
                    <div className="row mt-4">
                        {loading3 && (
                            <div className="col-md-12 mt-5 mb-5 pt-5 pb-5">
                                <div className="div-loading" />
                            </div>
                        )}
                        <div className="col-md-6">
                            {project && (
                                <React.Fragment>
                                    <table width="100%" className="table table-sm">
                                        <tbody>
                                            <tr>
                                                <th width="30%">Empresa Encargada</th>
                                                <td width="70%" align="right">{project.company.name}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Ingeniero Encargado</th>
                                                <td width="70%" align="right">{project.engineer}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Código</th>
                                                <td width="70%" align="right">{project.mainProject.code}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Proyecto</th>
                                                <td width="70%" align="justify">{project.mainProject.name}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Estado</th>
                                                <td width="70%" align="right">{statusBadge(project.status)}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Estado Cliente</th>
                                                <td width="70%" align="right">{statusProgress(project.status, (project.p1 || project.p2 || project.p3 || project.p4), project.test, project.todeliver)}</td>
                                            </tr>
                                            {project && project.subProject && (
                                                <React.Fragment>
                                                    <tr>
                                                        <th width="30%">Subproyecto</th>
                                                        <td width="70%" align="right">{project.subProject.code}</td>
                                                    </tr>
                                                    <tr>
                                                        <th width="30%">Orden de Venta</th>
                                                        <td width="70%" align="right">{!project.subProject.orden_venta ? <span className="badge badge-danger">Proyecto sin Orden de Venta</span> : project.subProject.orden_venta}</td>
                                                    </tr>
                                                </React.Fragment>
                                            )}
                                            <tr>
                                                <th width="30%"></th>
                                                <td width="70%" align="right"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <UncontrolledDropdown>
                                                <DropdownToggle caret>
                                                    <i className="fa fa-bars mr-3" />
                                                    {'Acciones'}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem header>Acciones</DropdownItem>
                                                    <DropdownItem onClick={() => this.printProject(project)} >
                                                        <i className="fa fa-print mr-3" />
                                                        {'Imprimir'}
                                                    </DropdownItem>
                                                    <DropdownItem divider />
                                                    {!project.subProject && ['new', 'A'].includes(project.status) && (
                                                        <DropdownItem disabled={loading2} onClick={this.AddSubproject} >
                                                            <i className={`mr-3 fas ${loading2 ? 'fa-cog fa-spin' : 'fa-plus'}`} />
                                                            {'Agregar Subproyecto'}
                                                        </DropdownItem>
                                                    )}
                                                    {!project.shopping && ['new', 'A'].includes(project.status) && (
                                                        <DropdownItem disabled={loading2} onClick={this.markForShopping} >
                                                            <i className={`mr-3 fas ${loading2 ? 'fa-cog fa-spin' : 'fa-cart-arrow-down'}`} />
                                                            {'Marcar para Comprar'}
                                                        </DropdownItem>
                                                    )}
                                                    {project.shopping && (
                                                        <DropdownItem disabled={loading2} onClick={this.pauseShopping} >
                                                            <i className={`mr-3 fas ${loading2 ? 'fa-cog fa-spin' : 'fa-pause'}`} />
                                                            {'Desmarcar para Compras'}
                                                        </DropdownItem>
                                                    )}
                                                    {project.status === 'A' && !project.todeliver && !project.test && (
                                                        <DropdownItem disabled={loading2} onClick={() => this.markTest(project)} >
                                                            <i className={`mr-3 fas ${loading2 ? 'fa-cog fa-spin' : 'fa-vials'}`} />
                                                            {'Marcar para Pruebas'}
                                                        </DropdownItem>
                                                    )}
                                                    {project.status === 'A' && project.test && !project.todeliver && (
                                                        <DropdownItem disabled={loading2} onClick={() => this.markToDeliver(project)} >
                                                            <i className={`mr-3 fas ${loading2 ? 'fa-cog fa-spin' : 'fa-dolly-flatbed'}`} />
                                                            {'Marcar para Entregar'}
                                                        </DropdownItem>
                                                    )}

                                                    {['P'].includes(project.status) && (
                                                        <DropdownItem disabled={loading2} onClick={this.PlayProject} >
                                                            <i className={`mr-3 fas ${loading2 ? 'fa-cog fa-spin' : 'fa-play'}`} />
                                                            {'Continuar Proyecto'}
                                                        </DropdownItem>
                                                    )}
                                                    {['A'].includes(project.status) && (
                                                        <DropdownItem disabled={loading2} onClick={this.pauseProject} >
                                                            <i className={`mr-3 fas ${loading2 ? 'fa-cog fa-spin' : 'fa-pause'}`} />
                                                            {'Pausar Proyecto'}
                                                        </DropdownItem>
                                                    )}
                                                    {['new'].includes(project.status) && (
                                                        <DropdownItem disabled={loading2} onClick={this.cancelProject} >
                                                            <i className={`mr-3 fas ${loading2 ? 'fa-cog fa-spin' : 'fa-ban'}`} />
                                                            {'Cancelar Proyecto'}
                                                        </DropdownItem>
                                                    )}
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                        <div className="col-md-8">
                                            {!['finished', 'C'].includes(project.status) && (
                                                <Alert color={project.shopping ? 'success' : 'warning'} >
                                                    {project.shopping ? 'Proyecto Marcado para Compras.' : 'Proyecto No marcado para Compras.'}
                                                </Alert>
                                            )}
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="col-md-6">
                            {project && (
                                <React.Fragment>
                                    <table width="100%" className="table table-sm">
                                        <tbody>
                                            <tr>
                                                <th width="30%">Cód. Cliente</th>
                                                <td width="70%" align="right">{project.mainProject.code_cliente}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Cliente</th>
                                                <td width="70%" align="right">{project.mainProject.name_cliente}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Orden de Venta</th>
                                                <td width="70%" align="right">{project.mainProject.orden_venta}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Fecha Inicio</th>
                                                <td width="70%" align="right">{project.dateCreated}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Fecha Entrega</th>
                                                <td width="70%" align="right">{project.dateFinish}</td>
                                            </tr>
                                            {project && project.status === 'finished' && (
                                                <tr>
                                                    <th width="30%">Fecha Finalizado</th>
                                                    <td width="70%" align="right">{project.dateFinished}</td>
                                                </tr>
                                            )}
                                            <tr>
                                                <th width="30%">{`Productos ${project.company.code}`}</th>
                                                <td width="70%" align="right"><InputSwitch checked={showListMain} onChange={(e) => this.setState({ showListMain: e.value })} /></td>
                                            </tr>
                                            {project && project.subProject && (
                                                <tr>
                                                    <th width="30%">{`Productos ${project.company.code !== 'ICA' ? 'ICA' : 'AP'}`}</th>
                                                    <td width="70%" align="right"><InputSwitch checked={showListSec} onChange={(e) => this.setState({ showListSec: e.value })} /></td>
                                                </tr>
                                            )}
                                            <tr>
                                                <th width="30%"></th>
                                                <td width="70%" align="right"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="p-d-flex p-jc-between">
                                        <label>Solicitados a Bodega:</label>
                                        <div className="p-d-flex p-flex-column">
                                            <label htmlFor="p1">P1</label>
                                            <InputSwitch disabled={loading2 || project.status !== 'A'} tooltipOptions={{ position: 'top' }} tooltip="Prioridad 1" checked={p1} inputId="p1" onChange={(e) => this.orderProducts('p1', e.value)} />
                                        </div>
                                        <div className="p-d-flex p-flex-column">
                                            <label htmlFor="p2">P2</label>
                                            <InputSwitch disabled={loading2 || project.status !== 'A'} tooltipOptions={{ position: 'top' }} tooltip="Prioridad 2" checked={p2} inputId="p2" onChange={(e) => this.orderProducts('p2', e.value)} />
                                        </div>
                                        <div className="p-d-flex p-flex-column">
                                            <label htmlFor="p3">P3</label>
                                            <InputSwitch tooltipOptions={{ position: 'top' }} disabled={loading2 || project.status !== 'A'} tooltip="Prioridad 3" checked={p3} inputId="p3" onChange={(e) => this.orderProducts('p3', e.value)} />
                                        </div>
                                        <div className="p-d-flex p-flex-column">
                                            <label htmlFor="p4">P4</label>
                                            <InputSwitch tooltipOptions={{ position: 'top' }} disabled={loading2 || project.status !== 'A'} tooltip="Prioridad 4" checked={p4} inputId="p4" onChange={(e) => this.orderProducts('p4', e.value)} />
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    {project && showListMain && (
                        <React.Fragment>
                            {!['finished', 'C'].includes(project.status) && (
                                <React.Fragment>
                                    <Button color="secondary" id="toggler" style={{ marginBottom: '1rem' }}> Importar .CSV</Button>
                                    <UncontrolledCollapse toggler="#toggler">
                                        <div className="mb-2">
                                            <ImportCsv LoadProducts={this.LoadProducts} whs={project.company.code === 'ICA' ? whsICA : whsAP} company={project.mainProject.company_id.code} loading={loading2} />
                                        </div>
                                    </UncontrolledCollapse>
                                </React.Fragment>
                            )}
                            {project.company.code === 'ICA' ? (
                                project.error_update_ica && <div className="alert alert-danger">{project.error_update_ica}</div>
                            ) :
                                (
                                    project.error_update_ap && <div className="alert alert-danger">{project.error_update_ap}</div>
                                )
                            }
                            <div className="row">
                                <h5 className="col-md-5">Listado de Materiales de Proyecto</h5>
                                <p className="offset-md-3 col-md-4 text-right">
                                    Ultima Actualización:
                                    {
                                        project.company.code === 'ICA' ? (
                                            project.updated_sap_ica ?
                                                moment(project.updated_sap_ica).format('DD-MM-YY hh:mm A')
                                                :
                                                '  No Actualizado'
                                        ) :
                                            (
                                                project.updated_sap_ap ?
                                                    moment(project.updated_sap_ap).format('DD-MM-YY hh:mm A')
                                                    :
                                                    '  No Actualizado'
                                            )
                                    }
                                </p>
                            </div>
                            <ProductsTableManager
                                SetChangesLines={this.SetChangesLines}
                                SetChanges={this.SetChanges}
                                returns={this.SetReturn}
                                projectId={project.id}
                                whs={project.company.code === 'ICA' ? whsICA : whsAP}
                                products={project.company.code === 'ICA' ? project.productsICA : project.productsAP}
                                table={project.company.code}
                                whsSelect={project.company.code === 'ICA' ? { code: '01', label: 'Bod. 01', value: 1 } : { code: '01', label: 'Insumos', value: 4 }}
                                RemoveLine={this.RemoveLine}
                                AddLine={this.AddLine}
                                AddMovement={this.AddMovement}
                                status={project.status}
                                project={project.mainProject}
                                getProject={this.getProject}
                                shopping={project.shopping}
                            />
                            <br />
                        </React.Fragment>
                    )}
                    {project && showListSec && (
                        <React.Fragment>
                            {!['finished', 'C'].includes(project.status) && (
                                <React.Fragment>
                                    <Button color="secondary" id="toggler2" style={{ marginBottom: '1rem' }}> Importar .CSV</Button>
                                    <UncontrolledCollapse toggler="#toggler2">
                                        <div className="mb-2">
                                            <ImportCsv LoadProducts={this.LoadProducts} whs={project.company.code !== 'ICA' ? whsICA : whsAP} company={project.subProject.company_id.code} loading={loading2} />
                                        </div>
                                    </UncontrolledCollapse>
                                </React.Fragment>
                            )}
                            {project.company.code !== 'ICA' ? (
                                project.error_update_ica && <div className="alert alert-danger">{project.error_update_ica}</div>
                            ) :
                                (
                                    project.error_update_ap && <div className="alert alert-danger">{project.error_update_ap}</div>
                                )
                            }
                            <div className="row">
                                <h5 className="col-md-5">Listado de Materiales Subproyecto</h5>
                                <p className="offset-md-3 col-md-4 text-right">
                                    Ultima Actualización:
                                    {
                                        project.company.code !== 'ICA' ? (
                                            project.updated_sap_ica ?
                                                moment(project.updated_sap_ica).format('DD-MM-YY hh:mm A')
                                                :
                                                '  No Actualizado'
                                        ) :
                                            (
                                                project.updated_sap_ap ?
                                                    moment(project.updated_sap_ap).format('DD-MM-YY hh:mm A')
                                                    :
                                                    '  No Actualizado'
                                            )
                                    }
                                </p>
                            </div>
                            <ProductsTableManager
                                SetChangesLines={this.SetChangesLines}
                                SetChanges={this.SetChanges}
                                returns={this.SetReturn}
                                projectId={project.id}
                                whs={project.company.code !== 'ICA' ? whsICA : whsAP}
                                products={project.company.code !== 'ICA' ? project.productsICA : project.productsAP}
                                table={project.company.code !== 'ICA' ? 'ICA' : 'AP'}
                                whsSelect={project.company.code !== 'ICA' ? { code: '01', label: 'Bod. 01', value: 1 } : { code: '01', label: 'Insumos', value: 4 }}
                                RemoveLine={this.RemoveLine}
                                AddLine={this.AddLine}
                                AddMovement={this.AddMovement}
                                status={project.status}
                                project={project.subProject}
                                getProject={this.getProject}
                                shopping={project.shopping}
                            />
                            <br />
                        </React.Fragment>
                    )}

                    {err && !err.go && !['finished', 'C'].includes(project.status) && (
                        <Alert color="warning" >
                            {err.msg}
                        </Alert>
                    )}

                    {project && project.mainProject && ['A', 'new'].includes(project.status) && (newChangesICA.length > 0 || newChangesLinesICA.length > 0 || newChangesAP.length > 0 || newChangesLinesAP.length > 0) && (
                        <button type="button" className="btn btn-primary mr-3" onClick={this.Save} disabled={loading}><i className={`${loading ? 'fas fa-cog fa-spin' : 'fas fa-save'} mr-2`} />Guardar</button>
                    )}
                    {project && project.mainProject && project.status === 'A' && (returnICA.length > 0 || returnAP.length > 0) && (
                        <button type="button" className="btn btn-primary mr-3" onClick={this.SendReturns} disabled={loading}><i className={`${loading ? 'fas fa-cog fa-spin' : 'fas fa-undo'} mr-2`} />Devolver Todos</button>
                    )}
                    {project && err && err.go && <button type="button" className="btn btn-success" onClick={() => this.FinishProject(project)} disabled={loading}><i className={`${loading ? 'fas fa-cog fa-spin' : 'fas fa-flag-checkered'} mr-2`} />Finalizar Proyecto</button>}
                </div>

                {open && (
                    <Modal isOpen={open} centered size="xl" zIndex={999}>
                        <ModalHeader toggle={() => this.setState({ open: false })}>Cargar Listado de Materiales</ModalHeader>
                        <ModalBody>
                            {errors.length > 0 && (
                                <div className="alert alert-danger">
                                    <table className="table table-sm">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Código</th>
                                                <th>Error</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {errors.map((e, i) => (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{e.code}</td>
                                                    <td>{e.error}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}

                            <table width="100%" className="table table-sm">
                                <thead>
                                    <tr>
                                        <th width="4%" >#</th>
                                        <th width="18%">Código</th>
                                        <th width="42%" >Producto</th>
                                        <th width="10%" >Cant.</th>
                                        <th width="10%">Prioridad</th>
                                        <th width="16%">Bodega</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products.map((e, i) => (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{e.code}</td>
                                            <td>{e.name}</td>
                                            <td>{e.cant}</td>
                                            <td>{e.priority}</td>
                                            <td>{e.warehouse.name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div>
                                {<Button label="Importar" icon="pi pi-save" iconPos="right" disabled={loading4} loading={loading4} onClick={() => this.ImportProducts(table, products)} />}
                            </div>
                        </ModalBody>
                    </Modal>
                )}
            </React.Fragment >
        );
    }
}
