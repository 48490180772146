// react
import React, { useEffect, useState } from 'react';
// third-party
import { Helmet } from 'react-helmet-async';
import { getMyInventory } from '../../store/directusSdk';
import PageHeader from '../shared/PageHeader';
import ProductsInventory from './ProductsInventory';


export default function EngprojectList({ currentUser }) {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    const GetInventory = (code) => getMyInventory(code).then(setProducts);

    useEffect(() => {
        const prom = [];
        if (currentUser.customer_ica) {
            prom.push(GetInventory(`${currentUser.customer_ica.id}-ICA`));
        } else if (currentUser.customer_ap) {
            prom.push(GetInventory(`${currentUser.customer_ap.id}-AP`));
        }
        if (prom.length > 0) {
            Promise.all(prom).then(() => setLoading(false));
        }
    }, []);


    const breadcrumb = [
        { title: 'Home', url: '/' },
        { title: 'Mi Inventario', url: '' },
    ];

    if (!currentUser.customer_ica && !currentUser.customer_ap) {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Mi Inventario - Ingeniería y Controles Automatizados</title>
                </Helmet>

                <PageHeader breadcrumb={breadcrumb} />
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <h3>{'Cliente sin Inventario Asignado.'}</h3>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    if (loading) {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Mi Inventario - Ingeniería y Controles Automatizados</title>
                </Helmet>

                <PageHeader breadcrumb={breadcrumb} />
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <div className="mt-5 mb-5 pt-5 pb-5">
                                <div className="div-loading" />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Mi Inventario - Ingeniería y Controles Automatizados</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />
            <div className="container">
                <div className="card">
                    <div className="card-body">
                        <ProductsInventory products={products} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}