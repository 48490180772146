// react
import React, { useEffect, useState } from "react";
// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { MDBDataTableV5 } from "mdbreact";
import { Button } from "reactstrap";
import { getMyEngprojects } from "../../store/directusSdk";
import PageHeader from "../shared/PageHeader";
import { statusProgress } from "./utils";

export default function EngprojectList({ currentUser }) {
    const [projectsICA, setProjectsICA] = useState([]);
    const [projectsAP, setProjectsAP] = useState([]);
    const [loading, setLoading] = useState(true);

    const GetProjects = (company, setProjects) =>
        getMyEngprojects(company, company === "ICA" ? currentUser.customer_ica.ruc : currentUser.customer_ap.ruc).then((engprojects) => {
            const proyectos = engprojects.map((e) => ({
                estado: statusProgress(e.status, e.p1 || e.p2 || e.p3 || e.p4, e.test, e.todeliver),
                actions: (
                    <Link to={`/mis-proyectos/${e.id}`}>
                        <Button outline color="info" title="Gestionar Proyecto">
                            <i className="fa fa-edit" />
                        </Button>
                    </Link>
                ),
            }));
            return setProjects(proyectos);
        });

    useEffect(() => {
        const prom = [];
        if (currentUser.customer_ica && currentUser.customer_ica.ruc) {
            prom.push(GetProjects("ICA", setProjectsICA));
        }
        if (currentUser.customer_ap && currentUser.customer_ap.ruc) {
            prom.push(GetProjects("AP", setProjectsAP));
        }
        if (prom.length > 0) {
            Promise.all(prom).then(() => setLoading(false));
        }
    }, []);

    const breadcrumb = [
        { title: "Home", url: "/" },
        { title: "Mis Proyectos de Ingenería", url: "" },
    ];

    if (!currentUser.customer_ica && !currentUser.customer_ap) {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Mis Proyectos de Ingeneria - Ingeniería y Controles Automatizados</title>
                </Helmet>

                <PageHeader breadcrumb={breadcrumb} />
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <h3>{"Cliente sin Proyectos."}</h3>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    if (loading) {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Mis Proyectos de Ingeneria - Ingeniería y Controles Automatizados</title>
                </Helmet>

                <PageHeader breadcrumb={breadcrumb} />
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <div className="mt-5 mb-5 pt-5 pb-5">
                                <div className="div-loading" />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    const data = {
        columns: [
            { label: "Código", field: "code", width: 170 },
            { label: "Proyecto", field: "name", width: 350 },
            { label: "Encargado", field: "engineer", width: 170 },
            //  { label: 'Estado', field: 'estado', width: 200 },
            { label: "Acciones", field: "actions", width: 70 },
        ],
        rows: projectsICA,
    };
    const data2 = {
        columns: [
            { label: "Código", field: "code", width: 170 },
            { label: "Proyecto", field: "name", width: 350 },
            { label: "Encargado", field: "engineer", width: 170 },
            //  { label: 'Estado', field: 'estado', width: 200 },
            { label: "Acciones", field: "actions", width: 70 },
        ],
        rows: projectsAP,
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Mis Proyectos de Ingeneria - Ingeniería y Controles Automatizados</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />
            <div className="container">
                <div className="card">
                    <div className="card-body">
                        {projectsICA.length > 0 && (
                            <React.Fragment>
                                <h5 className="mt-3">Mis Proyectos ICA</h5>
                                <MDBDataTableV5
                                    hover
                                    entriesOptions={[10, 25, 50]}
                                    entries={10}
                                    pagesAmount={4}
                                    data={data}
                                    pagingTop
                                    searchTop
                                    searchBottom={false}
                                    entriesLabel="Mostar"
                                    searchLabel="Buscar"
                                    infoLabel={["Viendo", "al", "de", "registros"]}
                                    paginationLabel={["Anterior", "Siguiente"]}
                                    noRecordsFoundLabel="No se han encontrado registros..."
                                />

                                <div className="card-divider mt-3 mb-3" />
                            </React.Fragment>
                        )}

                        {projectsAP.length > 0 && (
                            <React.Fragment>
                                <h5 className="mt-3">Mis Proyectos AP</h5>
                                <MDBDataTableV5
                                    hover
                                    entriesOptions={[10, 25, 50]}
                                    entries={10}
                                    pagesAmount={4}
                                    data={data2}
                                    pagingTop
                                    searchTop
                                    searchBottom={false}
                                    entriesLabel="Mostar"
                                    searchLabel="Buscar"
                                    infoLabel={["Viendo", "al", "de", "registros"]}
                                    paginationLabel={["Anterior", "Siguiente"]}
                                    noRecordsFoundLabel="No se han encontrado registros..."
                                />
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
