// react
import React, { useEffect, useState } from 'react';
// third-party
import { Helmet } from 'react-helmet-async';
import { getOrdersBydWhs } from '../../store/directusSdk';
import PageHeader from '../shared/PageHeader';
import UpdateQtty from '../engprojects/UpdateQtty';
import ProductsTableOrders from './ProductsTableOrders';

export default function Orders(props) {
    const { currentUser } = props;
    const [ordersICA, setOrdersICA] = useState([]);
    const [ordersAP, setOrdersAP] = useState([]);
    const [loading, setLoading] = useState(false);
    const whsICA = [];
    const whsAP = [];
    if (currentUser.warehouses.length > 0) {
        currentUser.warehouses.forEach((e) => {
            if (e.company === 'ICA') {
                whsICA.push(e.id);
            } else {
                whsAP.push(e.id);
            }
        });
    }
    const GetProjects = (collection, whs, SetOrders) => {
        return getOrdersBydWhs(collection, whs)
            .then((products) => {
                if (products.length > 0) {
                    products.map((e) => {
                        e.confirmed = <UpdateQtty value={0} />
                    });
                }
                SetOrders(products);
            });
    }

    useEffect(() => {
        if (whsICA.length > 0 || whsAP.length > 0) {
            setLoading(true);
            const req = [];
            if (whsICA.length > 0) {
                req.push(GetProjects('engprojectlinesica', whsICA, setOrdersICA));
            }
            if (whsAP.length > 0) {
                req.push(GetProjects('engprojectlinesap', whsAP, setOrdersAP));
            }

            Promise.all(req).then(() => setLoading(false));
        }
    }, []);


    const breadcrumb = [
        { title: 'Home', url: '/' },
        { title: 'Almacén', url: '/almacen' },
        { title: 'Materiales por Entregar', url: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>Materiales por Entregar - Ingeniería y Controles Automatizados</title>
            </Helmet>

            <PageHeader header="Entregas" breadcrumb={breadcrumb} />
            <div className="container-fluid">
                {loading ? (
                    <div className="mt-5 mb-5 pt-5 pb-5">
                        <div className="div-loading" />
                    </div>
                ) : (
                    <React.Fragment >
                        {ordersICA.length > 0 ? (
                            <React.Fragment>
                                <ProductsTableOrders products={ordersICA} table="ICA" whs={whsICA} setProducts={setOrdersICA} GetProjects={GetProjects} />
                            </React.Fragment>
                        ) : (
                            <h3 align="center" className="mt-5 mb-5" >No hay Materiales en ICA que Entregar.</h3>
                        )}

                        < div className="card-divider" />

                        {ordersAP.length > 0 ? (
                            <ProductsTableOrders products={ordersAP} table="AP" whs={whsAP} setProducts={setOrdersAP} GetProjects={GetProjects} />
                        ) : (
                            <h3 align="center" className="mt-5 mb-5" >No hay Materiales en AP que Entregar.</h3>
                        )}
                    </React.Fragment>
                )}

            </div>
        </React.Fragment>
    );
}