// react
import React from 'react';

export default function Footer(props) {
    return (
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__bottom">
                    <div className="site-footer__copyright">
                        {`Ingeniería y Controles Automatizados S.A. Todos los derechos reservados ® 2014 - ${new Date().getFullYear()}.`}
                    </div>
                </div>
            </div>
        </div>
    );
}
