// react
import React from 'react';

// third-party
import { Link } from 'react-router-dom';
import Dropdown from './Dropdown';



function Topbar(props) {

    const { currentUser, cart, history } = props;

    const accountLinks = [
        { title: 'Mi Perfil', url: '/account/dashboard' },
        { title: 'Contraseña', url: '/account/password' },
        { title: 'Salir', url: '#' },
    ];
    const dispatches = ['Administrator', 'Almacenista ICA', 'Almacenista AP', 'Admin ICA', 'Admin AP', 'VendedorICA-Logistica'];
    const returs = ['Administrator', 'Ingeniero ICA', 'Ingeniero AP', 'Admin ICA', 'Admin AP'];
    const quotesICA = ['Administrator', 'Vendedor ICA', 'Admin ICA',  'VendedorICA-Logistica'];
    const quotesAP = ['Administrator', 'Vendedor AP', 'Admin AP'];
    const surplus = ['Administrator', 'Ingeniero ICA', 'Ingeniero AP', 'Admin ICA', 'Admin AP', 'Vendedor ICA',  'VendedorICA-Logistica'];
    const puchaseReports = ['Administrator', 'Admin ICA', 'Compas ICA', 'VendedorICA-Logistica'];

    if (surplus.includes(currentUser && currentUser.role.name)) {
        accountLinks.splice(1, 0, { title: 'Mis Sobrantes', url: '/account/sobrantes' });
    }
    if (puchaseReports.includes(currentUser && currentUser.role.name)) {
        accountLinks.splice(1, 0, { title: 'Mis Compras', url: '/account/compras' });
    }
    if (dispatches.includes(currentUser && currentUser.role.name)) {
        accountLinks.splice(1, 0, { title: 'Mis Entregas', url: '/account/envios' });
    }
    if (returs.includes(currentUser && currentUser.role.name)) {
        accountLinks.splice(1, 0, { title: 'Mis Devoluciones', url: '/account/devoluciones' });
    }
    if (quotesAP.includes(currentUser && currentUser.role.name)) {
        accountLinks.splice(1, 0, { title: 'Mis Cotizaciones AP', url: '/account/cotizaciones-ap' });
    }
    if (quotesICA.includes(currentUser && currentUser.role.name)) {
        accountLinks.splice(1, 0, { title: 'Mis Cotizaciones ICA', url: '/account/cotizaciones' });
    }

    return (
        <div className="site-header__topbar topbar">
            <div className="topbar__container container">
                <div className="topbar__row">
                    {cart.customer ? (
                        <React.Fragment>
                            <strong>Cliente Seleccionado: </strong>
                            &nbsp;&nbsp;
                            {cart.customer.label}
                        </React.Fragment>
                    ) : null}
                    <div className="topbar__spring" />
                    <div className="topbar__item">
                        {!currentUser ? (
                            <div className="topbar__item topbar__item--link">
                                <Link className="topbar-link" to="/account/login"><b>Ingresar ó Registrarse</b></Link>
                            </div>
                        )
                            :
                            (

                                <Dropdown
                                    title={`${currentUser.first_name} ${currentUser.last_name}`}
                                    items={accountLinks}
                                    history={history}
                                />
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Topbar;
