/* eslint-disable default-case */
// react
import React, { Component } from "react";
// third-party
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { InputNumber } from "primereact/inputnumber";
import { Column } from "primereact/column";
import { Button, Badge, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Col, Row, Table } from "reactstrap";
import PageHeader from "../../shared/PageHeader";
import { CreateItem, CreateItems, UpdateItem, getPurchaseOrderFilterDispatchedByIdAP, getPurchaseOrderByInputAP } from "../../../store/directusSdk";
import { statusColorDispatch, statusLabelDispatch } from "../../shared/utilities";

import "./tableStyle.css";

class newDispatch extends Component {
    constructor(props) {
        super(props);
        const today = moment().format("YYYY-MM-DD");

        this.state = {
            loading: false,
            purchaseOrder: null,
            selectedRows: [],
            expandAll: false,
            expandedRows: null,
            open: false,
            open2: false,
            mountOrder: false,
            arrivalDate: today,
            dispatchDate: today,
            mountDate: today,
        };
    }

    exportCSV = () => this.dt.exportCSV();

    promiseOptions = (inputValue) => {
        return getPurchaseOrderByInputAP(inputValue);
    };

    searchPurchaseOrder = (e) => {
        if (e) {
            this.setState({ loading: true });
            return this.refreshPurchaseOrder(e.id)
                .catch((error) => toast.error(`¡Error!, ${error.message}`))
                .finally(() => this.setState({ loading: false }));
        }
        this.setState({ purchaseOrder: null });
    };

    createDispatches = () => {
        const { selectedRows, dispatchDate, arrivalDate, purchaseOrder } = this.state;
        const { currentUser } = this.props;

        if (!dispatchDate || !arrivalDate) {
            return toast.error("¡Error!, ¡Debe introducir las la fecha de despacho y la de llegada!");
        }

        const disp = {
            arrival_date: arrivalDate,
            arrival_marked_user: currentUser.id,
            dispatch_date: dispatchDate,
            order_id: purchaseOrder.id,
            n: purchaseOrder.dispatches.length + 1,
        };
        const items = [];

        selectedRows.forEach((e) => {
            if (e.qtyDispatch > 0) {
                items.push({ qty: e.qtyDispatch, item_id: e.id });
            }
        });

        if (!(items.length > 0)) {
            return toast.error("¡Error!,¡Un despacho no se puede crear si no tiene ítems!");
        }

        disp.items = items;
        this.setState({ loading: true });
        return CreateItem("ap_log_dispatches", disp)
            .then(() => {
                toast.success("¡Se ha creado un despacho de manera exitosa!");
                this.refreshPurchaseOrder(purchaseOrder.id);
                this.setState({ open: false, selectedRows: [] });
            })
            .catch((error) => toast.error(`¡Error!, ${error.message}`))
            .finally(() => this.setState({ loading: false }));
    };

    mountOrConfirOrder = (id) => {
        const { mountDate, purchaseOrder, mountOrder } = this.state;
        const { currentUser } = this.props;
        this.setState({ loading: true });

        if (!mountDate) {
            return toast.error("¡Error!, Debe introducir La fecha ");
        }

        if (mountOrder) {
            return CreateItems("ap_purchase_request", { placement_date: mountDate, placement_user: currentUser.id })
                .then(({ data }) => {
                    return UpdateItem("ap_purchase_orders", purchaseOrder.id, { purchase_request_id: data.id })
                        .then(() => {
                            toast.success("¡Orden de compra Colocada!");
                            this.refreshPurchaseOrder(purchaseOrder.id);

                            this.setState({
                                open2: false,
                                mountDate: moment().format("YYYY-MM-DD"),
                            });
                        })
                        .catch((error) => {
                            console.error(error);
                            toast.error(`¡Error!, ${error.message}`);
                        });
                })
                .catch((error) => toast.error(`¡Error!, ${error.message}`))
                .finally(() => {
                    this.setState({
                        loading: false,
                    });
                });
        }

        return UpdateItem("ap_purchase_request", id, { confirmation_date: mountDate, confirmation_user: currentUser.id, status: "CO" })
            .then(() => {
                toast.success("¡Orden de compra confirmada!");
                this.refreshPurchaseOrder(purchaseOrder.id);
                this.setState({
                    open2: false,
                    mountDate: moment().format("YYYY-MM-DD"),
                });
            })
            .catch((error) => toast.error(`¡Error!, ${error.message}`))
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    expandAll = () => {
        const { purchaseOrder } = this.state;
        const expandedRows = {};
        if (purchaseOrder) {
            purchaseOrder.lines.forEach((p) => {
                expandedRows[`${p.id}`] = true;
            });
            this.setState({ expandedRows, expandAll: true });
        }
    };

    collapseAll = () => this.setState({ expandedRows: null, expandAll: false });

    rowExpansionTemplate = (line) => (
        <div className="orders-subtable">
            {line && line.dispatches && line.dispatches.length > 0 ? (
                <DataTable value={line.dispatches} className="p-datatable-sm">
                    <Column field="n" header="Despacho" style={{ width: "16%" }} />
                    <Column field="guide" header="Guía" style={{ width: "16%" }} body={(row) => (row.guide ? row.guide : "-")} />

                    <Column
                        field="invoice_number"
                        header="Factura"
                        style={{ width: "18%" }}
                        body={(row) => (row.invoice_number ? row.invoice_number : "-")}
                    />

                    <Column field="qty" header="Cantidad" style={{ width: "15%" }} />

                    <Column
                        header="status"
                        body={(row) => {
                            return <Badge color={statusColorDispatch(row.status)}>{statusLabelDispatch(row.status)}</Badge>;
                        }}
                        style={{ width: "14%" }}
                    />
                </DataTable>
            ) : (
                <p>No se Han Realizado Despachos...</p>
            )}
        </div>
    );

    refreshPurchaseOrder = (id) => getPurchaseOrderFilterDispatchedByIdAP(id).then((purchaseOrder) => this.setState({ purchaseOrder }));

    buttonTemplate = (row) => {
        if (row && row.status === "C") {
            return <Badge color="success">Cerrado</Badge>;
        }

        if (row && row.dispatched === row.qty) {
            return <Badge color="info">Despacho Completo</Badge>;
        }
        if (!row) {
            return <Badge color="danger">ERROR</Badge>;
        }
    };

    changeQtys = (i, qty) => {
        const { selectedRows } = this.state;
        selectedRows[i].qtyDispatch = qty;
        this.setState({ selectedRows });
    };

    onStatusChange = (e) => {
        this.setState({ statusF: e.target.value });
        return this.dt.filter(e.target.value, "status", "equals");
    };

    checkSave = (rowsSelected) => rowsSelected.every((e) => e.status === "O" || e.qtyDispatch > 0);

    render() {
        const {
            loading,
            selectedRows,
            statusF,
            expandAll,
            expandedRows,
            purchaseOrder,
            open,
            mountDate,
            dispatchDate,
            arrivalDate,
            open2,
            mountOrder,
        } = this.state;
        const { currentUser } = this.props;
        let purchaseStatusLabel = "";
        let purchaseStatusColor = "";

        if (purchaseOrder) {
            switch (purchaseOrder.status) {
                case "O":
                    if (!purchaseOrder.purchase_request_id) {
                        purchaseStatusColor = "info";
                        purchaseStatusLabel = "ABIERTA";
                        break;
                    }
                    purchaseStatusLabel = purchaseOrder.purchase_request_id.status === "C" ? "COLOCADA" : "CONFIRMADA";
                    purchaseStatusColor = purchaseOrder.purchase_request_id.status === "C" ? "primary" : "success";

                    break;
                case "C":
                    purchaseStatusColor = "success";
                    purchaseStatusLabel = "CERRADA";
                    break;
                default:
                    toast.error("Error, Algo ha salido muy mal");
                    break;
            }
        }

        const breadcrumb = [
            { title: "Home", url: "/" },
            { title: "Logística", url: "/logistica" },
            { title: "Logística AP", url: "/logistica/logistica-ap" },
            { title: "Nuevo Despacho", url: "" },
        ];

        const header = (
            <div className="row">
                <h4 className="col-md-3">Lista de Productos</h4>
                {purchaseOrder && purchaseOrder.lines && (
                    <React.Fragment>
                        <div className="table-header-container col-md-2 offset-md-5">
                            {expandAll ? (
                                <Button color="warning" onClick={this.collapseAll}>
                                    <i className="fas fa-angle-double-right mr-2" />
                                    Contraer
                                </Button>
                            ) : (
                                <Button color="info" className="mr-2" onClick={this.expandAll}>
                                    <i className="fas fa-angle-double-down mr-2" />
                                    Expandir
                                </Button>
                            )}
                        </div>
                        <div className="col-md-2">
                            <Button color="info" onClick={this.exportCSV}>
                                <i className="fas fa-file-export mr-2" />
                                Exportar
                            </Button>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );

        const footer = (
            <div>
                <Button
                    color="primary"
                    hidden={!["Administrator", "Almacenista AP", "Admin AP", "Almacenista AP"].includes(currentUser.role.name)}
                    disabled={
                        selectedRows.length < 1 ||
                        loading ||
                        !(purchaseOrder && purchaseOrder.purchase_request_id && purchaseOrder.purchase_request_id.status === "CO")
                    }
                    onClick={() => this.setState({ open: true })}
                >
                    <i className={`${loading ? "fas fa-cog fa-spin" : "fas fa-save"} mr-2`} />
                    Crear
                </Button>
            </div>
        );

        const statusFilter = (
            <Input type="select" name="select" id="exampleSelect" value={statusF} onChange={this.onStatusChange}>
                <option value="">Todos</option>
                <option value="O">Abierto</option>
                <option value="C"> Cerrado</option>
            </Input>
        );

        return (
            <React.Fragment>
                <PageHeader breadcrumb={breadcrumb} />
                <div className="container">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <AsyncSelect
                                    cacheOptions
                                    loadOptions={this.promiseOptions}
                                    noOptionsMessage={() => "No se Encontró orden de compra."}
                                    loadingMessage={() => "Buscando..."}
                                    placeholder="Orden de Compra..."
                                    onChange={this.searchPurchaseOrder}
                                    value={purchaseOrder}
                                    className="col-md-4"
                                />
                            </div>
                            {loading ? (
                                <div className="div-loading m-5 p-5" />
                            ) : (
                                <div>
                                    <div className="row mt-2">
                                        <div className="col-md-4 mt-1">
                                            <b>Fecha Emisión:</b>
                                            <p>{purchaseOrder && moment(purchaseOrder.date).format("DD-MM-YYYY")}</p>
                                        </div>
                                        <div className="col-md-3 mt-1">
                                            <b>Fecha Llegada:</b>
                                            <p>{purchaseOrder && purchaseOrder.llegada && moment(purchaseOrder.llegada).format("DD-MM-YYYY")}</p>
                                        </div>
                                        <div className="col-md-1 mt-1">
                                            <b>Estado:</b>
                                            <p>{purchaseOrder && <Badge color={purchaseStatusColor}>{purchaseStatusLabel}</Badge>}</p>
                                        </div>
                                        <div className="col-md-4 mt-1">
                                            {purchaseOrder &&
                                                purchaseOrder.status !== "C" &&
                                                ["Administrator", "Almacenista AP", "Admin AP", "Compras AP"].includes(currentUser.role.name) &&
                                                !purchaseOrder.purchase_request_id && (
                                                    <Button
                                                        color="primary"
                                                        onClick={() =>
                                                            this.setState({
                                                                open2: true,
                                                                mountOrder: purchaseOrder && !purchaseOrder.purchase_request_id ? true : false,
                                                            })
                                                        }
                                                    >
                                                        <i className={`${loading ? "fas fa-cog fa-spin" : "fa fa-file-export"} mr-2`} />
                                                        Colocar Orden
                                                    </Button>
                                                )}
                                            {purchaseOrder &&
                                                purchaseOrder.status !== "C" &&
                                                ["Administrator", "Almacenista AP", "Admin AP", "Compras AP"].includes(currentUser.role.name) &&
                                                purchaseOrder.purchase_request_id &&
                                                purchaseOrder.purchase_request_id.status === "C" && (
                                                    <Button
                                                        color="primary"
                                                        onClick={() =>
                                                            this.setState({
                                                                open2: true,
                                                                mountOrder: purchaseOrder && !purchaseOrder.purchase_request_id ? true : false,
                                                            })
                                                        }
                                                    >
                                                        <i className={`${loading ? "fas fa-cog fa-spin" : "fa fa-file-export"} mr-2`} />
                                                        Confirmar Orden
                                                    </Button>
                                                )}
                                        </div>
                                        <div className="col-md-4 mt-1">
                                            <b>Proveedor:</b>
                                            <p>{purchaseOrder && purchaseOrder.supplier}</p>
                                        </div>
                                        <div className="col-md-8 mt-1">
                                            <b>Comentarios:</b>
                                            <p>{purchaseOrder && purchaseOrder.comments}</p>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        {purchaseOrder && purchaseOrder.purchase_request_id && (
                                            <>
                                                <div className="col-md-4 mt-1 ">
                                                    <b>Colocado Por:</b>
                                                    <p>
                                                        {purchaseOrder.purchase_request_id.placement_user &&
                                                            `${purchaseOrder.purchase_request_id.placement_user.first_name}
                                                 ${purchaseOrder.purchase_request_id.placement_user.last_name}`}
                                                    </p>
                                                </div>

                                                <div className="col-md-3 mt-1">
                                                    <b>Fecha de Colocación:</b>
                                                    <p>
                                                        {purchaseOrder &&
                                                            moment(purchaseOrder.purchase_request_id.placement_date).format("DD-MM-YYYY")}
                                                    </p>
                                                </div>
                                            </>
                                        )}

                                        {purchaseOrder && purchaseOrder.purchase_request_id && purchaseOrder.purchase_request_id.status === "CO" && (
                                            <>
                                                <div className="col-md-2 mt-1">
                                                    <b>Confirmada Por:</b>
                                                    <p>
                                                        {purchaseOrder.purchase_request_id.confirmation_user &&
                                                            `${purchaseOrder.purchase_request_id.confirmation_user.first_name} ${purchaseOrder.purchase_request_id.confirmation_user.last_name}`}
                                                    </p>
                                                </div>

                                                <div className="col-md-3 mt-1">
                                                    <b>Fecha de Confirmación :</b>
                                                    <p>
                                                        {purchaseOrder.purchase_request_id.confirmation_date &&
                                                            moment(purchaseOrder.purchase_request_id.confirmation_date).format("DD-MM-YYYY")}
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <DataTable
                                        ref={(el) => {
                                            this.dt = el;
                                        }}
                                        value={purchaseOrder ? purchaseOrder.lines : []}
                                        dataKey="id"
                                        header={header}
                                        footer={footer}
                                        paginator
                                        rows={10}
                                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                        currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                                        rowsPerPageOptions={[10, 20, 50, 100]}
                                        removableSort
                                        emptyMessage="No se han encontrado registros..."
                                        selection={selectedRows}
                                        onSelectionChange={(e) => this.setState({ selectedRows: e.value.filter((s) => s.qtyDispatch > 0) })}
                                        expandedRows={expandedRows}
                                        onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                                        className="p-datatable-new-dispatches"
                                        rowExpansionTemplate={this.rowExpansionTemplate}
                                    >
                                        <Column selectionMode="multiple" style={{ width: "4%" }} />
                                        <Column expander style={{ width: "4%" }} />
                                        <Column
                                            field="code"
                                            header="Código"
                                            sortable
                                            filter
                                            style={{ width: "18%" }}
                                            filterPlaceholder="Todos"
                                            filterHeaderClassName="p-inputtext-sm"
                                        />
                                        <Column
                                            field="sku"
                                            header="SKU"
                                            sortable
                                            filter
                                            style={{ width: "18%" }}
                                            filterPlaceholder="Todos"
                                            filterHeaderClassName="p-inputtext-sm"
                                        />
                                        <Column
                                            field="name"
                                            header="Descripción"
                                            sortable
                                            filter
                                            style={{ width: "38%" }}
                                            filterMatchMode="contains"
                                            filterPlaceholder="Todos"
                                            filterHeaderClassName="p-inputtext-sm"
                                        />
                                        <Column
                                            field="qty"
                                            header="Cant."
                                            sortable
                                            filter
                                            style={{ width: "12%" }}
                                            filterType="number"
                                            filterPlaceholder="Todos"
                                            filterHeaderClassName="p-inputtext-sm"
                                        />
                                        <Column
                                            field="dispatched"
                                            header="Despachado"
                                            sortable
                                            filter
                                            style={{ width: "12%" }}
                                            filterType="number"
                                            filterPlaceholder="Todos"
                                            filterHeaderClassName="p-inputtext-sm"
                                        />
                                        <Column
                                            field="status"
                                            header="Estado"
                                            sortable
                                            filter
                                            style={{ width: "12%" }}
                                            body={this.buttonTemplate}
                                            filterElement={statusFilter}
                                        />
                                    </DataTable>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {open && (
                    <Modal isOpen={open} toggle={() => !loading && this.setState({ open: false })} centered size="xl">
                        <ModalHeader toggle={() => !loading && this.setState({ open: false })}>{"Nuevo Despacho"}</ModalHeader>
                        <ModalBody>
                            <Row form>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="dispatchDate">Fecha Despacho</Label>
                                        <Input
                                            type="date"
                                            id="dispatchDate"
                                            value={dispatchDate}
                                            placeholder="DD/MM/AAAA"
                                            onChange={(e) => this.setState({ dispatchDate: e.target.value })}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="arrivalDate">Fecha Llegada</Label>
                                        <Input
                                            type="date"
                                            id="arrivalDate"
                                            value={arrivalDate}
                                            placeholder="DD/MM/AAAA"
                                            min={this.today}
                                            onChange={(e) => this.setState({ arrivalDate: e.target.value })}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={12}>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th width="20%">Código</th>
                                                <th width="20%">Pedido</th>
                                                <th width="20%">Despachado</th>
                                                <th width="20%">A Despachar</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {selectedRows.map((e, i) => {
                                                const tr = (
                                                    <tr key={i}>
                                                        <td>{e.code}</td>
                                                        <td>{e.qty}</td>
                                                        <td>{e.dispatched}</td>
                                                        <td>
                                                            <InputNumber
                                                                disabled={loading}
                                                                max={e.qty - e.dispatched}
                                                                min={0}
                                                                value={e.qtyDispatch}
                                                                onValueChange={(e) => this.changeQtys(i, e.target.value)}
                                                                showButtons
                                                                buttonLayout="horizontal"
                                                                decrementButtonClassName="p-button-secondary"
                                                                incrementButtonClassName="p-button-info"
                                                                incrementButtonIcon="pi pi-plus"
                                                                decrementButtonIcon="pi pi-minus"
                                                                size={5}
                                                            />
                                                        </td>
                                                    </tr>
                                                );

                                                if (e.qty - e.dispatched > 0) {
                                                    return tr;
                                                }
                                                return null;
                                            })}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" disabled={loading} onClick={() => this.setState({ open: false })}>
                                <i className="fa fa-ban mr-2" />
                                Cancelar
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => this.createDispatches()}
                                className="ml-3"
                                disabled={loading || !dispatchDate || !arrivalDate || selectedRows.length < 1}
                            >
                                <i className={`${loading ? "fas fa-cog fa-spin" : "fas fa-save"} mr-2`} /> Guardar
                            </Button>
                        </ModalFooter>
                    </Modal>
                )}
                <Modal isOpen={open2} toggle={() => !loading && this.setState({ open2: false })} centered size="md">
                    <ModalHeader toggle={() => !loading && this.setState({ open2: false })}>
                        {mountOrder ? "Montar Orden " : "Confirmar Orden"}
                    </ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col>
                                <FormGroup>
                                    <Label for="mountDate">Fecha</Label>
                                    <Input
                                        type="date"
                                        id="mountate"
                                        placeholder="DD/MM/AAAA"
                                        min={this.today}
                                        value={mountDate}
                                        onChange={(e) => this.setState({ mountDate: e.target.value })}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" disabled={loading} onClick={() => this.setState({ open2: false })}>
                            <i className="fa fa-ban mr-2" />
                            Cancelar
                        </Button>

                        <Button
                            color="primary"
                            onClick={() => this.mountOrConfirOrder(purchaseOrder.purchase_request_id && purchaseOrder.purchase_request_id.id)}
                        >
                            <i className={`${loading ? "fas fa-cog fa-spin" : "fas fa-save"} mr-2`} />
                            Guardar
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(newDispatch);
