// react
import React, { useState } from 'react';
import InputNumber from '../shared/InputNumber';

export default function UpdateQtty(props) {
    const [value, setValue] = useState(props.value)
    const { max, value: val, d } = props;
    const handleChange = (val) => {
        const { onChange } = props;
        onChange(val);
        setValue(val);
    };

    return (
        (!d && max === val) ? val : <InputNumber {...props} value={value} onChange={handleChange} />
    );
}