import { useState } from "react";
import fs from "file-saver";
import Swal from "sweetalert2";
import { Button } from "reactstrap";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import InventoryTakeProduct from "./InventoryTakeProduct";
import { CreateItem, UpdateItem } from "../../../../../store/directusSdk";
import { GenerateFormatInventoryClient } from "../../../../shared/utilities";
import moment from "moment";

export default function TableItemsInventory({
    isAdmin,
    items,
    loading,
    setLoading,
    setItems,
    type,
    update,
    company,
    excelProcut,
    excelMovements,
    currentUser,
}) {
    const [openTakeModal, setOpenTakeModal] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);

    const takeQtysIds = (id, qty) => {
        const newItems = [...items];
        const i = newItems.findIndex((item) => item.id === id);
        if (i >= 0) {
            newItems[i].takeQty = qty;
            setItems(newItems);
        }
    };

    const generateFormat = () => {
        GenerateFormatInventoryClient()
            .then((response) => {
                const blob = new Blob([response]);
                fs.saveAs(blob, `${"Formato_de_inventario_de_cliente".toUpperCase()}.xlsx`);
            })
            .catch((error) => {});
    };

    const buttonTemplate = (row) => (
        <div className="col-md-5" style={{ display: "flex" }}>
            {isAdmin && (
                <>
                    <Button color="danger" onClick={() => deleteItems(row)}>
                        <i className={`fas fa-trash`}></i>
                    </Button>
                </>
            )}
        </div>
    );

    const deleteItems = (row) => {
        if (row.csv) {
            setItems((value) => value.filter((v) => v.id !== row.id));
            return;
        }

        Swal.fire({
            title: `Eliminar Ítem de inventario`,
            text: `¿Desea eliminar código ${row.code} de este inventario?`,
            icon: "warning",
            confirmButtonColor: "#0059BD",
            cancelButtonColor: "#3d464d",
            buttons: true,
            dangerMode: true,
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: "Aceptar",
            preConfirm: () => {
                return UpdateItem("cus_inventory_items", row.id, { status: "delete" })
                    .then(() => {
                        update();
                        CreateItem("cus_inventory_movements", {
                            type: "D",
                            user_id: currentUser.id,
                            qty: row.stock,
                            note: "Se Eliminó el producto",
                            retirement_date: moment().format("YYYY-MM-DD"),
                            line_id: row.id,
                        });
                    })
                    .catch((error) => toast.error(`Error!, ${error.message}`));
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: "success",
                    title: `Se ha Eliminado el Producto`,
                });
            }
        });
    };

    const header = (
        <div className="row">
            {isAdmin ? (
                <>
                    <h4 className={"col-md-6"}>Lista de Productos</h4>
                    <div className="col-md-6" style={{ display: "flex", justifyContent: "end" }}>
                        {["U", "S"].includes(type) && (
                            <Button color="success" className="mr-3" onClick={() => excelProcut()}>
                                <i className=" fas fa-file-excel mr-2" />
                                Productos
                            </Button>
                        )}
                        {["U"].includes(type) && (
                            <div>
                                <Button color="success" onClick={() => excelMovements()}>
                                    <i className="fas fa-file-excel mr-2" />
                                    Movimientos
                                </Button>
                                <Button color="success" className="ml-3" onClick={() => generateFormat()}>
                                    <i className=" fas fa-file-excel mr-2" />
                                    Formato
                                </Button>
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <div className="col-md-12" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        {" "}
                        <h4>Lista de Productos</h4>
                    </div>

                    <div>
                        {" "}
                        {["U", "S"].includes(type) && (
                            <Button color="info" onClick={() => excelProcut()}>
                                <i className=" fas fa-file-excel mr-2" />
                                Productos
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );

    const footer = (
        <div>
            {["U", "S"].includes(type) && isAdmin && (
                <Button
                    color="primary"
                    disabled={selectedProducts.length < 1 || loading}
                    onClick={() => {
                        selectedProducts.forEach((product) => {
                            takeQtysIds(product.id, product.stock);
                        });
                        setOpenTakeModal(true);
                    }}
                >
                    <i className={`${loading ? "fas fa-cog fa-spin" : "fas fa-shopping-basket"} mr-2`}></i>
                    Tomar materiales
                </Button>
            )}
        </div>
    );
    useEffect(() => {}, [items]);
    return (
        <>
            <DataTable
                value={items}
                size="small"
                dataKey="id"
                header={header}
                footer={footer}
                paginator
                loading={loading}
                rows={10}
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                rowsPerPageOptions={[10, 20, 50, 100]}
                removableSort
                selection={selectedProducts}
                onSelectionChange={(e) => setSelectedProducts(e.value.filter((s) => s.stock > 0 && !s.csv))}
                emptyMessage="No se han encontrado registros..."
                className="p-datatable-new-dispatches"
                selectionMode={isAdmin ? "checkbox" : "null"}
            >
                {isAdmin && <Column selectionMode="multiple" headerStyle={{ width: "3%" }}></Column>}
                <Column
                    field="code"
                    header="Código"
                    headerStyle={{ width: "10%" }}
                    sortable
                    filter
                    filterPlaceholder="Todos"
                    filterHeaderClassName="p-inputtext-sm"
                />
                <Column
                    field="sku"
                    header="SKU"
                    headerStyle={{ width: "10%" }}
                    sortable
                    filter
                    filterPlaceholder="Todos"
                    filterHeaderClassName="p-inputtext-sm"
                />
                <Column
                    field="name"
                    headerStyle={{ width: "40%" }}
                    header="Descripción"
                    sortable
                    filter
                    filterMatchMode="contains"
                    filterPlaceholder="Todos"
                    filterHeaderClassName="p-inputtext-sm"
                />
                <Column
                    field="commited"
                    headerStyle={{ width: "10%" }}
                    header="Comprometido"
                    sortable
                    filter
                    filterType="number"
                    filterPlaceholder="Todos"
                    filterHeaderClassName="p-inputtext-sm"
                />
                <Column
                    field="stock"
                    headerStyle={{ width: "10%" }}
                    header="Cantidad"
                    sortable
                    filter
                    filterType="number"
                    filterPlaceholder="Todos"
                    filterHeaderClassName="p-inputtext-sm"
                />
                {isAdmin && <Column header="Acciones" style={{ width: "8%" }} body={buttonTemplate} />}
            </DataTable>
            {openTakeModal && (
                <InventoryTakeProduct
                    resetTop={update}
                    update={update}
                    company={company}
                    open={openTakeModal}
                    takeQty={takeQtysIds}
                    setOpen={setOpenTakeModal}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                />
            )}
        </>
    );
}
