// react
import "../../loadingTable.css";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { MDBDataTableV5 } from "mdbreact";
import AsyncSelect from "react-select/async";
import GetProductsCodesCSV from "./ImportCsvExternalInventory";
import Swal from "sweetalert2";
import {
    getExternalInventory,
    newExternalInventory,
    getExternalInventories,
    updateExternalInventory,
    deleteInventory,
    searchExternalProducsByCodes,
    searchProductsSuppliersForCode,
} from "../../../../store/directusSdk";
import PageHeader from "../../../shared/PageHeader";
import ListInventoriCsvSurplus from "../../ListInventoriCsvSurplus";
import ImportCsvExternalInventory from "./ImportCsvExternalInventoryica";
import ListInventoriCsv from "./ListInventoriCsv";
import ErrorListInventoriCsv from "./ErrorListInventoriCsv";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, UncontrolledCollapse, Col, Row, Table } from "reactstrap";

export function InventoryManagementSurplus(props) {
    const { currentUser } = props;
    const [timeDelivery, setTimeDelivery] = useState("");
    const [productsErrors, setProductsErrors] = useState([]);
    const [optionSelected, setOptionSelected] = useState(null);
    const [loading2, setLoading2] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [pseudonym, setPseudonym] = useState("");
    const [loading, setLoading] = useState(false);
    const [inventory, setInventory] = useState([]);
    const [products, setProducts] = useState([]);
    const [update, setUpdate] = useState(false);
    const [open, setOpen] = useState(false);
    const [typeModal, setTypeModal] = useState(null);
    const [open2, setOpen2] = useState(false);
    const [codes, setCodes] = useState([]);
    const [type, setType] = useState("IS");
    const [name, setName] = useState("");
    const [mode, setMode] = useState("A");
    const isAdmin = ["Administrator", "Admin ICA"].includes(currentUser.role.name);

    const promiseOptions = (inputValue) => searchProductsSuppliersForCode(inputValue);

    /*Función enc1argada de solicitar los inventarios externos a la API. */
    const getInventories = () => {
        getExternalInventories()
            .then((inventory) => {
                const inv = inventory.map((e) => {
                    e.actions = (
                        <>
                            <Button
                                outline
                                color="info"
                                title="Ver Detalles"
                                onClick={() => {
                                    setOpen(true);
                                    setTypeModal("D");
                                    setOptionSelected(e.id);
                                    getInventory(e.id);
                                }}
                            >
                                <i className="fas fa-search" />
                            </Button>
                            {isAdmin && (
                                <Button
                                    className="ml-1"
                                    outline
                                    color="danger"
                                    title="Eliminar"
                                    onClick={() => {
                                        deleteInventoryica(e);
                                    }}
                                >
                                    <i className="fa fa-trash" />
                                </Button>
                            )}
                        </>
                    );
                    return e;
                });
                setInventory(inv);
            })
            .catch((error) => toast.error("¡Error!, " + error.message))
            .finally(() => setLoading(false));
    };

    /*Función encargada de buscar todos los datos en la API, relacionados con el inventario seleccionado. */
    const getInventory = (id) => {
        setLoading1(true);
        id &&
            getExternalInventory(id)
                .then((data) => {
                    setMode("U");
                    setOptionSelected(id);
                    setProducts(data.items);
                    setName(data.name);
                    setPseudonym(data.pseudonym);
                    setTimeDelivery(parseInt(data.time_delivery));
                })
                .catch((error) => toast.error("¡Error!, " + error.message))

                .finally(() => setLoading1(false));
    };

    const ExcelFormatSearch = () => {
        const csvContent = "sku\n";
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", `${"Formato de búsqueda".toUpperCase()}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const getProductsByCodes = () => {
        const cod = codes.map((code) => code.value);
        searchExternalProducsByCodes({ codes: cod })
            .then(setProducts)
            .catch((error) => toast.error("¡Error!, " + error.message))
            .finally(() => setLoading(false));
    };

    const deleteInventoryica = (e) => {
        Swal.fire({
            title: "Eliminar Inventario",
            text: `¿Está seguro que desea eliminar el inventario ${e.name}, con ${e.products} productos que tiene.`,
            icon: "warning",
            confirmButtonColor: "#0059BD",
            cancelButtonColor: "#3d464d",
            buttons: true,
            dangerMode: true,
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: "Eliminar",
            preConfirm: () => {
                return deleteInventory({ id: e.id })
                    .then(() => getInventories())
                    .catch((error) => Swal.showValidationMessage(`Request failed: ${error}`))
                    .finally(() => setLoading2(false));
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {          
            if (result.isConfirmed) {
                Swal.fire({
                    icon: "success",
                    title: "El inventario se ha eliminado exitosamente",
                });
            }
        });
    };

    useEffect(() => {
        setLoading(true);
        getInventories();
    }, []);

    const breadcrumb = [
        { title: "Home", url: "/" },
        { title: "Inventarios", url: "/inventarios" },
        { title: "Gestion De Inventarios ICA", url: "/inventarios/gestion-de-inventarios-ica" },
        { title: "Inventarios Proveedores", url: "" },
    ];

    const dataTable = {
        columns: [
            { label: "Nº", field: "id", width: 90 },
            { label: "Nombre", field: "name", width: 200 },
            { label: "Tiempo de Entrega", field: "time", width: 180 },
            { label: "Productos en el inventario", field: "products", width: 180 },
            { label: "Última Actualización", field: "timeAction", width: 180 },
            { label: "Acciones", field: "actions", width: 80 },
        ],
        rows: inventory,
    };

    const resetState = () => {
        setProducts([]);
        setProductsErrors([]);
        setName("");
        setTimeDelivery("");
        setUpdate(false);
        setPseudonym("");
    };

    const createInventory = () => {
        if (!name) return toast.error("Falta el nombre ");
        if (!timeDelivery) return toast.error("Falta el tiempo de entrega");
        if (!pseudonym) return toast.error("Indique un seudónimo");
        if (!products.length > 0) return toast.error("Falta productos");

        const newInventory = {};
        const items = products.map((i) => ({ sku: i.code_manufacture, qty: i.qty }));
        newInventory.name = name;
        newInventory.timeDelivery = timeDelivery;
        newInventory.items = items;
        newInventory.pseudonym = pseudonym;
        newInventory.type = type;

        Swal.fire({
            title: mode === "U" ? "Actualizar inventario" : "Crear inventario",
            text: mode === "U" ? "¿Está seguro que desea actualizar este inventario?" : "¿Está seguro que desea crear este inventario?",
            icon: "warning",
            confirmButtonColor: "#0059BD",
            cancelButtonColor: "#3d464d",
            buttons: true,
            dangerMode: true,
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: mode === "U" ? "Actualizar" : "Guardar",
            preConfirm: () => {
                if (mode === "U") {
                    newInventory.id = optionSelected;
                    setLoading2(true);
                    return updateExternalInventory(newInventory)
                        .then(() => {
                            toast.success("El inventario se ha actualizado exitosamente");
                            setOpen(false);
                            resetState();
                            getInventories();
                        })
                        .catch((error) => {
                            Swal.showValidationMessage(`Request failed: ${error}`);
                        })
                        .finally(() => setLoading2(false));
                }

                return newExternalInventory(newInventory)
                    .then(() => {
                        toast.success("El inventario se ha registrado exitosamente");
                        setOpen(false);
                        resetState();
                        getInventories();
                    })
                    .catch((error) => {
                        Swal.showValidationMessage(`Request failed: ${error}`);
                    })
                    .finally(() => setLoading2(false));
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            console.log(result);
            if (result.isConfirmed) {
                Swal.fire({
                    icon: "success",
                    title: mode === "U" ? "El inventario se ha actualizado exitosamente" : "El inventario se ha registrado exitosamente",
                });
            }
        });
    };

    const loadingTable = {
        pointerEvents: "none",
        opacity: " 0.5",
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Módulo Almacen - Ingeniería y Controles Automatizados</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />
            <div className="container">
                <div className="card">
                    <div className="card-body">
                        <UncontrolledCollapse toggler="#toggler">
                            <div className="mb-2 mb-3">
                                <GetProductsCodesCSV LoadCodes={(codes) => setCodes(codes)} />
                            </div>
                        </UncontrolledCollapse>

                        <div className="row">
                            <div className="col-md-10">
                                {isAdmin && (
                                    <Button
                                        width="100%"
                                        color="info"
                                        className="mb-3"
                                        onClick={() => {
                                            setTypeModal(null);
                                            setOpen(true);
                                            setMode("A");
                                        }}
                                    >
                                        <i className={"fa fa-cubes mr-2"} />
                                        Nuevo &nbsp;
                                    </Button>
                                )}

                                <Button color="info" id="toggler" className="mb-3 ml-2 ">
                                <i className="fas fa-file-upload  mr-2" />  Importar
                                </Button>
                                <Button color="info" className="mb-3 ml-2" onClick={ExcelFormatSearch}>
                                    <i className="fas fa-file-download  mr-2" /> Formato
                                </Button>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-10">
                                <AsyncSelect
                                    isClearable
                                    cacheOptions
                                    loadOptions={promiseOptions}
                                    noOptionsMessage={() => "No se Encontraron registros..."}
                                    loadingMessage={() => "Buscando..."}
                                    placeholder="Códigos de productos..."
                                    value={codes}
                                    onChange={(e) => setCodes(e)}
                                    className="ms-md-auto"
                                    isMulti
                                />
                            </div>
                            <div className="col-md-2">
                                <Button
                                    color="primary"
                                    disabled={!codes.length > 0}
                                    onClick={() => {
                                        setTypeModal(null);
                                        setOptionSelected(5);
                                        getProductsByCodes();
                                        setOpen2(true);
                                        setMode("S");
                                    }}
                                >
                                    <i className="pi pi-search mr-2" />
                                    Buscar
                                </Button>
                            </div>
                        </div>
                        {loading ? (
                            <>
                                <div className="div-loading" style={{ width: 300 }} />
                                <br />
                                <br />
                            </>
                        ) : (
                            <div style={loading3 ? loadingTable : { width: "100%" }}>
                                <MDBDataTableV5
                                    entriesOptions={[25, 50, 75, 100]}
                                    entries={10}
                                    pagesAmount={4}
                                    data={dataTable}
                                    pagingTop
                                    searchBottom={false}
                                    entriesLabel="Mostar"
                                    searchLabel="Buscar"
                                    infoLabel={["Viendo", "al", "de", "registros"]}
                                    paginationLabel={["Anterior", "Siguiente"]}
                                    noRecordsFoundLabel="No se han encontrado registros..."
                                />
                            </div>
                        )}
                    </div>
                </div>

                {open && (
                    <Modal isOpen={open} centered size="lg" style={{ maxWidth: "1600px", width: "100%" }} zIndex="1000">
                        <ModalHeader
                            toggle={() => {
                                if (!loading1 && !loading2) {
                                    setOpen(false);
                                    resetState();
                                }
                            }}
                        >
                            {`Inventario ${name}`}
                        </ModalHeader>
                        <ModalBody>
                            {isAdmin ? (
                                <div className="row ml-5 mb-3">
                                    <div className="col-md-5">
                                        <FormGroup>
                                            <Label for="guide">Nombre:</Label>
                                            <Input type="text" id="code" disabled={loading2} value={name} onChange={(e) => setName(e.target.value)} />
                                        </FormGroup>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <FormGroup>
                                                    <Label for="invoice">Alias:</Label>
                                                    <Input
                                                        type="text"
                                                        id="time"
                                                        disabled={loading2}
                                                        value={pseudonym}
                                                        onChange={(e) => setPseudonym(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup>
                                                    <Label for="invoice">Tiempo de Entrega(Días):</Label>
                                                    <Input
                                                        id="time"
                                                        disabled={loading2}
                                                        type="number"
                                                        value={timeDelivery}
                                                        onChange={(e) => setTimeDelivery(e.target.value)}
                                                        onInput={(e) => {
                                                            const reg = /[A-Za-z@$?¡\-_*/+=!"·.)%&(`´]/g;
                                                            const str = e.target.value;
                                                            const newStr = str.split(reg);
                                                            return (e.target.value = newStr.join("").slice(0, 7));
                                                        }}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-7">
                                    <ImportCsvExternalInventory
                                            uploadTask={(e) => console.log(e)}
                                            update={getInventory}
                                            selected={optionSelected}
                                            setProducts={setProducts}
                                            products ={products}
                                            setProductsErrors={setProductsErrors}
                                            loading={setLoading1}
                                            setUpdate={setUpdate}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="card mb-1" style={{ width: "60%", marginLeft: "20%" }}>
                                        <div className="row ml-2" style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                {" "}
                                                <div className="font-weight-bold">Nombre:&nbsp;</div>
                                                {name}
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                {" "}
                                                <div className="font-weight-bold">Alias:&nbsp;</div>
                                                {pseudonym}
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                {" "}
                                                <div className="font-weight-bold">Tiempo de entrega:&nbsp;</div>
                                                {timeDelivery}&nbsp;Dias
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className="row">
                                {isAdmin ? (
                                    <>
                                        <div className="col-md-8 ">
                                            <ListInventoriCsv products={products} loading={loading1} loading2={loading2} />
                                        </div>
                                        <div className="col-md-4 ">
                                            <ErrorListInventoriCsv productsErrors={productsErrors} loading={loading1} loading2={loading2} />
                                        </div>
                                    </>
                                ) : (
                                    <div className="col-md-12">
                                        <ListInventoriCsv products={products} loading={loading1} loading2={loading2} />
                                    </div>
                                )}
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                color="secondary"
                                disabled={loading1 || loading2}
                                onClick={() => {
                                    setOpen(false);
                                    resetState();
                                }}
                            >
                                <i className="fa fa-ban mr-2" />
                                Cancelar
                            </Button>
                            {isAdmin && (
                                <Button
                                    color="primary"
                                    disabled={loading1 || loading2 || !name || !timeDelivery || !(products.length > 0) || (mode === "U" && !update)}
                                    onClick={createInventory}
                                >
                                    <i
                                        className={`${
                                            loading1 || loading2 ? "fas fa-cog fa-spin" : mode === "A" ? "fas fa-save" : "fas fa-edit"
                                        } mr-2`}
                                    />
                                    {mode === "A" ? "Guardar" : "Actualizar"}
                                </Button>
                            )}
                        </ModalFooter>
                    </Modal>
                )}

                {open2 && (
                    <Modal isOpen={open2} centered size="lg" style={{ maxWidth: "1600px", width: "100%" }} zIndex="1000">
                        <ModalHeader
                            toggle={() => {
                                if (!loading1 && !loading2) {
                                    setOpen2(false);
                                    resetState();
                                }
                            }}
                        >
                            Listado de productos encontrados.
                        </ModalHeader>
                        <ModalBody>
                            <div className="row mt-5">
                                <div className="col-md-12 ">
                                    {loading1 ? (
                                        <div>
                                            <div className="div-loading " />
                                            <br />
                                            <br />
                                        </div>
                                    ) : (
                                        <ListInventoriCsvSurplus products={products} loading={loading1} loading2={loading2} />
                                    )}
                                </div>
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            {" "}
                            <>
                                <Button
                                    color="secondary"
                                    disabled={loading1 || loading2}
                                    onClick={() => {
                                        setOpen2(false);
                                        resetState();
                                    }}
                                >
                                    <i className="fa fa-ban mr-2" />
                                    Cancelar
                                </Button>
                                {["D", "N"].includes(typeModal) && (
                                    <Button
                                        color="primary"
                                        //   disabled={loading1 || !name || !timeDelivery || !(products.length > 0) || (mode === "U" && !update)}
                                        onClick={createInventory}
                                    >
                                        <i
                                            className={`${
                                                loading1 || loading2 ? "fas fa-cog fa-spin" : mode === "A" ? "fas fa-save" : "fas fa-edit"
                                            } mr-2`}
                                        />
                                        {mode === "A" ? "Guardar" : "Actualizar"}
                                    </Button>
                                )}{" "}
                            </>
                        </ModalFooter>
                    </Modal>
                )}
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryManagementSurplus);
