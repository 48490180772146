// react
import React, { useEffect, useState } from "react";
// third-party
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { MDBDataTableV5 } from "mdbreact";
import { Button } from "reactstrap";
import Switch from "react-switch";
import { getEngprojects } from "../../store/directusSdk";
import PageHeader from "../shared/PageHeader";
import { statusBadge } from "../engprojects/utils";

export default function EngprojectList() {
    const [projectsICA, setProjectsICA] = useState([]);
    const [projectsAP, setProjectsAP] = useState([]);
    const [checkedICA, setChekedICA] = useState(false);
    const [checkedAP, setChekedAP] = useState(false);
    const [loadingICA, setLoadingICA] = useState(true);
    const [loadingAP, setLoadingAP] = useState(true);

    const GetProjects = (company, status, setProject, setLoading) =>
        getEngprojects(company, status)
            .then((engprojects) => {
                const proyectos = engprojects.map((e) => ({
                    ...e,
                    estado: statusBadge(e.status),
                    actions: (
                        <Link to={`/almacen/proyectos-de-ingeneria/${e.id}`}>
                            <Button outline color="info" title="Gestionar Proyecto">
                                <i className="fa fa-edit" />
                            </Button>
                        </Link>
                    ),
                }));
                setProject(proyectos);
            })
            .catch((error) => toast.error("¡Error!, " + error.message))
            .finally(() => setLoading(false));

    useEffect(() => {
        GetProjects("ICA", ["A"], setProjectsICA, setLoadingICA);
        GetProjects("AP", ["A"], setProjectsAP, setLoadingAP);
    }, []);

    const handleChecked = (checked, company, setChecked, setProjects, setLoading) => {
        setChecked(checked);
        setLoading(true);
        if (checked) {
            return GetProjects(company, ["finished", "C", "P"], setProjects, setLoading);
        }
        return GetProjects(company, ["A"], setProjects, setLoading);
    };
    const breadcrumb = [
        { title: "Home", url: "/" },
        { title: "Almacén", url: "/almacen" },
        { title: "Listado de Proyectos", url: "" },
    ];
    const dataICA = {
        columns: [
            { label: "Código", field: "code", width: 200 },
            { label: "Proyecto", field: "name", width: 200 },
            { label: "Encargado", field: "engineer", width: 180 },
            { label: "Cliente", field: "client", width: 180 },
            { label: "Estado", field: "estado", width: 100 },
            { label: "Acciones", field: "actions", width: 80 },
        ],
        rows: projectsICA,
    };
    const dataAP = {
        columns: [
            { label: "Código", field: "code", width: 200 },
            { label: "Proyecto", field: "name", width: 200 },
            { label: "Encargado", field: "engineer", width: 180 },
            { label: "Cliente", field: "client", width: 180 },
            { label: "Estado", field: "estado", width: 100 },
            { label: "Acciones", field: "actions", width: 80 },
        ],
        rows: projectsAP,
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Módulo Proyectos de Ingeneria - Ingeniería y Controles Automatizados</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />
            <div className="container">
                <div className="card">
                    <div className="card-body">
                        <h5 className="mt-3">Proyectos ICA</h5>
                        <label htmlFor="chkICA">Otros Proyectos:</label>
                        <Switch
                            className="ml-2 mt-1"
                            height={20}
                            width={40}
                            id="chkICA"
                            onChange={(checked) => handleChecked(checked, "ICA", setChekedICA, setProjectsICA, setLoadingICA)}
                            checked={checkedICA}
                        />

                        {loadingICA ? (
                            <div className="div-loading" style={{ height: 300 }} />
                        ) : (
                            <MDBDataTableV5
                                hover
                                entriesOptions={[10, 25, 50]}
                                entries={10}
                                pagesAmount={4}
                                data={dataICA}
                                pagingTop
                                searchTop
                                searchBottom={false}
                                entriesLabel="Mostar"
                                searchLabel="Buscar"
                                infoLabel={["Viendo", "al", "de", "registros"]}
                                paginationLabel={["Anterior", "Siguiente"]}
                                noRecordsFoundLabel="No se han encontrado registros..."
                            />
                        )}
                        <div className="card-divider" />
                        <h5 className="mt-3">Proyectos AP</h5>
                        <label htmlFor="chkAP">Otros Proyectos:</label>
                        <Switch
                            className="ml-2 mt-1"
                            height={20}
                            width={40}
                            id="chkAP"
                            onChange={(checked) => handleChecked(checked, "AP", setChekedAP, setProjectsAP, setLoadingAP)}
                            checked={checkedAP}
                        />

                        {loadingAP ? (
                            <div className="div-loading" style={{ height: 300 }} />
                        ) : (
                            <MDBDataTableV5
                                hover
                                entriesOptions={[10, 25, 50]}
                                entries={10}
                                pagesAmount={4}
                                data={dataAP}
                                pagingTop
                                searchTop
                                searchBottom={false}
                                entriesLabel="Mostar"
                                searchLabel="Buscar"
                                infoLabel={["Viendo", "al", "de", "registros"]}
                                paginationLabel={["Anterior", "Siguiente"]}
                                noRecordsFoundLabel="No se han encontrado registros..."
                            />
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
