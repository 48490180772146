// react
import React from 'react';
import { connect } from 'react-redux';

// third-party
import { Helmet } from 'react-helmet-async';
import Menu from '../shared/Menu';

function Finance({ menu }) {
    const engMenu = menu.find((e) => e.title === 'Compras');
    console.log(engMenu);
    return (
        <React.Fragment>
            <Helmet>
                <title> Finanzas - Ingeniería y Controles Automatizados</title>
            </Helmet>

            <Menu menu={engMenu.submenu.menu} title={engMenu.title} />

        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    menu: state.currentUser.menu
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Finance);
