// third-party
import fs from "file-saver";
import moment from "moment";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputNumber } from "primereact/inputnumber";
import {
    GenerateExcelInventorySurplusById,
    GenerateExcelInventorySurplusMovements,
    GenerateFormatExcelInventorySurplus,
} from "../../../shared/utilities";
import { takeProductsInventoriesSurplusAp, deleteItemInventorySurplusAp, getInventoriesMovementsSurplusAp } from "../../../../store/directusSdk";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Col, Row, Table, Badge } from "reactstrap";

export default function ListInventoriCsvSurplus({ user, inventory, products, setDisabled, setUpdate, setProducts, loading, loading2, type, update }) {
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [retirementDate, setRetirementDate] = useState(moment().format("YYYY-MM-DD"));
    const [open3, setOpen3] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [note, setNote] = useState("");

    const isAdmin = ["Administrator", "Admin AP"].includes(user.role.name);

    const getMovementsInventory = () => {
        getInventoriesMovementsSurplusAp({ id: inventory.id })
            .then((data) =>
                GenerateExcelInventorySurplusMovements(data)
                    .then((response) => {
                        const blob = new Blob([response]);
                        fs.saveAs(blob, `Movimientos del inventario ${inventory.name}`.toUpperCase() + ".xlsx");
                    })
                    .catch((error) => {})
            )
            .catch((error) => console.log(error));
    };

    const generateFormat = () => {
        GenerateFormatExcelInventorySurplus()
            .then((response) => {
                const blob = new Blob([response]);
                fs.saveAs(blob, `${"Formato_inventario_de_sobrantes".toUpperCase()}.xlsx`);
            })
            .catch((error) => {});
    };

    const changeQtysIds = (id, qty) => {
        const newItems = [...products];
        const i = newItems.findIndex((item) => item.id === id);
        if (i >= 0) {
            newItems[i].qty = qty;
            setProducts([...newItems]);
            setDisabled(true);
        }
    };

    const takeQtysIds = (id, qty) => {
        const newItems = [...products];
        const i = newItems.findIndex((item) => item.id === id);
        if (i >= 0) {
            newItems[i].takeQty = qty;
            setProducts([...newItems]);
            setDisabled(true);
        }
    };

    const changePricesIds = (id, price) => {
        const newItems = [...products];
        const i = newItems.findIndex((item) => item.id === id);
        if (i >= 0) {
            newItems[i].price = price;
            setProducts([...newItems]);
            setDisabled(true);
        }
    };

    const exportExcel = () => {
        GenerateExcelInventorySurplusById({ inventory, products })
            .then((response) => {
                const blob = new Blob([response]);
                fs.saveAs(
                    blob,
                    `${
                        inventory && inventory.name ? `productos del inventario ${inventory.name}`.toUpperCase() : "busqueda-sobrantes".toUpperCase()
                    } .xlsx`
                );
            })
            .catch((error) => {});
    };

    const buttonTemplate = (row) => (
        <div className="col-md-5" style={{ display: "flex" }}>
            {["U", "A"].includes(type) && (
                <>
                    <Button color="danger" onClick={() => deleteItems(row)}>
                        <i className={`fas fa-trash`}></i>
                    </Button>
                    {row.csv && (
                        <Button color="info" className="ml-1" title="Nuevo Producto">
                            <i className="fas fa-file-medical"></i>
                        </Button>
                    )}
                </>
            )}
        </div>
    );

    const deleteItems = (row) => {
        if (type === "A" || (type === "U" && row.csv)) {
            const newProductsList = [...products.filter((product) => product.id !== row.id)];
            setProducts(newProductsList);
            return;
        }

        Swal.fire({
            title: `Eliminar Ítem de inventario`,
            text: `¿Desea eliminar código ${row.code} de este inventario?`,
            icon: "warning",
            confirmButtonColor: "#0059BD",
            cancelButtonColor: "#3d464d",
            buttons: true,
            dangerMode: true,
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: "Aceptar",
            preConfirm: () => {
                return deleteItemInventorySurplusAp({ id: row.id })
                    .then(async () => {
                        await update();
                        toast.success(`¡El producto con el código:${row.code} se ha borrado de la lista!`);
                    })
                    .catch((error) => swal("Error!", error.message, "error"))
                    .finally(() => setLoading3(false));
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: "success",
                    title: `Se ha Eliminado el Producto`,
                });
            }
        });
    };

    const takeProductInventories = () => {
        if (!retirementDate) toast.error("Se debe indicar una fecha de retiro.");
        if (!note) toast.error("Se debe indicar una nota de retiro.");

        const materials = selectedProducts.reduce((acc, current) => {
            if (current.qty > 0) {
                acc.push({
                    id: current.id,
                    qty: current.takeQty,
                    code: current.code,
                    note,
                    retirementDate,
                });
                return acc;
            }
            return acc;
        }, []);

        if (!materials.length) toast.error("No hay materiales seleccionados.");

        Swal.fire({
            title: "Retirar productos.",
            text: `¿Está seguro que desea tomar estos productos?. `,
            icon: "warning",
            confirmButtonColor: "#0059BD",
            cancelButtonColor: "#3d464d",
            buttons: true,
            dangerMode: true,
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: "Tomar",
            preConfirm: () => {
                return takeProductsInventoriesSurplusAp({ items: materials })
                    .then(() => {
                        update();
                        setOpen3(false);
                        setSelectedProducts([]);
                        setNote("");
                    })
                    .catch((error) => swal("Error!", error.message, "error"))
                    .finally(() => setLoading3(false));
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: "success",
                    title: `¡Se han retirado los productos!`,
                });
            }
        });
    };

    const header = (
        <div className="row">
            {["Administrator", "Admin AP"].includes(user.role.name) ? (
                <>
                    <h4 className={"col-md-6"}>Lista de Productos</h4>
                    <div className="col-md-6" style={{ display: "flex", justifyContent: "end" }}>
                        {["U", "S"].includes(type) && (
                            <Button color="success" className="mr-3" onClick={() => exportExcel()}>
                                <i className=" fas fa-file-excel mr-2" />
                                Productos
                            </Button>
                        )}
                        {["U"].includes(type) && (
                            <>
                                <Button color="success" onClick={() => getMovementsInventory()}>
                                    <i className="fas fa-file-excel mr-2" />
                                    Movimientos
                                </Button>
                                <Button color="success" className="ml-3" onClick={() => generateFormat()}>
                                    <i className=" fas fa-file-excel mr-2" />
                                    Formato
                                </Button>
                            </>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <div className="col-md-12" style={{ display: "flex", justifyContent: "space-between" }}>
                        <h4>Lista de Productos</h4>
                        {["U", "S"].includes(type) && (
                            <Button color="info" className="mr-3" onClick={() => exportExcel()}>
                                <i className=" fas fa-file-excel mr-2" />
                                Productos
                            </Button>
                        )}
                    </div>
                </>
            )}
        </div>
    );

    const footer = (
        <div>
            {["U", "S"].includes(type) && ["Administrator", "Admin AP"].includes(user.role.name) && (
                <Button
                    color="primary"
                    disabled={selectedProducts.length < 1 || loading}
                    onClick={() => {
                        selectedProducts.forEach((product) => {
                            takeQtysIds(product.id, product.qty);
                        });
                        setOpen3(true);
                    }}
                >
                    <i className={`${loading ? "fas fa-cog fa-spin" : "fas fa-shopping-basket"} mr-2`}></i>
                    Tomar materiales
                </Button>
            )}
        </div>
    );

    return (
        <>
            <DataTable
                value={products}
                size="small"
                dataKey="id"
                header={header}
                footer={footer}
                paginator
                loading={loading3}
                rows={10}
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                rowsPerPageOptions={[10, 20, 50, 100]}
                removableSort
                emptyMessage="No se han encontrado registros..."
                onSelectionChange={(e) => setSelectedProducts(e.value.filter((s) => s.qty > 0 && !s.csv))}
                selection={selectedProducts}
                className="p-datatable-new-dispatches"
                selectionMode={isAdmin ? "checkbox" : "null"}
            >
                {isAdmin && <Column selectionMode="multiple" headerStyle={{ width: "3%" }} />}
                <Column
                    field="code"
                    header="Código"
                    headerStyle={{ width: "10%" }}
                    sortable
                    filter
                    filterPlaceholder="Todos"
                    filterHeaderClassName="p-inputtext-sm"
                />
                <Column
                    field="code_manufacture"
                    header="SKU"
                    headerStyle={{ width: "10%" }}
                    sortable
                    filter
                    filterPlaceholder="Todos"
                    filterHeaderClassName="p-inputtext-sm"
                />
                <Column
                    field="name"
                    headerStyle={{ width: "30%" }}
                    header="Descripción"
                    sortable
                    filter
                    filterMatchMode="contains"
                    filterPlaceholder="Todos"
                    filterHeaderClassName="p-inputtext-sm"
                    body={(row) => (
                        <div>
                            {row.name.substring(0, 50)}
                            {`${row.name.length > 50 ? "..." : ""}`}{" "}
                        </div>
                    )}
                />

                {type === "S" && (
                    <Column
                        header="Inventario"
                        sortable
                        filter
                        filterMatchMode="contains"
                        filterPlaceholder="Todos"
                        filterHeaderClassName="p-inputtext-sm"
                        field="inventoryName"
                        style={{ width: "10%" }}
                    />
                )}

                {type === "S" && (
                    <Column
                        field="qty"
                        headerStyle={{ width: "7%" }}
                        header="Cantidad"
                        sortable
                        filter
                        filterType="number"
                        filterPlaceholder="Todos"
                        filterHeaderClassName="p-inputtext-sm"
                    />
                )}

                {type === "S" && (
                    <Column
                        field="price"
                        headerStyle={{ width: "7%" }}
                        header="Precio"
                        sortable
                        filter
                        filterType="number"
                        filterPlaceholder="Todos"
                        filterHeaderClassName="p-inputtext-sm"
                    />
                )}

                {!isAdmin && (
                    <Column
                        field="qty"
                        headerStyle={{ width: "7%" }}
                        header="Cantidad"
                        sortable
                        filter
                        filterType="number"
                        filterPlaceholder="Todos"
                        filterHeaderClassName="p-inputtext-sm"
                    />
                )}

                {!isAdmin && (
                    <Column
                        field="price"
                        headerStyle={{ width: "7%" }}
                        header="Precio"
                        sortable
                        filter
                        filterType="number"
                        filterPlaceholder="Todos"
                        filterHeaderClassName="p-inputtext-sm"
                        style={{ with: "100%" }}
                    />
                )}

                {type === "S" && (
                    <Column
                        field="ubication"
                        header="Ubicación"
                        sortable
                        headerStyle={{ width: "14%" }}
                        filter
                        filterType="number"
                        filterPlaceholder="Todos"
                        filterHeaderClassName="p-inputtext-sm"
                    />
                )}

                {["U", "A"].includes(type) && isAdmin && (
                    <Column
                        field="qty"
                        headerStyle={{ width: "7%" }}
                        header="Cantidad"
                        sortable
                        filter
                        filterType="number"
                        filterPlaceholder="Todos"
                        filterHeaderClassName="p-inputtext-sm"
                        body={(row) => (
                            <InputNumber
                                disabled={loading}
                                min={0}
                                value={row.qty}
                                onValueChange={(e) => changeQtysIds(row.id, e.target.value)}
                                style={{ width: "100%" }}
                                size={5}
                            />
                        )}
                    />
                )}

                {["U", "A"].includes(type) && isAdmin && (
                    <Column
                        field="price"
                        headerStyle={{ width: "7%" }}
                        header="Precio"
                        sortable
                        filter
                        filterType="number"
                        filterPlaceholder="Todos"
                        filterHeaderClassName="p-inputtext-sm"
                        body={(row) => (
                            <InputNumber
                                id="horizontal"
                                onValueChange={(e) => changePricesIds(row.id, e.target.value)}
                                min={0}
                                locale="en-US"
                                mode="currency"
                                currency="USD"
                                value={row.price}
                                buttonLayout="horizontal"
                                step={0.25}
                                size={10}
                                style={{ width: "100%" }}
                            />
                        )}
                    />
                )}

                {["U", "A"].includes(type) && isAdmin && <Column header="Acciones" style={{ width: "8%" }} body={buttonTemplate} />}

                {type === "S" && (
                    <Column
                        header="Inventario"
                        sortable
                        filter
                        filterMatchMode="contains"
                        filterPlaceholder="Todos"
                        filterHeaderClassName="p-inputtext-sm"
                        field="inventoryName"
                        style={{ width: "10%" }}
                    />
                )}
            </DataTable>

            {open3 && (
                <Modal isOpen={open3} toggle={() => !loading && setOpen3(false)} centered size="xl">
                    <ModalHeader toggle={() => !loading && setOpen3(false)}>{"Listado de productos seleccionados."}</ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="retirementDateLabel">Fecha Retiro</Label>
                                    <Input
                                        autoComplete="off"
                                        type="date"
                                        id="retirementDate"
                                        value={retirementDate}
                                        placeholder="DD/MM/AAAA"
                                        onChange={(e) => setRetirementDate(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={8}>
                                <FormGroup>
                                    <Label for="noteLabel">Nota de retiro</Label>
                                    <Input
                                        autoComplete="off"
                                        type="text"
                                        id="note"
                                        value={note}
                                        placeholder="Inserte una nota de retiro…"
                                        onChange={(e) => setNote(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th width="20%">Código</th>
                                            {type === "S" && <th width="20%">Inventario</th>}
                                            <th width="20%">Cantidad</th>
                                            <th width="20%">Cantidad A Tomar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedProducts.map((item, i) => (
                                            <tr key={i}>
                                                <td>{item.code}</td>
                                                {type === "S" && <td>{item.inventoryName}</td>}
                                                <td>{item.qty}</td>
                                                <td>
                                                    <InputNumber
                                                        disabled={loading}
                                                        max={item.qty}
                                                        min={1}
                                                        value={item.qty}
                                                        onValueChange={(e) => takeQtysIds(item.id, e.target.value)}
                                                        style={{ width: "50%" }}
                                                        size={5}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" disabled={loading} onClick={() => setOpen3(false)}>
                            <i className="fa fa-ban mr-2" />
                            Cancelar
                        </Button>
                        <Button
                            color="primary"
                            onClick={() => takeProductInventories()}
                            className="ml-3"
                            disabled={loading || !retirementDate || selectedProducts.length < 1 || !note.length}
                        >
                            <i className={`${loading ? "fas fa-cog fa-spin" : "fas fa-shopping-cart"} mr-2`} /> Tomar
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
}
