import { toast } from "react-toastify";
import { CSVReader } from "react-papaparse";
import React, { Component, Fragment } from "react";
import { getProductsForSKU } from "../../store/directusSdk";

import * as XLSX from "xlsx/xlsx.mjs";

export default class ImportCsvShared extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            header: [],
        };
    }
    //Se encarga de hacer el procesamiento de la información en caso de que el archivo sea un CSV
    caseCsc = (data) => {
        const { loading } = this.props;

        const header = data.shift();
        this.setState({ errors: [], header, data });
        const { setProducts, setProductsErrors, setUpdate } = this.props;

        const sku = header.data.findIndex((e) => e === "SKU" || e === "sku");
        if (sku === -1) {
            return toast.error("¡Error, Debe indicar la columna SKU!");
        }
        const qty = header.data.findIndex((e) => e === "QTY" || e === "qty");
        if (qty === -1) {
            return toast.error("¡Error, Debe indicar la columna QTY!");
        }
        loading(true);
        const products = [];
        const codes = [];
        //se recorren los productos dentro de el csv
        data.forEach(({ data }) => {
            // Se excluyen todas aquellas líneas que su cantidad sea menos a 1
            if (data[qty] > 0) {
                products.push({ code: data[sku], qty: data[qty] });
            }
        });

        /*Se recorren para buscar los códigos repetidos y sumar sus cantidades en caso de encontrar alguno. */
        const productlistClean = products.reduce((acc, current) => {
            const x = acc.find((item) => item.code === current.code);

            if (!x) {
                const items = [];
                items.push({ code: current.code, qty: current.qty });
                return acc.concat(items);
            } else {
                return acc.map((element) => {
                    if (element.code === x.code) {
                        element.push({
                            ...current,
                        });
                        return {
                            ...element,
                            qty: current.qty + element.qty,
                        };
                    }
                    return element;
                });
            }
        }, []);
        console.log(productlistClean.length)

        getProductsForSKU("products", codes).then((productos) => {
            const prods = [];
            const errors = [];
            productlistClean.forEach((p, i) => {
                const comp = productos.find((e) => e.code_manufacture === p.code);
                if (comp) {
                    prods.push({ ...p, ...comp });
                } else {
                    errors.push({ code: p.code, error: "¡Código No Encontrado!" });
                }
            });

            setProducts(prods);
            setProductsErrors(errors);
            loading(false);
            setUpdate(true);
            return toast.info("Procesando productos...");
        });
    };

    //Se encarga de hacer el procesamiento de la información en caso de que el archivo sea un XLSX
    caseXlsx = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const dataxlsx = XLSX.utils.sheet_to_json(worksheet);

                if (!dataxlsx.length > 0) {
                    return toast.error(`El archivo ${file.name}, no contiene información o está errónea.`);
                }

                const sku = Object.keys(dataxlsx[0]).findIndex((e) => e === "SKU" || e === "sku");
                if (sku === -1) {
                    return toast.error("¡Error, Debe indicar la columna SKU!");
                }

                const qty = Object.keys(dataxlsx[0]).findIndex((e) => e === "QTY" || e === "qty");

                if (qty === -1) {
                    return toast.error("¡Error, Debe indicar la columna QTY!");
                }
                const { loading } = this.props;
                loading(true);

                //ser obtiene el nober de la key del objeto en caso e que se consiga alguna
                const qtyKeyName = Object.keys(dataxlsx[0])[qty];
                const skuKeyName = Object.keys(dataxlsx[0])[sku];

                const { setProducts, setProductsErrors, setUpdate } = this.props;

                const products = [];
                const codes = [];
                //se recorren los productos dentro de el csv
                dataxlsx.forEach((data) => {
                    // Se excluyen todas aquellas líneas que su cantidad sea menos a 1
                    if (data[qtyKeyName] > 0) {
                        products.push({ code: data[skuKeyName], qty: data[qtyKeyName] });
                    }
                });

                /*Se recorren para buscar los códigos repetidos y sumar sus cantidades en caso de encontrar alguno. */
                const productlistClean = products.reduce((acc, current) => {
                    const x = acc.find((item) => item.code === current.code);
                    
                
                
                    if (!x) {
                        const items = [];
                        items.push({ code: current.code, qty: current.qty });
                        return acc.concat(items);
                    } else {
                        return acc.map((element) => {
                            if (element.code === x.code) {
                                element.push({
                                    ...current,
                                });
                                return {
                                    ...element,
                                    qty: current.qty + element.qty,
                                };
                            }
                            return element;
                        });
                    }
                }, []);


                getProductsForSKU("products", codes).then(async (productos) => {
                    const prods = [];
                    const errors = [];
                    productlistClean.forEach((p, i) => {
                        const comp = productos.find((e) => e.code_manufacture === p.code);
                        if (comp) {
                            prods.push({ ...p, ...comp });
                        } else {
                            errors.push({ code: p.code, error: "¡Código No Encontrado!" });
                        }
                    });

                    await setProducts(prods);
                    await setProductsErrors(errors);
                    await setUpdate(true);
                    loading(false);
                });
            };
            reader.readAsArrayBuffer(file);

            return toast.info("Procesando productos...");
        }
    };

    handleOnDrop = (data, file) => {
        switch (file.type) {
            case "application/vnd.ms-excel":
                return this.caseCsc(data);
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                return this.caseXlsx(file);
            case "text/csv":
                return this.caseCsc(data);
            default:
                return toast.error("Formato No Aceptado");
        }
    };

    handleOnError = (err) => {
        console.log(err);
    };

    handleOnRemoveFile = () => {
        const { setProducts, setProductsErrors } = this.props;
        setProducts([]);
        setProductsErrors([]);
        return this.setState({ errors: [] });
    };

    render() {
        return (
            <Fragment>
                <div style={{ width: "93%", height: 200 }}>
                    <CSVReader
                        onDrop={this.handleOnDrop}
                        onError={this.handleOnError}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={this.handleOnRemoveFile}
                    >
                        <span>Arrastra y Suelta el archivo CSV aquí o haz clic para cargarlo.</span>
                    </CSVReader>
                </div>
            </Fragment>
        );
    }
}
