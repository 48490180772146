import React, { Component } from "react";
import { CSVReader } from "react-papaparse";
import { toast } from "react-toastify";

export default class ImportCsv extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            header: [],
        };
    }

    handleOnDrop = (data) => {
        const { loadItems } = this.props;
        const items = [];
        const errors = [];

        data.forEach((e) => {
            items.push({ code: e.data['Número de artículo'], whs: e.data['Almacén'], cant: e.data['Cantidad en almacén en fecha de recuento'] });
        });

        loadItems(items);

        return toast.info("Procesando productos...");
    };

    handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
    };

    handleOnRemoveFile = (data) => {
        return this.setState({ errors: [] });
    };

    render() {
        return (
            <div style={{ width: "100%", height: 200 }}>
                <CSVReader
                    onDrop={this.handleOnDrop}
                    onError={this.handleOnError}
                    addRemoveButton
                    removeButtonColor="#659cef"
                    onRemoveFile={this.handleOnRemoveFile}
                    config={{
                        delimiter: "",  // auto-detect
                        newline: "",  // auto-detect
                        quoteChar: '',
                        escapeChar: '',
                        header: true,
                        dynamicTyping: false,
                        preview: 0,
                        encoding: "",
                        worker: false,
                        skipEmptyLines: true,
                    }}
                >
                    <span>Arrastra y Suelta el archivo CSV aquí o haz clic para cargarlo.</span>
                </CSVReader>
            </div>
        );
    }
}
