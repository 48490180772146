import {
    CART_ADD_ITEM_AP,
    CART_REMOVE_ITEM_AP,
    CART_UPDATE_QUANTITIES_AP,
    CART_REMOVE_ALL_ITEMS_AP,
    CHANGE_PRICE_ITEM_AP,
    CART_LOAD_ALL_ITEMS_AP,
    RECALCULATE_PRICES_AP,
    CHANGE_COMMENTS_AP,
    CHANGE_CONTACT_AP,
    CHANGE_CONTACTS_AP,
    CHANGE_CUSTOMER_AP,
    CHANGE_TEXT_ITEM_AP,
    RECALCULATE_ITEM_PRICE_AP,
    CHANGE_INCITBMS_AP,
    CHANGE_QTY_AP,
} from './cartActionTypes';

function round(value, decimals = 2) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

export function calcSubtotal(items) {
    const subtotal = round(items.reduce((subtotal, item) => subtotal + item.total, 0));
    return subtotal;
}

function calcQuantity(items) {
    return items.reduce((quantity, item) => quantity + item.quantity, 0);
}

export function calcITBMS(items) {
    const subtotal = items.reduce((total, e) => {
        if (e.incItbms) {
            return total + e.total;
        }
        return total;
    }, 0);
    const itbms = round(subtotal * 0.07);
    return itbms;
}

export function calcTotal(subtotal, itbms) {
    const total = round(subtotal + itbms);
    return total;
}

function addItem(state, product, incItbms, quantity) {
    let newItems;
    let { lastItemId } = state;
    const price = round(product.price);

    lastItemId += 1;
    newItems = [...state.items, {
        id: lastItemId,
        product,
        price: price,
        total: round(price * quantity),
        quantity,
        incItbms,
    }];

    const subtotal = calcSubtotal(newItems);
    const itbms = calcITBMS(newItems);
    const total = calcTotal(subtotal, itbms);

    return {
        ...state,
        lastItemId,
        subtotal,
        itbms,
        total,
        items: newItems,
        quantity: calcQuantity(newItems),
    };
}

function removeItem(state, itemId) {
    const { items } = state;
    const newItems = items.filter((item) => item.id !== itemId);

    const subtotal = calcSubtotal(newItems);
    const itbms = calcITBMS(newItems);
    const total = calcTotal(subtotal, itbms);

    return {
        ...state,
        items: newItems,
        quantity: calcQuantity(newItems),
        subtotal,
        itbms,
        total,
    };
}

function updateQuantities(state, quantities) {
    let needUpdate = false;

    const newItems = state.items.map((item) => {
        const quantity = quantities.find((x) => x.itemId === item.id && x.value !== item.quantity);

        if (!quantity) {
            return item;
        }

        needUpdate = true;

        return {
            ...item,
            quantity: quantity.value,
            total: round(quantity.value * item.price),
        };
    });

    if (needUpdate) {
        const subtotal = calcSubtotal(newItems);
        const itbms = calcITBMS(newItems);
        const total = calcTotal(subtotal, itbms);

        return {
            ...state,
            items: newItems,
            quantity: calcQuantity(newItems),
            subtotal,
            itbms,
            total,
        };
    }

    return state;
}

function updatePrice(state, i, price) {
    price = round(price);
    state.items[i].price = price;
    state.items[i].total = round(state.items[i].quantity * price);

    const subtotal = calcSubtotal(state.items);
    const itbms = calcITBMS(state.items);
    const total = calcTotal(subtotal, itbms);
    return {
        ...state,
        subtotal,
        itbms,
        total,
    };
}

function RecalculatePrices(state, products, services) {
    const items = [...state.items];
    if (products.length > 0) {
        products.forEach((p) => {
            const i = items.findIndex((e) => e.product.id == p.id);
            if (i !== -1) {
                const price = round(p.price);
                items[i].price = price;
                items[i].total = round(price * state.items[i].quantity);
                items[i].product.price = price;
            }
        });
    }
    if (services.length > 0) {
        services.forEach((p) => {
            const i = items.findIndex((e) => e.product.id == p.id);
            if (i !== -1) {
                const price = round(p.price);
                items[i].price = price;
                items[i].total = round(price * state.items[i].quantity);
                items[i].product.price = price;
            }
        });
    }
    const subtotal = calcSubtotal(state.items);
    const itbms = calcITBMS(state.items);
    const total = calcTotal(subtotal, itbms);

    return {
        ...state,
        items,
        subtotal,
        itbms,
        total,
    };
}

function updateComments(state, comments) {
    return {
        ...state,
        comments,
    };

}
function updateCommentItem(state, index, comments) {
    const { items } = state;
    items[index].comments = comments;
    return {
        ...state,
        items,
    };

}

function recalculatePriceItem(state, index, oldFactor, newFactor) {
    if (index !== -1) {
        const { items } = state;
        const price = round(round(items[index].product.price / oldFactor) * newFactor.value);
        items[index].price = price;
        items[index].total = round(price * items[index].quantity);
        items[index].priceList = newFactor;

        const subtotal = calcSubtotal(items);
        const itbms = calcITBMS(items);
        const total = calcTotal(subtotal, itbms);

        return {
            ...state,
            items,
            subtotal,
            itbms,
            total,
        };
    }
    return state;
}

function recalculatePriceAllItem(state, customer, priceList) {
    const items = state.items.map((e) => {
        const price = round(round(e.product.price / state.priceList) * priceList);
        e.price = price;
        e.total = round(price * e.quantity);
        return e;
    });

    const subtotal = calcSubtotal(items);
    const itbms = calcITBMS(items);
    const total = calcTotal(subtotal, itbms);

    return {
        ...state,
        customer,
        priceList,
        items,
        subtotal,
        itbms,
        total,
    };
}

function editIncItbms(state, index, value) {
    if (index !== -1) {
        const { items } = state;
        items[index].incItbms = value;

        const subtotal = calcSubtotal(items);
        const itbms = calcITBMS(items);
        const total = calcTotal(subtotal, itbms);

        return {
            ...state,
            items,
            subtotal,
            itbms,
            total,
        };
    }
    return state;
}

function editQty(state, index, qty) {
    if (index !== -1) {
        const { items } = state;
        items[index].quantity = qty;
        items[index].total = round(qty * items[index].price);

        const subtotal = calcSubtotal(items);
        const itbms = calcITBMS(items);
        const total = calcTotal(subtotal, itbms);

        return {
            ...state,
            items,
            subtotal,
            itbms,
            total,
        };
    }
    return state;
}

const initialState = {
    lastItemId: 0,
    customer: null,
    priceList: 1.75,
    contact: null,
    contacts: [],
    comments: '',
    quantity: 0,
    items: [],
    subtotal: 0,
    itbms: 0,
    total: 0,
};

export default function cartAPReducer(state = initialState, action) {
    switch (action.type) {
        case CART_ADD_ITEM_AP:
            return addItem(state, action.product, action.incItbms, action.quantity);

        case CART_REMOVE_ITEM_AP:
            return removeItem(state, action.itemId);

        case CART_UPDATE_QUANTITIES_AP:
            return updateQuantities(state, action.quantities);

        case CHANGE_PRICE_ITEM_AP:
            return updatePrice(state, action.index, action.price);

        case CHANGE_COMMENTS_AP:
            return updateComments(state, action.comments);

        case RECALCULATE_PRICES_AP:
            return RecalculatePrices(state, action.products, action.services);

        case CART_REMOVE_ALL_ITEMS_AP:
            return initialState;

        case CART_LOAD_ALL_ITEMS_AP:
            return action.cart;

        case CHANGE_CONTACT_AP:
            return { ...state, contact: action.contact };

        case CHANGE_CONTACTS_AP:
            return { ...state, contacts: action.contacts };

        case CHANGE_CUSTOMER_AP:
            return recalculatePriceAllItem(state, action.customer, action.priceList);

        case CHANGE_TEXT_ITEM_AP:
            return updateCommentItem(state, action.index, action.text);

        case RECALCULATE_ITEM_PRICE_AP:
            return recalculatePriceItem(state, action.index, action.oldFactor, action.newFactor);

        case CHANGE_INCITBMS_AP:
            return editIncItbms(state, action.index, action.value);

        case CHANGE_QTY_AP:
            return editQty(state, action.index, action.qty);

        default:
            return state;
    }
}
